import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';

import { ExternalUsageRoutingModule } from './external-usage-routing.module';
import { TabsComponent } from './tabs/tabs.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ExternalUsageComponent } from './external-usage.component';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [
    TabsComponent,
    ExternalUsageComponent,
  ],
  imports: [
    CommonModule,
    ExternalUsageRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTabsModule,
    MatDialogModule,
    SharedModule
  ],
  exports: [
    CommonModule,
  ],

  providers: [],
})
export class ExternalUsageModule { }
