<div class="close-btn-wrapper">
  <div (click)="close()" class="icon close" id="close-btn"></div>
</div>

<div class="content">
  <h3>We need to know more about you</h3>

  <div class="text-wrapper">
    <p>In order to set up a recurring booking, you need to first complete your personal details in your profile settings.</p>
    <p>Note that the recurring slots we offer depend on your postcode.</p>
  </div>

  <a (click)="redirectToPersonalInfo()" class="btn secondary" id="redirect-btn">Complete personal details</a>
</div>
