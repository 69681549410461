<div class="banner">
  <div class="left-wrapper">
    <p class="label">Item(s)</p>
    <p class="count">-</p>
  </div>
  <img src="assets/icons/line-dashed-white.svg" class="line">
  
  <div class="right-wrapper">
    <p class="label">Total (incl. VAT & Discounts)</p>
    <h3 class="price">-</h3>
  </div>
</div>