<div class="instructions box">
    <a (click)="toggleShown()" class="toggle-btn" id="toggle-btn">
        <div *ngIf="isSummaryShown && instClear" class="heading summary-not-shown" id="summary-not-shown">
            <p><strong *ngIf="!isFormShown || instClear">+</strong> Add delivery instructions</p>
            <div class="icon arrow-down" [ngClass]="{'shown': isFormShown}"></div>
        </div>

        <div *ngIf="!isSummaryShown" class="heading summary-not-shown" id="summary-not-shown">
            <p><strong *ngIf="!isFormShown || instClear">+</strong> Add delivery instructions</p>
            <div *ngIf="isFormShown" (click)="clearInstruction()" class="icon bin"><mat-icon>delete</mat-icon></div>

            <div class="icon arrow-down" [ngClass]="{'shown': isFormShown}"></div>
        </div>

        <div *ngIf="isSummaryShown && !instClear" class="heading summary-shown" id="summary-shown">
            <div class="wrapper">
                <span>Pick up and deliver to</span>
                <p id="deliver-to">{{instructions.deliverToName}}</p>
            </div>
            <div class="wrapper" *ngIf="address.instructions.phoneNumber !== ''">
                <span>Phone number</span>
                <p id="phone-number">({{instructions.countryCode}}) {{instructions.phoneNumber}}</p>
            </div>
            <div *ngIf="instructions.other" class="wrapper others-instructions">
                <span>Other instructions</span>
                <p id="others-instructions">{{instructions.other}}</p>
            </div>
            <div class="icon arrow-down" [ngClass]="{'shown': isFormShown}"></div>
        </div>
    </a>

    <div *ngIf="hasAllData" class="more animation-slide-down" [ngClass]="{'shown': isFormShown}" id="more">
        <form [formGroup]="instructionsForm" (submit)="submitForm()">
            <div class="field-wrapper deliver-to">
                <p>Pick up and deliver to</p>

                <mat-form-field>
                    <mat-select formControlName="deliverToId" [(value)]="deliverToOptionSelected" disableOptionCentering>
                        <mat-option *ngFor="let option of deliverToOptions; let i = index" [value]="option.id">
                            {{option.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="error" *ngIf="getFormField('deliverToId').hasError('required') && instructionsForm.controls.deliverToId.touched">
                  Field is required
              </div>
            </div>

            <div class="field-wrapper postcode">
                <shared-country-code-picker (onPick)="setCountryCodeValue($event)" [countryCode]="code"></shared-country-code-picker>
            </div>

            <div class="field-wrapper custom-input-wrapper phone">
                <label>Their phone number</label>
                <input matinput type="text" formControlName="phoneNumber">
                <div class="error" *ngIf="getFormField('phoneNumber').hasError('required') && instructionsForm.controls.phoneNumber.touched">
                    Field is required
                </div>
                <div class="error" *ngIf="getFormField('phoneNumber').hasError('pattern') && instructionsForm.controls.phoneNumber.touched">
                    Phone number is invalid
                </div>
            </div>

            <div class="field-wrapper others-instructions">
                <p>Other instructions</p>
                <!-- <textarea formControlName="other" maxlength="150" (focusout)="submitForm()" matInput cdkTextareaAutosize placeholder="Ring the bell / knock, leave on doorstep ..."></textarea> -->
                <textarea formControlName="other" maxlength="150" matInput cdkTextareaAutosize placeholder="Ring the bell / knock, leave on doorstep ..."></textarea>
                <div class="counter">
                    <p>{{instructionsForm.controls.other.value.length}} / 150</p>
                </div>
            </div>

            <button class="btn primary absolute" [id]="saveAddressInstructions">Save</button>

            <!-- <button *ngIf="this.instructionsForm.touched" (click)="openInstructionsDialog()" class="btn secondary" id="delete-btn">Save Instructions</button> -->
        </form>
    </div>
</div>