<div  class="close-btn-wrapper">
    <div (click)="closeDialog()" class="icon close" id="close-btn"></div>
  </div>
  
  <div *ngIf="isPrivacyPolicy" class="content">
    <h3>We care about your privacy</h3>
  
    <div class="text-wrapper">
      <p class="ff-300">Here at BLANC, we respect your privacy and we are committed to protecting your personal data. </p>
      <p class="ff-300"> This privacy policy will inform you as to how we look after your personal data when you access our services and tell you about your privacy rights and how the law protects you.</p>
    </div>
    <a target="_blanc" href='https://blancliving.co/pages/privacy-policy' class="btn submit ">Read our Privacy Policy</a>
  </div>
  
  <div *ngIf="isTermsandConditions" class="content">
    <h3>Our Terms & Conditions</h3>
  
    <div class="text-wrapper">
      <p class="ff-300">By using our services you agree
        to be bound by our terms and conditions - please read them carefully before you submit your order to us.</p>
      <p class="ff-300"> These terms tell you who we are, how we will provide services to you, what to do if there is a problem and other important information.</p>
    </div>
    <a target="_blanc" href='https://blancliving.co/pages/terms-conditions' class="btn submit ">Read our Terms & Conditions</a>
  </div>