<div class="user-nav h-padding-x-2 v-padding">
  <div class="user-nav-item" routerLink="spot" routerLinkActive="active">
    Spot
  </div>
  <div class="user-nav-item" routerLink="schedules" routerLinkActive="active">
    Schedules
  </div>
  <div class="user-nav-item" routerLink="users" routerLinkActive="active">
    Users
  </div>
  <div class="user-nav-item" routerLink="orders" routerLinkActive="active">
    Orders
  </div>
  <div class="user-nav-item" routerLink="holidays" routerLinkActive="active">
    Holidays
  </div>
</div>

<div class="user-page">
  <router-outlet></router-outlet>
</div>