import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin.component';
import { SharedModule } from '../shared/shared.module';
import { ScheduleManagerComponent } from './schedule-manager/schedule-manager.component';
import { SpotManagerComponent } from './spot-manager/spot-manager.component';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { OrderSearchComponent } from './order-search/order-search.component';
import { UserSearchComponent } from './user-search/user-search.component';
import { HolidayManagerComponent } from './holiday-manager/holiday-manager.component';

@NgModule({
  declarations: [
    AdminComponent, 
    ScheduleManagerComponent, 
    SpotManagerComponent, 
    OrderSearchComponent, 
    UserSearchComponent, 
    HolidayManagerComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatPaginatorModule,
    AdminRoutingModule,
  ],
  exports: [
    CommonModule,
    AdminComponent,
    AdminRoutingModule
  ]
})
export class AdminModule { }
