import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceWrapperModel } from 'src/app/models/particle/service-wrapper.model';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { AlertService } from 'src/app/services/common/alert.service';
import { CommandService } from 'src/app/services/common/command.service';
import { StorageService } from 'src/app/services/common/storage.service';

@Component({
  selector: 'booking-wash-fold',
  templateUrl: './wash-fold.component.html',
  styleUrls: ['./wash-fold.component.scss']
})
export class WashFoldComponent implements OnInit {
  @Input() isMobile: boolean;
  @Input() serviceModel: ServiceWrapperModel = new ServiceWrapperModel();


  isEdited: boolean;
  SaveBtn : string;

  constructor(
    private router: Router,
    private commandService: CommandService,
    private storageService: StorageService
  ) {}

  ngOnInit(): void {
    var firstName = localStorage.getItem('firstName');
    let smsMarketing = localStorage.getItem('smsMarketing')
    if(smsMarketing){
      this.SaveBtn="sms-marketing-booking-redirectToSummary-editGarmentIns-save";
    }
    else if (firstName){
      this.SaveBtn="returning-user-booking-redirectToSummary-editGarmentIns-save";
    }else{
      this.SaveBtn="first-journey-booking-redirectToSummary-editGarmentIns-save";
    }
  }

  decreaseCount() {
    if (this.serviceModel.bagCount <= 0) {
      return;
    }

    this.serviceModel.bagCount--;
    this.isEdited = true;
  }

  increaseCount() {
    this.serviceModel.bagCount++;
    this.isEdited = true;
  }

  async save() {
    this.serviceModel.itemsCount = this.serviceModel.items.length;
    let GarmentInstructionsFull = this.storageService.getObject('GarmentInstructionsFull');
    if (GarmentInstructionsFull === undefined) {
      GarmentInstructionsFull = {
        models: {}
      };
    }

    GarmentInstructionsFull.models[this.serviceModel.parentItem.id] = this.serviceModel;
    this.storageService.saveObject('GarmentInstructionsFull', GarmentInstructionsFull);
    this.router.navigate(['/booking/services'])
  }
}
