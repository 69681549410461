import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddressComponent } from '../booking/address/address.component';
import { PaymentComponent } from '../booking/payment/payment.component';
import { ProvisionComponent } from '../delivery-only/provision/provision.component';
import { AuthGuardService as AuthGuard } from '../services/guards/auth-guard.service';

import { DeliveryOnlyComponent } from './delivery-only.component';
import { EntryComponent } from './entry/entry.component';
import { SlotComponent } from './slot/slot.component';
import { SummaryComponent } from './summary/summary.component';
import { ThankYouComponent } from './thank-you/thank-you.component';

const routes: Routes = [
  {
    path: '',
    component: DeliveryOnlyComponent,
    children: [
      {
        path: 'entry',
        component: EntryComponent
      },
      {
        path: 'provision',
        component: ProvisionComponent,
        canActivate: [ AuthGuard ]
      },
      {
        path: 'slot',
        component: SlotComponent,
        canActivate: [ AuthGuard ]
      },
      {
        path: 'summary',
        component: SummaryComponent,
        canActivate: [ AuthGuard ]
      },
      {
        path: 'thank-you',
        component: ThankYouComponent,
        canActivate: [ AuthGuard ]
      },
      {
        path: 'address',
        component: AddressComponent,
        canActivate: [ AuthGuard ]
      },
      {
        path: 'payment',
        component: PaymentComponent,
        canActivate: [ AuthGuard ]
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DeliveryOnlyRoutingModule { }
