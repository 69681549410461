<ng-template [ngIf]="isMobile" [ngIfElse]="isDesktop">
    <ng-container [ngSwitch]="step">
        <div *ngSwitchCase="stepsEnum.Pickup" id="delivery-not-active" class="delivery not-active box animation-fade-in">
            <div class="heading">
                <p>Please choose a delivery slot</p>
                <span>Our driver will come to your chosen address to deliver your clean items back.</span>
            </div>
        </div>

        <div *ngSwitchCase="stepsEnum.Delivery" id="delivery-not-selected" class="delivery not-selected box animation-fade-in">
            <div class="heading">
                <p>Please choose a delivery slot</p>
                <div class="info-message" id="infoMessageDelivery">
                    <img src="assets/icons/info-red.svg">
                    <p>If your order includes leather items, curtains or rugs, please allow 7 days between the pick up
                        date and the delivery date.</p>
                </div>
            </div>
            <booking-schedule [type]="slotType">
            </booking-schedule>
        </div>

        <div *ngSwitchCase="stepsEnum.SlotsSummary" id="delivery-selected" class="delivery selected box animation-fade-in">
            <div class="heading">
                <p>Your delivery slot </p>
                <div (click)="edit()" id="edit" class="btn href green">Edit</div>
                <div class="info-message" id="infoMessageSummary">
                    <img src="assets/icons/info-red.svg">
                    <p>If your order includes leather items, curtains or rugs, please allow 7 days between the pick up
                        date and the delivery date.</p>
                </div>
            </div>
            <div class="selected-slot">
                <p class="date">{{deliverySlot?.date | toMoment | dateFormat:dateFormat}}</p>
                <p class="hours">{{deliverySlot?.from | hourFormatPms}} - {{deliverySlot?.to | hourFormatPms}}</p>
            </div>
        </div>
    </ng-container>
</ng-template>


<ng-template #isDesktop>
    <div class="delivery box animation-fade-in" >
        <div class="heading" [ngClass]="{'disabled':step === stepsEnum.Pickup}">
            <p>Please choose a delivery slot</p>
            <span>Our driver will come to your chosen address to deliver your clean items back.</span>
            <div class="info-message" id="infoMessageDelivery">
                <img src="assets/icons/info-red.svg">
                <p>If your order includes leather items, curtains or rugs, please allow 7 days between the pick up
                    date
                    and the delivery date.</p>
            </div>
        </div>
        <booking-schedule [type]="slotType">
        </booking-schedule>
    </div>
</ng-template>