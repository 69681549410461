<div  class="close-btn-wrapper">
    <div (click)="closeDialog()" class="icon close" id="close-btn"></div>
  </div>
  
  <div  class="content">
    <h3>Want to save permanent authorisations for future orders?</h3>
  
    <div class="text-wrapper">
      <p class="ff-300">You can save permanent authorisations in your profile settings so they are taken into account for all future orders. </p>
      <p class="ff-300">If not, we will ask  for your authorisation every time.
    </p>
    </div>
    <button class="btn secondary-neutral " (click)="SaveOrderOnly()">Go to authorisations settings
    </button>
  </div>

 
  
