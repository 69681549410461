import { Component, OnInit, Input } from '@angular/core';

import { OrderStatusEnum } from 'src/app/models/order/enums/order-status.enum';
import { MockOrderDetails } from 'src/app/models/order/mocks/mock-order-details.model';
import { OrderDetails } from 'src/app/models/order/order-details.model';

@Component({
  selector: 'order-details-banner-wrapper',
  templateUrl: './banner-wrapper.component.html',
  styleUrls: ['./banner-wrapper.component.scss']
})
export class BannerWrapperComponent implements OnInit {
  
  status: string
  @Input() order: any;
  
  constructor() { }

  ngOnInit(): void {
    this.status = this.order.Status;
  }
}
