<div *ngIf="!hasAllData" class="spinner-wrapper">
    <shared-spinner></shared-spinner>
</div>

<div *ngIf="hasAllData" class="content address animation-fade-in">
    <shared-back-button></shared-back-button>
    <booking-header *ngIf="!store" title="address" imgPath="assets/icons/location-pin-green.svg"></booking-header>

    <form [formGroup]="addressForm" (submit)="form()">
        <div *ngIf="!store"  class="heading">
            <h2>Enter your full address for pickup & delivery </h2>
            <p *ngIf="!isAuthenticated">Already have a BLANC online account?</p>
            <p *ngIf="!isAuthenticated">Please
                <a (click)="redirectToSignPage()" class="sign-in-btn">Login</a>
            </p>
        </div>
        <div *ngIf="store"  class="heading-store heading" style="margin-bottom: 30px;">
            <booking-progress-bar-mobile [step]=step></booking-progress-bar-mobile>

            <h1>Your address</h1>
            <p class="text-store">Please enter your address for future collection & deliveries. You can return to this later in app.</p>
        </div>

        <div class="field-wrapper custom-input-wrapper address">
            <label>Address</label>
            <input matInput type="text" formControlName="addressLine1" placeholder="Address line 1">
            <div class="error" *ngIf="getFormField('addressLine1').hasError('maxlength')">
                Could not be more than 50 symbols
            </div>
            <input matInput type="text" formControlName="addressLine2" placeholder="Address line 2">
            <div class="error" *ngIf="getFormField('addressLine2').hasError('maxlength')">
                Could not be more than 50 symbols
            </div>
            <input matInput type="text" formControlName="town" placeholder="City">
            <div class="error" *ngIf="getFormField('town').hasError('maxlength')">
                Could not be more than 50 symbols
            </div>
        </div>

        <div class="field-wrapper custom-input-wrapper postcode">
            <input matInput type="text" formControlName="postalCode" placeholder="Postcode">
            <div class="error" *ngIf="getFormField('postalCode').hasError('required')">
                Post code is required
            </div>
            <div class="error" *ngIf="getFormField('postalCode').hasError('maxlength')">
                Could not be more than 50 symbols
            </div>
            <div class="error" *ngIf="getFormField('postalCode').hasError('unsupported')">
                Code is unsupported or invalid
            </div>
        </div>

        <div class="instructions">
            <div *ngIf="!hasInstructions" class="summary-not-shown" [id]="saveInst">
                <a *ngIf="store" (click)="savaAddress()" [routerLink]="['/booking/instructions']" [queryParams]="{'redirect': redirect,store:store}" class="btn secondary instructions">Pick up & delivery instructions</a>
                <a *ngIf="!store" (click)="savaAddress()" [routerLink]="['/booking/instructions']" [queryParams]="{'redirect': redirect}"
                    class="btn secondary instructions">Pick up & delivery instructions</a>
            </div>

            <div *ngIf="hasInstructions" class="summary-shown" id="has-instructions">
                <div class="heading">
                    <h2>Instructions</h2>
                    <a routerLink="/booking/instructions" [queryParams]="{'redirect': redirect}" class="btn href green" [id]="orderMobileEditAddressEdit"><span>Edit</span></a>

                </div>
                <div class="instructions-summary box">
                    <div class="wrapper">
                        <span>Pick up and deliver to</span>
                        <p id="deliver-to">{{address.instructions.deliverToName}}</p>
                    </div>
                    <div class="wrapper" *ngIf="address.instructions.phoneNumber !== ''">
                        <span>Their phone number</span>
                        <p id="phone-number">({{address.instructions.countryCode}}) {{address.instructions.phoneNumber}}</p>
                    </div>
                    <div *ngIf="address.instructions.other !== ''" class="wrapper">
                        <span>Other instructions</span>
                        <p id="phone-number">{{address.instructions.other}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="bloc-skip">
            <span class="skip" *ngIf="store" (click)="skipStep()">Skip this step</span>
        </div>
        
        <button [disabled]="!isValid || isLoading" type="submit" class="btn primary bottom-fixed with-arrow input btn-resize" [id]="orderMobileEditAddressNext" (click)="savaAddress()">
            <ng-container *ngIf="!isLoading" >Next</ng-container>
            <shared-spinner *ngIf="isLoading" color="white"></shared-spinner>
        </button>
    </form>

    <!-- <div class="donate">
    <div class="media">
      <img src="assets/icons/donate-blue.svg">
    </div>

    <a (click)="openDonateDialog()" id="open-dialog-btn">
      <div *ngIf="address.donateBags === 0" class="no-bags" id="no-bags">
        <p>Donate clothes </p>
        <p>to charity</p>
        <img src="assets/icons/right-arrow-blue.svg">
      </div>

      <div *ngIf="address.donateBags > 0" class="has-bags" id="has-bags">
        <p><strong>{{address.donateBags}}</strong> bag(s) of clothes</p>
        <p>to charity</p>
      </div>
    </a>
  </div> -->
</div>