import { StorageService } from './../../../services/common/storage.service';
import { ParticleService } from 'src/app/services/particle/particle.service';
import { CardService } from 'src/app/services/user/card.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SummaryBase } from '../summary.base';
import { CommandService } from 'src/app/services/common/command.service';
import { BlancCommandService } from 'src/app/services/common/blanc-command.service';
import { AuthService } from 'src/app/services/user/auth.service';
import { AlertService } from 'src/app/services/common/alert.service';
import { BookingStepsService } from 'src/app/services/booking/booking-steps.service';
import { AccountSettingService } from 'src/app/services/user/account-setting.service';
import { CookieService } from 'src/app/services/common/cookie.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'booking-summary-desktop',
  templateUrl: './summary-desktop.component.html',
  styleUrls: ['./../summary.component.scss', './summary-desktop.component.scss']
})
export class SummaryDesktopComponent extends SummaryBase implements OnInit {
  isShown = false;
  constructor(
    accountSettingService: AccountSettingService,
    auth: AuthService,
    router: Router,
    commandService: CommandService,
    alertService: AlertService,
    bookingStepsService: BookingStepsService,
    authService: AuthService,
    cookieService: CookieService,
    http:HttpClient,
    public cardService: CardService,
    protected particleService: ParticleService,
    protected storageService: StorageService,
    protected blancCommandService: BlancCommandService,

  ) {
    super(accountSettingService , auth, router,cookieService, commandService, alertService, bookingStepsService, authService, cardService, particleService, storageService, blancCommandService,http)
  }

  async ngOnInit() {
    await super.ngOnInit();
  }
  toggleShown() {
    this.isShown = !this.isShown;
  }
 

 
}