import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

import { LogoButtonEnum } from 'src/app/models/shared/enums/logo-button.enum';
import { AuthService } from 'src/app/services/user/auth.service';

@Component({
  selector: 'shared-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  logoTypeEnum: typeof LogoButtonEnum = LogoButtonEnum;
  isMobile: boolean;
  isAuthenticated: boolean;
  store: string;
  menuDeskSignOut: string = "menu-desk-sign-out";

  @Input() isBooking?: boolean;
  @Input() signBtnRedirectUrl: string;

  constructor(
    private deviceService: DeviceDetectorService,
    private authService: AuthService,
    protected route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
    this.isAuthenticated = this.authService.isLoggedIn();
    this.store = this.route.snapshot.queryParams.store;

  }

  redirectToSignIn() {
    if (this.signBtnRedirectUrl) {
      this.router.navigate(['/sign-in'], { queryParams: { redirect: this.signBtnRedirectUrl }});
      return;
    }

    this.router.navigate(['/sign-in']);
  }

  signOut(): void {
    this.authService.signout();
    localStorage.clear();
  }
}
