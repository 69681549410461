<div class="content service-mobile animation-fade-in">
    <div class="wrapper-service">
        <shared-back-button></shared-back-button>
        <div class="header">
            <h3>{{serviceModel.parentItem.name}}</h3>
            <h3>instructions</h3>
            <div class="description-details">
                <p>{{serviceModel.parentItem.parsedValue.descriptionDetails}}</p>
            </div>
        </div>
        <div *ngIf="serviceModel.parentItem.parsedValue.viewType === 0">
            <div class="cards">
                <div *ngIf="serviceModel.items.length > 0; then thenBlock; else elseBlock"></div>
                <ng-template #thenBlock>
                    <div class="item" *ngFor="let item of serviceModel.items">
                        <span class="title">{{item.selected.nameInSpot}}</span>
                        <div (click)="removeItem(item)" class="icon close"></div>
                        <div class="break"></div>
                        <div class="brand-search">
                            <booking-brand-search-mobile [item]="item" (onSearch)="storeBrand($event)">
                            </booking-brand-search-mobile>
                        </div>
                        <div class="break"></div>
                        <div *ngIf="item.instructions.length > 0; then thenBlock; else elseBlock"></div>
                        <ng-template #thenBlock>
                            <div class="instructions">
                                <div class="instruction" *ngFor="let instruction of item.instructions">
                                    <span>{{instruction.name}}</span>
                                    <div (click)="removeInstruction(item,instruction)" class="icon close close-btn">
                                    </div>
                                </div>
                                <div (click)="viewInstructions(item)" class="instruction add-btn">
                                    <span>+</span>
                                </div>
                            </div>
                            <div class="break"></div>

               
                            <div class="additional-instructions"
                                *ngFor="let instruction of item.additionalInstructions">
                                <mat-checkbox *ngIf ="instruction.name != 'Additional comments'" [(ngModel)]="instruction.parsedValue.isChecked" type="checkbox">
                                    <span>{{instruction.name}}</span>
                                </mat-checkbox>
                                <span *ngIf ="instruction.name == 'Additional comments'">{{instruction.name}}</span>
                                <div  class="mat-form-field-infix " *ngIf="instruction.parsedValue.hasText">
                                    <input  matInput type="text" placeholder="Any additional instructions?" [maxlength]="additionalInstructionsMaxLength"  [(ngModel)]="instruction.parsedValue.text"> 
                                    <!-- <div class="counter">
                                        <p>{{instruction.parsedValue.text.length}} / {{additionalInstructionsMaxLength}}</p>
                                    </div> -->
                                </div>
                            </div>
                        </ng-template>
                        <ng-template #elseBlock>
                            <booking-give-instruction [viewInstructions]="viewInstructions" [item]="item">
                            </booking-give-instruction>
                        </ng-template>
                    </div>
                    <div class="add-item" (click)="viewItems()">
                        <span>Add another item</span>
                    </div>
                </ng-template>
                <ng-template #elseBlock>
                    <div class="empty-item" (click)="viewItems()">
                        <p>Describe item</p>
                        <div class="empty-item-type">
                            <span>Type of item</span>
                        </div>
                    </div>
                </ng-template>
            </div>
            <button class="btn primary bottom-fixed with-arrow btn-resize" (click)="save()" [id]="saveInst">Save instructions</button>
        </div>
        <div *ngIf="serviceModel.parentItem.parsedValue.viewType === 1">
            <booking-wash-fold [serviceModel]="serviceModel" [isMobile]="true"></booking-wash-fold>
        </div>
    </div>
</div>
