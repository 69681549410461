<div *ngIf="isLoading" class="spinner-wrapper">
    <shared-spinner></shared-spinner>
</div>

<form *ngIf="!isLoading" [formGroup]="personalInfoForm" (ngSubmit)="onFormSubmit()">

    <div class="field-wrapper custom-input-wrapper">
        <label>First name</label>
        <input matInput type="text" formControlName="FirstName">
        <div class="error" *ngIf="getFormField('FirstName').hasError('required')">
            First name is required
        </div>
        <div class="error" *ngIf="getFormField('FirstName').hasError('maxlength')">
            First name can't be more than 30 symbols
        </div>
    </div>
    <div class="field-wrapper custom-input-wrapper">
        <label>Last name</label>
        <input matInput type="text" formControlName="LastName">
        <div class="error" *ngIf="getFormField('LastName').hasError('required')">
            Last name is required
        </div>
        <div class="error" *ngIf="getFormField('LastName').hasError('maxlength')">
            Last name can't be more than 30 symbols
        </div>
    </div>

    <div class="field-wrapper phone">
        <div class="country-code">
            <shared-country-code-picker (onPick)="setCountryCodeValue($event)" [countryCode]="countryCode">
            </shared-country-code-picker>
        </div>

        <div class="custom-input-wrapper phone-number">
            <label>Mobile phone</label>
            <input matInput type="tel" formControlName="Phone" class="phone-input" inputmode="numeric">
            <div class="error" *ngIf="getFormField('Phone').hasError('required')">
                Mobile phone is required
            </div>
            <div class="error" *ngIf="getFormField('Phone').hasError('pattern')">
                Mobile phone is invalid
            </div>
        </div>
    </div>

    <div class="field-wrapper custom-input-wrapper address">
        <label>Address</label>
        <input matInput type="text" formControlName="address1" placeholder="Address line 1">
        <div class="error" *ngIf="getFormField('address1').hasError('required')">
            Address is required
        </div>
        <div class="error" *ngIf="getFormField('address1').hasError('maxlength')">
            Could not be more than 50 symbols
        </div>
        <input matInput type="text" formControlName="address2" placeholder="Address line 2">
        <div class="error" *ngIf="getFormField('address2').hasError('maxlength')">
            Could not be more than 50 symbols
        </div>
        <input matInput type="text" formControlName="town" placeholder="City">
        <div class="error" *ngIf="getFormField('town').hasError('maxlength')">
            Could not be more than 50 symbols
        </div>
    </div>

    <div class="field-wrapper custom-input-wrapper postal-code">
        <label>Post code</label>
        <input matInput type="text" formControlName="postcode" placeholder="Postcode">
        <div class="error" *ngIf="getFormField('postcode').hasError('required')">
            Post code is required
        </div>
        <div class="error" *ngIf="getFormField('postcode').hasError('maxlength')">
            Could not be more than 50 symbols
        </div>
        <div class="error" *ngIf="getFormField('postcode').hasError('unsupported')">
            Code is unsupported or invalid
        </div>
    </div>



    <div class="field-wrapper custom-input-wrapper email">
        <label>Email</label>
        <input matInput type="email" formControlName="EmailAddress" readonly>
        <div class="error" *ngIf="getFormField('EmailAddress').hasError('email')">
            Please enter a valid email address
        </div>
        <div class="error" *ngIf="getFormField('EmailAddress').hasError('required')">
            Email is required
        </div>
    </div>

    <a routerLink="/change-password" class="btn secondary-neutral">Change password</a>

    <div *ngIf="error">
        <div class="mat-error">{{error}}</div>
    </div>
    <button [disabled]="!isValid || isSaving" class="btn primary small fixed-top" type="submit">
        <ng-container *ngIf="!isSaving">Save</ng-container>
        <shared-spinner *ngIf="isSaving" color="white">Save</shared-spinner>
    </button>


</form>