<ng-template [ngIf]="isMobile" [ngIfElse]="isDesktop">
  <booking-progress-bar-mobile></booking-progress-bar-mobile>

  <div class="content animation-fade-in">
    <shared-back-button customPath=""></shared-back-button>
    <booking-header title="slot" imgPath="assets/icons/calendar.svg"></booking-header>

    <ng-template [ngIf]="pickupSlotVisibilty">
      <booking-pick-up></booking-pick-up>
    </ng-template>
    <ng-template [ngIf]="deliverySlotVisibilty">
      <booking-delivery></booking-delivery>
    </ng-template>
    <button (click)="submit()" [disabled]="!isReady || isSaving" type="submit" class="btn primary bottom-fixed with-arrow btn-resize"
      [id]="orderEditPickupNext" >
      <ng-container *ngIf="!isSaving">Next</ng-container>
      <shared-spinner *ngIf="isSaving" color="white"></shared-spinner>
    </button>
  </div>
</ng-template>

<ng-template #isDesktop>
  <booking-progress-bar-desktop [step]="stepsEnum.Pickup"></booking-progress-bar-desktop>
  <div class="content animation-fade-in">
    <ng-template [ngIf]="pickupSlotVisibilty">
      <booking-pick-up></booking-pick-up>
      <button (click)="submit()" [disabled]="!isReady || isSaving" type="submit" class="btn primary absolute" [ngClass]="{'with-arrow': !isSaving}"
        [id]="orderEditPickupNext">
        <ng-container *ngIf="!isSaving">Next</ng-container>
        <shared-spinner *ngIf="isSaving" color="white"></shared-spinner>
      </button>
    </ng-template>
    <ng-template [ngIf]="deliverySlotVisibilty">
      <booking-delivery></booking-delivery>
      <button (click)="submit()" [disabled]="!isReady || isSaving" type="submit" class="btn primary absolute" [ngClass]="{'with-arrow': !isSaving}"
        [id]="orderEditDeliveryNext">
        <ng-container *ngIf="!isSaving">Next</ng-container>
        <shared-spinner *ngIf="isSaving" color="white"></shared-spinner>
      </button>
    </ng-template>
    
  </div>
</ng-template>
