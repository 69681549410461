import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { ConfirmModalService } from 'src/app/services/shared/confirm-modal.service';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';

@Component({
  selector: 'user-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
})
export class PaymentsComponent implements OnInit {
  title: string = 'Payment information';
  showModal: boolean;
  modalSubscription: Subscription;

  constructor(
    private confirmModalService: ConfirmModalService,
    public matDialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.modalSubscription = this.confirmModalService.$showModal.subscribe((value) => {
      this.showModal = value;
    });
  }

  canDeactivate(): boolean {
    if (this.showModal) {
      this.matDialog.open(ConfirmModalComponent);
      return false;
    }
    return true;
  } 

  ngOnDestroy() {
    this.confirmModalService.setShowModal(false);
    this.confirmModalService.setSubmitForm(false);
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
  }
}
