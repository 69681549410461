import { Component, Input, OnInit } from '@angular/core';

import { OrderDetails } from 'src/app/models/order/order-details.model';
import { Routes } from 'src/app/constants/routes.constant';

@Component({
  selector: 'order-details-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss']
})
export class HeadingComponent implements OnInit {
  orderListRoute = Routes.ORDER.LIST;
  css : string = 'green';
  image : string = 'order-on-its-way';
  @Input() order:any;

  constructor() { }

  ngOnInit(): void {

    if (this.order.Status == 'CANCELLED') {
      this.css = 'light-grey';
    } else if (this.order.Status == 'PART PENDING') {
      this.css = 'red';
    }

    if (this.order.Status == 'IN PROCESS') {
      this.image = 'order-in-progress';
    }
  }

}
