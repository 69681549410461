import { Component, OnInit } from '@angular/core';

import { RecurringBase } from '../recurring.base';
import { RecurringService } from 'src/app/services/user/recurring.service';
import { CommandService } from 'src/app/services/common/command.service';

@Component({
  selector: 'user-recurring-mobile',
  templateUrl: './recurring-mobile.component.html',
  styleUrls: ['./recurring-mobile.component.scss']
})
export class RecurringMobileComponent extends RecurringBase implements OnInit {
  title: string = 'Recurring booking';

  constructor(
    protected recurringService: RecurringService,
    protected commandService: CommandService,
  ) {
    super(recurringService, commandService);
  }

  async ngOnInit() {
    await super.ngOnInit();
  }
}
