<div class="content animation-fade-in">
  <div class="progress-bar-wrapper">

    <div class="lines-and-dots">
      <div *ngIf="step === stepsEnum.Pickup || step === stepsEnum.Delivery || step === stepsEnum.SlotsSummary" id="lines-and-dots-slots">
        <div class="line line-1 line-gradient"></div>
        <div class="line line-2 line-grey"></div>
        <div class="dot dot-1 dot-green-big"></div>
        <div class="dot dot-2 dot-grey"></div>
        <div class="dot dot-3 dot-grey-empty"></div>
      </div>

      <div *ngIf="step === stepsEnum.Address" id="lines-and-dots-address">
        <div class="line line-1 line-green"></div>
        <div class="line line-2 line-gradient"></div>
        <div class="dot dot-1 dot-green"></div>
        <div class="dot dot-2 dot-green-big"></div>
        <div class="dot dot-3 dot-grey-empty"></div>
      </div>
      
      <div *ngIf="step === stepsEnum.Summary" id="lines-and-dots-summary">
        <div class="line line-1 line-green"></div>
        <div class="line line-2 line-green"></div>
        <div class="dot dot-1 dot-green"></div>
        <div class="dot dot-2 dot-green"></div>
        <div class="dot dot-3 dot-green-big"></div>
      </div>
    </div>

    <div class="icon-and-text slots" [ngClass]="{'active': step === stepsEnum.Pickup || step === stepsEnum.Delivery  || step === stepsEnum.SlotsSummary}">
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 4.92308C5.89543 4.92308 5 5.81851 5 6.92308V35.4154C5 36.52 5.89543 37.4154 7 37.4154H33C34.1046 37.4154 35 36.52 35 35.4154V6.92308C35 5.81851 34.1046 4.92308 33 4.92308H7ZM7 5.9077C6.44772 5.9077 6 6.35541 6 6.9077V35.4308C6 35.9831 6.44771 36.4308 7 36.4308H33C33.5523 36.4308 34 35.9831 34 35.4308V6.9077C34 6.35541 33.5523 5.9077 33 5.9077H7Z" fill="#42A71E"/>
        <rect x="8" y="14.7692" width="24" height="0.984615" rx="0.492308" fill="#42A71E"/>
        <rect x="11" y="1.96923" width="1" height="6.89231" rx="0.5" fill="#C4C4C4"/>
        <rect x="11" y="1.96923" width="1" height="6.89231" rx="0.5" fill="#42A71E"/>
        <rect x="28" y="1.96923" width="1" height="6.89231" rx="0.5" fill="#42A71E"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5 11.8154C12.8807 11.8154 14 10.7133 14 9.35385C14 7.99438 12.8807 6.89231 11.5 6.89231C10.1193 6.89231 9 7.99438 9 9.35385C9 10.7133 10.1193 11.8154 11.5 11.8154ZM11.5 10.8308C12.3284 10.8308 13 10.1695 13 9.35385C13 8.53817 12.3284 7.87693 11.5 7.87693C10.6716 7.87693 10 8.53817 10 9.35385C10 10.1695 10.6716 10.8308 11.5 10.8308Z" fill="#42A71E"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M28.5 11.8154C29.8807 11.8154 31 10.7133 31 9.35385C31 7.99438 29.8807 6.89231 28.5 6.89231C27.1193 6.89231 26 7.99438 26 9.35385C26 10.7133 27.1193 11.8154 28.5 11.8154ZM28.5 10.8308C29.3284 10.8308 30 10.1695 30 9.35385C30 8.53817 29.3284 7.87693 28.5 7.87693C27.6716 7.87693 27 8.53817 27 9.35385C27 10.1695 27.6716 10.8308 28.5 10.8308Z" fill="#42A71E"/>
        <path d="M9 20.1846C9 19.9127 9.22041 19.6923 9.49231 19.6923H12.5077C12.7796 19.6923 13 19.9127 13 20.1846C13 20.4565 12.7796 20.6769 12.5077 20.6769H9.49231C9.22041 20.6769 9 20.4565 9 20.1846Z" fill="#42A71E"/>
        <path d="M9 24.1231C9 23.8512 9.22041 23.6308 9.49231 23.6308H12.5077C12.7796 23.6308 13 23.8512 13 24.1231C13 24.395 12.7796 24.6154 12.5077 24.6154H9.49231C9.22041 24.6154 9 24.395 9 24.1231Z" fill="#42A71E"/>
        <path d="M9 28.0615C9 27.7896 9.22041 27.5692 9.49231 27.5692H12.5077C12.7796 27.5692 13 27.7896 13 28.0615C13 28.3334 12.7796 28.5538 12.5077 28.5538H9.49231C9.22041 28.5538 9 28.3334 9 28.0615Z" fill="#42A71E"/>
        <path d="M9 32C9 31.7281 9.22041 31.5077 9.49231 31.5077H12.5077C12.7796 31.5077 13 31.7281 13 32C13 32.2719 12.7796 32.4923 12.5077 32.4923H9.49231C9.22041 32.4923 9 32.2719 9 32Z" fill="#42A71E"/>
        <path d="M15 20.1846C15 19.9127 15.2204 19.6923 15.4923 19.6923H18.5077C18.7796 19.6923 19 19.9127 19 20.1846C19 20.4565 18.7796 20.6769 18.5077 20.6769H15.4923C15.2204 20.6769 15 20.4565 15 20.1846Z" fill="#42A71E"/>
        <path d="M15 24.1231C15 23.8512 15.2204 23.6308 15.4923 23.6308H18.5077C18.7796 23.6308 19 23.8512 19 24.1231C19 24.395 18.7796 24.6154 18.5077 24.6154H15.4923C15.2204 24.6154 15 24.395 15 24.1231Z" fill="#42A71E"/>
        <path d="M15 28.0615C15 27.7896 15.2204 27.5692 15.4923 27.5692H18.5077C18.7796 27.5692 19 27.7896 19 28.0615C19 28.3334 18.7796 28.5538 18.5077 28.5538H15.4923C15.2204 28.5538 15 28.3334 15 28.0615Z" fill="#42A71E"/>
        <path d="M15 32C15 31.7281 15.2204 31.5077 15.4923 31.5077H18.5077C18.7796 31.5077 19 31.7281 19 32C19 32.2719 18.7796 32.4923 18.5077 32.4923H15.4923C15.2204 32.4923 15 32.2719 15 32Z" fill="#42A71E"/>
        <path d="M21 20.1846C21 19.9127 21.2204 19.6923 21.4923 19.6923H24.5077C24.7796 19.6923 25 19.9127 25 20.1846C25 20.4565 24.7796 20.6769 24.5077 20.6769H21.4923C21.2204 20.6769 21 20.4565 21 20.1846Z" fill="#42A71E"/>
        <path d="M21 24.1231C21 23.8512 21.2204 23.6308 21.4923 23.6308H24.5077C24.7796 23.6308 25 23.8512 25 24.1231C25 24.395 24.7796 24.6154 24.5077 24.6154H21.4923C21.2204 24.6154 21 24.395 21 24.1231Z" fill="#42A71E"/>
        <path d="M21 28.0615C21 27.7896 21.2204 27.5692 21.4923 27.5692H24.5077C24.7796 27.5692 25 27.7896 25 28.0615C25 28.3334 24.7796 28.5538 24.5077 28.5538H21.4923C21.2204 28.5538 21 28.3334 21 28.0615Z" fill="#42A71E"/>
        <path d="M21 32C21 31.7281 21.2204 31.5077 21.4923 31.5077H24.5077C24.7796 31.5077 25 31.7281 25 32C25 32.2719 24.7796 32.4923 24.5077 32.4923H21.4923C21.2204 32.4923 21 32.2719 21 32Z" fill="#42A71E"/>
        <path d="M27 20.1846C27 19.9127 27.2204 19.6923 27.4923 19.6923H30.5077C30.7796 19.6923 31 19.9127 31 20.1846C31 20.4565 30.7796 20.6769 30.5077 20.6769H27.4923C27.2204 20.6769 27 20.4565 27 20.1846Z" fill="#42A71E"/>
        <path d="M27 24.1231C27 23.8512 27.2204 23.6308 27.4923 23.6308H30.5077C30.7796 23.6308 31 23.8512 31 24.1231C31 24.395 30.7796 24.6154 30.5077 24.6154H27.4923C27.2204 24.6154 27 24.395 27 24.1231Z" fill="#42A71E"/>
        <path d="M27 28.0615C27 27.7896 27.2204 27.5692 27.4923 27.5692H30.5077C30.7796 27.5692 31 27.7896 31 28.0615C31 28.3334 30.7796 28.5538 30.5077 28.5538H27.4923C27.2204 28.5538 27 28.3334 27 28.0615Z" fill="#42A71E"/>
        <path d="M27 32C27 31.7281 27.2204 31.5077 27.4923 31.5077H30.5077C30.7796 31.5077 31 31.7281 31 32C31 32.2719 30.7796 32.4923 30.5077 32.4923H27.4923C27.2204 32.4923 27 32.2719 27 32Z" fill="#42A71E"/>
      </svg>
      <span>slot</span>
    </div>

    <div class="icon-and-text address" [ngClass]="{'active': step === stepsEnum.Address}">
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20 38.0001C20 38.0001 32 22.0001 32 14.0001C32 7.37266 26.6274 2.00008 20 2.00008C13.3726 2.00008 8 7.37266 8 14.0001C8 22.0001 20 38.0001 20 38.0001ZM20 36.0001C20 36.0001 31 21.3335 31 14.0001C31 7.92498 26.0752 3.00012 20 3.00012C13.9249 3.00012 9.00004 7.92498 9.00004 14.0001C9.00004 21.3335 20 36.0001 20 36.0001Z" fill="#42A71E"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20 22C24.4183 22 28 18.4183 28 14C28 9.58176 24.4183 6.00004 20 6.00004C15.5817 6.00004 12 9.58176 12 14C12 18.4183 15.5817 22 20 22ZM20 21.0001C23.866 21.0001 27 17.8661 27 14.0001C27 10.1341 23.866 7.00008 20 7.00008C16.134 7.00008 13 10.1341 13 14.0001C13 17.8661 16.134 21.0001 20 21.0001Z" fill="#42A71E"/>
      </svg>
      <span>address</span>
    </div>

    <div class="icon-and-text summary" [ngClass]="{'active': step === stepsEnum.Summary}">
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8 2C6.89543 2 6 2.89543 6 4V36C6 37.1046 6.89543 38 8 38H32C33.1046 38 34 37.1046 34 36V8L28 2H8ZM8 3C7.44772 3 7 3.44772 7 4V36C7 36.5523 7.44772 37 8 37H32C32.5523 37 33 36.5523 33 36V8.5L27.5 3H8Z" fill="#42A71E"/>
        <path d="M27 2H28V8H34V9H27V2Z" fill="#42A71E"/>
        <rect x="17" y="15" width="11" height="1" rx="0.5" fill="#42A71E"/>
        <rect x="12" y="15" width="3" height="1" rx="0.5" fill="#42A71E"/>
        <rect x="12" y="18" width="3" height="1" rx="0.5" fill="#42A71E"/>
        <rect x="12" y="21" width="3" height="1" rx="0.5" fill="#42A71E"/>
        <rect x="12" y="24" width="3" height="1" rx="0.5" fill="#42A71E"/>
        <rect x="12" y="27" width="3" height="1" rx="0.5" fill="#42A71E"/>
        <rect x="17" y="18" width="11" height="1" rx="0.5" fill="#42A71E"/>
        <rect x="17" y="21" width="11" height="1" rx="0.5" fill="#42A71E"/>
        <rect x="17" y="24" width="11" height="1" rx="0.5" fill="#42A71E"/>
        <rect x="17" y="27" width="11" height="1" rx="0.5" fill="#42A71E"/>
      </svg>
      <span>summary</span>
    </div>
  </div>
</div>