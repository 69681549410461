import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { HelpFeedbackComponent } from '../help-feedback.component';
import { AuthService } from 'src/app/services/user/auth.service';

@Component({
  selector: 'guest-help-feedback-mobile',
  templateUrl: './help-feedback-mobile.component.html',
  styleUrls: ['./help-feedback-mobile.component.scss']
})
export class HelpFeedbackMobileComponent extends HelpFeedbackComponent implements OnInit {

  constructor(
    protected deviceService: DeviceDetectorService,
    protected authService: AuthService,
  ) {
    super(deviceService, authService);
  }

  ngOnInit(): void {
  }

}
