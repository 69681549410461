import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Routes } from 'src/app/constants/routes.constant';
import { DeliveryOnlyState } from 'src/app/models/delivery-only/delivery-only-state.model';
import { DeliveryOnlyStateService } from 'src/app/services/delivery-only/delivery-only-state.service';
import { AuthService } from 'src/app/services/user/auth.service';

@Component({
  selector: 'delivery-only-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.scss']
})
export class EntryComponent implements OnInit {

  state: DeliveryOnlyState = new DeliveryOnlyState();
  constructor(
    private stateService: DeliveryOnlyStateService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.state.invoiceKeys = this.route.snapshot.queryParamMap.get('invoices')?.split(',');
    let promisedDate = this.route.snapshot.queryParamMap.get('promisedDate') ?? moment().format('YYYY-MM-DD');
    let promisedMoment = moment(promisedDate);
    this.state.promisedDate = promisedMoment.format('YYYY-MM-DD');
    this.stateService.setState(this.state);

    const provisionUrl = Routes.DELIVERY_ONLY.PROVISION;

    if (this.authService.isLoggedIn()) {
      this.router.navigate([provisionUrl]);
    } else {
      this.router.navigate([Routes.GUEST.SIGN], { queryParams: { redirect: provisionUrl } });
    }
  }
}
