import { CommonModule } from "@angular/common";
import { AppRoutingModule } from "./app-routing.module";
import { RouterTestingModule } from "@angular/router/testing";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HttpClientTestingModule } from "@angular/common/http/testing";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { ToastrModule } from 'ngx-toastr';
import { ServiceWorkerModule } from "@angular/service-worker";

import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatRadioModule } from "@angular/material/radio";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatButtonModule } from "@angular/material/button";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTabsModule } from "@angular/material/tabs";
import { MatIconModule } from '@angular/material/icon';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';

import { SharedModule } from './shared/shared.module';
import { PipesModule } from './pipes/pipes.module';
import { UserModule } from "./user/user.module";
import { BookingModule } from "./booking/booking.module";
import { OrderModule } from "./order/order.module";
import { AdminModule } from "./admin/admin.module";
import { GuestModule } from "./guest/guest.module";
import { DeliveryOnlyModule } from "./delivery-only/delivery-only.module";
import { ExternalUsageModule } from "./external-usage/external-usage.module";
import { SlickCarouselModule } from "ngx-slick-carousel";

export const AppModules = {
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ClipboardModule,
    DeviceDetectorModule,
    ToastrModule.forRoot({
        closeButton: true,
        timeOut: 3000,
    }),
    PipesModule,
    SharedModule,
    SlickCarouselModule
  ],
  testImports: [
    CommonModule,
    AppRoutingModule,
    RouterTestingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientTestingModule,
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    DeviceDetectorModule,
    ToastrModule.forRoot({
        closeButton: true,
        timeOut: 3000,
    }),
    ServiceWorkerModule.register('', { enabled: false }),
    MatInputModule,
    MatIconModule,
    MatBottomSheetModule,
    MatSelectModule,
    MatRadioModule,
    MatCheckboxModule,
    MatButtonModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatInputModule,
    SharedModule,
    PipesModule,
    UserModule,
    BookingModule,
    OrderModule,
    AdminModule,
    GuestModule,
    DeliveryOnlyModule,
    ExternalUsageModule,
    SlickCarouselModule
  ],
}