import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Routes } from 'src/app/constants/routes.constant';

@Component({
  selector: 'user-cancelled-order-banner',
  templateUrl: './cancelled-order-banner.component.html',
  styleUrls: ['./cancelled-order-banner.component.scss']
})
export class CancelledOrderBannerComponent implements OnInit {
  @Input() type_stop: string;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  redirect() {
    if(this.type_stop == "Delivery"){
      this.router.navigate([Routes.DELIVERY_ONLY.SLOT]);
    }else{
      this.router.navigate([Routes.BOOKING_REGULAR.SLOTS]);
    }
  }

  getCssClass() {
    return 'authorization-banner';
  }
}
