<div class="recurring-banner center-absolute-element">
    <div class="media">
        <img src="assets/icons/truck-reccurence-green.svg" alt="recurrence">
    </div>
    <div class="text">
        <p>Why not save yourself some time?</p>
        <span>Let us take the weekly laundry off your to-do list. Let us know the slot that works for you and we’ll pick up your items each week at the agreed date & time, and take care of them for you!</span>
    </div>
    <div class="action">
        <a (click)="redirectToRecurring()" id="redirect-btn">Reserve my slot</a>
    </div>
</div>