import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AccountSettingService } from 'src/app/services/user/account-setting.service';
import { PendingBannerModalStateEnum } from 'src/app/models/order/enums/pending-banner-modal-state.enum';

@Component({
  selector: 'order-details-pending-modal',
  templateUrl: './pending-modal.component.html',
  styleUrls: ['./pending-modal.component.scss']
})
export class PendingModalComponent implements OnInit {
  stateEnum: typeof PendingBannerModalStateEnum = PendingBannerModalStateEnum; 
  state: PendingBannerModalStateEnum = PendingBannerModalStateEnum.Initial;

  constructor(
    public dialogRef: MatDialogRef<PendingModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private accountSettingService: AccountSettingService
  ) { }
  
  ngOnInit(): void {
  }

  submit(selected: PendingBannerModalStateEnum) {
    this.state = selected;
    
    if (this.state === PendingBannerModalStateEnum.SavedAsDefault) {
      this.updateUserAuthorisation();
    } 
  }

  updateUserAuthorisation() {
    this.accountSettingService.updateAuthorization(this.data.authorisation).subscribe(
      (response) => {
        this.state = PendingBannerModalStateEnum.SavedAsDefault
      }, (error) => {
        // TODO: Uncomment the following line once the updateAuthorization() is inplemented
        // this.state = PendingBannerModalStateEnum.NotSavedAsDefault;
      }
    ); 
  }
  
  closeModal() {
    this.dialogRef.close();
  }
}
