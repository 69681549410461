<div class="rate-banner">
    <div class="rate-progress">
        <img src="assets/icons/rate-us-banner.svg" alt="rate us Google">
    </div>

    <div class="text-content">
        <p>Happy with our service? Rate us on Google or Trustpilot</p>
        <a href="https://blancliving.co/pages/review-blanc" id="rate-btn">
            <span>Rate us</span>
            <img src="assets/icons/arrow-right-white.svg" alt="action rate us blanc" />
        </a>
    </div>
</div>
