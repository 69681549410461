<form
  [formGroup]="authorisationsForm"
  (ngSubmit)="onFormSubmit()"
  class="animation-fade-in"
>
  <ul>
    <li class="authorisation-category">
      <p class="name">Cleaning authorisations</p>
      <!-- <div class="authorisation-wrapper">
        <div class="bloc-authorisation">
          <span>Minor repairs </span>
          <p class="shorter" *ngIf="authorisationsForm.get('0').value">
            I authorise BLANC to proceed with minor repairs up to £20 (such as
            fixing small tears, missing buttons or moth holes) that are not
            mentioned in your instructions.
          </p>
        </div>
        <mat-slide-toggle formControlName="0"></mat-slide-toggle>
      </div> -->

      <div class="authorisation-wrapper">
        <div class="bloc-authorisation">
          <span>Cleaning partner </span>
          <p class="shorter" *ngIf="authorisationsForm.get('0').value">
            I authorise BLANC to use a cleaning partner whenever your experts
            assess that solvent-based cleaning will be better suited to an
            individual garment (if no is selected, we will send your item back to you unprocessed).
          </p>
        </div>
        <mat-slide-toggle formControlName="0"></mat-slide-toggle>
      </div>

      <div class="authorisation-wrapper">
        <div class="bloc-authorisation">
          <span class="shorter">No care labels</span>
          <p class="shorter" *ngIf="authorisationsForm.get('1').value">
            I authorise BLANC to clean items for which the care label is missing without asking me every single time; I understand
            the risk and assume full liability.
          </p>
        </div>
        <mat-slide-toggle formControlName="1"></mat-slide-toggle>
      </div>
    </li>
  </ul>

  <!-- <button
  [disabled]="authorisationsForm.pristine"
    type="submit"
    class="btn primary absolute"
    id="save-authorisations-btn"
  >
    Save
  </button> -->
</form>
