<div class="content animation-fade-in">
    <div class="wrapper">
        <shared-background-rectangle></shared-background-rectangle>

        <header>
            <h1>Preferences</h1>
            <p>Please complete your authorisations & preferences below to allow us to provide you with a seamless, customised service.</p>
        </header>

        <user-preferences-desktop></user-preferences-desktop>


        <!-- <user-authorisations-form-desktop></user-authorisations-form-desktop> -->

    </div>
</div>