import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { GuestRoutingModule } from './guest-routing.module';
import { PipesModule } from '../pipes/pipes.module';
import { SharedModule } from '../shared/shared.module';
import { GuestComponent } from './guest.component';
import { SignComponent } from './sign/sign.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignupModelComponent } from './signup-model/signup-model.component';
import { FromQrCodeModalComponent } from './sign/from-qr-code-modal/from-qr-code-modal.component';
import { SetPasswordNewUserComponent } from './set-password-new-user/set-password-new-user.component';
import { BookingModule } from '../booking/booking.module';
import { SignUpStoreComponent } from './sign-up-store/sign-up-store.component';
// import { UrlSerializer } from '@angular/router';
// import { CustomUrlSerializer } from '../url/custom-url-serializer';

@NgModule({
  declarations: [
    GuestComponent,
    SignComponent,
    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    ConfirmEmailComponent,
    ResetPasswordComponent,
    SignupModelComponent,
    FromQrCodeModalComponent,
    SetPasswordNewUserComponent,
    SignUpStoreComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDialogModule,
    MatTabsModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatIconModule,
    GuestRoutingModule,
    PipesModule,
    SharedModule,
    BookingModule
  ],
  entryComponents: [
    ResetPasswordComponent,
    ForgotPasswordComponent,
    FromQrCodeModalComponent
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    },
    // { provide: UrlSerializer, useClass: CustomUrlSerializer }

 ],
  exports: [
    CommonModule,
  ]
})
export class GuestModule { }
