import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';

import { AuthService } from 'src/app/services/user/auth.service';
import { FormStatusEnum } from 'src/app/models/common/enums/form-status.enum';
import { AlertService } from 'src/app/services/common/alert.service';
import { CommandService } from 'src/app/services/common/command.service';
import { LogoButtonEnum } from 'src/app/models/shared/enums/logo-button.enum';
import { SignUpRequest } from 'src/app/models/user/sign-up-request.model';
import { MatDialog } from '@angular/material/dialog';
import { SignupModelComponent } from '../signup-model/signup-model.component';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'src/app/services/common/cookie.service';
import { Routes } from 'src/app/constants/routes.constant';
import { BookingStepsEnum } from 'src/app/models/booking/enums/booking-steps.enum';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  isMobile: boolean;
  logoTypeEnum: typeof LogoButtonEnum = LogoButtonEnum;
  redirect: string;
  redirectUrl: string;
  private readonly SessionID = 'Session-ID';
  working = false;
  valid: boolean;
  error: string;
  disabled: boolean;
  isUsed: boolean;
  step: BookingStepsEnum = BookingStepsEnum.Address;

  isPasswordVisible: boolean;

  signupForm = new FormGroup({
    firstName: new FormControl('', [Validators.required, Validators.maxLength(30)]),
    lastName: new FormControl('', [Validators.required, Validators.maxLength(30)]),
    countryCode: new FormControl('', [Validators.required, Validators.pattern(`^(\\+\\d{1,4})$`)]),
    phone: new FormControl('', [Validators.required, Validators.pattern('^ *(?:[0-9] *){9,12}$')]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    conditions:  new FormControl(0, Validators.requiredTrue),
    PrivacyPolicy: new FormControl(0 , Validators.requiredTrue),
    newsletter: new FormControl(0)
  });

  SignUpConitnue: string;
  
  constructor(
    private deviceService: DeviceDetectorService,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private alertService: AlertService,
    private commandService: CommandService,
    public dialog: MatDialog,
    private http: HttpClient,
    private cookieService: CookieService,
  ) { }

  ngOnInit(): void {
    this.isUsed = false;
    this.isMobile = this.deviceService.isMobile();
    this.redirect = this.route.snapshot.queryParams.redirect;
    this.redirectUrl = this.redirect ? this.redirect : '/';
    this.isPasswordVisible = false;

    this.signupForm.statusChanges.subscribe((status) => {
      this.error = null;
      this.valid = status === FormStatusEnum.Valid;
    });

    this.SignUpConitnue = "first-journey-sign-up-continue";
  }

  getFieldFromForm(fieldName: string) {
    return this.signupForm.get(fieldName);
  }

  setCountryCodeValue(value) {
    const countryCodeField = this.getFieldFromForm('countryCode');

    countryCodeField.setValue(value);
  }

  showPassword() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  getReferralCode(): string {
    let code: string;

    this.route.params.subscribe((params) => {
      code = params.referralCode;
    });

    return code;
  }

  onFormSubmit() {
    this.disabled = false
    this.signup({
      Firstname: this.getFieldFromForm('firstName').value,
      Lastname: this.getFieldFromForm('lastName').value,
      countryCode: this.getFieldFromForm('countryCode').value,
      Phone: this.getFieldFromForm('countryCode').value+'|'+ this.getFieldFromForm('phone').value,
      EmailAddress: this.getFieldFromForm('email').value,
      Password: this.getFieldFromForm('password').value,
      AcceptPrivacy: this.getFieldFromForm('PrivacyPolicy').value ? 1 : 0,
      AcceptTerms:  this.getFieldFromForm('conditions').value ? 1 : 0,
      AcceptMarketing: this.getFieldFromForm('newsletter').value ? 1 : 0,

    });

  }

  signup(request: SignUpRequest) {
    this.working = true;
    this.auth.checkEmail({EmailAddress: request.EmailAddress})
    .then(response => {
      this.auth
      .signup(request)
      .then(() => {
        localStorage.setItem('first-journey', 'true');
        this.auth
          .login({
            username: request.EmailAddress,
            password: request.Password
          })
          .then(() => {
            //this.saveDefaultPeferance();
            this.router.navigate([Routes.BOOKING_REGULAR.ADDRESS]);
          });
      })
      .catch((err) => {
        console.error('Sign up failed: ', err);
        this.error = err.error.message;
        this.alertService.showError(this.error);
        this.working = false;
      });
    })
    .catch((err) => {
      this.isUsed = true;
      console.error('Sign up failed: ', err);
      this.error = err.error.error;
      this.alertService.showError(this.error);
      this.working = false;
    });
  }

  openDialogPrivacyPolicy() {
    this.dialog.open(SignupModelComponent, {
      data: {
        modal: 'PrivacyPolicy' ,
      },
    });
  }
  openDialogTermsandConditions(){
    this.dialog.open(SignupModelComponent, {
      data: {
        modal: 'TermsandConditions' ,
      },
    });
  }
  saveDefaultPeferance(){
    const pref = {
      InfoCustomerPreference: [
        { id_preference: 2, Titre: 'Risk of damage', Value: 'Upon request' },
        { id_preference: 7, Titre: 'Cleaning partner', Value: 'Always' }
      ]
    };
    this.commandService.executeUpdate(CommandEnum.SaveCustomerPreference, pref).then(
      (response) => {
      },
      (error) => { }
    );
  }
  redirectToLogin(){
    this.router.navigate(['/signinup']);
  }
}