import { Component, OnInit, Output,EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Routes } from 'src/app/constants/routes.constant';
import { Instructions } from 'src/app/models/booking/instructions.model';
import { Constant } from 'src/app/models/common/constant.model';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { FormStatusEnum } from 'src/app/models/common/enums/form-status.enum';
import { PmsIdPreference, PmsTitrePreference, PreferenceCategory,PreferenceCategoryStore,PmsIdPreferenceStore,PmsTitrePreferenceStore } from 'src/app/models/user/enums/preference-category.enum';
import { AlertService } from 'src/app/services/common/alert.service';
import { CommandService } from 'src/app/services/common/command.service';
import { StorageService } from 'src/app/services/common/storage.service';
import { ParticleService } from 'src/app/services/particle/particle.service';
import { AccountSettingService } from 'src/app/services/user/account-setting.service';
import { AuthService } from 'src/app/services/user/auth.service';
import { AuthorisationsFormDesktopComponent } from 'src/app/user/authorisations/authorisations-desktop/authorisations-form/authorisations-form.component';
import { AuthorisationsFormMobileComponent } from 'src/app/user/authorisations/authorisations-mobile/authorisations-form/authorisations-form.component';
import { BookingStepsEnum } from 'src/app/models/booking/enums/booking-steps.enum';
import { BookingStepsService } from 'src/app/services/booking/booking-steps.service';

@Component({
  selector: 'app-preferences-store',
  templateUrl: 'preferences-store.component.html',
  styleUrls: ['preferences-store.component.scss']
})
export class PreferencesStoreComponent implements OnInit {
  title = 'Your preferences';
  enumPreferenceCategory: PreferenceCategory;
  preferences: any[] = [];
  authorizations: any[] = [];
  minor: any[] = [];
  preferencesForm: FormGroup;
  authorisationsForm: FormGroup;
  receiveOffers: boolean;
  isValid = false;
  user: any;
  lessons: boolean;
  accountSubscription: any;
  deliveryInstruction: any;
  // dataInstruction: any;
  hasInstruction = false;
  public deliverToOptions: Constant[];
  public deliverToOptionSelected: string;
  public hasAllData: boolean;
  public instructionsForm: FormGroup;
  public confirmInstruction = false;
  dataInstruction: any;
  PmsPreferences: any;
  @Output() outputChanged: EventEmitter<any> = new EventEmitter<any>();
  public instClear: boolean = false;
  @ViewChild(AuthorisationsFormDesktopComponent) childDescktop: AuthorisationsFormDesktopComponent;
  @ViewChild(AuthorisationsFormMobileComponent) childMobile: AuthorisationsFormMobileComponent;
  isMobile: boolean;
  isSaving = false;
  instructions: Instructions;
  store: string;
  step : BookingStepsEnum = BookingStepsEnum.Summary;
  constructor(
    protected bookingStepsService: BookingStepsService,
    public route: ActivatedRoute,
    public accountSettingService: AccountSettingService,
    public formBuilder: FormBuilder,
    public router: Router,
    public commandService: CommandService,
    private authService: AuthService,
    public alertService: AlertService,
    public particleService: ParticleService,
    protected storageService: StorageService,
    public deviceService: DeviceDetectorService,

  ) { }

  async ngOnInit() {
    this.bookingStepsService.setStep(BookingStepsEnum.Summary);
    this.store = this.route.snapshot.queryParams.store;
    this.isMobile = this.deviceService.isMobile();
    await this.initForm();
    await this.initPreferences();
  }

  initForm() {
    this.preferencesForm = this.formBuilder.group({
      0: [false, Validators.required],
      1: [false, Validators.required],
      2: [false, Validators.required],
      3: [false, Validators.required],
      4: [false, Validators.required],
      receiveOffers: [false, Validators.required],
    });

    this.isValid = this.preferencesForm.status === FormStatusEnum.Valid;
    this.preferencesForm.statusChanges.subscribe((status) => {
      this.isValid = status === FormStatusEnum.Valid;
    });
  }

  initPreferences() {
    this.commandService.executeCommand(CommandEnum.GetCustomerPreferences)
      .then(response => {
        for (let i = 0; i < response.ReturnObject.length; i++) {
          if ((response.ReturnObject[i].id_preference === 3) || (response.ReturnObject[i].id_preference === 4) ||(response.ReturnObject[i].id_preference === 10) || (response.ReturnObject[i].id_preference === 5)) {
            this.preferences.push(response.ReturnObject[i]);
          }
          if ((response.ReturnObject[i].id_preference === 7) || (response.ReturnObject[i].id_preference === 2)) {
            this.authorizations.push(response.ReturnObject[i]);
          }
          if (response.ReturnObject[i].id_preference === 1){
            this.minor.push(response.ReturnObject[i]);
          }
        }
        
        if (this.preferences.length === 0) {
          this.populatePreferences();
        }
        this.setFormValues();
    },
    error => {
    });
  }


  getFormField(fieldName: string) {
    return this.preferencesForm.get(fieldName.toString());
  }

  onFormSubmit() {
    if (!this.isValid) {
      return;
    }
    const data = this.preferences.map((preference, index) => {
      const selected = this.preferencesForm.get(index.toString()).value;
      const categoryStr = index;
      const category = PreferenceCategoryStore[categoryStr];
      return { ...preference, selected, category };
    });
    
    let InfoCustomerPreference = [];
    var PmsValue;
    for (let i = 0; i < data.length; i++) {
      PmsValue = '0';
      if (data[i].selected === true) {
        PmsValue = '1';
      } else if (data[i].selected === false) {
        PmsValue = '0';
      }
      this.PmsPreferences = {
        id_preference: PmsIdPreferenceStore[data[i].category],
        Titre: PmsTitrePreferenceStore[data[i].category],
        Value: PmsValue
      };
      InfoCustomerPreference.push(this.PmsPreferences);
    }

    let x;
    if(this.preferencesForm.get('4').value === true){
      x = {
        id_preference: 1,
        Titre: "Proceed with minor repairs",
        Value: "Always up to £8"
      };
    }else{
      x = {
        id_preference: 1,
        Titre: "Proceed with minor repairs",
        Value: "Never"
      };
    }
    for (let index = 0; index < this.authorizations.length; index++) {
      const element = {
        id_preference: this.authorizations[index]['id_preference'],
        Titre: this.authorizations[index]['Titre'],
        Value: this.authorizations[index]['Value']
      };
      InfoCustomerPreference.push(element);
    }
    
    InfoCustomerPreference.push(x);
    
    var pref = {
      InfoCustomerPreference: InfoCustomerPreference
    }

    this.commandService.executeUpdate(CommandEnum.SaveCustomerPreference, pref).then(
      (response) => {
        if (this.isMobile) {
          this.accountSettingService
            .updatePreferencePercentage()
            .then(
              response => {
                console.log(' ')
              },
              (error) => {
              }
            );
          this.router.navigate([Routes.USER.LANDING ]);
        } else {
          this.router.navigate([Routes.ORDER.LIST ]);
        } 

      },
      (error) => { }
    );

  }

  setFormValues() {
    const data = this.preferences.map((preference, index) => {
      if (this.preferences[index].Value === "Yes") {
        this.preferencesForm.get(String(index)).setValue(true);
      }
      if (this.preferences[index].Value === "Always") {
        this.preferencesForm.get(String(index)).setValue(true);
      }
    });
    for(let i=0; i<this.minor.length;i++){
      if(this.minor[0].id_preference === 1 && this.minor[i].Value === 'Always up to £8'){
        this.preferencesForm.get('4').setValue(true);
      }
    }
    for(let i=0; i<this.preferences.length;i++){
      if(this.preferences[i].id_preference === 3 && this.preferences[i].Value === "Yes"){
        this.preferencesForm.get('0').setValue(true);
      }else if(this.preferences[i].id_preference === 4 && this.preferences[i].Value === "Yes"){
        this.preferencesForm.get('1').setValue(true);
      }else if(this.preferences[i].id_preference === 10 && this.preferences[i].Value === "Yes"){
        this.preferencesForm.get('2').setValue(true);
      }else if(this.preferences[i].id_preference === 5 && this.preferences[i].Value === "Yes"){
        this.preferencesForm.get('3').setValue(true);
      }
    }
  }



  populatePreferences() {
    this.preferences = [
      { selected: false, number: 0, category: PreferenceCategory[0] },
      { selected: false, number: 0, category: PreferenceCategory[1] },
      { selected: false, number: 0, category: PreferenceCategory[2] },
      { selected: false, number: 0, category: PreferenceCategory[3] }
    ];
  }



}
