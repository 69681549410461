import { OnInit } from '@angular/core';

import { RecurringService } from 'src/app/services/user/recurring.service';
import { Recurring } from 'src/app/models/user/recurring.model';
import { ReccurenceStateEnum } from 'src/app/models/user/enums/recurrence-state.enum';
import { CommandService } from 'src/app/services/common/command.service';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';

export class RecurringBase implements OnInit {
  isLoading: boolean = true;
  recurring: Recurring;
  state: ReccurenceStateEnum = ReccurenceStateEnum.Loading;
  stateEnum: typeof ReccurenceStateEnum = ReccurenceStateEnum;
  data: any;
  constructor(
    protected recurringService: RecurringService,
    protected commandService: CommandService
  ) {}

  async ngOnInit() {
    this.recurringService.$recurring.subscribe((value) => {
      this.recurring = value;
    });

    this.recurringService.$state.subscribe((value) => {
      this.state = value;
    });

    await this.commandService
      .executeCommand(CommandEnum.CustomerDetail)
      .then((response) => {

        this.data = response.ReturnObject.infoCustomer;

        this.recurringService.setRecurring(this.convertRecuring(this.data));
        //this.recurringService.setRecurring(response.ReturnObject);

        //this.recurring.isActive
        this.data.DeliveryMon != '' || this.data.DeliveryTu != '' || this.data.DeliveryWed != '' 
        || this.data.DeliveryTh != '' || this.data.DeliveryFri != '' || this.data.DeliverySat != ''
          ? this.recurringService.setState(ReccurenceStateEnum.View)
          : this.recurringService.setState(ReccurenceStateEnum.Form);
        this.isLoading = false ;
      })
      .catch((error) => {
        this.commandService.handleCommandTokenError(error);
      });
  }
  convertRecuring(data){
    let recurring = new Recurring();
    let listDays : string[]= [
      "",
      data.DeliveryMon,
      data.DeliveryTu,
      data.DeliveryWed,
      data.DeliveryTh,
      data.DeliveryFri,
      data.DeliverySat
    ];
    let listRecDays:  Array<{key: number, value: string}>=[];
    listDays.forEach((item, index) => {
      if (item !== "") {
        listRecDays.push({key:index,value:item});
      }
      if (listRecDays.length === 2) {
        return;
      }
    });
    if(listRecDays.length>0){
    recurring.type=listRecDays.length;
    recurring.pickupDay = listRecDays[0].key;
    recurring.pickupSlot = listRecDays[0].value;
    if(recurring.type === 2){
      recurring.deliveryDay = listRecDays[1].key;
      recurring.deliverySlot = listRecDays[1].value;
    }
    recurring.pauseStart=data.PauseDateFrom;
    recurring.pauseEnd=data.PauseDateTo;}
    return recurring;
  }
}
