import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/user/auth.service';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss']
})
export class ConfirmEmailComponent implements OnInit {

  error = null;
  message = null;
  missing = false;
  done = false;
  working = false;

  constructor(private route: ActivatedRoute, private auth: AuthService) { }

  ngOnInit(): void {
    let userId = this.route.snapshot.queryParamMap.get('userId');
    let token = this.route.snapshot.queryParamMap.get('token');

    if (!userId || !token) {
      this.missing = true;
      this.error = "Invalid url - try clicking on the link in your email again or contact customer care";
    } else {
      this.working = true;
      this.message = "Please wait whilst we confirm your email...";

      this.auth.confirmEmail(userId, token)
              .then(() => {
                this.done = true;
              }).catch(err => {
                this.error = `Unable to verify your account
                \n${err.error.message}
                \nNote: The email confirmation link expires after 3 hours or if you reset your password.
                \nPlease contact customer care if the problem persists.
                `
              }).finally(() => {
                this.message = null;
                this.working = false;
              })
    }
  }

}
