<div *ngIf="isLoading" class="spinner-wrapper"><shared-spinner></shared-spinner></div>

<div *ngIf="!isLoading" class="content animation-fade-in"> 
  <shared-background-rectangle [height]="400"></shared-background-rectangle>
  <order-past-heading [order]="order"></order-past-heading>

  <div *ngIf="hasItems" class="body">
    <div class="wrapper">
      <order-details-banner-no-price [order]="order"></order-details-banner-no-price>
      <order-details-items-table [order]="order"></order-details-items-table>
    </div>
  </div>
</div>

