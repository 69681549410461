<div *ngIf="isLoading" class="spinner-wrapper"><shared-spinner></shared-spinner></div>

<mat-tab-group *ngIf="!isLoading" animationDuration="0ms" class="animation-fade-in">
  <mat-tab [label]="'Current orders (' + statistics.length + ')'">
    <order-list-current *ngIf="statistics.length > 0" [fromParent]="fromParent" ></order-list-current>
    <order-list-current-empty *ngIf="statistics.length === 0" [hasOrderHistory]="statistics.hasPastOrdersAndNotCurrent"></order-list-current-empty>
  </mat-tab>

  <mat-tab [label]="'Past orders'">
    <order-list-past [fromParentPast]="fromParentPast"></order-list-past>
  </mat-tab>
</mat-tab-group>
