import { Component, OnInit, Inject, Optional, ChangeDetectorRef, ChangeDetectionStrategy, NgZone, OnChanges, SimpleChanges } from '@angular/core';
import { ParticleItem } from 'src/app/models/particle/particle-item.model';
import { ParticleService } from 'src/app/services/particle/particle.service';
import { ParticleTypeEnum } from 'src/app/models/particle/enums/particle-type.enum';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'booking-item-modal',
  templateUrl: './item-modal.component.html',
  styleUrls: ['./item-modal.component.scss']
})
export class ItemModalComponent implements OnInit {

  historyItems: ParticleItem[] = [];
  items: ParticleItem[] = [];
  constructor(
    public dialogRef: MatDialogRef<ItemModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private particleService: ParticleService) {
    this.historyItems.push(data.item);
  }

  ngOnInit(): void {
    this.populateItems();
  }

  populateItems(): void {
    let lastItem = this.getLastHistoryItem();
    this.particleService.getParticle(ParticleTypeEnum.Item, lastItem.id).subscribe(
      (response) => {
        this.items = response.map(x => new ParticleItem(x));
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getLastHistoryItem(): ParticleItem {
    return this.historyItems[this.historyItems.length - 1]
  }

  backward() {
    this.historyItems = this.historyItems.slice(0, this.historyItems.length - 1);
    this.populateItems();
  }

  forward(item: ParticleItem) {
    this.historyItems.push(item);
    this.populateItems();
  }

  selectedItem(item: ParticleItem) {
    this.dialogRef.close(
      {
        event: 'close',
        data: item
      });
  }

  close() {
    this.dialogRef.close();
  }
}
