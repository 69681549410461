import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Routes } from 'src/app/constants/routes.constant';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { AlertService } from 'src/app/services/common/alert.service';
import { CommandService } from 'src/app/services/common/command.service';
import { EditEnum } from 'src/app/models/booking/enums/edit.enum';
import { StorageService } from 'src/app/services/common/storage.service';
import { RequestPickup } from 'src/app/models/spot/requestPickup.model';

@Component({
  selector: 'order-edit-finalize',
  templateUrl: './edit-finalize.component.html',
  styleUrls: ['./edit-finalize.component.scss']
})
export class EditFinalizeComponent implements OnInit {
  order_response : any;
  result :any;
  orderId : string;
  constructor(
    protected storageService: StorageService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected commandService: CommandService,
    protected alertService: AlertService,
  ) { }

  async ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    const editType = this.route.snapshot.paramMap.get('edit_type');

    this.order_response = this.storageService.getObject('order_response');
    if(Number(editType)=== EditEnum.Delivery){
      
      let test_delivery = this.storageService.getObject('delivery');
      if (test_delivery) {
        var parametres ={
          infoOrder_id : +id,
          DeliveryDate : test_delivery.date,
          DeliveryStartTime : test_delivery.from,
          DeliveryEndTime :test_delivery.to,
        }
      }else {
        var parametres ={
          infoOrder_id : +id,
          DeliveryDate : this.order_response.DeliveryDate,
          DeliveryStartTime : this.order_response.DeliveryTimeSlot.split('-')[0],
          DeliveryEndTime :this.order_response.DeliveryTimeSlot.split('-')[1].substr(1, 6),
        }
      }
      this.result = await this.commandService.executeCommand(CommandEnum.EditDelivery, parametres);
      this.orderId = id;
    } else{
      var paramDelivry = {
        DeliveryaskID : localStorage.getItem('Deliveryask_ID')
      }
      const test_pick = await this.commandService.executeCommand(CommandEnum.DeleteDelivery, paramDelivry);
      var paramPickup = {
        PickupID : localStorage.getItem('Pickup_ID')
      }
         // delete pickup
      const test = await this.commandService.executeCommand(CommandEnum.DeletePickup, paramPickup);
      
      let order_response = this.order_response;
      let requestPickup = new RequestPickup();
      let pickupDate,year,deliveryDate;
      if (this.storageService.getObject('pickup') !=null) {
        const pickup = this.storageService.getObject('pickup') ;
        requestPickup.PickupStartTime = pickup.from ;
        requestPickup.PickupEndTime = pickup.to ;
        pickupDate = pickup.date.substr(5) ;
        year = pickup.date.substr(0, 4) ;
      }else {
        const pickup = order_response.PickupDate;
        requestPickup.PickupStartTime = order_response.PickupTimeSlot.split('-')[0];
        requestPickup.PickupEndTime = order_response.PickupTimeSlot.split('-')[1].substr(1, 6);
        pickupDate = order_response.PickupDate.substr(5);
        year = order_response.PickupDate.substr(0, 4);
      }
      if (this.storageService.getObject('delivery') !=null) {
        const delivery = this.storageService.getObject('delivery');
        deliveryDate = delivery.date.substr(5);
        requestPickup.DeliveryStartTime = delivery.from;
        requestPickup.DeliveryEndTime = delivery.to;
      }else {
        const delivery = order_response.DeliveryDate;
        deliveryDate = order_response.DeliveryDate.substr(5);
        requestPickup.DeliveryStartTime = order_response.DeliveryTimeSlot.split('-')[0];
        requestPickup.DeliveryEndTime = order_response.DeliveryTimeSlot.split('-')[1].substr(1, 6);
      }

      requestPickup.PickupDate = pickupDate + '-' + year;
      requestPickup.DeliveryDate = deliveryDate + '-' + year;
      requestPickup.VisitType = 'Both Pickup and Delivery';
      requestPickup.PickupCodeCountry = order_response.PickupCodeCountry;
      requestPickup.PickupPhoneNumber = order_response.PickupPhoneNumber;
      requestPickup.PickupTypeDelivery = order_response.PickupTypeDelivery;
      requestPickup.DeliveryCodeCountry = order_response.DeliveryCodeCountry;
      requestPickup.DeliveryPhoneNumber = order_response.DeliveryPhoneNumber;
      requestPickup.DeliveryTypeDelivery = order_response.DeliveryTypeDelivery;
      requestPickup.Comments = '';
      requestPickup.DeliveryComments = order_response.DeliveryComment;
      // requestPickup.GarmentInstruction = order_response.GarmentInstruction;
      requestPickup.GarmentInstruction = order_response.GarmentInstruction ? JSON.stringify(order_response.GarmentInstruction) : '';

      this.result = await this.commandService.executeCommand(CommandEnum.RequestPickup, requestPickup)
      
    }
    
    if (this.result.Failed) {
      this.alertService.showError(this.result.Message);
      this.router.navigate([Routes.ORDER.LIST]);
    }
    else if (!this.result.Failed) {
      this.orderId = this.result.ReturnObject.infoOrder_id;
      this.commandService.deleteSecretToken();
      if(Number(editType)=== EditEnum.AddressInstruction){
        const redirectUrl = Routes.ORDER.EDITFINALIZE + this.orderId + '/' + 'Pick up & Delivery details' + '/' + EditEnum.Address;
        const navigationExtras = { queryParams: { redirect: redirectUrl } };
        this.router.navigate(
          [Routes.BOOKING_REGULAR.ADDRESS],
          navigationExtras
        );
      }else {

        this.router.navigate([Routes.ORDER.DETAILS + this.orderId +'/'+'Pick up & Delivery details']);
      }
    } else {
      this.alertService.showError(this.result.Message, 10000);
      this.router.navigate([Routes.ORDER.LIST]);
    }
  }

}
