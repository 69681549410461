import { StorageService } from './../../../services/common/storage.service';
import { ParticleService } from 'src/app/services/particle/particle.service';
import { CardService } from 'src/app/services/user/card.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SummaryBase } from '../summary.base';
import { CommandService } from 'src/app/services/common/command.service';
import { BlancCommandService } from 'src/app/services/common/blanc-command.service';
import { AuthService } from 'src/app/services/user/auth.service';
import { AlertService } from 'src/app/services/common/alert.service';
import { CookieService } from 'src/app/services/common/cookie.service';
import { BookingStepsService } from 'src/app/services/booking/booking-steps.service';
import { BackButtonComponent } from 'src/app/shared/back-button/back-button.component';
import { AccountSettingService } from 'src/app/services/user/account-setting.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CommonConstants } from 'src/app/constants/common';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'booking-summary-mobile',
  templateUrl: './summary-mobile.component.html',
  styleUrls: ['./../summary.component.scss', './summary-mobile.component.scss']
})
export class SummaryMobileComponent extends SummaryBase implements OnInit {
  isShown = false;
  _isIos: boolean;
  constructor(
    accountSettingService : AccountSettingService,
    auth: AuthService,
    router: Router,
    commandService: CommandService,
    alertService: AlertService,
    bookingStepsService: BookingStepsService,
    authService: AuthService,
    public cardService: CardService,
    cookieService: CookieService,
    http:HttpClient,
    protected particleService: ParticleService,
    protected storageService: StorageService,
    protected blancCommandService: BlancCommandService,
    private _deviceDetectorService: DeviceDetectorService,
  ) {
      super(accountSettingService, auth, router, cookieService, commandService, alertService, bookingStepsService, authService, cardService, particleService, storageService, blancCommandService, http)
  }

  async ngOnInit() {
    await super.ngOnInit();
    if (this._deviceDetectorService.os === CommonConstants.DEVICES_OS.iOS) {
      this._isIos = true;
    } else {
      this._isIos = false;
    }
  }
  toggleShown() {
    this.isShown = !this.isShown;
  }
}
