export class Instructions {
  deliverToId: string;
  deliverToName: string;
  countryCode: string;
  phoneNumber: string;
  other?: string;

  constructor(
    deliverToId: string = '',
    deliverToName: string = '',
    countryCode: string = '',
    phoneNumber: string = '',
    other: string = ''
  ) {
    this.deliverToId = deliverToId;
    this.deliverToName = deliverToName;
    this.countryCode = countryCode;
    this.phoneNumber = phoneNumber;
    this.other = other;
  }
}
