<div class="content">
  <p class="heading">Share my account</p>

  <div class="media">
    <img src="assets/icons/share-my-account.svg" class="tshirt">
  </div>

  <div class="text">
    <span>You can give someone permission to manage your bookings & orders. Enter a phone number below and they will
      receive a link to access your account.</span>
    <span class="smaller">Note that your payment method will not be accessible or visible by the second user.</span>
  </div>

  <form [formGroup]="phoneForm" (ngSubmit)="onSubmit()">
    <div class="field-wrapper code">
      <shared-country-code-picker (onPick)="setCountryCodeValue($event)"></shared-country-code-picker>
    </div>

    <div class="field-wrapper phone">
      <label>Their Mobile phone</label>
      <mat-form-field floatPlaceholder="never" floatLabel="never" class="phone-field">
        <input matInput formControlName="phone" type="tel">

        <mat-error id="phoneRequiredError" *ngIf="getFieldFromForm('phone').hasError('required')">
          Mobile phone is required
        </mat-error>

        <mat-error *ngIf="getFieldFromForm('phone').hasError('pattern')">
          Mobile phone must be between 9 and 12 digits
        </mat-error>
      </mat-form-field>
    </div>

    <button class="btn submit secondary" id="submitBtn" type="submit">Share my account</button>
  </form>
</div>

<div *ngIf="phonesList.length > 0" class="phones-list">
  <p>Shared with</p>
  <ul>
    <li *ngFor="let phone of phonesList; let i = index">
      <span class="phone">({{phone.countryCode}}) {{phone.phoneNumber}}</span>
      <span class="status">SMS sent</span>
      <a (click)="removePhone(i)"><img src="assets/icons/close.svg" alt="close icon"></a>
    </li>
  </ul>
</div>
