<shared-navigation-top [title]="title"></shared-navigation-top>

<div class="content">
    <p class="heading">Please complete your preferences below to allow us to provide you with a seamless, customised service.</p>

    <form [formGroup]="preferencesForm">
        <ul>
            <li class="preference-category box">
                <p class="name">Finish instructions</p>

                <div class="bloc-preference">
                    <div class="preference-wrapper">
                        <span>Shirts premium finish </span>
                        <mat-slide-toggle formControlName="0"></mat-slide-toggle>
                    </div>
                    <user-authentications-read-more text="Supplement: £1.30/ shirt. I want all my shirts to be hand-finished (premium) with a crease on the sleeves."></user-authentications-read-more>
                </div>

                <div class="bloc-preference">
                    <div class="preference-wrapper">
                        <span>Shirts folded </span>
                        <mat-slide-toggle formControlName="1"></mat-slide-toggle>
                    </div>
                    <user-authentications-read-more text="Supplement: £1.20/ shirt I want all my shirts to be delivered folded in a compostable shirt bag. "></user-authentications-read-more>
                </div>
                <div class="bloc-preference">
                    <div class="preference-wrapper">
                        <span>Garments folded </span>
                        <mat-slide-toggle formControlName="2"></mat-slide-toggle>
                    </div>
                    <user-authentications-read-more
                        text="Supplement: £1.00/ garment I want all my items to be delivered folded in a reusable bag. ">
                    </user-authentications-read-more>
                </div>
               
                <div class="bloc-preference">
                    <div class="preference-wrapper">
                        <span class="shorter">Debobbling</span>
                        <mat-slide-toggle formControlName="3"></mat-slide-toggle>
                    </div>
                    <user-authentications-read-more text="Supplement: £2 to £10/ item I want all my coats and jumpers to be debobbled when required, without having to specify it in special instructions."></user-authentications-read-more>
                </div>
                <div class="bloc-preference">
                  <div class="preference-wrapper">
                      <span>Minor repairs </span>
                      <mat-slide-toggle formControlName="4"></mat-slide-toggle>
                  </div>
                <user-authentications-read-more text="I authorise BLANC to proceed with minor repairs up to £8 (such as fixing small tears, missing buttons or moth holes) that are not mentioned in my instructions."></user-authentications-read-more>
              </div>

            </li>
            <user-authorisations-form-mobile (outputChanged)="setAuthorisation($event)"></user-authorisations-form-mobile>

            <li class="preference-category box">

                <div *ngIf="!lessons else loading">
                    <p class="name">Delivery instructions</p>
                    <div class="bloc-preference">
                        <div class="preference-wrapper">
                            <span>You don't have any instructions yet</span>
                        </div>
                    </div>

                    <button (click)="onAddInstruction()" class="btn secondary-neutral">Add instructions</button>
                </div>
                <ng-template #loading>
                    <app-preference-instruction-modal (outputChanged)="setOutput($event)"></app-preference-instruction-modal>
                </ng-template>

            </li>

            <li class="preference-category box">
                <p class="name">Communications</p>
                <div class="bloc-preference">
                    <div class="preference-wrapper">
                        <span class="shorter">Receive monthly newsletter</span>
                        <mat-slide-toggle formControlName="receiveOffers" class="form-control"></mat-slide-toggle>
                    </div>
                    <user-authentications-read-more text="I want to receive the monthly BLANC Edit, including eco-living tips, and exclusive partner offers."></user-authentications-read-more>
                </div>
                <div class="bloc-preference">
                    <div class="preference-wrapper">
                        <span class="shorter">SMS Marketing</span>
                        <mat-slide-toggle formControlName="smsMarketing" class="form-control"></mat-slide-toggle>
                    </div>
                    <user-authentications-read-more text="Customer agrees to receiving Marketing SMS from us."></user-authentications-read-more>
                </div>
            </li>
        </ul>
        <button [disabled]="!(!preferencesForm.pristine||!authChanged || (lessons && instIsValid))|| isSaving" class="btn submit primary small" type="submit" (click)="onFormSubmit()">        <ng-container *ngIf="!isSaving">Save</ng-container>
            <shared-spinner *ngIf="isSaving" color="white"></shared-spinner>
        </button>
    </form>
</div>
