import { Component, Input } from '@angular/core';

import { BookingStepsEnum } from 'src/app/models/booking/enums/booking-steps.enum';

@Component({
  selector: 'booking-progress-bar-desktop',
  templateUrl: './progress-bar-desktop.component.html',
  styleUrls: ['./progress-bar-desktop.component.scss']
})
export class ProgressBarDesktopComponent {
  stepsEnum: typeof BookingStepsEnum = BookingStepsEnum;

  @Input() step: BookingStepsEnum;

  constructor() { }

}
