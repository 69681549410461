<shared-spinner *ngIf="isLoading"></shared-spinner>

<div *ngIf="!isLoading" class="card card-view animation-fade-in">
    <div class="card-type" [ngSwitch]="getCardInfoType()">
        <span class="helper"></span>
        <img *ngSwitchCase="cardType.MasterCard" src="assets/images/cards/mastercard.png" class="mc" alt="mastercard">
        <img *ngSwitchCase="cardType.Maes" src="assets/images/cards/mastercard.png" class="mc" alt="mastercard">
        <img *ngSwitchCase="cardType.Visa" src="assets/images/cards/visa.png" class="visa" alt="visa">
        <img *ngSwitchCase="cardType.AmericanExpress" src="assets/images/cards/amex.png" class="amex" alt="amex">
    </div>
    
    <div class="card-info" [ngSwitch]="getCardInfoType()">
        <!-- <p class = "user-info">{{user?.firstName}} {{user?.lastName}}</p> -->
        <br>
            <p *ngSwitchCase="cardType.MasterCard" class = "info-card">Mastercard ***{{ card?.cardNumber }}
                <a (click)="edit()"  class="btn href green"  [id]="editPaymentCard">
                    <span>Edit</span>
                </a>
            </p>
            <p *ngSwitchCase="cardType.Maes" class = "info-card">Mastercard ***{{ card?.cardNumber }}
                <a (click)="edit()"  class="btn href green"  [id]="editPaymentCard">
                    <span>Edit</span>
                </a>
            </p>
            <p *ngSwitchCase="cardType.Visa" class = "info-card">Visa ***{{ card?.cardNumber }} 
                <a (click)="edit()"  class="btn href green"  [id]="editPaymentCard">
                    <span>Edit</span>
                </a>
            </p>
            <p *ngSwitchCase="cardType.AmericanExpress" class = "info-card">Amex ***{{ card?.cardNumber }} 
                <a (click)="edit()"  class="btn href green"  [id]="editPaymentCard">
                    <span>Edit</span>
                </a>
            </p>
            <div class="card-secure">
                <img src="assets/icons/lock.svg" height="13" width="17">
                <span style="color: #868686;">Your card details are securely encrypted and stored with Stripe.</span>
            </div>
    </div>

    <!-- <button (click)="edit()" class="btn secondary small {{btnCssClasses}}" [id]="editPaymentCard">edit</button> -->
</div>
