import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Routes } from 'src/app/constants/routes.constant';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss']
})
export class PreferencesComponent implements OnInit {
  isMobile: boolean;
  store: string;
  constructor(
    private deviceService: DeviceDetectorService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
     this.store = this.route.snapshot.queryParams.store;
    if (!this.isMobile) {
      this.router.navigate([Routes.USER.AUTHORISATIONS]);
    }
  }
}
