import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ParticleService } from 'src/app/services/particle/particle.service';
import { Brand } from 'src/app/models/booking/brand.model';
import { FormControl, Validators } from '@angular/forms';
import { ServiceItem } from 'src/app/models/particle/service-item.model';

@Component({
  selector: 'booking-brand-search-mobile',
  templateUrl: './brand-search-mobile.component.html',
  styleUrls: ['./brand-search-mobile.component.scss'],
})
export class BrandSearchMobileComponent implements OnInit {
  isTimeoutExpired: boolean;
  isBrandSelected: boolean;

  suggestions: Brand[] = [];
  brandInput = new FormControl('', Validators.maxLength(50));

  @Input() isMobile: boolean;
  @Input() item: ServiceItem;

  @Output() onSearch: EventEmitter<ServiceItem> = new EventEmitter<ServiceItem>();

  constructor(protected particleService: ParticleService) { }

  ngOnInit(): void {
    this.isTimeoutExpired = true;
    this.isBrandSelected = false;
    this.getSearchResults = this.getSearchResults.bind(this);
    if (this.item.brand) {
      this.isBrandSelected = true;
      this.brandInput.setValue(this.item.brand.name);
    }
  }

  search() {
    if (this.isTimeoutExpired) {
      this.isTimeoutExpired = false;
      setTimeout(this.getSearchResults, 1000);
    }
  }

  getSearchResults() {
    this.particleService
      .searchBrands(this.brandInput.value)
      .subscribe((suggestions) => {
        this.suggestions = suggestions;
        this.isTimeoutExpired = true;
      });
  }

  emitOnSearchEvent() {
    let brand: Brand = this.suggestions.filter(
      (b) => b.name === this.brandInput.value
    )[0];

    if (!brand) {
      brand = {
        name: this.brandInput.value,
        id: null,
      };
    }

    this.item.brand = brand;
    this.onSearch.emit(this.item);
  }

  selectBrand() {
    if (this.brandInput.value !== '') {
      this.isBrandSelected = true;
    }

    this.emitOnSearchEvent();
  }

  deselectBrand() {
    this.isBrandSelected = false;
  }

  setBrand(event) {
    const brand = event.currentTarget.innerText;
    this.brandInput.setValue(brand);
  }
}
