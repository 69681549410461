<shared-navigation-top *ngIf="isMobile" [title]="title"></shared-navigation-top>

<div id="app-refering" class="content animation-fade-in">
    <div class="media">
        <img src="assets/icons/refer-a-friend.svg">
    </div>

    <div id="refering-action" class="wrapper">
        <h1>Give £10, Get £10.</h1>
        <span>Introduce your friends to natural garment care and we will top up your account & theirs with £10 for every one that uses us. </span>
        <span>Simply share your unique link below to get some free credit with us: </span>
        <input matInput id="refering-link" type="text" [value]="link" [cdkCopyToClipboard]="link" (click)="showMessage()" readonly/>
        <button (click)="shareLink()" id="refering-submit" class="btn primary with-icon btn-share">Share</button>
    </div>
</div>
