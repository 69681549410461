import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { CommandEnum } from '../../models/common/enums/command.enum';
import { CommandResponse } from '../../models/common/command-response.model';
import { ICommandService } from './contracts/ICommandService';
import { TokenService } from './token.service';
import { HttpStatusCode } from '../../models/common/enums/http-status-code';
import { Router } from '@angular/router';
import { AlertService } from './alert.service';
import { Observable, Subject } from 'rxjs';
import {
  CookieService
} from 'ngx-cookie-service';
import { PersonalInfo } from 'src/app/models/user/personal-info.model';
import { InfoCustomer } from 'src/app/models/user/info-customer.model';

@Injectable({
  providedIn: 'root',
})
export class CommandService implements ICommandService {
  secretTokenHeaderName: string;
  endpoint: string;
  body: {
    command: string,
    body: string
  };
  body_settings: {
    RequestType : string,
    AccountKey : string,
    SessionID : string,
      Parameters : any
  };
  body_setting: {
    RequestType : string,
    AccountKey : string,
    SessionID : string,
    body: object
  };

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private cookieService: CookieService,
    private router: Router,
    private alertService: AlertService
  ) {
    this.secretTokenHeaderName = 'secret';
    this.endpoint = environment.blancApi;

    this.body = {
      command: '',
      body: ''
    };
    this.body_settings = {
      RequestType : '',
      AccountKey : environment.blancAccountKey,
      SessionID : '',
      Parameters :{}, 
    };
    this.body_setting = {
      RequestType : '',
      AccountKey : environment.blancAccountKey,
      SessionID : '',
      body: {}
    };
 
  }

  executeCommandObservable(command: CommandEnum, data: string | object = null): Observable<CommandResponse> {
    if (typeof data !== 'string') {
      data = JSON.stringify(data);
    }
    const secretToken = this.cookieService.get(this.secretTokenHeaderName);

    let headers = new HttpHeaders();
    headers = headers.set(this.secretTokenHeaderName, secretToken);

    this.body.command = command;
    this.body.body = data;
    let res: Subject<any> = new Subject();
    //`commands/execute`
    this.http.post<CommandResponse>(this.endpoint,
      this.body, { headers: headers, withCredentials: true })
      .subscribe(result => {
        res.next(result)
        console.log('result of CommandResponse',result)
      });
    return res;
  }

  executeCommand(command: CommandEnum, data: any | object = null) {

    this.body_settings.RequestType = command;
    this.body_settings.SessionID = this.cookieService.get('blanc_session_id');
    this.body_settings.Parameters = data;
    return this.http.post<any>(this.endpoint, this.body_settings).toPromise();
  }
  executeSimpleCommand(command: CommandEnum) {
    var settings ={
      'RequestType' : command,
      'AccountKey' : environment.blancAccountKey,
      'SessionID' : this.cookieService.get('blanc_session_id'),
    }
    return this.http.post<any>(this.endpoint, settings).toPromise();
  }
  executeUpdate(command: CommandEnum, data: any | object = null): Promise<any> {
    this.body_setting.RequestType = command;
    this.body_setting.SessionID = this.cookieService.get('blanc_session_id');
    this.body_setting.body = data;
    return this.http.post<any>(this.endpoint, this.body_setting).toPromise();
  }

  async initializeOrder() {
    this.cookieService.delete(this.secretTokenHeaderName);
    let token = await this.tokenService.refreshToken();
    this.cookieService.set(this.secretTokenHeaderName, token.toString());
    const response = await this.executeCommand(CommandEnum.OrderCreateCommand);
    return response.commandResult;
  }

  deleteSecretToken() {
    this.cookieService.delete(this.secretTokenHeaderName);
  }

  isCommandTokenExisting(): boolean {
    var result = this.cookieService.get(this.secretTokenHeaderName);
    return !!result;
  }

  handleCommandTokenError(
    error,
    redirectPath: string = '/',
    errorMessage: string = 'Order Session has expired!') {
    if (error.statusCode === HttpStatusCode.Unauthorized) {
      this.alertService.showError(errorMessage);
      this.router.navigate([redirectPath]);
      return;
    }

    this.alertService.showError(error.errorMessage);
    return;
  }
}
