import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Particle } from 'src/app/models/particle/particle.model';

@Component({
  selector: 'booking-give-instruction',
  templateUrl: './give-instruction.component.html',
  styleUrls: ['./give-instruction.component.scss']
})
export class GiveInstructionComponent implements OnInit {
  @Input() viewInstructions: Function;
  @Input() item: Particle

  constructor() { }

  ngOnInit(): void {
  }

}
