<div *ngIf="active_promo && show_promo == 0 && type !== 'SLIDER'" class="percentage-banner">
    <div class="percentage-progress">
        <img src="{{url}}" alt="Percentage loader">
    </div>
    <div class="text-content">
        <p><span class="special_promo">{{title}}</span></p>
        <div [innerHTML]="text"> </div>
        <!-- <a href="{{link}}" id="booking-btn">
            <span>Book Now</span>
            <img src="assets/icons/left-arrow-small.svg" alt="action preferences blanc" />
        </a> -->
    </div>
</div>

<div *ngIf="active_promo && show_promo == 0 && type == 'SLIDER'" width="100%">
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" style="padding : 0 2%;">
        <div ngxSlickItem *ngFor="let slide of slides" class="slide" width="100%">
            <a href="{{slide.link}}"> <img src="{{slide.url}}" alt="Percentage loader" width="100%" style="border-radius: 15px;"> </a>
        </div>
    </ngx-slick-carousel>
</div>