import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmModalService {
  public showModal = new BehaviorSubject<boolean>(false);
  public $showModal = this.showModal.asObservable();

  public submitForm = new BehaviorSubject<boolean>(false);
  public $submitForm = this.submitForm.asObservable();

  setShowModal(value: boolean) {
    this.showModal.next(value);
  }
  
  setSubmitForm(value: boolean) {
    this.submitForm.next(value);
  }
}
