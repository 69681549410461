import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor() { }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }
  setCookie(cname, cvalue) {
    var newDecodedCookie = cname + '=' + decodeURI(cvalue);
    var now = new Date();
    var todayPlusAYear = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate());

    document.cookie = newDecodedCookie + '; expires=' + todayPlusAYear.toString() + '; path=/';
  }
  setCookieForPeriod(cname, cvalue) {
    var newDecodedCookie = cname + '=' + decodeURI(cvalue);
    var now = new Date();
    var nowPlusAMinute = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() + 24, now.getMinutes(), now.getSeconds());

    document.cookie = newDecodedCookie + '; expires=' + nowPlusAMinute.toString() + '; path=/';
  }

  setCookieForLongPeriod(cname, cvalue) {
    var newDecodedCookie = cname + '=' + decodeURI(cvalue);
    var now = new Date();
    var nowPlusAMinute = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7, now.getHours(), now.getMinutes(), now.getSeconds());

    document.cookie = newDecodedCookie + '; expires=' + nowPlusAMinute.toString() + '; path=/';
  }
  removeCookie(cname) {
    var cookies = document.cookie.split(';');

    for (var i = 0; i < cookies.length; i += 1) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf('=');
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

      if (name.includes(cname) || name === cname) {
        document.cookie = name + '=;path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT';
      }
    }
  }
  clearCookies() {
    var cookies = document.cookie.split(';');

    for (var i = 0; i < cookies.length; i += 1) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf('=');
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }
}
