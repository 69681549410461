import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { StorageService } from 'src/app/services/common/storage.service';
import { AccountSettingService } from 'src/app/services/user/account-setting.service';
import { Routes } from 'src/app/constants/routes.constant';
import { PersonalInfoModalComponent } from 'src/app/shared/personal-info-modal/personal-info-modal.component';

@Component({
  selector: 'order-list-recurring-banner',
  templateUrl: './recurring-banner.component.html',
  styleUrls: ['./recurring-banner.component.scss']
})
export class RecurringBannerComponent {

  constructor(
    private accountSettingService: AccountSettingService,
    private storage: StorageService,
    public dialog: MatDialog,
    private router: Router,
  ) { }
  
  redirectToRecurring() {
    this.accountSettingService.getPersonalInfo().subscribe(response => {
      if (response.postalCode === '') {
        this.dialog.open(PersonalInfoModalComponent);
      } else {
        this.storage.savePostcode(response.postalCode);
        this.router.navigate([Routes.USER.RECURRING]);
      }
    });
  }
}
