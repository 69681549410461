<div class="h-padding v-padding">
  <h1>User Search</h1>
  <form (ngSubmit)="reset()">
    <ng-container [formGroup]="searchDates">
      <mat-form-field>
        <mat-label>Start Date</mat-label>
        <input matInput [matDatepicker]="startDatePicker" formControlName="startDate">
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label>End Date</mat-label>
        <input matInput [matDatepicker]="endDatePicker" formControlName="endDate">
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>
    </ng-container>

    <button class="button" type="submit">
      <span *ngIf="!working">Refresh</span>
      <shared-spinner *ngIf="working" color="white"></shared-spinner>
    </button>
  </form>

  <div *ngIf="error" class="mat-error">{{error}}</div>

  <div>
    <mat-form-field>
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia">
    </mat-form-field>

    <div class="mat-elevation-z8">
      <table mat-table #matTable [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Created </th>
          <td mat-cell *matCellDef="let row"> {{row.createdAt}}</td>
        </ng-container>
        
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Full name </th>
          <td mat-cell *matCellDef="let row"> {{row.firstName}} {{row.lastName}}</td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
          <td mat-cell *matCellDef="let row"> {{row.userName}}</td>
        </ng-container>

        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone </th>
          <td mat-cell *matCellDef="let row"> {{row.phoneNumber}}</td>
        </ng-container>

        <ng-container matColumnDef="verified">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Verified </th>
          <td mat-cell *matCellDef="let row"> {{row.emailConfirmed}}</td>
        </ng-container>

        <ng-container matColumnDef="marketing">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Marketing </th>
          <td mat-cell *matCellDef="let row"> {{row.receiveOffers}}</td>
        </ng-container>

        <ng-container matColumnDef="spot">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Linked<br/>to Spot </th>
          <td mat-cell *matCellDef="let row"> {{row.spotCustomerId ? 'true' : 'false'}}</td>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
    </div>
  </div>
</div>