<form [formGroup]="recurringForm" (ngSubmit)="onFormSubmit()" [ngClass]="{'desktop-version': !isMobile}" class="recurring-form animation-fade-in">
    <p class="mobile-only">Why not save yourself some time and let us take the weekly laundry off your to-do list? Let us know the slot that works for you.</p>



    <div class="fields-wrapper benefits">
        <h2>Benefits</h2>
        <div>

            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="26" height="26" viewBox="0 0 172 172" style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#47454b"><path d="M149.28546,31.29387l-11.86117,-8.0625c-3.28185,-2.22236 -7.77825,-1.36959 -9.97476,1.88642l-58.14303,85.74159l-26.71996,-26.71996c-2.79087,-2.79087 -7.33894,-2.79087 -10.12981,0l-10.15565,10.15565c-2.79087,2.79087 -2.79087,7.33894 0,10.15565l41.08774,41.08774c2.29988,2.29988 5.91767,4.05709 9.17368,4.05709c3.25601,0 6.53786,-2.04146 8.65685,-5.11658l69.97837,-103.23618c2.22235,-3.25601 1.36959,-7.72656 -1.91226,-9.94892z"></path></g></g></svg>
            <span>
                No delivery fee
            </span>

        </div>
        <div>

            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="26" height="26" viewBox="0 0 172 172" style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#47454b"><path d="M149.28546,31.29387l-11.86117,-8.0625c-3.28185,-2.22236 -7.77825,-1.36959 -9.97476,1.88642l-58.14303,85.74159l-26.71996,-26.71996c-2.79087,-2.79087 -7.33894,-2.79087 -10.12981,0l-10.15565,10.15565c-2.79087,2.79087 -2.79087,7.33894 0,10.15565l41.08774,41.08774c2.29988,2.29988 5.91767,4.05709 9.17368,4.05709c3.25601,0 6.53786,-2.04146 8.65685,-5.11658l69.97837,-103.23618c2.22235,-3.25601 1.36959,-7.72656 -1.91226,-9.94892z"></path></g></g></svg>

            <span>
                No need to book each week
            </span>

        </div>
        <div>

            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="26" height="26" viewBox="0 0 172 172" style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#47454b"><path d="M149.28546,31.29387l-11.86117,-8.0625c-3.28185,-2.22236 -7.77825,-1.36959 -9.97476,1.88642l-58.14303,85.74159l-26.71996,-26.71996c-2.79087,-2.79087 -7.33894,-2.79087 -10.12981,0l-10.15565,10.15565c-2.79087,2.79087 -2.79087,7.33894 0,10.15565l41.08774,41.08774c2.29988,2.29988 5.91767,4.05709 9.17368,4.05709c3.25601,0 6.53786,-2.04146 8.65685,-5.11658l69.97837,-103.23618c2.22235,-3.25601 1.36959,-7.72656 -1.91226,-9.94892z"></path></g></g></svg>

            <span>
                Get priority for your preferred slot(s)
            </span>

        </div>
        <div>

            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="26" height="26" viewBox="0 0 172 172" style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#47454b"><path d="M149.28546,31.29387l-11.86117,-8.0625c-3.28185,-2.22236 -7.77825,-1.36959 -9.97476,1.88642l-58.14303,85.74159l-26.71996,-26.71996c-2.79087,-2.79087 -7.33894,-2.79087 -10.12981,0l-10.15565,10.15565c-2.79087,2.79087 -2.79087,7.33894 0,10.15565l41.08774,41.08774c2.29988,2.29988 5.91767,4.05709 9.17368,4.05709c3.25601,0 6.53786,-2.04146 8.65685,-5.11658l69.97837,-103.23618c2.22235,-3.25601 1.36959,-7.72656 -1.91226,-9.94892z"></path></g></g></svg>

            <span>
                Collect & drop-off on same visit
            </span>

        </div>
        <div>

            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="26" height="26" viewBox="0 0 172 172" style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#47454b"><path d="M149.28546,31.29387l-11.86117,-8.0625c-3.28185,-2.22236 -7.77825,-1.36959 -9.97476,1.88642l-58.14303,85.74159l-26.71996,-26.71996c-2.79087,-2.79087 -7.33894,-2.79087 -10.12981,0l-10.15565,10.15565c-2.79087,2.79087 -2.79087,7.33894 0,10.15565l41.08774,41.08774c2.29988,2.29988 5.91767,4.05709 9.17368,4.05709c3.25601,0 6.53786,-2.04146 8.65685,-5.11658l69.97837,-103.23618c2.22235,-3.25601 1.36959,-7.72656 -1.91226,-9.94892z"></path></g></g></svg>

            <span>
                No commitment: cancel or pause anytime
            </span>

        </div>
    </div>
    <div class="fields-wrapper type">
        <h2 class="mobile-only">Choose your recurrence</h2>
        <mat-radio-group formControlName="type" (change)="resetData()">
            <mat-radio-button [value]=typeEnum.OnceAWeek>Once a week</mat-radio-button>
            <mat-radio-button [value]=typeEnum.TwiceAWeek>Twice a week</mat-radio-button>
        </mat-radio-group>
    </div>

    <div class="fields-wrapper day">
        <h2>Pickup & delivery days</h2>
        <span *ngIf="getFormControlValue('type') === typeEnum.OnceAWeek" class="info" >We will come to your chosen address each week on the day & time selected to pick up your items to clean and deliver your cleaned items from the week before at same time.</span>
        <span *ngIf="getFormControlValue('type') === typeEnum.TwiceAWeek" class="info">We will come to your chosen address twice a week to pick up your items to clean and deliver your cleaned items from the last pick up. Note that the 2 slots need to be 3 days apart so we have time to process your order.</span> 
       
        <ul class="days-of-week">
            <li *ngFor="let slot of slotsByDay | slice:1; index as i;">
                <a (click)="setSlots(i+1)" [ngClass]="getCssClasses(i+1)" class="day">
                    <span>{{getLabel(i+1)}}</span>
                </a>
            </li>
        </ul>
    </div>

    <div *ngIf="pickupSlots !== null" class="fields-wrapper slots animation-fade-in slots-block">
        <div>
            <h2>Pickup & delivery slot </h2>

            <div *ngIf="pickupSlots !== null" class="wrapper pickup">
                <span *ngIf="getFormControlValue('type') === typeEnum.OnceAWeek">Select a slot</span>
                <span *ngIf="getFormControlValue('type') === typeEnum.TwiceAWeek">Select first slot</span>
                <mat-form-field>
                    <mat-select formControlName="pickupSlot" disableOptionCentering>
                        <mat-option *ngFor="let slot of pickupSlots; let i = index" [value]="slot.value">
                            {{slot.format}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div *ngIf="getFormControlValue('type') === typeEnum.TwiceAWeek" class="wrapper delivery">
                <span>Select second slot</span>
                <mat-form-field>
                    <mat-select formControlName="deliverySlot" disableOptionCentering>
                        <mat-option *ngFor="let slot of deliverySlots; let i = index" [value]="slot.value">
                            {{slot.format}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>

    <button *ngIf="isMobile" [disabled]="recurringForm.invalid" class="btn primary submit small" type="submit">Save</button>
    <button *ngIf="!isMobile" [disabled]="recurringForm.invalid" class="btn primary absolute" type="submit">Book</button>
</form>
