import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminService, OrderDetail } from '../admin.service';
import { FormGroup, FormControl } from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource, MatTable} from '@angular/material/table';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { User } from 'src/app/models/user/user.model';

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss']
})
export class UserSearchComponent implements OnInit {

  searchDates = new FormGroup({
    startDate: new FormControl(new Date()),
    endDate: new FormControl(new Date())
  });

  working = false;
  error;

  displayedColumns: string[] = ['created', 'name', 'email', 'phone', 'verified', 'marketing', 'spot'];
  dataSource: MatTableDataSource<User>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  matTable: MatTable<User>;

  constructor(private admin: AdminService) { 
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;    
    this.reset()
  }

  reset() {
    let startDate = this.searchDates.get('startDate').value;
    let endDate = this.searchDates.get('endDate').value;

    this.admin.getAllUsers(startDate, endDate)
              .then(users => {
                this.dataSource.data = users;
                if (this.matTable) {
                  console.info("Rendering");
                  this.matTable.renderRows();
                }
              })
              .catch(err => this.error = err.error.description)
              .finally(() => this.working = false);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}