import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SummaryAddress } from 'src/app/models/booking/summary-address.model';
import { CommandService } from 'src/app/services/common/command.service';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { AlertService } from 'src/app/services/common/alert.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Routes } from 'src/app/constants/routes.constant';
import { OrderTrackingTypeEnum } from 'src/app/models/order/enums/order-tracking-type.enum';
import { CookieService } from 'ngx-cookie-service';
import { StatusOrder } from 'src/app/models/order/enums/status-order.enum';
import * as moment from 'moment';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent {
  isMobile: boolean;
  isLoading: boolean = true;
  order: any;
  address: SummaryAddress;
  ordertype: OrderTrackingTypeEnum;
  isRecurring: boolean = false;
  cookieService: CookieService;
  myDate_pickup : any;
  orderId : string;
  statusOrder = new StatusOrder;
  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected commandService: CommandService,
    protected alertService: AlertService,
    protected deviceService: DeviceDetectorService,
  ) {
    this.isMobile = this.deviceService.isMobile();
    this.orderId = this.route.snapshot.paramMap.get('id');

    var param = {
      infoOrder_id : +this.orderId,
    }
 
    this.commandService.executeCommand(CommandEnum.OrderDetailsCommand, param).then(result => {
      this.order = result.ReturnObject;

      var allStatus = this.statusOrder.getCurrentStatusOrder();
      var allStatusPast = this.statusOrder.getPastStatusOrder();
      this.order.Status=allStatus[this.order.Status.toUpperCase()]?allStatus[this.order.Status.toUpperCase()]:this.order.Status;
      if ( !(allStatus[this.order.Status.toUpperCase()]) && !(allStatusPast[this.order.Status.toUpperCase()]) ) {

        this.router.navigate([Routes.ORDER.LIST]);
      }

      this.isRecurring = (this.order.Status.toUpperCase() == "RECURRING") ? true : false ;
      // show cancel button if date pickup >= today
      this.myDate_pickup = new Date( this.order.DatePickup.toString());
      const current_date = new Date();
      const cancellationDueDate = new Date(this.myDate_pickup - 24 * 60 * 60 * 1000 );
      cancellationDueDate.setHours(17, 0, 0);

      if (moment(current_date).isBefore(cancellationDueDate) ) {
        this.order.CanBeCancelled = true;
      } else {
        this.order.CanBeCancelled = false;
      }
      this.isLoading = false;
    })
    .catch(
      err => {console.log(err)
      this.router.navigate([Routes.ORDER.LIST]);}
      )
    ;
  }

  initData() {
    let orderId = this.route.snapshot.paramMap.get('id');
    var param = {
      infoOrder_id : +orderId,
    }
    
    
    this.commandService.executeCommand(CommandEnum.GetDeliveryByOrder, param)
      .then(response => {
        localStorage.setItem('Pickup_ID',response.ReturnObject.PickupID);
        localStorage.setItem('Deliveryask_ID',response.ReturnObject.DeliveryaskID);
        localStorage.setItem('order_response',JSON.stringify(response.ReturnObject));
      })
  }

  cancelOrder() {

    this.isLoading = true;
    // delete pickup
    var paramDelivry = {
      DeliveryaskID : localStorage.getItem('Deliveryask_ID')
    }
    this.commandService.executeCommand(CommandEnum.DeleteDelivery, paramDelivry)
      .then(result => {
        console.log('Delete Order Done !');
        })
      .catch(err => {
        this.alertService.showError(err.error.errorMessage)
      })
      .finally(() => { return this.isLoading = true; });
      // delete delivery 
      var paramPickup = {
        PickupID : localStorage.getItem('Pickup_ID')
      }
      this.commandService.executeCommand(CommandEnum.DeletePickup, paramPickup)
        .then(result => {
          this.router.navigate([Routes.ORDER.LIST]);
        })
        .catch(err => {
          this.alertService.showError(err.error.errorMessage)
        })
        .finally(() => { this.isLoading = false; });
  }
}
