import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { UserRoleEnum } from 'src/app/models/user/enums/user-role.enum';

import { AuthService } from '../user/auth.service';

@Injectable()
export class
  AdminGuardService implements CanActivate {

  constructor(public auth: AuthService, public router: Router) {}

  canActivate(): boolean {  
    return false
    // this.auth.getUser().then((user) => {
    //   if (user?.claims?.role === UserRoleEnum.Admin) {
    //     return true;
    //   } else {
    //     this.router.navigate(['/welcome']);
    //     return false;
    //   }
    // })
  }
}
