<div class="content">
    <div class="heading">
        <shared-back-button></shared-back-button>
        <h1>Reschedule your order</h1>
        <p>Because the total turnaround for your item(s) is 5 days, we now need you to reschedule your delivery.</p>
    </div>
    <form [formGroup]="rescheduleForm" (ngSubmit)="onFormSubmit()" id="reschedule-form">
        <mat-radio-group formControlName="choice">
            <mat-radio-button [value]="rescheduleTypeEnum.AllItems">I want to delay the delivery for the whole order (free of charge)
            </mat-radio-button>
            <mat-radio-button [value]="rescheduleTypeEnum.PendingItems">I want to receive the ready items as scheduled and the pending item(s) in a separate delivery later (free of charge)</mat-radio-button>
        </mat-radio-group>

        <button [disabled]="buttonDisabled" class="btn primary bottom-fixed with-arrow input" type="submit">Reschedule</button>
    </form>

    <p class="message">Please choose a delivery slot</p>

    <div class="delivery-slot">
        <div *ngIf="!rescheduleForm.dirty" id="schedule-disabled" class="overlay"></div>
        <booking-schedule [type]="slotType"></booking-schedule>
    </div>
</div>