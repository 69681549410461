import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthorisationsBase } from '../../authorisations.base';
import { Authorisation } from 'src/app/models/user/authorisation.model';
import { AccountSettingService } from 'src/app/services/user/account-setting.service';
import { AlertService } from 'src/app/services/common/alert.service';
import { HttpClient } from '@angular/common/http';
import { CommandService } from 'src/app/services/common/command.service';

@Component({
  selector: 'user-authorisations-form-desktop',
  templateUrl: './authorisations-form.component.html',
  styleUrls: ['./authorisations-form.component.scss']
})
export class AuthorisationsFormDesktopComponent extends AuthorisationsBase {
  authorizations: Authorisation[];
  authorisationsForm: FormGroup;
  redirectUrl: string;

  @Input() isInBookingProcess: boolean;

  constructor(
    commandService: CommandService,
    http: HttpClient,
    formBuilder: FormBuilder,
    accountSettingService: AccountSettingService,
    alertService: AlertService,
    router: Router,
  ) {
    super(commandService,http, formBuilder, accountSettingService, alertService, router);
  }
}
