import { Component, OnInit, ElementRef, Inject,  } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss']
})
export class ShareModalComponent implements OnInit {

  link: string;

  constructor(@Inject(DOCUMENT) private document, private elementRef: ElementRef, @Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit(): void {
    this.link = this.data.link;
  }

  ngAfterViewInit() {
    const s = this.document.createElement('script');
    s.type = 'text/javascript';
    s.src = '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5f041590d4f0db7d';
    this.elementRef.nativeElement.appendChild(s);
  }
}
