import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { BookingBase } from '../../booking.base';

@Component({
  selector: 'booking-sustainability-section',
  templateUrl: './sustainability-section.component.html',
  styleUrls: ['./sustainability-section.component.scss']
})
export class SustainabilitySectionComponent extends BookingBase implements OnInit {
  
  constructor(protected deviceService: DeviceDetectorService) {
    super(deviceService)
  }

  ngOnInit(): void {
  }

}
