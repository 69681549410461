import { Component, OnInit, Input } from '@angular/core';

import { LogoButtonEnum } from 'src/app/models/shared/enums/logo-button.enum';

@Component({
  selector: 'shared-logo-button',
  templateUrl: './logo-button.component.html',
  styleUrls: ['./logo-button.component.scss']
})
export class LogoButtonComponent implements OnInit {
  logoButtonEnum: typeof LogoButtonEnum = LogoButtonEnum;
  @Input() logoType: LogoButtonEnum;

  constructor() { }

  ngOnInit(): void {
  }
}
