import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { RecurringService } from 'src/app/services/user/recurring.service';
import { Recurring } from 'src/app/models/user/recurring.model';
import { ReccurenceStateEnum } from 'src/app/models/user/enums/recurrence-state.enum';
import { CommandService } from 'src/app/services/common/command.service';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { InfoCustomer } from 'src/app/models/user/info-customer.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-pause-modal',
  templateUrl: './pause-modal.component.html',
  styleUrls: ['./pause-modal.component.scss'],
})
export class PauseModalComponent implements OnInit {
  defaultDate: string = '0001-01-01';
  dateFormat: string = 'DD MMM YYYY';
  pauseStartMin: string = '0001-01-01';
  pauseEndMin: string;
  user: any;
  add: any;
  recurring: InfoCustomer;
  rec: Recurring;
  pauseForm: FormGroup;
  loading = false;
  infoCustomer: any;
  constructor(
    public dialogRef: MatDialogRef<PauseModalComponent>,
    private recurringService: RecurringService,
    private commandService: CommandService,
    private formBuilder: FormBuilder,
    public datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.recurringService.$recurring.subscribe((value) => {
      this.rec = value;
    });
    this.initForm();
  }

  initForm() {
    this.commandService
      .executeCommand(CommandEnum.CustomerDetail)
      .then((resp) => {
        this.user = resp.ReturnObject.infoCustomer;
        this.add = resp.ReturnObject.address;
        this.pauseForm = this.formBuilder.group({
          PauseDateFrom: [this.user.PauseDateFrom, Validators.required],
          PauseDateTo: [this.user.PauseDateTo, Validators.required],
        });
      })
      .catch((err) => console.log('falsy'));
  }

  getFormControlValue(fieldName: string) {
    const today = new Date().toISOString();
    const mydateFrom = this.pauseForm.get('PauseDateFrom').value;
    this.pauseStartMin = this.calculateMinValue(today, 1);
    if (this.datepipe.transform(mydateFrom, 'yyyy-MM-dd') === null) {
      this.pauseEndMin = this.calculateMinValue(today, 2);
    } else {
      this.pauseEndMin = this.calculateMinValue(
        this.datepipe.transform(mydateFrom, 'yyyy-MM-dd'),
        1
      );
    }

    //onChangeMethode
    this.pauseForm.get('PauseDateFrom').valueChanges.subscribe((data) => {
      this.user.PauseDateFrom = this.formatDate(data);
      this.pauseEndMin = this.calculateMinValue(this.user.PauseDateFrom, 1);
      if (
        new Date(this.user.PauseDateFrom) >= new Date(this.user.PauseDateTo)
      ) {
        this.pauseForm.get('PauseDateTo').setValue(this.defaultDate);
      }
    });

    this.pauseForm.get('PauseDateTo').valueChanges.subscribe((x) => {
      this.user.PauseDateTo = this.formatDate(x);
    });
    return this.pauseForm.get(fieldName).value;
  }

  async onFormSubmit() {
    this.loading = true;
    this.recurringService.setState(ReccurenceStateEnum.Loading);

    let customerDetails = await this.commandService.executeCommand(
      CommandEnum.CustomerDetail
    );
    this.recurringService.setRecurring(this.rec);

    customerDetails.ReturnObject.infoCustomer.PauseDateFrom = this.formatDate(
      this.getFormControlValue('PauseDateFrom')
    );
    customerDetails.ReturnObject.infoCustomer.PauseDateTo = this.formatDate(
      this.getFormControlValue('PauseDateTo')
    );

    await this.commandService
      .executeUpdate(CommandEnum.SaveCustomer, customerDetails.ReturnObject)
      .then((response) => {
        this.recurringService.setState(ReccurenceStateEnum.View);
        this.closeDialog();
      })
      .catch((error) => {
        this.commandService.handleCommandTokenError(error);
      });
  }

  calculateMinValue(minDate: string, additionalDays: number) {
    const startDate = new Date(minDate);
    startDate.setDate(startDate.getDate() + additionalDays);
    const minValue = startDate.toISOString();
    return minValue;
  }

  formatDate(dateToAdjust: Date): string {
    if (String(dateToAdjust) === this.defaultDate) {
      return this.defaultDate;
    }
    const timeSelected = new Date(dateToAdjust);
    const offsetMs = timeSelected.getTimezoneOffset() * 60000;
    const date = new Date(timeSelected.getTime() - offsetMs);
    const dateAsString = date.toISOString();
    return dateAsString;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
