import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/user/auth.service';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {

  token: string;

  constructor(auth: AuthService) {
    auth.authToken.subscribe(t => this.token = t);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.token) {
      req = req.clone({
        headers: req.headers.append('Authorization', 'Bearer ' + this.token)
      });
    }
    return next.handle(req);
  }
}