import { Particle } from './particle.model';

export class ParticleAdditionalInstruction {
  id: string;
  name: string;
  parsedValue: {
    isChecked: boolean;
    hasText: boolean;
    text: string;
  };
  parentId: string;
  hasChildren: boolean;

  constructor(particle: Particle) {
    this.id = particle.id;
    this.name = particle.name;
    this.parentId = particle.parentId;
    this.hasChildren = particle.hasChildren;
    this.parsedValue = JSON.parse(particle.value);
  }
}