import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'toMoment'
})
export class ToMomentPipe implements PipeTransform {

  transform(value: string, format?: string): moment.Moment {
    if (value) {
      format = format ? format : 'YYYY-MM-DD';
      return moment(value, format);
    }
  }

}
