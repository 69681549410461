import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'user-change-password-mobile',
  templateUrl: './change-password-mobile.component.html',
  styleUrls: ['./change-password-mobile.component.scss']
})
export class ChangePasswordMobileComponent implements OnInit {
  title: string = 'Change password';
  
  constructor() { }

  ngOnInit(): void {
  }

}
