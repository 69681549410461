import { OrderTrackingTypeEnum } from 'src/app/models/order/enums/order-tracking-type.enum';
import { Recurring } from 'src/app/models/user/recurring.model';
import { Component, Input, OnInit } from '@angular/core';

import { OrderList } from 'src/app/models/order/order-list.model';
import { Routes } from 'src/app/constants/routes.constant';
import { ListService } from '../list.service';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'order-list-current',
  templateUrl: './list-current.component.html',
  styleUrls: ['./list-current.component.scss'],
})
export class ListCurrentComponent implements OnInit {
  orderList: OrderList = { count: 0, orders: [] };
  isLoading: boolean = true;
  orderDetailsRoute = Routes.ORDER.DETAILS;
  recurringOrderRoute = Routes.USER.RECURRING;
  recurring: Recurring;
  pickupRec: any;
  deliveryRec: any;
  countcurrentorder: any;
  type: string;
  current_date : Date = new Date();
  css : string = 'box order';
  isMobile: boolean;
  orderselected: string;
  @Input() fromParent: any;

  constructor(
    public listservice: ListService,
    private deviceService: DeviceDetectorService,
  ) {
    
  }

  ngOnInit() {
    let myDate_pickup;
    this.isMobile = this.deviceService.isMobile();
    this.fromParent.forEach(function(item){
      if(item.PickupDate){
        myDate_pickup = new Date(item.PickupDate.toString()).toDateString();
      }else if(item.DatePickup){
        myDate_pickup = new Date(item.DatePickup.toString()).toDateString();
      }
      const current_date = new Date().toDateString();
      if (item.TypeDelivery != 'STORE'){
        if ( moment(current_date).isSameOrBefore(myDate_pickup) ) {
          item.typeAsk = 'Pickup';
          
        }else {
          item.typeAsk = 'Delivery';
        }
      } else {
        item.typeAsk = 'Collection';
      }
      
    })
   
    this.orderList.orders.push(...this.fromParent);
    // let tmpArray = this.orderList.orders;

    // tmpArray = this.formatOrderListToBeSorted(tmpArray);
    // this.quicksort(0, tmpArray.length - 1, tmpArray);
    // const sortedArray = [];
    // // map the sorted orders by their index
    // for (let i = 0; i < tmpArray.length; i++) {
    //   sortedArray[i] = this.orderList.orders[tmpArray[i]['index']];
    // }
    // sortedArray.sort(this.compare);
    // this.orderList.orders = sortedArray;
    this.isLoading = false;
    if (!this.isMobile) {
      this.orderselected = "order-desk-selected";
    }else{
      this.orderselected = "order-mobile-selected";
    }
  }

  /**
   * function to sort list order => in process in the top 
   */
  compare( a ) {
    if ( a.Status == 'IN PROCESS' ){
      return 1;
    }
    return 0;
  }

  /**
   * format the array to be sorted , picking either DatePickup or DateDeliveryAsk and index
   * => {date: DatePickup or DateDeliveryAsk, index: index in this.orderList.orders}
   */
  formatOrderListToBeSorted(orderListToFormat) {
    const currentDate = new Date().toDateString();
    const tmpArray = [];
    for (let i = 0; i < orderListToFormat.length; i++) {
      if (moment(currentDate).isSameOrBefore(orderListToFormat[i].DeliveryDate)) {
        tmpArray.push({date: orderListToFormat[i].DeliveryDate, index: i});
      } else {
        tmpArray.push({date: orderListToFormat[i].DatePickup, index: i});
      }
    }
    return tmpArray;
  }

  /**
   * quick sort for sorting the formatted array
   */
  quicksort(low, high, arrayToSort): void {
    let i = low;
    let j = high;
    const pivot = arrayToSort[Math.floor((low + high) / 2)];
    while (i <= j) {
      while (arrayToSort[i].date < pivot.date) {
        i++;
      }

      while (arrayToSort[j].date > pivot.date) {
        j--;
      }

      if (i <= j) {
        [arrayToSort[i], arrayToSort[j]] = [arrayToSort[j], arrayToSort[i]];
        i++;
        j--;
      }
    }

    if (low < j) {
      this.quicksort(low, j, arrayToSort);
    }
    if (i < high) {
      this.quicksort(i, high, arrayToSort);
    }
  }

  orderLink(order) {
    if(OrderTrackingTypeEnum[order.TypeDelivery]){
      this.type = OrderTrackingTypeEnum[order.TypeDelivery]
    }else{
      this.type = 'Drop-off & Collection details';
    }
    return [
      this.orderDetailsRoute,
      order.id,
      this.type,
    ];
  }
  
}
