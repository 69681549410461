<shared-header [isBooking]="true"></shared-header>
<booking-progress-bar-mobile [step]=step></booking-progress-bar-mobile>

<div *ngIf="!hasAllData" class="spinner-wrapper"><shared-spinner></shared-spinner></div>

<div *ngIf="hasAllData" class="content summary animation-fade-in">
  <shared-back-button></shared-back-button>

  <header>
    <div class="media">
      <img src='assets/icons/summary.svg'>
      <span>summary</span>
    </div>
  </header>

  <ul class="summary">
    <li *ngIf="address" class="address" id="address">
      <div class="heading">
        <p>Address</p>
      </div>
      <div class="info">
        <p class="address-line-1">{{address.addressLine1}}</p>
        <p class="address-line-2">{{address.addressLine2}} {{address.postcode}}</p>
      </div>
    </li>
    <hr>

    <li *ngIf="deliverySlot" class="slots" id="slots">
      <div class="heading">
        <p>Delivery</p>
        <a routerLink="/delivery/slot" [queryParams]="{'redirect': '/delivery/summary'}"
          class="btn href green"><span>Edit</span></a>
      </div>
      <div class="info">
        <p class="delivery-date">{{deliverySlot?.date | toMoment | dateFormat:dateFormat}}</p>
        <p class="delivery-hour">{{deliverySlot?.from | hourFormat}} - {{deliverySlot?.to | hourFormat}}</p>
      </div>
    </li>

    <li *ngIf="hasInstructions" class="deliverTo" id="deliverTo">
      <div class="heading">
        <p>Contact</p>
      </div>
      <div class="info">
        <p class="name">{{instructions.deliverToName}}</p>
        <p class="phone-number">({{instructions.countryCode}}) {{instructions.phoneNumber}}</p>
      </div>
    </li>
    <hr *ngIf="hasInstructions">

    <li *ngIf="payment" class="payment" id="payment">
      <div class="heading">
        <p>Payment details</p>
      </div>
      <div class="info">
        <p class="card-info">{{payment.CardInfo}}</p>
      </div>
    </li>
  </ul>

  <button (click)="confirmBooking()" class="btn primary bottom-fixed with-arrow" id="confirm-booking-btn"
    type="submit">Confirm booking</button>
</div>
