import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService as AuthGuard } from 'src/app/services/guards/auth-guard.service';
//import { AdminGuardService as AdminGuard } from 'src/app/services/guards/admin-guard.service';
import { AdminComponent } from './admin.component';
import { ScheduleManagerComponent } from './schedule-manager/schedule-manager.component';
import { SpotManagerComponent } from './spot-manager/spot-manager.component';
import { OrderSearchComponent } from './order-search/order-search.component';
import { UserSearchComponent } from './user-search/user-search.component';
import { HolidayManagerComponent } from './holiday-manager/holiday-manager.component';

const routes: Routes = [
  { 
    // path: '',
    // canActivate: [AuthGuard, AdminGuard],
    // component: AdminComponent, 
    // children: [
    //   {
    //     path: '',
    //     redirectTo: 'spot',
    //     pathMatch: 'full',
    //   },
    //   {
    //     path: 'spot', 
    //     component: SpotManagerComponent
    //   }, 
    //   {
    //     path: 'schedules',
    //     component: ScheduleManagerComponent
    //   }, 
    //   {
    //     path: 'users',
    //     component: UserSearchComponent
    //   }, 
    //   {
    //     path: 'orders',
    //     component: OrderSearchComponent
    //   }, 
    //   {
    //     path: 'holidays',
    //     component: HolidayManagerComponent
    //   }
    // ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
