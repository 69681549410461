<!-- <div class="banner">
  <div class="left-wrapper">
    <p class="label">Items</p>
    <p class="count">{{order.countAsString}}</p>
  </div>
  <img src="assets/icons/line-dashed-white.svg" class="line">

  <div class="right-wrapper">
    <p class="label">Invoice</p>
    <h3 class="price">{{order.totalAsString}}</h3>
  </div>
</div> -->

<div class="banner">
  <div class="left-wrapper">

      <p class="label">Item(s)</p>
      <p *ngIf = "order.items.length == 0" class="count">-</p>
      <p *ngIf = "order.items.length != 0"class="count">{{order.items.length}}</p>
  </div>

  <img src="assets/icons/line-dashed-white.svg" class="line">
  <div class="right-wrapper">
      <p class="label">Total (incl. VAT & Discounts)</p>
      <p *ngIf = "order.items.length == 0" class="price">-</p>
      <p *ngIf = "order.items.length != 0 && order.Total == 0" class="price">{{order.Total}}</p>
      <p *ngIf = "order.items.length != 0 && order.Total != 0" class="price">£{{order.Total}}</p>
  </div>

</div>