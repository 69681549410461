import { Component, Input, OnInit } from '@angular/core';
import { OrderDetails } from 'src/app/models/order/order-details.model';

@Component({
  selector: 'order-details-banner-info',
  templateUrl: './info.component.html',
  styleUrls: ['./../banner-wrapper.component.scss', './info.component.scss']
})
export class InfoComponent implements OnInit {

  @Input() order: any;

  constructor() { }

  ngOnInit(): void {
    this.order.Total = this.order.Total.toFixed(2);
  }

}