<div class="content animation-fade-in"> 
  <div class="wrapper">
    <shared-background-rectangle></shared-background-rectangle>
    <h1>Personal information</h1>

    <div class="box">
      <h4>Contact information</h4>
      <user-personal-info-form></user-personal-info-form>
    </div>

    <div class="box">
      <h4>Payment information</h4>
    <app-card-wrapper></app-card-wrapper> 
    </div>
  </div>
</div>
