import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlancCommandService } from 'src/app/services/common/blanc-command.service';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { AlertService } from 'src/app/services/common/alert.service';
import { Routes } from 'src/app/constants/routes.constant';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-card-confirmation',
  templateUrl: './card-confirmation.component.html',
  styleUrls: ['./card-confirmation.component.scss'],
})
export class CardConfirmationComponent implements OnInit {
 
  isMobile : any;
  constructor(
    private route: ActivatedRoute,
    private blancCommandService: BlancCommandService,
    protected alertService: AlertService,
    protected router: Router,
    protected deviceService: DeviceDetectorService
  ) { }
  async ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
    if(this.route.snapshot.queryParams['setup_intent']){
      let Parameters= {
          "StripeTest":false,
          "SetupIntent":this.route.snapshot.queryParams['setup_intent']
        }
      this.blancCommandService.executeCommand(CommandEnum.ConfirmCard, Parameters)
      .then( response => {
        if(response['err'] !== false){
          this.alertService.showError(response['err_txt']);
        }else{
          this.alertService.showSuccess('Success! Payment details saved');
          if(this.route.snapshot.queryParams['camefrom'] == "booking"){
            this.router.navigate([Routes.BOOKING_REGULAR.SUMMARY])
          }else if(this.route.snapshot.queryParams['camefrom'] == "personal-info"){
            this.router.navigate([Routes.USER.PERSONAL_INFO])
          }else if(this.route.snapshot.queryParams['camefrom'] == "payments"){
            this.router.navigate([Routes.USER.PAYMENTS])
          }else{
            console.log("cameFrom",this.route.snapshot.queryParams['camefrom'])
          }
        }
       })
    }
  
  }
 


}
