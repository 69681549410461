<div *ngIf="shouldDisplay" class="content authorisations-desktop animation-fade-in">
  <div class="grey-background"></div>

  <div class="wrapper">
    <div class="heading">
      <shared-back-button></shared-back-button>
      <h1>Authorisations</h1>
      <p>In order to provide you with the best service possible, we need to know what is important to you.</p>
    </div>

    <user-authorisations-form-desktop [isInBookingProcess]="true"></user-authorisations-form-desktop>
  </div>
</div>
