<div class="content service-desktop animation-fade-in">
    <div class="grey-background"></div>

    <div *ngIf="isLoading" class="spinner-wrapper">
        <shared-spinner></shared-spinner>
    </div>

    <div *ngIf="!isLoading" class="wrapper animation-fade-in">
        <div class="heading">
            <h3>{{serviceModel.parentItem.name}} instructions</h3>
            <shared-back-button></shared-back-button>
        </div>
        <div class="service">
            <div class="info-card">
                <p class="title">{{serviceModel.parentItem.name}}</p>
                <span class="price">{{serviceModel.parentItem.parsedValue.priceDescription}}</span>
                <div class="media" [ngClass]="serviceModel.parentItem.parsedValue.css">
                    <img src="assets/icons/{{serviceModel.parentItem.parsedValue.css}}.svg" alt="service">
                </div>
                <span class="description">{{serviceModel.parentItem.parsedValue.description}}</span>
            </div>
            <div class="instructions">
                <div *ngIf="serviceModel.parentItem.parsedValue.viewType === 0">
                    <div class="instruction-cards">
                        <div *ngIf="serviceModel.items.length > 0; then thenBlock; else elseBlock"></div>
                        <ng-template #thenBlock>
                            <div *ngFor="let item of serviceModel.items; let i = index">
                                <ng-container *ngIf="item.selectedState === itemStateEnum.ItemWithBrandCard">
                                    <div class="empty-item">
                                        <div class="icon close" (click)="removeItem(item)"></div>
                                        <div class="item-wrapper">
                                            <p>Item n°{{i+1}}</p>
                                            <p class="item-name">
                                                {{item.selected.nameInSpot}}
                                            </p>
                                        </div>

                                        <div class="brand-search">
                                            <booking-brand-search-desktop [item]="item" (onSearch)="updateItemWithBrand($event)">
                                            </booking-brand-search-desktop>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="item.selectedState === itemStateEnum.ItemWithInstructionsCard">
                                    <div class="item-set-instructions">
                                        <div class="icon close" (click)="removeItem(item)"></div>
                                        <div class="item-wrapper">
                                            <p> {{item.selected.nameInSpot}}</p>
                                            <span *ngIf ='item.brand'>
                                                {{item.brand.name}}
                                            </span>
                                        </div>
                                        <div class="instructions-select-wrapper">


                                            <div #instructionsSelect class="instructions-select">
                                                <p *ngIf="item.selectedInstructionIds.length === 0">Select instructions
                                                </p>

                                                <div *ngIf="item.selectedInstructionIds.length !== 0" class="instruction-bubbles">
                                                    <div class="instruction-bubble tag status" *ngFor="let instruction of item.selectedInstructionIds">
                                                        <span>{{getInstructionNameById(item, instruction)}}</span>
                                                        <div (click)="removeInstruction(item, instruction)" class="icon close close-btn">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="dropdown-arrow-wrapper" (click)="openInstructionsModal(item)">
                                                    <img src="assets/icons/arrow-down.svg" alt="down arrow">
                                                </div>
                                            </div>

                                            <div *ngIf="item.selectedInstructionIds.length !== 0" class="additional-instructions">
                                                <div class="additional-instruction" *ngFor="let instruction of item.additionalInstructions">
                                                    <mat-checkbox *ngIf ="instruction.name !== 'Additional comments'" [(ngModel)]="instruction.parsedValue.isChecked" type="checkbox">
                                                        <span>{{instruction.name}}</span>
                                                    </mat-checkbox>
                                                    <span *ngIf ="instruction.name === 'Additional comments'">{{instruction.name}}</span>
                                                    <div  class="mat-form-field-infix " *ngIf="instruction.parsedValue.hasText">
                                                        <input  matInput type="text" placeholder="Any additional instructions?"  [(ngModel)]="instruction.parsedValue.text" class="additional-input">
                                                        <!-- <textarea [maxlength]="additionalInstructionsMaxLength" class="text" [(ngModel)]="instruction.parsedValue.text"></textarea> -->
                                                        <div class="counter">
                                                            <p>{{instruction.parsedValue.text.length}} / {{additionalInstructionsMaxLength}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="item" *ngIf="item.selectedState === itemStateEnum.ItemWithEditCard">
                                    <div class="icon close" (click)="removeItem(item)"></div>
                                    <div class="row item-number-row">
                                        <p class="item-number">Item n°{{i+1}} <a (click)="displayItemInstructionsEditState(item)">Edit</a></p>
                                    </div>
                                    <div class="row instructions-row">
                                        <div class="title-and-brand">
                                            <p class="title">{{item.selected.nameInSpot}}</p>
                                            <p *ngIf ='item.brand' class="brand">{{item.brand.name}}</p>
                                        </div>

                                        <div *ngIf="item.instructions.length > 0; then thenBlock"></div>
                                        <ng-template #thenBlock>
                                            <div class="instructions-list">
                                                <div class="instruction" *ngFor="let instruction of item.instructions">
                                                    <span>{{instruction.name}}</span>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>

                            </div>
                            <ng-container *ngIf="serviceModel.addItemBtnState === addBtnStateEnum.AddItemBtn && serviceModel.areAddAndSaveBtnVisible">
                                <a class="btn secondary add-item" (click)="changeAddBtnStateAndItemsViewToFinal()">
                                    Add another item
                                </a>
                            </ng-container>

                            <ng-container *ngIf="serviceModel.addItemBtnState === addBtnStateEnum.AddItemCard">
                                <div class="empty-item">
                                    <div class="icon close"></div>
                                    <div class="item-wrapper">
                                        <p>Item n°{{serviceModel.items.length + 1}}</p>
                                        <button class="select-item-btn" mat-button [matMenuTriggerFor]="menu.childMenu">
                                            Select an item
                                        </button>
                                        <booking-menu-item #menu [itemId]="serviceModel.id" [addItemFunc]="addItem">
                                        </booking-menu-item>
                                    </div>

                                    <div class="brand-search">
                                        <booking-brand-search-desktop (onSearch)="updateItemWithBrand($event)">
                                        </booking-brand-search-desktop>
                                    </div>
                                </div>
                            </ng-container>

                        </ng-template>
                        <ng-template #elseBlock>
                            <div class="empty-item">
                                <div class="icon close"></div>
                                <div class="item-wrapper">
                                    <p>Item n°1</p>
                                    <button class="select-item-btn" mat-button [matMenuTriggerFor]="menu.childMenu">
                                        Select an item
                                    </button>
                                    <booking-menu-item #menu [itemId]="serviceModel.id" [addItemFunc]="addItem">
                                    </booking-menu-item>
                                </div>

                                <div class="brand-search">
                                    <booking-brand-search-desktop (onSearch)="updateItemWithBrand($event)">
                                    </booking-brand-search-desktop>
                                </div>
                            </div>

                        </ng-template>
                    </div>
                    <div class="btn-wrapper">
                        <button class="btn primary with-arrow" (click)="save()" [id]="saveInst">Save instructions</button>
                    </div>
                </div>
                <div *ngIf="serviceModel.parentItem.parsedValue.viewType === 1">
                    <booking-wash-fold [serviceModel]="serviceModel" [isMobile]="false"></booking-wash-fold>
                </div>
            </div>
        </div>
    </div>
</div>