<ng-template [ngIf]="isMobile" [ngIfElse]="isDesktop">
    <div *ngIf="!redirect_sign" class="content sign client_signed animation-fade-in">
        <div class="heading">
            <shared-logo-button [logoType]="logoTypeEnum.LogoGreen"></shared-logo-button>
        </div>

        <div class="blanc_icon media_sign">
            <img src="assets/icons/tshirt-stained.svg" />
        </div>

        <div class="content_wrapper wrapper_sign">
            <!-- <shared-back-button></shared-back-button> -->

            <h1>Welcome!</h1>
            <span>If you already have an account, please sign in.
                Don't have an account yet? We need to check if you are in our delivery area,
                 so please check your postcode by hitting  « Check postcode » below.</span>

            <div class="buttons">
                <a (click)="goToSignIn()" class="btn primary" [id]="signIn">Sign in</a>
                <a (click)="goToSignUp()" class="btn secondary-neutral" [id]="postcodeBtn">Check postcode</a>
            </div>
        </div>
    </div>

    <div *ngIf="redirect_sign" class="content sign client_notsigned animation-fade-in">
        <div class="heading">
            <shared-back-button></shared-back-button>
        </div>

        <div class="blanc_icon media_image">
            <img src="assets/icons/tshirt-stained.svg" />
        </div>

        <div class="content_wrapper wrapper_sign">
            <h1>New Costumer? <br> Welcome!</h1>
            <p>We just need a few more things to complete <br> your order (it's very quick).</p>

            <div class="buttons">
                <a (click)="goToSignUp()" class="btn primary" id="sign-up-btn">Create an account</a>
            </div>
            <p class="setlign">
                Already have an account?
                <a (click)="goToSignIn()" class="lign" id="sign-in-btn">Login</a>
            </p>
        </div>
    </div>
</ng-template>

<ng-template #isDesktop>
    <div *ngIf="!redirect_sign" class="content sign animation-fade-in">
        <div class="heading">
            <shared-logo-button [logoType]="logoTypeEnum.LogoGreen"></shared-logo-button>
        </div>

        <div class="media">
            <img src="assets/icons/tshirt-stained.svg" alt="blanc tshirt" class="tshirt-img" />
        </div>

        <div class="wrapper">
            <!-- <shared-back-button></shared-back-button> -->
            
            <h1>Welcome!</h1>
            <span>If you already have an account, please sign in.<br/>
                Don't have an account yet?<br/>
                We need to check if you are in our delivery area,
                so please check your postcode by hitting « Check postcode »  below.</span>

            <div class="buttons">
                <a (click)="goToSignIn()" class="btn primary" [id]="signIn">Sign in</a>
                <a (click)="goToSignUp()" class="btn secondary-neutral" [id]="postcodeBtn">Check postcode</a>
            </div>
        </div>
    </div>
    <div *ngIf="redirect_sign" class="content sign animation-fade-in">
        <div class="media">
            <img src="assets/icons/tshirt-stained.svg" alt="blanc tshirt" class="tshirt-img" />
        </div>
        <div class="wrapper">
            <shared-back-button></shared-back-button>
            <h1>New Costumer? <br> Welcome!</h1>
            <p>
                We just need a few more things to complete <br> your order (it's very quick).
            </p>
            <div class="buttons">
                <a (click)="goToSignUp()" class="btn primary" id="sign-up-btn">Create an account</a>
            </div>
            <p class="setlign">
                Already have an account?
                <a (click)="goToSignIn()" class="lign" id="sign-in-btn">Login</a>
            </p>
        </div>
    </div>
</ng-template>