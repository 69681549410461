import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError, switchMap, map, filter } from 'rxjs/operators';
import { LogService } from '../services/common/logService';

@Injectable()
export class LogsInterceptor implements HttpInterceptor {

  private baseUrl: string;
  private requestsToLog: Array<string>;

  constructor(
    private logService: LogService,
  ) {
    this.baseUrl = environment.api;
    this.requestsToLog = ['Signup','SignupStore','DeleteDelivery', 'DeletePickup', 'RequestPickup', 'SaveDeliveryPreference', 'SaveCustomer', 'SaveCustomerPreference'];

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!req.url.includes('saveLog') && !req.url.includes('app.blancliving.co') ) {
      const body = {
        endPoint: req.body?.RequestType ? req.body.RequestType : req.url,
        body: req.body?.Parameters ? req.body.Parameters : req.body,
        sessionId: req.body?.SessionID ? req.body.SessionID : null,
        status: '',
        message: ''
      };
      this.logService.saveLog(body);
    }
    if (!req.url.startsWith('http')) {
      req = req.clone({
        url: this.baseUrl + req.url,
      });
    }

    return next.handle(req).pipe(catchError(error => {
        if (
            error instanceof HttpErrorResponse &&
            (this.requestsToLog.indexOf(req.body?.RequestType) !== -1 || (error.status === 500 && !req.url.includes('saveLog')))
            ) {
            const body = {
            endPoint: req.body?.RequestType ? req.body.RequestType : req.url,
            body: req.body?.Parameters ? req.body.Parameters : req.body,
            sessionId: req.body?.SessionID ? req.body.SessionID : null,
            status: error.status,
            message: error.error?.message ? error.error.message : error.message
            };
            this.logService.saveLog(body);
            return next.handle(req);
        } else {
            return throwError(error);
        }
    }));
  }
}
