<booking-progress-bar-desktop *ngIf="!store" [step]="step"></booking-progress-bar-desktop>
<div *ngIf="store" class="content-bg animation-fade-in"></div>
<shared-spinner *ngIf="!hasAllData"></shared-spinner>

<div *ngIf="hasAllData" class="content address animation-fade-in">
    <div *ngIf="!store" class="heading">
        <h1>Pick up and delivery address</h1>
        <div style="padding: 10px 10px 10px 10px; width: 92%; ">
            <p  style="margin-bottom: 0px; color: #000; font-weight: bold;">Where are we picking up from/delivering to?</p>
        </div>
        
        <p *ngIf="!isAuthenticated">Already have a BLANC online account? Please
            <a (click)="redirectToSignPage()" class="sign-in-btn">Login</a>
        </p>
    </div>
    <div *ngIf="store" class="heading" style="margin-bottom: 0;">
        <h1>Pick up and delivery address</h1>
        <div  style="padding: 10px 10px 10px 00px;">
            <p  style="margin-bottom: 0px; color: #868686;">Please enter your address for
                future
                collections & deliveries. You can return to this later in app</p>
        </div>
        <p *ngIf="!isAuthenticated">Already have a BLANC online account? Please
            <a (click)="redirectToSignPage()" class="sign-in-btn">Login</a>
        </p>
    </div>
    <div class="box">
        <h3>Your address</h3>

        <form [formGroup]="addressForm" (submit)="onFormSubmit()">
            <div class="field-wrapper custom-input-wrapper address">
                <label>Address</label>
                <input matInput type="text" formControlName="addressLine1" placeholder="Address line 1">
                <div class="error" *ngIf="getFormField('addressLine1').hasError('maxlength')">
                    Could not be more than 50 symbols
                </div>
                <input matInput type="text" formControlName="addressLine2" placeholder="Address line 2">
                <div class="error" *ngIf="getFormField('addressLine2').hasError('maxlength')">
                    Could not be more than 50 symbols
                </div>
             
            </div>

            <div class="field-wrapper custom-input-wrapper post-code">
                <br>
                <input matInput type="text" formControlName="postalCode" placeholder="Postcode">
                <div class="error" *ngIf="getFormField('postalCode').hasError('required')">
                    Post code is required
                </div>
                <div class="error" *ngIf="getFormField('postalCode').hasError('maxlength')">
                    Could not be more than 50 symbols
                </div>
                <div class="error" *ngIf="getFormField('postalCode').hasError('unsupported')">
                    Code is unsupported or invalid
                </div>
                <input matInput type="text" formControlName="town" placeholder="City">
                <div class="error" *ngIf="getFormField('town').hasError('maxlength')">
                    Could not be more than 50 symbols
                </div>
            </div>

            <button [disabled]="!isValid || isLoading" type="submit" class="btn primary absolute with-arrow" [id]="BookingAddressSave">
                <ng-container *ngIf="!isLoading">Next</ng-container>
                <shared-spinner *ngIf="isLoading" color="white"></shared-spinner>
            </button>
            <span class="skip" *ngIf="store" (click)="skipStep()">Skip this step</span>
        </form>
    </div>

    <booking-address-instructions-desktop (onInstructionsSubmit)="setInstructions($event)" [instructions]="address.instructions"></booking-address-instructions-desktop>

    <!-- <booking-donate-desktop (onBagsCountSelected)="setDonateBags($event)" [bagsCount]="address.donateBags"></booking-donate-desktop> -->
</div>