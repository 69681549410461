<div *ngIf="!isLoading" class="heading">
  <a [routerLink]="[orderListRoute]" class="back-btn" id="back-btn">
    <img src="assets/icons/arrow-left-keyboard.png" class="arrow-img" alt="arrow blanc">
  </a>

  <div class="wrapper">
    <!-- <p class="lighter date">{{order.created_at | date : 'dd.MM.yy'}}</p> -->
    <h1>Order n° {{order.infoOrder_id}}</h1>
    <p>{{order.Status}}</p>
    <p class="lighter address">{{address.addressLine1}}</p>
    <p class="lighter address">{{address.addressLine2}}</p>
    <p class="lighter address">{{address.town}} {{address.postcode}}</p>
    <p *ngIf="hasInstructions" class="lighter smaller instructions">Deliver to {{instructions?.deliverToName}}</p>
  </div>
</div>