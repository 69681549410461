import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ITokenService } from './contracts/ITokenService';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CookieService } from './cookie.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService implements ITokenService {
  private readonly endpoint: string;

  constructor(private http: HttpClient, private cookieService: CookieService) {
    this.endpoint = environment.api;
  }

  refreshToken() {
    return this.http.get(this.endpoint + 'refreshToken');
  }

}
