import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Routes } from 'src/app/constants/routes.constant';

@Component({
  selector: 'app-authorisation-permanent-popup',
  templateUrl: './authorisation-permanent-popup.component.html',
  styleUrls: ['./authorisation-permanent-popup.component.scss']
})
export class AuthorisationPermanentPopupComponent implements OnInit {


  order_only: boolean;

  constructor( 
    protected dialogRef: MatDialogRef<AuthorisationPermanentPopupComponent>,
     protected router: Router ,
    ) { }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }
 
  SaveOrderOnly(){
    this.order_only = true;
    this.router.navigate([Routes.USER.AUTHORISATIONS]);
    this.closeDialog();
  }

}
