import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { HttpClientModule } from '@angular/common/http';
import { UserModule } from '../user/user.module';
import { ToastrModule } from 'ngx-toastr';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { PipesModule } from '../pipes/pipes.module';
import { SharedModule } from '../shared/shared.module';
import { DeliveryOnlyRoutingModule } from './delivery-only-routing.module';
import { DeliveryOnlyComponent } from './delivery-only.component';
import { ProvisionComponent } from './provision/provision.component';
import { BookingModule } from '../booking/booking.module';
import { SummaryComponent } from './summary/summary.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { SlotComponent } from './slot/slot.component';
import { EntryComponent } from './entry/entry.component';

@NgModule({
  declarations: [
    DeliveryOnlyComponent,
    ProvisionComponent,
    SummaryComponent,
    ThankYouComponent,
    SlotComponent,
    EntryComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatSelectModule,
    HttpClientModule,
    UserModule,
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 3000,
    }),
    DeviceDetectorModule,
    PipesModule,
    SharedModule,
    DeliveryOnlyRoutingModule,
    BookingModule
  ],
  exports: [
    CommonModule,
    DeliveryOnlyComponent,
    DeliveryOnlyRoutingModule,
  ]
})
export class DeliveryOnlyModule { }
