import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ListService } from './list.service';

@Component({
  selector: 'order-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  isMobile: boolean;

  constructor(
    private deviceService: DeviceDetectorService,
    public listService :ListService
  ) { }

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
  }
}
