<shared-navigation-top [title]="title"></shared-navigation-top>

<div class="content animation-fade-in">
  <div *ngIf="isLoading" class="spinner-wrapper"><shared-spinner></shared-spinner></div>

  <ng-container *ngIf="!isLoading" [ngSwitch]="state">
    <user-recurring-form *ngSwitchCase="stateEnum.Form"></user-recurring-form>
    <user-recurring-view *ngSwitchCase="stateEnum.View"></user-recurring-view>
    <shared-spinner *ngSwitchCase="stateEnum.Loading"></shared-spinner>
  </ng-container>
</div>