import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'shared-navigation-main',
  templateUrl: './navigation-main.component.html',
  styleUrls: ['./navigation-main.component.scss']
})
export class NavigationMainComponent implements OnInit {
  isDesktop: boolean;

  constructor(protected deviceService: DeviceDetectorService) { }

  ngOnInit(): void {
    this.isDesktop = this.deviceService.isDesktop();
  }
}
