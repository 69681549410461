<ng-template [ngIf]="isMobile" [ngIfElse]="isDesktop">
    <booking-progress-bar-mobile [step]=step></booking-progress-bar-mobile>

    <div class="content sign-in animation-fade-in" style="display: flex; flex-wrap: wrap; padding: 40px 40px 0px 45px;">
        <div class="heading">
            <shared-logo-button [logoType]="logoTypeEnum.LogoGreyWithText"></shared-logo-button>
            <!-- <shared-back-button></shared-back-button> -->
            <h1 style="margin-top: 40px;">Have we met?</h1>
            <p style="margin-top: 10px;">Sign in here</p>
        </div>

        <form style="margin-top: 10px;" *ngIf="!isLoading" [formGroup]="loginForm" (ngSubmit)="onFormSubmit()">
            <div class="field-wrapper email">
                <label>Email</label>
                <mat-form-field floatLabel="never">
                    <input matInput type="text" formControlName="username">
                    <mat-error *ngIf="getFieldFromForm('username').hasError('required')">
                        Email is required
                    </mat-error>
                    <mat-error *ngIf="getFieldFromForm('username').hasError('invalid')">
                        Invalid Email
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="field-wrapper password">
                <label>Password</label>
                <div class="password-wrapper">
                    <mat-form-field floatPlaceholder="never" floatLabel="never">
                        <input matInput formControlName="password" [type]="passwordTextType ? 'text' : 'password'"
                            name="password" autocomplete="current-password">
                        <mat-error *ngIf="getFieldFromForm('password').hasError('required')">
                            Password is required
                        </mat-error>
                        <mat-error *ngIf="getFieldFromForm('password').hasError('minlength')">
                            Password must be at least 6 symbols
                        </mat-error>
                    </mat-form-field>
                    <a (click)="showPassword()" [className]="passwordTextType ? 'shown' : '' "></a>
                </div>
            </div>

            <div class="margin-wrapper">
                <div class="btn-href-wrapper">
                    <a (click)="forgotPassword()" class="btn href smallest green">I forgot my password</a>
                </div>
            </div>

            <div class="buttons">
                <a  [id]="SignIn" class="btn primary" (click)="onFormSubmit()"
                    style="padding: 12px 118px; margin-top: 20px;">Sign in</a>
            </div>

        </form>

        <div *ngIf="isLoading" class="spinner-wrapper">
            <shared-spinner></shared-spinner>
        </div>
    </div>

    <div class="content sign-in animation-fade-in" style="display: flex; flex-wrap: wrap; padding: 40px 40px 100px 45px;">
        <hr size="3" width="90%" color="#E0E0E0">
        <div class="heading">
            <h1 style="margin-top: 40px;">New to BLANC?</h1>
            <p style="margin-top: 10px;">Nice to meet you</p>
        </div>
        <span style="margin-top: 10px;">We just need a few more things to complete your order (it's very quick).</span>

        <div class="buttons">
            <a [id]="SignUp" (click)="redirectSignUp()" class="btn primary"
                style="padding: 12px 118px; margin-top: 20px;">Next</a>
        </div>

    </div>
</ng-template>

<ng-template #isDesktop>
    <booking-progress-bar-desktop [step]="step"></booking-progress-bar-desktop>
    
    <div class="row box content address animation-fade-in" style="width: 688px; margin: 0 auto; margin-top: -260px;padding-top: 12px; display: flex; background-color: #FFF; justify-content: center;">
        <div class="col-md-6" style="width: 688px;     margin-right: 50px;">
            <div class="heading">
                <h1>Have we met?</h1>
                <p style="margin-top: 20px;"> Sign in here</p>
            </div>

            <div style="margin-top: 45px;">
                <form *ngIf="!isLoading" [formGroup]="loginForm" (ngSubmit)="onFormSubmit()">
                    <div class="field-wrapper email" style="margin-bottom: 20px;">
                        <label>Email</label>
                        <mat-form-field floatLabel="never">
                            <input matInput type="text" formControlName="username">
                            <mat-error *ngIf="getFieldFromForm('username').hasError('required')">
                                Email is required
                            </mat-error>
                            <mat-error *ngIf="getFieldFromForm('username').hasError('invalid')">
                                Invalid Email
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="field-wrapper password">
                        <label>Password</label>
                        <div class="password-wrapper">
                            <mat-form-field floatPlaceholder="never" floatLabel="never">
                                <input matInput formControlName="password"
                                    [type]="passwordTextType ? 'text' : 'password'" name="password"
                                    autocomplete="current-password">
                                <mat-error *ngIf="getFieldFromForm('password').hasError('required')">
                                    Password is required
                                </mat-error>
                                <mat-error *ngIf="getFieldFromForm('password').hasError('minlength')">
                                    Password must be at least 6 symbols
                                </mat-error>
                            </mat-form-field>
                            <a (click)="showPassword()" [className]="passwordTextType ? 'shown' : '' "></a>
                        </div>
                    </div>

                    <div class="margin-wrapper">
                        <div class="btn-href-wrapper">
                            <a (click)="forgotPassword()" class="btn href smallest green" [id]="ForgetPwd">I forgot my password</a>
                        </div>
                    </div>

                    <div *ngIf="error" class="mat-error request-error v-padding">{{error}}</div>

                    <div class="margin-wrapper">
                        <button [disabled]="!valid || isLoading"
                            style="position: inherit; margin-top: 38px; bottom: 0; padding: 8px; width: 160px; border-radius: 5px; text-align: center; "
                            type="submit" class="btn primary absolute with-arrow" [id]="SignIn">
                            Sign in
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-md-6" style="width: 688px;">
            <div class="heading">
                <h1>New to BLANC?</h1>
                <p style="margin-top: 20px;"> Nice to meet you</p>
            </div>

            <div style="margin-top: 45px;">
                <span>We just need a few more things to complete your order (it's very quick).</span>



                <button
                    style="position: relative; margin-top: 38px; bottom: 0; padding: 8px; width: 160px; border-radius: 5px; "
                    type="submit" class="btn primary absolute with-arrow" [id]="SignUp"
                    (click)="redirectSignUp()">
                    Next
                </button>

            </div>
        </div>
        <!-- <booking-address-instructions-desktop (onInstructionsSubmit)="setInstructions($event)" [instructions]="address.instructions"></booking-address-instructions-desktop> -->
        <!-- <booking-donate-desktop (onBagsCountSelected)="setDonateBags($event)" [bagsCount]="address.donateBags"></booking-donate-desktop> -->
    </div>
</ng-template>