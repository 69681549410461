<div class="content animation-fade-in" *ngIf="!isMobile"> 
  <div class="wrapper">
    <shared-background-rectangle></shared-background-rectangle>
    <header>
    <div class="box">
      <h3>Confirm with card</h3>
      <br>
      <p>Please DO NOT REFRESH this page or try to go back as we securely save and encrypt your payment information.</p>
      <br><br>
      <shared-spinner></shared-spinner>
    </div> </header>
  </div>
</div>

<shared-header  *ngIf="isMobile"></shared-header>
<div class="content animation-fade-in"  *ngIf="isMobile">
  <shared-back-button customPath="booking/address"></shared-back-button>
  <header>
      <h3>Confirm with card</h3>
      <p>Please DO NOT REFRESH this page or try to go back as we securely save and encrypt your payment information.</p>
      <br>
      <br>
      <br>
      <shared-spinner></shared-spinner>
  </header>
</div>
