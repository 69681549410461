<div class="close-btn-wrapper">
  <div (click)="closeDialog()" class="icon close" id="close-btn"></div>
</div>

<div class="content">
  <h3>Cancel your recurring booking</h3>

  <div class="text-wrapper">
    <p class="ff-300">You can cancel your recurring booking anytime - please note the cut-off time to cancel your recurring booking is 4pm on the day before the scheduled date.</p>
    <p>Are you sure you want to cancel your recurring booking?</p>
  </div>

  <button [disabled]="loading" (click)="cancel()" class="btn submit secondary-danger">
    <ng-container *ngIf="!loading">Yes, cancel</ng-container>
    <shared-spinner *ngIf="loading" color="red"></shared-spinner>
  </button>
</div>
