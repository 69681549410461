<div *ngIf="isLoading" class="spinner-wrapper">
    <shared-spinner></shared-spinner>
</div>

<div *ngIf="!isLoading" class="content animation-fade-in">
    <header>
        <a [routerLink]="[detailsRoute]">
            <img src="assets/icons/arrow-left-keyboard.png" class="arrow-img" alt="arrow blanc">
        </a>
    </header>

    <order-leaflet-map [height]="386"></order-leaflet-map>

    <div class="info">
        <p class="lighter">Order n° {{order?.idHashCode}}</p>
        <h3>Your driver is on his way</h3>
        <p>You will be delivered between 12-2 pm</p>

        <div class="text-wrapper">
            <p class="lighter">{{order.address.addressLine1}}, {{order.address.addressLine2}}</p>
            <p class="lighter">{{order.address.city}} {{order.address.postcode}}</p>
        </div>

        <a href="tel:+442080042630" class="btn primary">Call the driver</a>
    </div>
</div>