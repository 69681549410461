import { Component, OnInit, Input } from '@angular/core';

import { Routes } from 'src/app/constants/routes.constant';
import { ListService } from '../list.service';

@Component({
  selector: 'order-list-past',
  templateUrl: './list-past.component.html',
  styleUrls: ['./list-past.component.scss']
})
export class ListPastComponent implements OnInit {
  pastOrders: [];
  hasPastOrders: boolean;
  pastOrderDetailsRoute = Routes.ORDER.PASTDETAILS;
  countcurrentorder: any;
  loadingOredrpast: boolean = true;
  @Input() fromParentPast: any;

  constructor( public listservice :ListService) { }
  
  ngOnInit(): void {
    this.pastOrders = this.fromParentPast;
   
    if(this.pastOrders?.length == 0){
      this.hasPastOrders = false;
    } else {
      this.hasPastOrders = true;
    }
     
  }
}
