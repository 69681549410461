import { InstructionsBase } from 'src/app/booking/address/base/instructions.base';
import { Instructions } from './instructions.model';

export class BookingAddressData {
  addressLine1: string;
  addressLine2: string;
  town: string;
  postcode: string;
  instructions: Instructions
  donateBags: number;

  constructor(
    addressLine1: string = '',
    addressLine2: string = '',
    town: string = '',
    postcode: string = null,
    instructions: Instructions = new Instructions(),
    donateBags: number = 0
  ) {
    this.addressLine1 = addressLine1;
    this.addressLine2 = addressLine2;
    this.town = town;
    this.postcode = postcode;
    this.instructions = instructions;
    this.donateBags = donateBags;
  }
}
  