<section class="box">
    <order-leaflet-map [height]="260"></order-leaflet-map>

    <div class="info">
        <p class="lighter">N° {{order?.idHashCode}}</p>
        <h3>Your driver is on his way</h3>
        <p>You will be delivered between 12-2 pm</p>

        <div class="text-wrapper">
            <p class="lighter">{{order.address.addressLine1}}, {{order.address.addressLine2}}</p>
            <p class="lighter">{{order.address.city}} {{order.address.postcode}}</p>
            <!-- <p class="lighter instructions" *ngIf="order.address.instructions.deliverToName">Contact: {{order.address?.instructions?.deliverToName}}</p>   -->
        </div>

        <div class="text-wrapper">
            <p class="lighter">Driver’s phone number</p>
            <p class="phone-number">+44 (0)20 8004 2630</p>
        </div>
    </div>
</section>