<div class="content" [ngClass]="{'shown': isShown}" id="content">
  <a (click)="toggleShown()" class="toggle-btn" id="toggle-btn">  
    <div *ngIf="!isShown" class="wrapper" id="not-shown-wrapper">
      <img src="assets/icons/donate-blue.svg" class="donate-img">
      <div *ngIf="bagsCount === 0" class="text no-bags" id="text-no-bags">
        <p>Donate clothes to charity </p>
      </div>

      <div *ngIf="bagsCount > 0" class="text has-bags" id="text-has-bags">
        <p><strong id="bags-count">{{bagsCount}}</strong> bag(s) of clothes to charity</p>
      </div>
    </div>

    <div *ngIf="isShown" class="wrapper" id="shown-wrapper">
      <div class="text no-bags">
        <p>Donate clothes to charity </p>
      </div>
    </div>
    <img src="assets/icons/arrow-down-blue.svg" class="arrow-img">
  </a>

  <div class="more animation-slide-down" [ngClass]="{'shown': isShown}" id="more"> 
    <div class="text">
      <p>Clothes donations made easy</p>
      <p class="mb-20">Donate your unwanted items through BLANC and help us keep all textiles out of landfill! We will clean & mend everything free of charge, and direct all items to the right charity or recycling partners.</p>
      <p>Please note we can only accept clothes & textile donations. Donations must be added to a regular pick up and delivery including cleaning or tailoring services - we unfortunately cannot proceed a booking for donations only.</p>
    </div> 
    
    <div class="count-wrapper">
      <p>Number of donation bags required:</p>
      <button (click)="decreaseCount(); emitBags()" class="counter-btn decrease-btn" id="decrease-btn">
        <p>-</p>
      </button>
      <p class="count" id="bags-count">{{bagsCount}}</p>
      <button (click)="increaseCount(); emitBags()" class="counter-btn increase-btn" id="increase-btn">
        <p>+</p>
      </button>
    </div>
  </div>
</div>
