import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Routes } from 'src/app/constants/routes.constant';
import { CommandService } from 'src/app/services/common/command.service';
import { ServerlessService } from 'src/app/services/common/serverless.service';
import { DeliveryOnlyStateService } from 'src/app/services/delivery-only/delivery-only-state.service';
import { AlertService } from 'src/app/services/common/alert.service';
import { SlotService } from 'src/app/services/booking/slot.service';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { StorageService } from 'src/app/services/common/storage.service';

@Component({
  selector: 'delivery-only-provision',
  templateUrl: './provision.component.html',
  styleUrls: ['./provision.component.scss']
})
export class ProvisionComponent implements OnInit {

  isSpinnerShown: boolean = true;
  defaultTimeSpan: string = '00:00:00';

  constructor(
    public router: Router,
    public commandService: CommandService,
    public deliveryStateService: DeliveryOnlyStateService,
    public serverlessService: ServerlessService,
    public alertService: AlertService,
    public slotService: SlotService,
    public storageService: StorageService) {
  }
  async ngOnInit() {
    const response = await this.commandService.initializeOrder();
    let state = this.deliveryStateService.getState();
    state.orderId = response.orderId;
    state.email = response.userEmail;
    this.deliveryStateService.setState(state);

    let serverlessError = undefined;

    const updateAddressResult =
      await this.serverlessService.updateUserAddressAndOrderPaymentFunction(response.userEmail, response.orderId)
        .catch(err => serverlessError = err.error.text);

    const updateOrderAddressAndUserIdResult =
      await this.serverlessService.updateOrderAddressAndUserIdAsync(response.userEmail, response.orderId)
        .catch(err => serverlessError = err.error.text);

    const updateRelationsResult =
      await this.serverlessService.updateOrderInvoiceRelationsAsync(response.userEmail, state.invoiceKeys, response.orderId)
        .catch(err => serverlessError = err.error.text);

    console.log([updateAddressResult, updateOrderAddressAndUserIdResult, updateRelationsResult]);

    if (serverlessError !== undefined || [updateAddressResult, updateOrderAddressAndUserIdResult, updateRelationsResult].includes(undefined)) {
      var errorMessage = serverlessError ? serverlessError : "There was an unexpected error."
      this.alertService.showError(`${errorMessage} An Email has been sent to CC.`);
      this.isSpinnerShown = false;

      await this.commandService.executeCommand(CommandEnum.DeliveryOrderErrorEmailSendCommand, errorMessage)
        .catch(err => console.log(err));
      return;
    }

    this.slotService.setPickupSlot({
      date: state.promisedDate,
      from: this.defaultTimeSpan,
      to: this.defaultTimeSpan,
      ignore: true,
    });

    this.slotService.setDeliveryFromSlot({
      date: state.promisedDate,
      from: this.defaultTimeSpan,
      to: this.defaultTimeSpan,
      ignore: true,
    });
    var address = await this.commandService.executeCommand(CommandEnum.OrderAddressReadCommand);
    this.storageService.savePostcode(address.commandResult.postcode);
    this.router.navigate([Routes.DELIVERY_ONLY.SLOT], { queryParams: { redirect: '/delivery/summary' } });
  }
}