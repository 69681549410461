<div class="modal-wrapper">
    <div class="heading">
        <div (click)="close()" class="icon close"></div>
    </div>
    <form class="form" [formGroup]="form" (ngSubmit)="submit()">
        <div class="description" formArrayName="instructions"
            *ngFor="let instruction of instructionsFromArray.controls; let i = index">
            <mat-checkbox [formControlName]="i">
                <span>{{instructions[i].name}}</span>
            </mat-checkbox>
        </div>
        <button [disabled]="!form.valid" type="submit" class="btn primary">Add</button>
    </form>
</div>
