import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { IServerlessService } from './contracts/IServerlessService';
import { OrderFunctionsResponse } from 'src/app/models/common/serverless-order-functions-response.model';

@Injectable({
  providedIn: 'root'
})
export class ServerlessService implements IServerlessService {

  constructor(private http: HttpClient) {
  }

  defaultKey: string = environment.serverless.defaultKey;

  updateUserAddressAndOrderPaymentFunction(email: string, orderId: string) {
    return this.http.post<OrderFunctionsResponse>(environment.serverless.updateUserAddressAndOrderPaymentFunction + this.defaultKey, { email, orderId }).toPromise();
  }

  updateOrderAddressAndUserIdAsync(email: string, orderId: string) {
    return this.http.post<OrderFunctionsResponse>(environment.serverless.updateOrderAddressAndUserIdFunction + this.defaultKey, { email, orderId }).toPromise();
  }

  updateOrderInvoiceRelationsAsync(email: string, invoiceKeys: string[], orderId: string) {
    return this.http.post<OrderFunctionsResponse>(environment.serverless.updateOrderInvoiceRelationsFunction + this.defaultKey, { email, invoiceKeys, orderId }).toPromise();
  }

  blancSignUpAllUsersFunctionAsync() {
    return this.http.post<OrderFunctionsResponse>(environment.serverless.blancSignUpAllUsersFunction + this.defaultKey, {}).toPromise();
  }

  blancSignUpIfNotExistingFunctionAsync(emailAddress: string, firstName: string, lastName: string, phone: string) {
    return this.http.post<OrderFunctionsResponse>(environment.serverless.blancSignUpIfNotExistingFunction + this.defaultKey, { emailAddress, firstName, lastName, phone }).toPromise();
  }
}