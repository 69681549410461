import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'shared-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent implements OnInit {
  @Input() customPath: string;
  @Input() customFunc: () => void;

  constructor(
    public location: Location,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  redirect() {
    if (this.customFunc) {
      this.customFunc();
    } else if (this.customPath) {
      this.router.navigate([this.customPath]);
    } else {
      this.location.back();
    }
  }
}
