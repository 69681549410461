<ng-template [ngIf]="isMobile" [ngIfElse]="isDesktop">
  <div class="content sign client_signed animation-fade-in">
    <div class="heading">
      <shared-logo-button [logoType]="logoTypeEnum.LogoGreen"></shared-logo-button>
    </div>

    <div class="blanc_icon media_sign">
      <img src="assets/icons/tshirt-stained.svg" />
    </div>

    <div class="content_wrapper wrapper_sign">
      <h1>Welcome!</h1>
      <span>We need to check if you are in our delivery area before showing you our available time slots.
        Please enter your postcode below.</span>
      <form [formGroup]="postCodeForm" (submit)="submitForm()" class="postCodeForm">
        <div class="tabs spacebutton">
          <input formControlName="postCode" type="text" class="btn btnborderleft secondary-neutral"
            placeholder="Enter full postcode" id="myInputMobile" required autocomplete="off" name="myCode" />
          <button type="submit" class="btn primary" [id]="FirstJourneyPostcode" [disabled]="!postCodeForm.valid">
            CHECK NOW
          </button>
          <div id="msgerror" style="display:none;">
            Invalid postcode.
          </div>
        </div>
      </form>
      <p class="setlign">
        Already have an account?
        <a (click)="goToSignIn()" class="lign">Sign in</a>
      </p>
    </div>
  </div>
</ng-template>

<ng-template #isDesktop>
  <div class="content sign client_signed animation-fade-in">
    <div class="heading">
      <shared-logo-button [logoType]="logoTypeEnum.LogoGreen"></shared-logo-button>
    </div>

    <div class="media">
      <img src="assets/icons/tshirt-stained.svg" alt="blanc tshirt" class="tshirt-img" />
    </div>

    <div class="wrapper">
      <h1>Welcome!</h1>
      <span>We need to check if you are in our delivery area before showing you our available time slots.
        Please enter your postcode below.</span>
      
      <form [formGroup]="postCodeForm" (submit)="submitForm()" class="postCodeForm">
        <div class="tabs">
          <input formControlName="postCode" type="text" class="btn btnborderleft secondary-neutral"
            placeholder="Enter full postcode" onkeyup="stoppedTyping()" id="myInput" required autocomplete="off"
            name="myCode" />
          <button type="submit" class="primarybook" [id]="FirstJourneyPostcode" [disabled]="!postCodeForm.valid">
            CHECK NOW
          </button>
        </div>
      </form>
      <div id="msgerror" style="display:none;">
        Invalid postcode
      </div>
      <p>
        Already have an account?
        <a (click)="goToSignIn()" class="lign">Sign in</a>
      </p>
    </div>
  </div>
</ng-template>
