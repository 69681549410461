import { Component, Input, OnInit } from '@angular/core';

import { OrderDetails } from 'src/app/models/order/order-details.model';

@Component({
  selector: 'order-gps-tracking-section',
  templateUrl: './gps-tracking-section.component.html',
  styleUrls: ['./gps-tracking-section.component.scss']
})
export class GpsTrackingSectionComponent implements OnInit {

  @Input() order: OrderDetails;

  constructor() { }

  ngOnInit(): void {
  }

}
