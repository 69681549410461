import { Component, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { BookingStepsService } from 'src/app/services/booking/booking-steps.service';
import { TimeSlot } from 'src/app/models/booking/time-slot.model';
import { BookingStepsEnum } from 'src/app/models/booking/enums/booking-steps.enum';
import { SlotService } from 'src/app/services/booking/slot.service';
import { TimeSlotTypeEnum } from 'src/app/models/booking/enums/time-slot-type.enum';
import { Subscription } from 'rxjs';
import { StorageService } from 'src/app/services/common/storage.service';

@Component({
  selector: 'booking-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./../slots.component.scss', './delivery.component.scss']
})
export class DeliveryComponent implements OnDestroy {
  isMobile: boolean;
  dateFormat: string = 'dddd[,] D MMMM';
  slotType: TimeSlotTypeEnum = TimeSlotTypeEnum.Delivery;
  deliverFromSlot: TimeSlot;
  deliverySlot: TimeSlot;
  step: BookingStepsEnum;
  stepsEnum: typeof BookingStepsEnum = BookingStepsEnum;
  deliverySlotSubscription: Subscription;
  bookingStepSubscription: Subscription;
  constructor(
    public bookingStepsService: BookingStepsService,
    private slotService: SlotService,
    private deviceService: DeviceDetectorService,
    protected storageService: StorageService,
  ) { }
  ngOnDestroy(): void {
    this.deliverySlotSubscription.unsubscribe();
    this.bookingStepSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.step = this.bookingStepsService.getStep();
    this.isMobile = this.deviceService.isMobile();
    this.initStep();
    this.initSlots();
  }

  initSlots() {
    const slot = this.slotService.deliverySlot.getValue();
    this.deliverySlot = slot;
    if (this.storageService.getObject('delivery') && !slot) {
      this.deliverySlot = this.storageService.getObject('delivery');
    }
    this.deliverySlotSubscription = this.slotService.deliverySlot.subscribe(
      slot => {
        this.onSelected(slot);
      }
    );
  }

  initStep() {
    this.bookingStepSubscription = this.bookingStepsService.step.subscribe(value => {
      this.step = value;
    });
  }

  onSelected(slot: TimeSlot) {
    if (slot) {
      this.deliverySlot = slot;
      this.bookingStepsService.setStep(BookingStepsEnum.SlotsSummary);
    }
  }

  edit() {
    this.bookingStepsService.setStep(BookingStepsEnum.Delivery);
  }
}
