import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { RecurringService } from 'src/app/services/user/recurring.service';
import { Recurring } from 'src/app/models/user/recurring.model';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { CommandService } from 'src/app/services/common/command.service';
import { DayOfWeekEnum } from 'src/app/models/user/enums/day-of-week.enum';
import { OrderStatusEnum } from 'src/app/models/order/enums/order-status.enum';
import { ReccurenceTypeEnum } from 'src/app/models/user/enums/recurrence-type.enum';
import { ReccurenceStateEnum } from 'src/app/models/user/enums/recurrence-state.enum';
import { InfoCustomer } from 'src/app/models/user/info-customer.model';

@Component({
  selector: 'app-cancel-modal',
  templateUrl: './cancel-modal.component.html',
  styleUrls: ['./cancel-modal.component.scss']
})
export class CancelModalComponent implements OnInit {
  
  recurring: Recurring;
  loading=false;
  constructor(
    public dialogRef: MatDialogRef<CancelModalComponent>,
    private recurringService: RecurringService,
    private commandService: CommandService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.recurringService.$recurring.subscribe(value => {
      this.recurring = value;
    });
  }
  
  async cancel() {
    this.loading=true;
    this.recurringService.setState(ReccurenceStateEnum.Loading);

    let customerDetails = await this.commandService.executeCommand(CommandEnum.CustomerDetail);

        customerDetails.ReturnObject.infoCustomer.PauseDateFrom = null;
        customerDetails.ReturnObject.infoCustomer.PauseDateTo = null;
        customerDetails.ReturnObject.infoCustomer.DeliveryTu = '';
        customerDetails.ReturnObject.infoCustomer.DeliveryTh = '';
        customerDetails.ReturnObject.infoCustomer.DeliveryMon = '';
        customerDetails.ReturnObject.infoCustomer.DeliveryFri = '';
        customerDetails.ReturnObject.infoCustomer.DeliverySat = '';
        customerDetails.ReturnObject.infoCustomer.DeliveryWed = '';

    this.commandService.executeUpdate(CommandEnum.SaveCustomer, customerDetails.ReturnObject)
      .then(response => {
        this.router.navigate(['/']);
        this.closeDialog();
      }).catch(error => {
        this.commandService.handleCommandTokenError(error) ;
      }); 
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
