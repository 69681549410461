export class DonateBase {
  bagsCount: number;

  constructor() {}

  decreaseCount() {
    if (this.bagsCount === 0) {
      return;
    }
    this.bagsCount -= 1;
  }

  increaseCount() {
    if (this.bagsCount === 100) {
      return;
    }
    this.bagsCount += 1;
  }
}
