import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

import { CommandService } from 'src/app/services/common/command.service';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { AlertService } from 'src/app/services/common/alert.service';
import { OrderDetails } from 'src/app/models/order/order-details.model';

@Component({
  selector: 'app-past',
  templateUrl: './past.component.html',
  styleUrls: ['./past.component.scss']
})
export class PastComponent {
  isMobile: boolean;
  isLoading: boolean;
  order: any;
  hasItems: boolean;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected commandService: CommandService,
    protected alertService: AlertService,
    protected deviceService: DeviceDetectorService,
  ) {
    this.isMobile = this.deviceService.isMobile();
    this.isLoading = true;
  }

  async initOrder() {
    const orderId = this.route.snapshot.paramMap.get('id');
    var param = {
      infoOrder_id : +orderId,
    }
    const result = await this.commandService.executeCommand(CommandEnum.OrderDetailsCommand,param);
    this.order = result.ReturnObject;
    this.hasItems = this.order.items.length > 0;
  }
}
