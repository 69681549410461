<div class="percentage-banner">
  <div class="percentage-progress">
    <img src="assets/icons/percentages-progress.svg" alt="Percentage loader">
    <span class="percentage" id="percentage">{{percentage}}%</span>
  </div>

  <div class="text-content">
    <p>Help us know your preferences!</p>
    <a routerLink="/preferences" id="preferences-btn">
      <span>Add my preferences</span>
      <img src="assets/icons/left-arrow-small.svg" alt="action preferences blanc" />
    </a>
  </div>
</div>
