import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminService, Holiday } from '../admin.service';
import * as moment from 'moment';
import { MatTableDataSource, MatTable } from '@angular/material/table';

@Component({
  selector: 'app-holiday-manager',
  templateUrl: './holiday-manager.component.html',
  styleUrls: ['./holiday-manager.component.scss']
})
export class HolidayManagerComponent implements OnInit {

  working = false;
  error = null;

  holidayForm = new FormGroup({
    holidayName: new FormControl('', Validators.required),
    holidayDate: new FormControl(new Date(), Validators.required)
  });

  displayedColumns: string[] = ['name', 'date', 'delete'];
  dataSource: MatTableDataSource<Holiday>;
  matTable: MatTable<Holiday>;

  constructor(private admin: AdminService) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.reset();
  }

  addHoliday() {
    if (this.holidayForm.valid && !this.working) {
      this.working = true;
      this.error = null;
      let name = this.holidayForm.get('holidayName').value;
      let holidayDate = this.holidayForm.get('holidayDate').value;

      this.admin.addHoliday({
        description: name,
        date: moment(holidayDate).format('YYYY-MM-DD')
      }).then(() => this.reset())
        .catch(err => this.error = err.error.description)
        .finally(() => this.working = false);
    }
  }

  reset() {
    this.working = true;
    this.error = null;
    this.admin.getAllHolidays()
      .then(hols => {
        this.dataSource.data = hols;
        if (this.matTable) {
          console.info("Rendering");
          this.matTable.renderRows();
        }
      })
      .catch(err => this.error = err.error.description)
      .finally(() => this.working = false);
  };

  deleteHoliday(holiday: Holiday) {
    if (holiday && !this.working) {
      this.working = true;
      this.error = null;
      this.admin.deleteHoliday(holiday)
        .then(() => this.reset())
        .catch(err => this.error = err.error.description)
        .finally(() => this.working = false);;
    }
  }
}