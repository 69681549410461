<div class="heading">
  <a [routerLink]="[orderListRoute]" class="back-btn" id="back-btn">
    <img src="assets/icons/arrow-left-keyboard.png" class="arrow-img" alt="arrow blanc">
  </a>

  <div class="wrapper">
    <h1>Your order</h1>
    <p class="id" *ngIf="((order.Status | lowercase) != 'scheduled') && ((order.Status| lowercase) != 'recurring')">N°
      {{order.infoOrder_id}}</p>
    <span class="status tag" class="{{css}}">
      {{((order.Status == 'RECURRING') ? 'scheduled' : order.Status) | lowercase}}
    </span>
  </div>
  
  <img src="assets/icons/{{image}}.svg" class="order-img ng-star-inserted" alt="order">
</div>