import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Routes } from 'src/app/constants/routes.constant';

@Component({
  selector: 'app-address-popup',
  templateUrl: './address-popup.component.html',
  styleUrls: ['./address-popup.component.scss']
})
export class AddressPopupComponent implements OnInit {

  isMobile: boolean;
  navigationExtras: NavigationExtras;

  constructor(
    public dialogRef: MatDialogRef<AddressPopupComponent>,
    private router: Router,
    private deviceService: DeviceDetectorService
  ) { }

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
  }
  redirectToPersonalInfo() {
    this.close();

    if (this.isMobile){
      this.navigationExtras = {
       queryParams: { 'redirect': Routes.USER.PAYMENTS },
     };
    } else {
      this.navigationExtras = {
        queryParams: { 'redirect': Routes.USER.PERSONAL_INFO },
      };
    }
    this.router.navigate([Routes.USER.PERSONAL_INFO], this.navigationExtras);
  }

  close() {
    this.dialogRef.close();
  }
}


