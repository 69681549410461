import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { GoogleAnalyticsService } from 'src/app/services/common/google-analytics.service';
import { CommandService } from 'src/app/services/common/command.service';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { FormStatusEnum } from 'src/app/models/common/enums/form-status.enum';
import { InterestedCustomer } from 'src/app/models/shared/interested-customer.mode';

@Component({
  selector: 'app-not-supported',
  templateUrl: './not-supported.component.html',
  styleUrls: ['./not-supported.component.scss']
})
export class NotSupportedComponent implements OnInit, OnDestroy {

  interestedCustomer: InterestedCustomer = new InterestedCustomer();
  isInIframe: boolean;
  isSent: boolean;
  isValid: boolean;
  statusSubscription: Subscription;
  isExtenal:boolean=false;
  emailForm = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required]),
  });

  constructor(
    public dialogRef: MatDialogRef<NotSupportedComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: any,
    private commandService: CommandService,
  ) { }

  ngOnInit(): void {
    if(window.location.href.indexOf("provision") > -1){this.isExtenal=true;}
    this.interestedCustomer.postalCode = this.data.postcode;

    this.isInIframe = this.data.isInIframe;
    this.statusSubscription = this.emailForm.statusChanges.subscribe((status) => {
      this.isValid = status === FormStatusEnum.Valid;
    });

    GoogleAnalyticsService.event("not_supported",
      {
        category: 'postcode',
        label: this.interestedCustomer.postalCode,
        value: this.interestedCustomer.postalCode
      });
  }

  ngOnDestroy(): void {
    this.statusSubscription.unsubscribe();
  }

  getField(fieldName: string) {
    return this.emailForm.get(fieldName);
  }

  saveEmail() {
    this.interestedCustomer.email = this.emailForm.get('email').value;

    this.commandService.executeCommand(CommandEnum.InterestedCustomerWriteCommand, this.interestedCustomer)
      .then(response => {
        this.isSent = true;
      })
      .catch(error => {
        this.commandService.handleCommandTokenError(error);
      });
  }

  close() {
    this.dialogRef.close();
    if(this.isExtenal){
      window.top.close();
    }
    if(window.location.toString().includes("external") == true){
      window.location.href = "https://blancliving.co/";
    }

  }
}
