<div class="modal-wrapper">
    <div class="heading">
        <div *ngIf="historyItems.length > 1" class="heading-back">
            <i class="icon arrow-left icon-back"></i>
            <span (click)="backward()">{{getLastHistoryItem().name}}</span>
        </div>
        <div (click)="close()" class="icon close"></div>
    </div>
    <ul>
        <li *ngFor="let item of items">
            <div class="description">
                <div *ngIf="item.hasChildren; then thenBlock; else elseBlock"></div>
                <ng-template #thenBlock>
                    <p (click)="forward(item)">{{item.name}}</p>
                </ng-template>
                <ng-template #elseBlock>
                    <p (click)="selectedItem(item)">{{item.name}}</p>
                </ng-template>
            </div>
        </li>
    </ul>
</div>
