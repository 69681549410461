import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

import { AuthService } from '../user/auth.service';
import { Routes } from 'src/app/constants/routes.constant';

@Injectable()
export class LandingGuardService implements CanActivate {

  constructor(
    public authService: AuthService, 
    public router: Router,
    public deviceService: DeviceDetectorService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {

    if (!this.authService.isLoggedIn()) {
      this.router.navigate([Routes.GUEST.SIGN]);
      return false;
    }

    if (!this.deviceService.isMobile()) {
      this.router.navigate([Routes.ORDER.LIST]);
      return false;
    }
    
    return true;
  }
}
