import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

import { Routes } from 'src/app/constants/routes.constant';

@Component({
  selector: 'order-gps-tracking',
  templateUrl: './gps-tracking.component.html',
  styleUrls: ['./gps-tracking.component.scss']
})
export class GpsTrackingComponent implements OnInit {
  isMobile: boolean;
  orderId: string;

  constructor(
    private deviceService: DeviceDetectorService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }
  
  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
    this.orderId = this.route.snapshot.paramMap.get('id');

    if (!this.isMobile) {
      this.router.navigate([Routes.ORDER.DETAILS + this.orderId]);
    }
  }

}
