import { Component } from '@angular/core';

import { AuthorisationsComponent } from '../authorisations.component';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CommandService } from 'src/app/services/common/command.service';
import { CardService } from 'src/app/services/user/card.service';

@Component({
  selector: 'booking-authorisations-desktop',
  templateUrl: './authorisations-desktop.component.html',
  styleUrls: ['./authorisations-desktop.component.scss']
})
export class AuthorisationsDesktopComponent extends AuthorisationsComponent {

  shouldDisplay: boolean = false;

  constructor(
    protected deviceService: DeviceDetectorService, 
    protected commandService: CommandService,
    protected cardService: CardService,
     protected router: Router) {
    super(deviceService, commandService, cardService ,router);
  }

  ngOnInit() {

    super.hasAuthorisationsAsync()
      .then(response => {
        this.shouldDisplay = !response.commandResult.hasAuthorizations;
        // if (!this.shouldDisplay) {
        //   super.redirectToPayment();
        // }
      });
  }

}
