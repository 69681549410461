<div class="h-padding v-padding">
  <h1>Schedule Overview [WIP]</h1>

  <ng-container *ngIf="!working">
    <form>
      <mat-label>Please select a postcode from the list</mat-label>
      <mat-form-field class="align-center">
        <mat-select [(value)]="selectedPostcode" (selectionChange)="selectedPostcodeChanged($event)">
          <mat-option *ngFor="let postcode of postcodes" [value]="postcode">
            {{postcode | uppercase}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
    <div *ngIf="selectedPostcodeSchedule">
      <div class="row">
        <form>
          <mat-form-field>
            <mat-label>Postcode</mat-label>
            <input matInput placeholder="Postcode" [value]="selectedPostcodeSchedule.postCode">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Area</mat-label>
            <input matInput placeholder="Area" [value]="selectedPostcodeSchedule.area">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Postcode</mat-label>
            <input matInput placeholder="Postcode" [value]="selectedPostcodeSchedule.postCode">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Route</mat-label>
            <mat-select [(value)]="selectedPostcodeSchedule.route">
              <option value="" [disabled]="true">Select Route</option>
              <mat-option *ngFor="let route of routes" [value]="route">
                {{route.routeName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>

        <div style="width: 100%; overflow-x: auto;overflow-y: hidden">
          <div style="width:500px">
            <div style="opacity:0; display:inline-block; width: 70px">hidden</div>
            <div *ngFor="let day of days" style="text-align:center; display:inline-block; width: 60px">
              <span style="text-transform: uppercase">{{getDayShort(day)}}</span>
            </div>
          </div>
          <div *ngFor="let slot of slots | keyvalue" class="row" style="text-align:left">
            <div style="text-align:center; display:inline-block; width: 70px">
              <div class="grid-cell">{{slot.value.friendlyName}}</div>
            </div>

            <div style="text-align:center; display:inline-block; width: 60px" *ngFor="let day of days">
              <div class="grid-cell">
                <mat-checkbox [(ngModel)]="selectedPostcodeSchedule.slots[day][slot.key].active"></mat-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <a (click)="save()" class="button">
            <span *ngIf="!working">Save</span>
            <span *ngIf="working">Saving...</span>
          </a>
        </div>
      </div>
    </div>
  </ng-container>

  <shared-spinner *ngIf="working"></shared-spinner>
</div>
