import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Routes } from 'src/app/constants/routes.constant';

import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { HttpStatusCode } from 'src/app/models/common/enums/http-status-code';
import { CommandService } from 'src/app/services/common/command.service';
import { StorageService } from 'src/app/services/common/storage.service';
import { NotSupportedComponent } from '../not-supported/not-supported.component';
import { PostCodeService } from 'src/app/services/shared/postCode.service';

@Component({
  selector: 'app-postcode-modal',
  templateUrl: './postcode-modal.component.html',
  styleUrls: ['./postcode-modal.component.scss']
})
export class PostcodeModalComponent implements OnInit {
  hasError: boolean = false;
  isWorking: boolean = false;
  redurectUrl: string;
  
  postCodeForm: FormGroup = new FormGroup({
    postCode: new FormControl('', [Validators.required]),
  });

  constructor(
    public dialogRef: MatDialogRef<PostcodeModalComponent>,
    public dialog: MatDialog,
    private commandService: CommandService,
    private storageService: StorageService,
    private postCodeService: PostCodeService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.postCodeForm.get('postCode').valueChanges.subscribe(res=>{
      this.hasError = false;
    })
  }

  get postcode() {
    return this.postCodeForm.get('postCode');
  }

  submitForm() {
    const postcode = this.postCodeForm.get('postCode').value;
    this.isWorking = true;

    this.postCodeService.verifyPostCode(postcode)
      .then(resp => {
        this.isWorking = false;
        this.hasError = false;
        this.storageService.savePostcode(resp['postCode']);
        this.close();
        // this.router.navigate([Routes.BOOKING_REGULAR.PROVISION]);
        if (this.router.url.search('deliveryonly') != -1) {
          this.router.navigate([Routes.DELIVERY_ONLY.SLOT]);
        } else {
          if (this.storageService.getObject('fromRecurring') == 'recurring') {
            this.router.navigate([Routes.USER.RECURRING]);
          } else {
            this.router.navigate([Routes.BOOKING_REGULAR.SLOTS]);
          }
        }
        
        
      })
      .catch(err => {
        if(err.status === HttpStatusCode.BadRequest){
          this.isWorking = false;
          this.hasError = true;
          this.dialog.open(NotSupportedComponent, {
            data: { 
              postcode: postcode,
              isInIframe: false
            }
          });
        }
      });
  }

  close() {
    this.dialogRef.close();
  }
}
