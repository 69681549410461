import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { CommandEnum } from '../../models/common/enums/command.enum';
import { CommandResponse } from '../../models/common/command-response.model';
import { ICommandService } from './contracts/ICommandService';
import { CookieService } from './cookie.service';

@Injectable({
  providedIn: 'root',
})
export class BlancCommandService {
  endpoint: string;

  body: {
    RequestType: string,
    AccountKey: string,
    SessionID: string,
    Parameters: string | object
  };

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
  ) {
    this.endpoint = environment.blancApi;

    this.body = {
      RequestType: '',
      AccountKey: environment.blancAccountKey,
      SessionID: '',
      Parameters: {}
    };
  }

  executeCommand(requestType: CommandEnum, data: string | object = {}) {
    this.body.RequestType = requestType;
    this.body.SessionID = this.cookieService.getCookie('blanc_session_id');
    this.body.Parameters = data;

    return this.http.post(this.endpoint, this.body).toPromise();
  }

}
