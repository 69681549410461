import { Component, OnInit } from '@angular/core';
@Component({
    selector: 'delivery-only-root',
    templateUrl: './delivery-only.component.html',
    styleUrls: ['./delivery-only.component.scss']
})
export class DeliveryOnlyComponent implements OnInit {
    constructor(
    ) { }

    ngOnInit(): void {

    }
}