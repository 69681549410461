<div class="content animation-fade-in"> 
  <div class="wrapper">
    <shared-background-rectangle></shared-background-rectangle>
    <h1>Personal information</h1>

    <div class="box">
      <h4>Change password</h4>
      <user-change-password-form></user-change-password-form>
    </div>
  </div>
</div>
