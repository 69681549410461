<div  class="close-btn-wrapper">
  <div (click)="closeDialog()" class="icon close" id="close-btn"></div>
</div>

<div *ngIf="!preference && !order_only " class="content">
  <h3>Save delivery instructions for future orders?</h3>

  <div class="text-wrapper">
    <p class="ff-300">You can add your delivery instructions to your Preferences settings so they are taken into account for all future orders. </p>
    <p class="ff-300"> If not, they will apply to this order only.</p>
  </div>
  <button class="btn submit " (click)="SavePreference()" [id]="orderEditAddressSaveAsPreference">Save as a preference</button>
  <button class="btn secondary-neutral " (click)="SaveOrderOnly()" [id]="orderEditAddressSaveForThisOrderOnly">Save for this order only</button>
</div>

<div *ngIf="preference"  class="content">
  <div class="heading">
    <h3> Added to your settings!</h3>
    <span>You can manage your delivery instructions in your Preferences settings from your online profile.</span>
  </div>
  <div class="icon done"></div>
  </div>

  <div *ngIf="order_only"  class="content">
    <div class="heading">
      <h3> Ok, your instructions have been saved but will not apply to future orders.</h3>
      <span>You will still be able to provide specific delivery  instructions the next time you book online.</span>
      
    </div>
    <div class="icon done"></div>
  </div>
