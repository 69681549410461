<menu class="animation-fade-in">
    <header>
        <div *ngIf="!isLoading" class="wrapper animation-fade-in">
            <h3>{{greetings}}</h3>
            <p>What can we do for you today?</p>
            <a (click)="redirectToBooking()" class="btn primary left-rounded with-icon with-arrow" [id]="menuDeskNewBooking">
       New Booking
      </a>
        </div>
    </header>

    <ul>
        <li>
            <a [routerLink]="orderListRoute" routerLinkActive="active" (click)="cleanState()" [id]="menuDeskOrders">
                <div class="icon orders-small-green"></div>
                <p>Your orders</p>
            </a>
        </li>
        <li>
            <a (click)="redirectToRecurring()" [ngClass]="{'active': isOnRecurring}" [id]="menuDeskRecurring">
                <div class="icon recurrence"></div>
                <div class="bl-new-tag"  *ngIf="showBadgeNew"><span>NEW</span></div>
                <p>Recurring booking</p>
                &nbsp;&nbsp;<shared-spinner *ngIf="spinner" color="green"></shared-spinner>
            </a>
        </li>
        <li>
            <a routerLink="/personal-info" routerLinkActive="active" (click)="cleanState()" [id]="menuDeskPersonnalInfo">
                <div class="icon profile"></div>
                <p>Personal information</p>
            </a>
        </li>
        <li>
            <a routerLink="/authorisations" routerLinkActive="active" (click)="cleanState()" [id]="menuDeskPreferences">
                <div class="icon preferences"></div>
                <p>Preferences</p>
            </a>
        </li>
        <!-- <li>
      <a routerLink="/refer" routerLinkActive="active" (click)="cleanState()">
        <div class="icon gift"></div>
        <p>Refer a friend</p>
      </a>
    </li> -->
        <li>
            <a routerLink="/help-feedback" routerLinkActive="active" (click)="cleanState()" [id]="menuDeskHelpFeedback">
                <div class="icon help"></div>
                <p>Help & feedback</p>
            </a>
        </li>
        <li *ngIf="isAdmin">
            <a routerLink="/admin" routerLinkActive="active" class="highlighted" id="admin-btn" (click)="cleanState()">
                <div class="icon logo-green"></div>
                <p>Admin panel</p>
            </a>
        </li>
    </ul>
</menu>