import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BookingStepsEnum } from 'src/app/models/booking/enums/booking-steps.enum';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { SummaryAddress } from 'src/app/models/booking/summary-address.model';
import { Instructions } from 'src/app/models/booking/instructions.model';
import { TimeSlot } from 'src/app/models/booking/time-slot.model';
import { ServiceWrapperModel } from 'src/app/models/particle/service-wrapper.model';
import { CommandService } from 'src/app/services/common/command.service';
import { AuthService } from 'src/app/services/user/auth.service';
import { HttpStatusCode } from 'src/app/models/common/enums/http-status-code';
import { AlertService } from 'src/app/services/common/alert.service';
import { PaymentInfo } from 'src/app/models/delivery-only/payment-info.model';
import { Routes } from 'src/app/constants/routes.constant';
import { BookingStepsService } from 'src/app/services/booking/booking-steps.service';
import { CookieService } from 'src/app/services/common/cookie.service';

@Component({
  selector: 'delivery-only-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {

  dateFormat: string = 'dddd[,] D MMMM';
  step: BookingStepsEnum = BookingStepsEnum.Summary;

  hasAllData: boolean = false;
  deliverySlot: TimeSlot;
  instructions: Instructions;
  hasInstructions: boolean;
  address: SummaryAddress;
  payment: PaymentInfo;
  services: ServiceWrapperModel[] = [];

  constructor(
    protected auth: AuthService,
    protected router: Router,
    protected commandService: CommandService,
    protected cookieService: CookieService,
    protected alertService: AlertService,
    protected stepService: BookingStepsService
  ) { }

  async ngOnInit() {
    await this.initData();
    this.hasAllData = true;
  }

  async initData() {
    this.stepService.setStep(BookingStepsEnum.Summary);
    var response = await this.commandService.executeCommand(CommandEnum.OrderSummaryReadCommand);
    this.deliverySlot = response.commandResult.slots?.delivery;
    this.payment = response.commandResult.payment?.paymentMethodInformation;

    this.instructions = new Instructions(
      response.commandResult.address.instructions?.deliverToId,
      response.commandResult.address.instructions?.deliverToName.toLowerCase(),
      response.commandResult.address.instructions?.countryCode,
      response.commandResult.address.instructions?.phoneNumber,
      response.commandResult.address.instructions?.other,
    );

    this.hasInstructions = this.instructions?.deliverToName.length > 0;

    this.address = new SummaryAddress(
      response.commandResult.address.addressLine1,
      response.commandResult.address.addressLine2,
      response.commandResult.address.postcode,
    );
  }

  async confirmBooking() {
    this.hasAllData = false;
    const result = await this.commandService.executeCommand(CommandEnum.OrderDeliveryConfirmCommand);
    const orderId = result.commandResult?.orderId;

    if (result.errorMessage !== '') {
      this.alertService.showError(`${result.errorMessage} An email has been sent to CC.`);
      await this.commandService.executeCommand(CommandEnum.DeliveryOrderErrorEmailSendCommand, result.errorMessage)
        .catch(err => console.log(err));
    } else if (result.statusCode === HttpStatusCode.Ok) {
      this.cookieService.removeCookie('secret');
      this.router.navigate([Routes.DELIVERY_ONLY.THANK_YOU], {queryParams: { orderId }});
    } else {
      this.alertService.showError(result.errorMessage);
    }

    this.hasAllData = true;
  }
}
