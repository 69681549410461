import { OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ScheduleService } from 'src/app/services/booking/schedule.service';
import { SlotService } from 'src/app/services/booking/slot.service';

export class ScheduleBase implements OnInit, OnDestroy {

    daysToShow = 1;
    displayDateFormat: 'DD MMM';
    displayDayFormat: {};

    apiDateFormat = 'YYYY-MM-DD';

    constructor(
        protected schedules: ScheduleService,
        protected slotService: SlotService) {

    }
    ngOnInit() {
        this.initSize();
    }
    formatDate(date: moment.Moment) {
        return date.calendar(null, this.displayDayFormat) + ' ' + date.format(this.displayDateFormat);;
    }
    private initSize() {
        this.setScreenSpecificSettings();
        window.addEventListener('resize', this.setScreenSpecificSettings.bind(this));
        window.addEventListener('orientationchange', this.setScreenSpecificSettings.bind(this));
    }

    private setScreenSpecificSettings() {
        if (window.innerWidth <= 740) {
            this.daysToShow = 1;
            this.displayDateFormat = 'DD MMM';
            this.displayDayFormat = {
                sameDay: 'ddd.',
                nextDay: 'ddd.',
                nextWeek: 'ddd.',
                lastDay: 'ddd.',
                lastWeek: 'ddd.',
                sameElse: 'ddd.'
            }
        } else {
            this.displayDateFormat = 'DD MMM';
            this.displayDayFormat = {
                sameDay: '[Today]',
                nextDay: '[Tomorrow]',
                nextWeek: 'ddd.',
                lastDay: '[Yesterday]',
                lastWeek: '[Last] ddd.',
                sameElse: 'ddd.'
            }
            if (window.innerWidth >= 940) {
                this.daysToShow = 4;
            } else {
                this.daysToShow = 3;
            }
        }
    }

    ngOnDestroy() {
        window.removeEventListener('resize', this.setScreenSpecificSettings.bind(this));
        window.removeEventListener('orientationchange', this.setScreenSpecificSettings.bind(this));
    }

    toMoment(date: string): moment.Moment {
        var convertedToMoment = moment(date);
        return convertedToMoment;
    }

    addDays(date: moment.Moment, days: number) {
        var newDate = moment(date).add(days, 'days');
        return newDate;
    }
    substractDays(date: moment.Moment, days: number) {
        var newDate = moment(date).subtract(days, 'days');
        return newDate;
    }
}