import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Routes } from 'src/app/constants/routes.constant';

@Component({
  selector: 'user-authorisations-modal',
  templateUrl: './authorisations-modal.component.html',
  styleUrls: ['./authorisations-modal.component.scss']
})
export class AuthorisationsModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AuthorisationsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) { }
  
  ngOnInit(): void {
  }

  redirect() {
    this.closeModal();
    
    if (this.data.pendingOrders.length > 1) {
      this.router.navigate([Routes.ORDER.LIST]);
    } else if (this.data.pendingOrders.length > 0) {
      this.router.navigate([Routes.ORDER.DETAILS + this.data.pendingOrders[0].id]);
    }
  }

  closeModal() {
    this.dialogRef.close();
  }
}
