import { Injectable } from '@angular/core';
import { TimeSlot } from 'src/app/models/booking/time-slot.model';
import { ISlotService } from './contracts/ISlotService';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SlotService implements ISlotService {
  public pickupSlotVisibilty = new BehaviorSubject<boolean>(true);
  public deliverySlotVisibility = new BehaviorSubject<boolean>(true);
  public pickupSlot = new BehaviorSubject<TimeSlot>(null);

  public deliverFrom = new BehaviorSubject<TimeSlot>(null);

  public deliverySlot = new BehaviorSubject<TimeSlot>(null);

  setPickupSlot(slot: TimeSlot) {
    this.pickupSlot.next(slot);
  }

  getPickupSlot(): TimeSlot {
    return this.pickupSlot.getValue();
  }

  setDeliverySlot(slot: TimeSlot) {
    this.deliverySlot.next(slot);
  }

  getDeliverySlot() {
    return this.deliverySlot.getValue();
  }

  setDeliveryFromSlot(slot: TimeSlot) {
    this.deliverFrom.next(slot);
  }

  getDeliveryFromSlot() {
    this.deliverFrom.getValue();
  }

  setPickupSlotVisibility(visibility: boolean) {
    this.pickupSlotVisibilty.next(visibility);
  }

  getPickupSlotVisibility(): boolean {
    return this.pickupSlotVisibilty.getValue();
  }

  setDeliverySlotVisibility(visibility: boolean) {
    this.deliverySlotVisibility.next(visibility);
  }

  getDeliverySlotVisibility(): boolean {
    return this.deliverySlotVisibility.getValue();
  }
}
