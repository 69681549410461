import { Component, Input, OnInit } from '@angular/core';
import { icon, latLng, marker, tileLayer } from 'leaflet';

import { Coordinates } from 'src/app/models/order/coordinates.model';

@Component({
  selector: 'order-leaflet-map',
  templateUrl: './leaflet-map.component.html',
  styleUrls: ['./leaflet-map.component.scss']
})
export class LeafletMapComponent implements OnInit {
  isLoading: boolean = true;
  coordinatesAddress: Coordinates = new Coordinates();
  coordinatesDriver: Coordinates = new Coordinates();
  options: any;
  layerAddress: any;
  layerDriver: any;
  layers: any[];

  @Input() height: string;

  constructor() { }

 async ngOnInit() {
    await this.initCoordinates();
    await this.initMapOptions();
    await this.initMapLayers();
    this.isLoading = false;
  }

  initCoordinates() {
    this.coordinatesAddress.latitude = 51.514570;
    this.coordinatesAddress.longitude = -0.203910;

    this.coordinatesDriver.latitude = 51.490390;
    this.coordinatesDriver.longitude = -0.156050;
  }

  initMapOptions() {
    this.options = {
      layers: [
        tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 19,
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        })
      ],
      zoom: 16,
      center: latLng(this.coordinatesDriver.latitude, this.coordinatesDriver.longitude)
    };
  }

  initMapLayers() {
    this.layerAddress = marker([ this.coordinatesAddress.latitude, this.coordinatesAddress.longitude ], {
      icon: icon({
        iconSize: [ 31, 44 ],
        iconUrl: 'assets/icons/pin-address.svg',
      })
    });
  
    this.layerDriver = marker([ this.coordinatesDriver.latitude, this.coordinatesDriver.longitude ], {
      icon: icon({
        iconSize: [ 62, 62 ],
        iconUrl: 'assets/icons/pin-driver.svg',
      })
    });
  
    this.layers = [ this.layerAddress, this.layerDriver ];
  }

}
