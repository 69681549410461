<div class="content">
  <div class="close-btn-wrapper">
    <div (click)="closeModal()" class="icon close" id="close-btn"></div>
  </div>

  <h3>We need your authorisation</h3>
  <p>{{data.pendingOrders[0].pendingCount}} authorisation(s) needed to </p>
  <p>proceed with your order </p>
  <p>n°{{data.pendingOrders[0].id}}.</p>
  
  <div class="actions">
    <a (click)="redirect()" class="btn secondary-danger" id="pending-order-btn">See my order</a>
  </div>

  <div class="contact-info">
    <p>Need to talk to us?</p>
    <p>call +44 (0)20 8004 2630</p>
  </div>
</div>

