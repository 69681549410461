import { Component, Input, OnInit } from '@angular/core';

import { OrderDetails } from 'src/app/models/order/order-details.model';
import { Routes } from 'src/app/constants/routes.constant';
import { SummaryAddress } from 'src/app/models/booking/summary-address.model';
import { Instructions } from 'src/app/models/booking/instructions.model';
import { OrderTypeEnum } from 'src/app/models/order/enums/order-delivery-type.enum';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { CommandService } from 'src/app/services/common/command.service';

@Component({
  selector: 'order-past-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss']
})
export class HeadingPastComponent implements OnInit {
  isLoading: boolean = true;
  orderListRoute = Routes.ORDER.LIST;
  address: SummaryAddress;
  instructions: Instructions;
  hasInstructions: boolean;

  @Input() order :any;

  constructor(private commandService: CommandService) { }

  async ngOnInit() {
    await this.initOrderSummary();
    this.isLoading = false;

  }

  async initOrderSummary() {

    var param = {
      infoOrder_id : +this.order.infoOrder_id,
    }
    const response = await this.commandService.executeCommand(CommandEnum.GetDeliveryByOrder, param);

    // this.order.Status = OrderTypeEnum[this.order.TypeDelivery];
    this.order.Status = 'Pickup and Delivery address';

    this.instructions = new Instructions(
      response.ReturnObject?.DeliveryaskID,
      response.ReturnObject?.PickupTypeDelivery,
      response.ReturnObject?.PickupCodeCountry,
      response.ReturnObject?.PickupPhoneNumber,
      response.ReturnObject?.PickupComment,
    );
    
    this.hasInstructions = this.instructions?.deliverToName?.length > 0;

    this.address = new SummaryAddress(
      response.ReturnObject?.PickupAddress1,
      response.ReturnObject?.PickupAddress2,
      response.ReturnObject?.PickupPostcode,
      response.ReturnObject?.PickupTown
    );
  }
}
