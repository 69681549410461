<div class="h-padding v-padding">
  <h1>Sync Spot User</h1>
  <form (ngSubmit)="sync()">
    <p>Enter the user's email address below and hit Sync User.</p>
    <ng-container [formGroup]="syncForm">
      <mat-form-field>
        <mat-label for="test">EMAIL</mat-label>
        <input matInput formControlName="username" autocomplete="username" name="test">
        <mat-error *ngIf="usernameForm.hasError('email') && !usernameForm.hasError('required')">
          Please enter a valid email address
        </mat-error>
        <mat-error *ngIf="usernameForm.hasError('required')">
          Email is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </ng-container>
    <button class="button" type="submit">
      <span *ngIf="!working">Sync User</span>
      <shared-spinner *ngIf="working" color="white"></shared-spinner>
    </button>
    <p *ngIf="error" class="mat-error">{{error}}</p>
    <div *ngIf="user" class="card">
      <h1>Spot User Details:</h1>
      <div style="white-space: pre-wrap;">
        {{user | json}}
      </div>
    </div>
  </form>
  <h1>Sync all users</h1>
  <form (ngSubmit)="syncSpotToBlancApi()">
    <p>Sync all users from spot to Blanc Api v1.</p>
    <button class="button" type="submit">
      <span *ngIf="!workingV1">Sync all users</span>
      <shared-spinner *ngIf="workingV1" color="white"></shared-spinner>
    </button>
    <p *ngIf="errorV1 && !workingV1" class="mat-error">{{errorV1}}</p>
  </form>
  <br>
  <div class="instructions v-margin">
    <h1>Instructions</h1>
    <ol>
      <li>Go to the person’s account on Spot and update their password to the default password. (Image below)</li>
      <li>Enter the email address for the customer to connect above and hit Sync.</li>
    </ol>
    <img src="assets/images/spot.jpg" />
  </div>
</div>
