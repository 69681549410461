import { PwaService } from './../../../services/common/pwa.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-from-qr-code-modal',
  templateUrl: './from-qr-code-modal.component.html',
  styleUrls: ['./from-qr-code-modal.component.scss']
})
export class FromQrCodeModalComponent implements OnInit {
  private _isIos: boolean;
  doShowPopup: boolean;
  doShowIosInstruction: boolean;
  doFadeOut: boolean;
  constructor(public dialogRef: MatDialogRef<FromQrCodeModalComponent>,
              private _pwaService: PwaService,

  ) {
    _pwaService.checkForUpdates();
  }
  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e: Event) {
    e.preventDefault();
    this._pwaService.setDefferdPrompt(e);
  }

  ngOnInit(): void {
  }

  addToHomescreen(event: Event): void {
    event.preventDefault();
    this._pwaService.addToHomescreen();
  }
  close() {
    this.dialogRef.close();
  }
}
