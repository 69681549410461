import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IAlertService } from './contracts/IAlertService';

@Injectable({
  providedIn: 'root'
})
export class AlertService implements IAlertService{

  constructor(private toastr: ToastrService) {}

  showError(error: string = 'An error occurred', timeoutMs: number = 5000) {
    this.toastr.error(error, '', {
      timeOut: timeoutMs
    });
  }

  showMessage(msg: string, timeoutMs: number = 5000) {
    this.toastr.info(msg, '', {
      timeOut: timeoutMs
    });
  }
  
  showSuccess(msg: string, timeoutMs: number = 5000) {
    this.toastr.success(msg, '', {
      timeOut: timeoutMs
    });
  }

}
