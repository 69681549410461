<shared-navigation-top [title]="ordertype"></shared-navigation-top>
<shared-spinner *ngIf="!working" color="white"></shared-spinner>
<div class="content animation-fade-in" *ngIf="working">
    <ul>

        <li *ngIf="pickupSlot && deliverySlot" class="slots" id="slots">
            <div class="Delivery-details">
                <div class="pickup">
                    <div class="heading">
                        <p>{{pickup}}</p>
                        <button class="btn href green" [id]="orderMobileEditPickup" (click)="edit(editEnum.Pickup)" *ngIf="Edit_pickup">Edit</button>
                    </div>
                    <div class="info">
                        <p class="pickup-hour" *ngIf="!Instore">{{pickupSlot }}</p>
                        <p class="pickup-date">{{ (myDate_pickup !== '2020-01-01') ? (myDate_pickup | date : 'EEE  dd/MM' ) : "-" }}</p>
                        <p class="" *ngIf="Instore">At 3.56PM</p>
                        <p class="delivery-type" *ngIf="Instore">{{order.TypeDelivery | titlecase}}</p>
                    </div>
                </div>
                <div class="delevery">
                    <div class="heading">
                        <p>{{delivery}}</p>
                        <button *ngIf="Edit_delivery" class="btn href green" id="edit-address-btn" (click)="edit(editEnum.Delivery)">Edit</button>
                        <button *ngIf="Edit_delivery_PickupUpcoming" class="btn href green" [id]="orderMobileEditDelivery" (click)="edit(editEnum.PickupUpcoming)">Edit</button>
                    </div>
                    <div class="info">
                        <p class="delivery-hour" *ngIf="!Instore">{{deliverySlot}}</p>
                        <p class="delivery-date">{{(myDate !== '2020-01-01') ? (myDate | date : 'EEE  dd/MM'  ):"-"}}</p>
                        <p class="" *ngIf="Instore">From 6PM</p>
                        <p class="delivery-type" *ngIf="Instore">{{order.TypeDelivery | titlecase}}</p>
                    </div>
                    <!-- <div *ngIf="instructions.deliverToName && !Instore" class="info">
        <p class="deliver-to">{{instructions.deliverToName}}</p>
      </div> -->
                </div>
            </div>
        </li>
        <li *ngIf="address && !Instore" class="address" id="address">
            <div class="heading">
                <p>Address</p>
                <button class="btn href green" [id]="orderMobileEditAddress" (click)="edit(editEnum.Address)" *ngIf="Edit_pickup">Edit</button>
            </div>
            <div class="info">
                <p class="address-line-1">{{address.addressLine1}}</p>
                <p class="address-line-2">{{address.addressLine2}}</p>
                <p class="address-line-2">{{address.town}} {{address.postcode}}</p>
            </div>
        </li>
        <li *ngIf="!Instore && !isRecurring" class="content-instruction">
            <div class="heading">
                <h2>Delivery instructions</h2>
                <a (click)="toggleShown()" class="icon arrow-down" id="toggle-button"></a>
            </div>
            <button class="btn href green" [id]="orderMobileEditDeliveryInst" (click)="edit(editEnum.Instruction)" *ngIf="Edit_pickup">Edit</button>
            <div class="more" class="more animation-slide-down" [ngClass]="{'shown': isShown}">
                <div *ngIf="hasInstructions" class="instruction">
                    <div class="heading" *ngIf="instructions.other !== ''">
                        <p class="name">instructions</p>
                    </div>
                    <div class="left" *ngIf="instructions.other !== ''">
                        <p class="item-name black">{{instructions.other}}</p>
                    </div>
                    <div class="heading">
                        <p class="item-name">Preferred contact</p>
                    </div>
                    <div class="left">
                        <p class="name">Pick up and deliver to</p>
                        <span>{{instructions.deliverToName | titlecase}}</span>
                    </div>
                    <div class="left" *ngIf="instructions.phoneNumber !== ''">
                        <p class="name">Their phone number</p>
                        <span class="item-name">{{instructions.countryCode}} {{instructions.phoneNumber}}</span>
                    </div>
                </div>
                <div *ngIf="!hasInstructions" class="instruction">
                    <p>You haven't added any delivery instructions.</p>

                </div>
            </div>
        </li>
    </ul>
</div>