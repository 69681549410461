import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ParticleService } from 'src/app/services/particle/particle.service';
import { ParticleTypeEnum } from 'src/app/models/particle/enums/particle-type.enum';

@Component({
  selector: 'shared-country-code-picker',
  templateUrl: './country-code-picker.component.html',
  styleUrls: ['./country-code-picker.component.scss']
})
export class CountryCodePickerComponent implements OnInit {

  countryCodeOptions = [];

  @Input() countryCode: string;

  countryCodeField: FormControl;
  countryCodeForm: FormGroup;
  
  @Output() onPick: EventEmitter<string> = new EventEmitter<string>();

  constructor(private particleService: ParticleService) { }

  ngOnInit(): void {
    this.countryCodeField = new FormControl(this.countryCode, Validators.required);
    
    this.countryCodeForm = new FormGroup({
      countryCodeField: this.countryCodeField
    });

    this.getCountryCodes();
  
    if (this.countryCode) {
      this.countryCodeField.setValue("+"+this.countryCode);
    }
  }

  getCountryCodes() {
    this.particleService.getParticle(ParticleTypeEnum.CountryCode).subscribe(codes => this.countryCodeOptions = codes);
  }

  onSelect(option) {
    this.countryCodeField.setValue(option.value);
    
    this.onPick.emit(this.countryCodeField.value);
  }

}
