import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { CardService } from 'src/app/services/user/card.service';
import { CardResponse } from 'src/app/models/user/card-response.model';
import { CommandService } from 'src/app/services/common/command.service';
import { BlancCommandService } from 'src/app/services/common/blanc-command.service';
import { AlertService } from 'src/app/services/common/alert.service';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { PaymentMethodEnum } from 'src/app/models/booking/enums/payment-method.enum';
import { CardStateEnum } from 'src/app/models/user/enums/card-state.enum';
import { Subscription } from 'rxjs';
import { Routes } from 'src/app/constants/routes.constant';
import { BookingStepsService } from 'src/app/services/booking/booking-steps.service';
import { BookingStepsEnum } from 'src/app/models/booking/enums/booking-steps.enum';

@Injectable({
  providedIn: 'root',
})
export class PaymentBase implements OnInit, OnDestroy {
  
  cardStateEnum: typeof CardStateEnum = CardStateEnum;
  cardState: CardStateEnum = CardStateEnum.Default;
  card: CardResponse;
  stateSubscription: Subscription;
  cardSubscription: Subscription;
  // bookingStepsService: BookingStepsService;
  store: string;
  constructor(
    protected router: Router,
    protected location: Location,
    protected route: ActivatedRoute,
    protected cardService: CardService,
    protected bookingStepsService: BookingStepsService,
    protected commandService: CommandService,
    protected blancCommandService: BlancCommandService,
    protected alertService: AlertService,
  ) { }

  ngOnDestroy(): void {
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }
    if (this.cardSubscription) {
      this.cardSubscription.unsubscribe();
    }
  }

  async ngOnInit() {
   
    this.store = this.route.snapshot.queryParams.store;
    this.stateSubscription = this.cardService.state.subscribe(value => {
      this.cardState = value;
    });
    this.cardSubscription = this.cardService.card.subscribe(value => {
      this.card = value;
    })
  }

  submitPaymentInfo() {
    const paymentInfo = {
      paymentMethodInformation: this.card,
      method: PaymentMethodEnum.Card
    };

    this.blancCommandService.executeCommand(CommandEnum.CustomerDetail)
      .then(response =>
      {
        if ( this.store) {
          this.router.navigate([Routes.BOOKING_REGULAR.AUTHORISATIONS_STORE],{ queryParams: { store: this.store } });
        } else {
          this.router.navigate([Routes.BOOKING_REGULAR.SUMMARY]);
        }
        
      })
      .catch(err => {
        this.commandService.handleCommandTokenError(err);
      });
  }
}
