<div class="content">
  <div class="heading">
    <p class="name">Delivery instructions</p>
    <a (click)="EditInstruction()" *ngIf="hasInstruction&&!instClear" class="btn href green"><span>Edit</span></a>
    <a (click)="clearInstruction()" *ngIf="hasInstruction&&!instClear" class="bin">
      <mat-icon>delete</mat-icon>
    </a>

  </div>
  <!-- <shared-spinner *ngIf="!working | async"color="white"></shared-spinner> -->
  <div *ngIf="working | async">
    <form [formGroup]="instructionsForm" *ngIf="!hasInstruction && !instClear">
      <div class="others-instructions">
        <p>Instructions</p>
        <textarea formControlName="other" maxlength="150" matInput cdkTextareaAutosize
          placeholder="Ring the bell / knock, leave on doorstep ..."></textarea>
        <div class="counter">
          <p>{{ instructionsForm.controls.other.value.length }} / 150</p>
        </div>
      </div>

      <div class="field-wrapper deliver-to">
        <p>Preferred contact</p>
        <mat-form-field class="deliver-to" >
          <mat-select formControlName="deliverToId" [(value)]="deliverToOptionSelected" disableOptionCentering>
              <mat-option *ngFor="let option of deliverToOptions; let i = index" [value]="option.value">
                  {{option.value}}
              </mat-option>
          </mat-select>
      </mat-form-field>
        <div class="error" *ngIf="
            getInstructionFormField('deliverToId').hasError('required') &&
            instructionsForm.controls.deliverToId.touched
          ">
          Field is required
        </div>
      </div>
      <div class="field-wrapper postcode">
        <shared-country-code-picker (onPick)="setCountryCodeValue($event)" [countryCode]="code">
        </shared-country-code-picker>
      </div>
      <div class="field-wrapper custom-input-wrapper phone">
        <label>Their phone number</label>
        <div>
          <input matinput type="tel" formControlName="phoneNumber" />
          <div class="error" *ngIf="
            getInstructionFormField('phoneNumber').hasError('required') &&
            instructionsForm.controls.phoneNumber.touched
          ">
            Field is required
          </div>
          <div class="error" *ngIf="
            getInstructionFormField('phoneNumber').hasError('pattern') &&
            instructionsForm.controls.phoneNumber.touched
          ">
            Phone number is invalid
          </div>
        </div>
      </div>
      <div class="buttons">
        <button class="btn secondary-danger cancel" (click)="cancelInstruction()">
          Cancel
        </button>
        &nbsp;
        <button type="submit" class="btn secondary green save" id="save-address-instructions-btn"
          [disabled]="!isValidInstruction" (click)="SaveInstruction()">
          Confirm
        </button>
      </div>
    </form>
    <shared-spinner *ngIf="loading && instClear" color="white"></shared-spinner>
    <div *ngIf="instClear && !loading" class="add">
      <div class="bloc-preference">
        <div class="preference-wrapper">
          <span>You don't have any instructions yet</span>
        </div>
      </div>

      <button (click)="onAddInstruction()" class="btn secondary-neutral">Add instructions</button>
    </div>
    <div *ngIf="hasInstruction&&!instClear" class="instruction">
      <div class="others-instructions" *ngIf="deliveryInstruction.other">
        <p>Instructions</p>
        <span>{{ deliveryInstruction.other }}</span>
      </div>

      <div class="field-wrapper deliver-to" *ngIf="deliveryInstruction.deliverToName">
        <p>Preferred contact</p>
        <span>{{ deliveryInstruction.deliverToName }}</span>
      </div>

      <div class="field-wrapper custom-input-wrapper phone" *ngIf="deliveryInstruction.phoneNumber">
        <label>Their phone number</label>
        <div style="margin-top: 10px">
          <span>({{ deliveryInstruction.countryCode}}) {{ deliveryInstruction.phoneNumber }}</span>
        </div>
      </div>
    </div>
  </div>
</div>