import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { SlotService } from 'src/app/services/booking/slot.service';
import { TimeSlot } from 'src/app/models/booking/time-slot.model';
import { TimeSlotTypeEnum } from 'src/app/models/booking/enums/time-slot-type.enum';
import { RescheduleTypeEnum } from 'src/app/models/order/enums/reschedule-type.enum';
import { Routes } from 'src/app/constants/routes.constant';
import { TimeSlots } from 'src/app/models/booking/time-slots.model';
import { CommandService } from 'src/app/services/common/command.service';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';

@Component({
  selector: 'app-reschedule',
  templateUrl: './reschedule.component.html',
  styleUrls: ['./reschedule.component.scss']
})
export class RescheduleComponent implements OnInit {
  orderDetailsRoute = Routes.ORDER.DETAILS;
  orderId: string;

  buttonDisabled: boolean;
  rescheduleForm: FormGroup;
  rescheduleTypeEnum: typeof RescheduleTypeEnum = RescheduleTypeEnum;

  deliverySlot: TimeSlot;
  deliverFromSlot: TimeSlot;
  slotType: TimeSlotTypeEnum = TimeSlotTypeEnum.Delivery;
  @Output() onSelect: EventEmitter<TimeSlot> = new EventEmitter<TimeSlot>();
  pickupSlot: TimeSlot;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private slotService: SlotService,
    protected router: Router,
    private commandService: CommandService,
  ) { }

  ngOnInit(): void {
    this.orderId = this.route.snapshot.paramMap.get('id');
    this.initSlots();
    this.initForm();
  }

  initSlots() {
    this.slotService.deliverFrom.subscribe(slot => this.getDeliverFromSlot(slot));
    this.slotService.deliverySlot.subscribe(slot => this.onSelected(slot));
  }

  initForm() {
    this.rescheduleForm = this.formBuilder.group({
      choice: [RescheduleTypeEnum.Initial, Validators.required]
    });

    this.buttonDisabled = true;
  }

  onFormSubmit() {
    
    // throw new Error('Method not implemented.');
    let order = new TimeSlots();
    order.delivery = this.deliverySlot;
    this.pickupSlot =  {date: "2021-02-21", from: "10:00:00", to: "12:00:00", ignore: true };
    this.slotService.setPickupSlot(this.pickupSlot);
    order.pickup = this.pickupSlot;
    this.commandService.executeCommand(CommandEnum.OrderSlotsSaveCommand, order)
      .then(response => {
        console.log(response)
        this.router.navigate([Routes.BOOKING_REGULAR.SUMMARY])
        //this.router.navigate([this.redirectUrlAdresse])
      })
  }

  getDeliverySlot(slot: TimeSlot) {
    this.deliverySlot = slot;
  }

  getDeliverFromSlot(slot: TimeSlot) {
    this.deliverFromSlot = slot;
  }

  onSelected(slot: TimeSlot) {
    if (slot) {
      this.deliverySlot = slot;
      this.onSelect.emit(this.deliverySlot);
      this.buttonDisabled = false;
    }
  }
}
