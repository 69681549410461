import { Component, OnInit, Inject, Optional } from '@angular/core';
import { ParticleItem } from 'src/app/models/particle/particle-item.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ParticleService } from 'src/app/services/particle/particle.service';
import { ParticleTypeEnum } from 'src/app/models/particle/enums/particle-type.enum';
import { Particle } from 'src/app/models/particle/particle.model';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  FormControl,
  ValidatorFn
} from '@angular/forms';

@Component({
  selector: 'booking-instruction-modal',
  templateUrl: './instruction-modal.component.html',
  styleUrls: ['./instruction-modal.component.scss']
})
export class InstructionModalComponent implements OnInit {

  form: FormGroup;
  item: ParticleItem;
  instructions: Particle[] = [];
  selectedInstructions: Particle[] = [];
  constructor(public dialogRef: MatDialogRef<InstructionModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private particleService: ParticleService,
    private formBuilder: FormBuilder) {
    this.item = data.item;
    this.selectedInstructions = data.selectedInstructions;
    this.form = this.formBuilder.group({
      instructions: new FormArray([], this.minSelectedCheckboxes(1))
    });
  }

  ngOnInit(): void {
    this.particleService.getParticle(ParticleTypeEnum.Instruction, this.item.id).subscribe(
      (response) => {
        this.instructions = response;
        this.instructions.forEach((i) => this.instructionsFromArray.push(new FormControl(this.isSelected(i))));
      },
      (error) => {
        console.log(error);
      }
    );
  }

  private isSelected(i: Particle): boolean {
    let result = this.selectedInstructions.find(s => s.id == i.id) != undefined;
    return result;
  }

  get instructionsFromArray() {
    return this.form.controls.instructions as FormArray;
  }

  minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
        .map(control => control.value)
        .reduce((prev, next) => next ? prev + next : prev, 0);
      return totalSelected >= min ? null : { required: true };
    };

    return validator;
  }

  close = () => this.dialogRef.close({ event: 'close' });

  submit() {
    const selectedInstructions = this.form.value.instructions
      .map((checked, i) => checked ? this.instructions[i] : null)
      .filter(v => v !== null);

    this.dialogRef.close({
      event: 'close',
      item: this.item,
      selectedInstructions: selectedInstructions
    });
  }
}