import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CommonConstants } from 'src/app/constants/common';
import { ParticleService } from 'src/app/services/particle/particle.service';
import { AccountSettingService } from 'src/app/services/user/account-setting.service';

@Component({
  selector: 'app-update-release',
  templateUrl: './update-release-modal.component.html',
  styleUrls: ['./update-release-modal.component.scss']
})
export class UpdateReleaseModalComponent implements OnInit {

  version: string;
  email: string;
  _isIos: boolean
  constructor(
    public dialogRef: MatDialogRef<UpdateReleaseModalComponent>,
     @Inject(MAT_DIALOG_DATA) protected data: any,
    public dialog: MatDialog,
    protected particleService: ParticleService,
    private _deviceDetectorService: DeviceDetectorService,
    protected accountSettingService: AccountSettingService,

  ) { }

  ngOnInit(): void {
    if (this._deviceDetectorService.os === CommonConstants.DEVICES_OS.iOS) {
      this._isIos = true;
      // this.version = localStorage.getItem('version');
      this.version = this.data.version;
      this.email = this.data.email;

    }
    
  }
  close() {
    this.accountSettingService.closeAppWithoutCkeck(this.email)
      .then((data) => { 
        if (data['message'] == 'DONE') {
          this.dialogRef.close();
        }
      });
  }

  saveVersion() {
    this.accountSettingService.checkAppClicked(this.email)
      .then((data) => { 
        if (data['message'] == 'DONE') {
          window.location.href = 'https://apps.apple.com/app/blanc-laundry-app/id1613486714';
          this.dialogRef.close();
        }
      });    
  }
}
