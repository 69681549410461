<form [formGroup]="postCodeForm" (submit)="submitForm()">
    <p>Please enter your postcode</p>

    <div class="field-wrapper custom-input-wrapper">
        <input matInput type="text" formControlName="postCode" autocomplete="postCode" name="postCode" placeholder="e.g. NW6 7XW">
        <div *ngIf="hasError" class="error">Your post code is invalid or unsupported</div>
    </div>

    <button type="submit" class="btn primary submit small" [disabled]="!postCodeForm.valid || isWorking">
    <ng-container *ngIf="!isWorking">Book</ng-container>
    <shared-spinner *ngIf="isWorking" color="white"></shared-spinner>
  </button>
</form>
