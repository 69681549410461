<ng-template [ngIf]="isMobile" [ngIfElse]="isDesktop">
    <div class="content sign-in animation-fade-in" *ngIf="!isIosAppLogged">
        <div class="heading">
            <shared-logo-button [logoType]="logoTypeEnum.LogoGreyWithText"></shared-logo-button>
            <shared-back-button></shared-back-button>
            <h1>Welcome</h1>
            <p>sign in to continue</p>
        </div>

        <form *ngIf="!isLoading" [formGroup]="loginForm" (ngSubmit)="onFormSubmit()">
            <div class="field-wrapper email">
                <label>Email</label>
                <mat-form-field floatLabel="never">
                    <input matInput type="text" formControlName="username" name="username">
                    <mat-error *ngIf="getFieldFromForm('username').hasError('required')">
                        Email is required
                    </mat-error>
                    <mat-error *ngIf="getFieldFromForm('username').hasError('invalid')">
                        Invalid Email
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="field-wrapper password">
                <label>Password</label>
                <div class="password-wrapper">
                    <mat-form-field floatPlaceholder="never" floatLabel="never">
                        <input matInput formControlName="password" [type]="passwordTextType ? 'text' : 'password'" name="password" autocomplete="current-password">
                        <mat-error *ngIf="getFieldFromForm('password').hasError('required')">
                            Password is required
                        </mat-error>
                        <mat-error *ngIf="getFieldFromForm('password').hasError('minlength')">
                            Password must be at least 6 symbols
                        </mat-error>
                    </mat-form-field>
                    <a (click)="showPassword()" [className]="passwordTextType ? 'shown' : '' "></a>
                </div>
            </div>

            <div class="margin-wrapper">
                <div class="btn-href-wrapper">
                    <a (click)="forgotPassword()" class="btn href smallest green">I forgot my password</a>
                </div>
            </div>

            <div *ngIf="error" class="mat-error request-error v-padding">{{error}}</div>

            <button [disabled]="!valid || isLoading" class="btn primary bottom-fixed with-arrow input btn-resize" type="submit" [id]="signInBtn">Sign in</button>
        </form>

        <div *ngIf="isLoading" class="spinner-wrapper">
            <shared-spinner></shared-spinner>
        </div>
    </div>
    <shared-spinner *ngIf="isIosAppLogged && isLoading" class="spinner-wrapper"></shared-spinner>

</ng-template>


<ng-template #isDesktop>
    <div class="content sign-in animation-fade-in">
        <div class="media">
            <img src="assets/icons/tshirt-stained.svg" alt="blanc tshirt" class="tshirt-img">
        </div>

        <div class="wrapper">
            <div class="heading">
                <shared-back-button></shared-back-button>
                <h1>Welcome</h1>
                <p>Sign in to continue</p>
            </div>

            <form *ngIf="!isLoading" [formGroup]="loginForm" (ngSubmit)="onFormSubmit()" class="box animation-fade-in">
                <div class="field-wrapper email">
                    <label>Email</label>
                    <mat-form-field floatLabel="never">
                        <input matInput type="text" formControlName="username">
                        <mat-error *ngIf="getFieldFromForm('username').hasError('required')">
                            Email is required
                        </mat-error>
                        <mat-error *ngIf="getFieldFromForm('username').hasError('invalid')">
                            Invalid Email
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="field-wrapper pasword">
                    <label>Password</label>
                    <div class="password-wrapper">
                        <mat-form-field floatPlaceholder="never" floatLabel="never">
                            <input matInput formControlName="password" [type]="passwordTextType ? 'text' : 'password'" name="password" autocomplete="current-password">
                            <mat-error *ngIf="getFieldFromForm('password').hasError('required')">
                                Password is required
                            </mat-error>
                            <mat-error *ngIf="getFieldFromForm('password').hasError('minlength')">
                                Password must be at least 6 symbols
                            </mat-error>
                        </mat-form-field>
                        <a (click)="showPassword()" [className]="passwordTextType ? 'shown' : '' "></a>
                    </div>
                </div>

                <div class="margin-wrapper">
                    <div class="btn-href-wrapper">
                        <a (click)="forgotPassword()" class="btn href smallest green">I forgot my password</a>
                    </div>
                </div>

                <div *ngIf="error" class="mat-error request-error v-padding">{{error}}</div>

                <button [disabled]="!valid" class="btn primary with-arrow absolute" type="submit" [id]="signInBtn">Sign in</button>
            </form>

            <div *ngIf="isLoading" class="spinner-wrapper">
                <shared-spinner></shared-spinner>
            </div>
        </div>
    </div>
</ng-template>