import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-signup-model',
  templateUrl: './signup-model.component.html',
  styleUrls: ['./signup-model.component.scss']
})
export class SignupModelComponent implements OnInit {
  isPrivacyPolicy: boolean;
  isTermsandConditions: boolean;
 
 
  constructor(
    public dialogRef: MatDialogRef<SignupModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }


  ngOnInit(): void {
    if(this.data.modal == "PrivacyPolicy"){
    this.isPrivacyPolicy = true;
    }
    if(this.data.modal == "TermsandConditions"){
      this.isTermsandConditions = true;
      }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}



