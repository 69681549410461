<div class="grey-background"></div>

<div class="content payment-desktop animation-fade-in center-absolute-element">
  <header>
    <shared-back-button customPath="booking/address"></shared-back-button>
    <h3 *ngIf="paymentStripe !== 'true'">Your payment information</h3>
    <h3 *ngIf="paymentStripe == 'true'">Confirm with card</h3>
    <p *ngIf="!store && paymentStripe == 'false'">Please note that you will not be charged now. We will only charge your card after we have collected your items and inspected them in our Atelier. We will then send you an e-receipt with an itemised bill.</p>
    <p *ngIf="store && paymentStripe == 'false'">Please note that we are a cashless business. We require your card details so you can drop off in store without queuing,
    or organise a home collection & delivery. We do not see your card details.</p>
    <!-- <p *ngIf="paymentStripe == 'true'">Please enter your card details below. </p> -->
    <p *ngIf="paymentStripe == 'true'">You won't be charged now, payment will be collected when we start processing your items</p>

  </header>

  <div class="box">
    <h4>Payment information</h4>

    <app-card-wrapper></app-card-wrapper>
    <!-- <span *ngIf="(cardState !== cardStateEnum.ViewCard) && (cardState !== cardStateEnum.InProcess)" class="skip" (click)="skipStep()">Skip this step</span> -->
    <ng-container *ngIf="cardState === cardStateEnum.ViewCard">
      <!-- <booking-voucher-field></booking-voucher-field> -->
      <button (click)="submitPaymentInfo()" class="btn primary absolute  with-arrow" [id]="Proceed">Proceed</button>
      
    </ng-container>
  </div>
</div>
