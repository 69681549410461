export enum PreferenceCategory {
  ShirtsFinish = 0,
  Debobbling = 3,
  ReusablePackaging = 1,
  Garmentsfolded = 2
//  MinorRepaires = 3,
}

export enum PreferenceCategoryStore {
  ShirtsFinish = 0,
  Debobbling = 3,
  ReusablePackaging = 1,
  Garmentsfolded = 2,
  MinorRepaires = 4,
}

export enum PmsPreferenceCategory {
  Crease = 0,
  Debobbling = 2,
  Folded = 1,
  Garmentsfolded = 10,
  //MinorRepaires = 3,

}


export enum PmsIdPreference {
  ShirtsFinish = 3,
  Debobbling = 5,
  ReusablePackaging = 4,
  Garmentsfolded = 10,
  //MinorRepaires = 1
}

export enum PmsIdPreferenceStore {
  ShirtsFinish = 3,
  Debobbling = 5,
  ReusablePackaging = 4,
  Garmentsfolded = 10,
  MinorRepaires = 1
}
export enum PmsTitrePreference {
  ShirtsFinish = 'Shirts Premium Finish',
  Debobbling = 'Debobbling',
  ReusablePackaging = 'Shirts folded',
  Garmentsfolded = "Garments folded",
 // MinorRepaires = 'Proceed with minor repairs'
}
export enum PmsTitrePreferenceStore {
  ShirtsFinish = 'Shirts Premium Finish',
  Debobbling = 'Debobbling',
  ReusablePackaging =  'Shirts folded',
  MinorRepaires = 'Proceed with minor repairs',
  Garmentsfolded = "Garments folded",
}
