import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { CommandService } from 'src/app/services/common/command.service';
import { Recurring } from 'src/app/models/user/recurring.model';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { ReccurenceStateEnum } from 'src/app/models/user/enums/recurrence-state.enum';
import { IRecurringService } from './contracts/IRecurringService';

@Injectable({
  providedIn: 'root'
})
export class RecurringService implements IRecurringService {

  public recurring = new BehaviorSubject<Recurring>(null);
  public $recurring = this.recurring.asObservable();
  public state = new BehaviorSubject<ReccurenceStateEnum>(ReccurenceStateEnum.Loading);
  public $state = this.state.asObservable();

  constructor() { }

  async setRecurring(newValue: Recurring) {
    this.recurring.next(newValue);
  }
  
  setState(state: ReccurenceStateEnum) {
    this.state.next(state);
  }
} 
