import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { IBookingStepsService } from './contracts/IBookingStepsService';

@Injectable({
  providedIn: 'root'
})
export class BookingStepsService implements IBookingStepsService {

  public step = new BehaviorSubject<number>(1);

  constructor() { }

  setStep(step: number) {
    this.step.next(step);
  }
  getStep() {
    return this.step.getValue();
  }
}
