import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class LogService {

    endpoint: string;

  constructor(private http: HttpClient) {
    this.endpoint = environment.api;
   }

    saveLog(body) {
        return this.http.post('saveLog', body).toPromise();
    }
}
