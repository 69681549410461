<div class="content">
    <div #searchInput class="search">
        <label>Brand (optional)</label>
        <mat-form-field floatLabel="never">
            <input [formControl]="brandInput" matInput type="text" placeholder="E.g. Chanel" [matAutocomplete]="auto"
                (input)="search()">
            <mat-autocomplete id="autocomplete" #auto="matAutocomplete">
                <mat-option *ngFor="let suggestion of suggestions" [value]="suggestion.name"
                    (mouseover)="setBrand($event)">
                    {{suggestion.name}}
                </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="brandInput.hasError('maxlength')">
                Brand can't be more than 50 symbols
            </mat-error>
        </mat-form-field>
    </div>

    <div class="add-btn">
        <button class="btn secondary" [disabled]="!item" (click)="selectBrand()">
            ADD
        </button>
    </div>
</div>
