import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DonateModalComponent } from 'src/app/booking/address/address-mobile/donate-modal/donate-modal.component';
import { BookingAddressData } from 'src/app/models/booking/address-data.model';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { CommandService } from 'src/app/services/common/command.service';
import { StorageService } from 'src/app/services/common/storage.service';
import { ParticleService } from 'src/app/services/particle/particle.service';
import { AuthService } from 'src/app/services/user/auth.service';
import { CardService } from 'src/app/services/user/card.service';
import { ServicesComponent } from '../services.component';

@Component({
  selector: 'booking-services-mobile',
  templateUrl: './services-mobile.component.html',
  styleUrls: ['./../services.component.scss', './services-mobile.component.scss']
})
export class ServicesMobileComponent extends ServicesComponent {
  address: BookingAddressData = new BookingAddressData();
  donateBags: number;
  AddInst: string;
  EditInst: string;
  AddDonation: string;
  saveInstNext: string;

  constructor(
    protected particleService: ParticleService,
    protected commandService: CommandService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected authService: AuthService,
    public storageService: StorageService,
    protected cardService: CardService,
    protected deviceDetectorService: DeviceDetectorService,
    public dialog: MatDialog
  ) {
    super(particleService, commandService, storageService, router,  cardService, route, authService, deviceDetectorService);
  }

  async ngOnInit() {
    await super.ngOnInit();
    this.address.donateBags = this.storageService.getObject('adresse').donateBags;
    this.donateBags = this.address.donateBags;

    var firstName = localStorage.getItem('firstName');
    let smsMarketing = localStorage.getItem('smsMarketing')
    if (smsMarketing){
      this.AddInst="sms-marketing-booking-redirectToSummary-editGarmentIns-addInst";
      this.EditInst= "sms-marketing-booking-redirectToSummary-editGarmentIns-EditInst";
      this.AddDonation= "sms-marketing-booking-redirectToSummary-editGarmentIns-addDonation";
      this.saveInstNext = "sms-marketing-booking-redirectToSummary-editGarmentIns-next";
    }else if( firstName){
      this.AddInst="returning-user-booking-redirectToSummary-editGarmentIns-addInst";
      this.EditInst= "returning-user-booking-redirectToSummary-editGarmentIns-EditInst";
      this.AddDonation= "returning-user-booking-redirectToSummary-editGarmentIns-addDonation";
      this.saveInstNext = "returning-user-booking-redirectToSummary-editGarmentIns-next";
    }else{
      this.AddInst="first-journey-booking-redirectToSummary-editGarmentIns-addInst";
      this.EditInst= "first-journey-booking-redirectToSummary-editGarmentIns-EditInst";
      this.AddDonation= "first-journey-booking-redirectToSummary-editGarmentIns-addDonation";
      this.saveInstNext = "first-journey-booking-redirectToSummary-editGarmentIns-next";
    }
  } 
}
