<div class="close-btn-wrapper">
  <div (click)="close()" class="icon close" id="close-btn"></div>
</div>

<div *ngIf="!isSent" class="content" [ngClass]="{'iframe': isInIframe}">
  <h3>Coming soon</h3>
  <p>We do not deliver to: <strong>{{interestedCustomer.postalCode}}</strong> but we are expanding quickly and could be there soon.</p>
  <p class="highlighted">Register your email and we'll contact you with a discount when we get there!</p>

  <form [formGroup]="emailForm" (ngSubmit)="saveEmail()">
    <div class="field-wrapper">
      <label>Email</label>
      <mat-form-field floatLabel="never">
        <input matInput type="email" formControlName="email">
        <mat-error *ngIf="getField('email').hasError('email')">
          Please enter a valid email address
        </mat-error>
        <mat-error *ngIf="getField('email').hasError('required')">
          Email is required
        </mat-error>
      </mat-form-field>
    </div>

    <button type="submit" [disabled]="!isValid" class="btn secondary">Save</button>
  </form>
</div>

<div *ngIf="isSent" class="content success-view">
  <h3>Successfully registered!</h3>
  <img src="assets/icons/success.svg" alt="blanc success">
</div>
