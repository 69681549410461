import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import {NewModalComponent} from './new-modal/new-modal.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  isDesktop: boolean;
  store: string;

  constructor(
    protected deviceService: DeviceDetectorService,
    public dialog: MatDialog,
    public _bottomSheet: MatBottomSheet
  ) { }

  ngOnInit(): void {
    // this._bottomSheet.open(NewModalComponent);
    this.isDesktop = this.deviceService.isDesktop();
  }

}
