import { Particle } from './particle.model';

export class ParticleItem {
  id: string;
  name: string;
  parsedValue: {
    nameInSpot: string;
    css: string;
    description: string;
    descriptionDetails: string;
    priceDescription: string;
    viewType: number;
  };
  parentId: string;
  hasChildren: boolean;
  nameInSpot: string;
  
  constructor(particle: Particle) {
    this.id = particle.id;
    this.name = particle.name;
    this.parentId = particle.parentId;
    this.hasChildren = particle.hasChildren;
    this.parsedValue = JSON.parse(particle.value);
    this.nameInSpot = this.parsedValue?.nameInSpot ?? this.name;
  }
}
