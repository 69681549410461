export enum BookingStepsEnum {
  Pickup = 1,
  Delivery = 2,
  SlotsSummary = 3,
  Address = 4,
  Instructions = 5,
  Authorisations = 6,
  Summary = 7,
  ThankYou = 8,
}
