import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { ConfirmModalService } from 'src/app/services/shared/confirm-modal.service';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';

@Component({
  selector: 'user-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss']
})
export class PersonalInfoComponent implements OnInit {
  isMobile: boolean;
  showModal: boolean;
  modalSubscription: Subscription;

  constructor(
    private deviceService: DeviceDetectorService,     
    private confirmModalService: ConfirmModalService,
    public matDialog: MatDialog,
  ) { }
  
  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
    
    this.modalSubscription = this.confirmModalService.$showModal.subscribe((value) => {
      this.showModal = value;
    });
  }

  canDeactivate(): boolean {
    if (this.showModal) {
      this.matDialog.open(ConfirmModalComponent);
      return false;
    }
    return true;
  } 
  
  ngOnDestroy() {
    this.confirmModalService.setShowModal(false);
    this.confirmModalService.setSubmitForm(false);
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
  }
}
