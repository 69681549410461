<div class="content animation-fade-in"> 
  <shared-background-rectangle [height]="227"></shared-background-rectangle>

  <div class="wrapper">
    <h1>Your orders</h1>
    
    <order-list-tabs></order-list-tabs>
  </div>

  <order-list-recurring-banner></order-list-recurring-banner>
</div>
