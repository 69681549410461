import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CommandService } from 'src/app/services/common/command.service';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { OrderDetails } from 'src/app/models/order/order-details.model';
import { Routes } from 'src/app/constants/routes.constant';

@Component({
  selector: 'order-gps-tracking-mobile',
  templateUrl: './gps-tracking-mobile.component.html',
  styleUrls: ['./gps-tracking-mobile.component.scss']
})
export class GpsTrackingMobileComponent implements OnInit {
  isLoading: boolean = true;
  orderId: string;
  order: OrderDetails;
  detailsRoute: string;

  constructor(
    protected route: ActivatedRoute,
    protected commandService: CommandService,
  ) { }

  async ngOnInit() {
    this.orderId = this.route.snapshot.paramMap.get('id');
    this.detailsRoute = Routes.ORDER.DETAILS + this.orderId;

    await this.initOrder();
    this.isLoading = false;
  }

  async initOrder() {
    var param = {
      infoOrder_id : +this.orderId,
    }
    var result = await this.commandService.executeCommand(CommandEnum.OrderDetailsCommand, param);
    this.order = result.commandResult;
  }

}
