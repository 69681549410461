<div [ngSwitch]="cardState">
  <shared-spinner *ngSwitchCase="CardStateEnum.Default && !CardStateEnum.InProcess" id="spinner"></shared-spinner>
  <shared-spinner *ngSwitchCase="CardStateEnum.InProcess" id="spinner"></shared-spinner>
  <user-payment-card-view *ngSwitchCase="CardStateEnum.ViewCard" id="card-view"></user-payment-card-view>
  <user-payment-card-add *ngSwitchCase="CardStateEnum.AddCard" id="card-add"></user-payment-card-add>
  <div *ngSwitchCase="CardStateEnum.AddCard">
    <span *ngIf="isMobile && store" class="skip" (click)="skipStep()" style=" bottom: -190px;">Skip this step</span>
    <span *ngIf="!isMobile && store" class="skip" (click)="skipStep()">Skip this step</span>
    
  </div>
  
  <div *ngSwitchCase="CardStateEnum.InProcess" class="alert_msg">
    <p>Please do not refresh this page. Your payment details are being securely encrypted, this might take a little while. Any questions or problems please reach out to our customer care on 020 8004 2630.</p>
  </div>
  <!--  *ngIf="currentPath" -->
</div>
