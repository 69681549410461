<div class="content" *ngIf="version">
    <h3>Update Available</h3>

    <div class="text-wrapper">
        <p>
            A new version of the BLANC app is available. Please click below to update to version {{version}} now.
        </p>
    </div>
    <a class="btn primary submit small" href="https://apps.apple.com/app/blanc-laundry-app/id1613486714">
        Update</a>
    <!-- (click)="saveVersion()" -->
</div>
<div *ngIf="!version" class="close-btn-wrapper">
  <div (click)="close()" class="icon close" id="close-btn"></div>
</div>
<div *ngIf="!version" class="content">
    <h3>Update Available</h3>

    <div class="text-wrapper">
        <p>
            Is your app up to date?
        </p>
    </div>
    <a  class="btn primary submit small"  (click)="saveVersion()" href="https://apps.apple.com/app/blanc-laundry-app/id1613486714" >
        Check now</a>
    <!-- (click)="saveVersion()" -->
</div>
