import { Component, OnInit } from '@angular/core';

import { RecurringBase } from '../recurring.base';
import { RecurringService } from 'src/app/services/user/recurring.service';
import { CommandService } from 'src/app/services/common/command.service';

@Component({
  selector: 'user-recurring-desktop',
  templateUrl: './recurring-desktop.component.html',
  styleUrls: ['./../recurring.component.scss', './recurring-desktop.component.scss']
})
export class RecurringDesktopComponent extends RecurringBase implements OnInit {

  constructor(
    protected recurringService: RecurringService,
    protected commandService: CommandService,
  ) {
    super(recurringService, commandService);
  }

  async ngOnInit() {
    await super.ngOnInit();
  }
}
