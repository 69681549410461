<div class="content-mobile" *ngIf="isMobile">
    <p class="highlighted">Number of bags:</p>
    <div class="count-wrapper">
        <button class="counter-btn decrease-btn" id="decreaseBtn" (click)="decreaseCount()">
            <p>-</p>
        </button>
        <p class="count">{{serviceModel.bagCount}}</p>

        <button class="counter-btn increase-btn" id="increaseBtn" (click)="increaseCount()">
            <p>+</p>
        </button>
    </div>
    <a class="btn secondary" [id]="SaveBtn" (click)="save()">Add</a>
</div>

<div class="content" *ngIf="!isMobile">
    <div class="bags-counter">
        
        <div class="count-wrapper">
            <p class="highlighted">Number of bags required:</p>
        <button class="counter-btn decrease-btn" id="decreaseBtn" (click)="decreaseCount()">
            <p>-</p>
        </button>
        <p class="count">{{serviceModel.bagCount}}</p>

        <button class="counter-btn increase-btn" id="increaseBtn" (click)="increaseCount()">
            <p>+</p>
        </button>
        </div>
    </div>
    <div class="btn-wrapper">
        <button class="btn primary with-arrow left-rounded" [id]="SaveBtn" (click)="save()" [disabled]="!isEdited">Save instructions</button>
    </div>
</div>
