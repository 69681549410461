

export const MockOrderAuthorisatiuon = {

    "Version": "1.0",
    "Failed": false,
    "Message": null,
    "MessageDetails": null,
    "ValidationInfo": null,
    "ReturnObject": {
      "Cleaning": [
        {
          "infoitem_id": 77836,
          "Colors": "Black",
          "DepartmentName": "Business",
          "brand": "BalenciagaUniform",
          "typeitem": "Trousers"
        },
        {
          "infoitem_id": 72058,
          "Colors": "Black",
          "DepartmentName": "Garment Care",
          "brand": "Isabel Marant",
          "typeitem": "Trousers"
        }
      ],
      "MinorRepair": [
        {
          "infoitem_id": 77836,
          "Colors": "Black",
          "DepartmentName": "Business",
          "brand": "BalenciagaUniform",
          "typeitem": "Trousers",
          "Quote": 9.99
        }
      ],
      "Tailoring": [
        {
          "infoitem_id": 72058,
          "Colors": "Black",
          "DepartmentName": "Garment Care",
          "brand": "Isabel Marant",
          "typeitem": "Trousers",
          "Quote": 5.99,
          "Timing": 6
        }
      ],
      "Risk": [
        {
          "infoitem_id": 72058,
          "Colors": "Black",
          "DepartmentName": "Garment Care",
          "brand": "Isabel Marant",
          "typeitem": "Trousers"
        }
      ],
      "Leather": [
        {
          "infoitem_id": 72058,
          "Colors": "Black",
          "DepartmentName": "Garment Care",
          "brand": "Isabel Marant",
          "typeitem": "Trousers",
          "Quote": 3.5,
          "Timing": 3
        }
      ]
    }
  }
