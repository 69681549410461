import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/services/common/alert.service';
import { CommandService } from 'src/app/services/common/command.service';
import { PostCodeService } from 'src/app/services/shared/postCode.service';
import { AccountSettingService } from 'src/app/services/user/account-setting.service';

@Component({
  selector: 'app-promo-banner',
  templateUrl: './promo-banner.component.html',
  styleUrls: ['./promo-banner.component.scss']
})
export class PromoBannerComponent implements OnInit {
  show_promo ;
  pub;
  url : string;
  title: string;
  text : string;
  link : string;
  type: string;
  active_promo: boolean;
  pre_url : string = "https://fullcirclepos.co.uk/storage/";
  isLoading = true;
  slides = [];
  constructor( private accountSettingService: AccountSettingService,
    private commandService: CommandService,
    private alertService: AlertService,
    private postcodeService: PostCodeService,) { 
   
  }

  slideConfig = {"slidesToShow": 1, "slidesToScroll": 1, autoplay: true, autoplaySpeed: 4000};
  ngOnInit(): void {
    this.getBanner();
    this.accountSettingService.getPromo().then(data => {
      this.show_promo = data;
    }); 
  }
  
  getBanner() {
   this.postcodeService.getBanner().then(
     (response) => {
       if (response['Message'] !== null) {
          this.active_promo = false;
          this.title = "Care & Repair";
          this.text = " this month 10% OFF Alterations & Repairs in March Code TAILOR";
          this.url = "assets/icons/Tailoring service.png";
        }
       else {
         this.active_promo = true;
          for(let i=0;i<response['ReturnObject'].length;i++){
            if(response['ReturnObject'][i].Actif == 1){
              this.pub = response['ReturnObject'][i];
              if(this.pub.type == "SLIDER"){
                this.slides.push( {url : this.pre_url + this.pub.url,
                  link : this.pub.link });
              }
            }
          }
        
          if(this.pub.type == "BANNER"){
            this.title = this.pub.title;
            this.text = this.pub.text;
            this.type = this.pub.type;
            this.url = this.pre_url + this.pub.url;
          }else if(this.pub.type == "SLIDER"){
            this.link = this.pub.link;
            this.type = this.pub.type;
            this.url = this.pre_url + this.pub.url;
          }
        
        }
      });
  }
  
  updatePromo (){
    this.accountSettingService
      .updatePromo()
      .then(
        response => {
          
        },
        (error) => {
          
        }
      );
  }
 
}
