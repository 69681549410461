import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'user-authorisations-mobile',
  templateUrl: './authorisations-mobile.component.html',
  styleUrls: ['./../authorisations.component.scss', './authorisations-mobile.component.scss']
})
export class AuthorisationsMobileComponent implements OnInit {
  title: string = 'Authorisations';

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
