export class RequestPickup {
    GarmentInstruction: any;
    PickupDate: any;
    VisitType: any;
    PickupStartTime: any;
    PickupEndTime: any;
    PickupCodeCountry: any;
    PickupPhoneNumber: any;
    PickupTypeDelivery: any;
    DeliveryDate: any;
    DeliveryStartTime: any;
    DeliveryEndTime: any;
    DeliveryCodeCountry: any;
    DeliveryPhoneNumber: any;
    DeliveryTypeDelivery: any;
    Comments: any;
    DeliveryComments: any;
}