<div *ngIf="isLoading" class="spinner-wrapper">
    <shared-spinner></shared-spinner>
</div>

<div *ngIf="!isLoading" class="details-wrapper content animation-fade-in">
    <order-details-heading [order]="order" class="details-header"></order-details-heading>

    <div [ngSwitch]="order.status" class="details-body">
        <a [routerLink]="[orderEditRoute, order.infoOrder_id , type]" class="edit-btn box" [id]="orderMobilePickupAndDeliveryDetails">
      {{type}}
      <img src="assets/icons/arrow-left-black.svg" class="arrow-right-img" alt="arrow blanc">
    </a>

    
    <!-- <order-gps-tracking-banner></order-gps-tracking-banner> -->
    <!-- <order-details-banner-wrapper [order]="order"></order-details-banner-wrapper> -->

    <order-details-banner-info [order]="order"></order-details-banner-info>

    <order-details-items-table [order]="order"></order-details-items-table>
    <div style=" padding-bottom: 5px;">
        <button *ngIf="order.CanBeCancelled && !isRecurring" (click)="cancelOrder()" class="btn secondary-neutral cancel-btn" [id]="cancelOrderMobile">Cancel Booking</button>

    </div>
    </div>
</div>
