<div class="content animation-fade-in">
    <div class="heading">
        <shared-back-button *ngIf="isMobile"></shared-back-button>
        <div class="media">
            <img src="assets/icons/instructions.svg">
        </div>

        <div class="heading">
            <div class="wrapper">

                <div class="text">

                    <h1>Any instructions?</h1>
                    <p>Let us know about anything you would like us to treat specifically and any preference you may have, or skip this step if you have no specific requirements.</p>
                </div>

                <div class="buttons">
                    <button (click)="navigateToNext()" class="btn primary with-arrow" [ngClass]="{'left-rounded': isMobile}" id="quick-checkout">Quick
        checkout</button>
                    <!-- <button (click)="navigateToServices()" class="btn secondary secondary-neutral" id="give-instuctions">Add instructions</button> -->
                </div>
            </div>
        </div>