import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, observable } from 'rxjs';

import { IAccountService } from './contracts/IAccountService';

import { environment } from '../../../environments/environment';
import { PhoneList } from '../../models/user/phone-list.model';
import { ChangePasswordRequest } from '../../models/user/change-password-request.model';
import { PhoneNumber } from '../../models/user/phone-number.model';
import { CookieService } from '../common/cookie.service';
import { BlancCommandService } from '../common/blanc-command.service';
import { CommandEnum } from '../../models/common/enums/command.enum';

@Injectable({
  providedIn: 'root',
})
export class AccountService implements IAccountService {
  endpoint: string;
  phoneList: PhoneList = new PhoneList();
  private readonly authTokenName = 'auth_token';


  constructor(private http: HttpClient, 
    private cookieService: CookieService, 
    private blancCommandService: BlancCommandService
     ) {
    this.endpoint = environment.api;
  }

  setLocalPhoneList(phones: PhoneNumber[]) {
    this.phoneList.Phones = phones;
  }

  getLocalPhoneList(): PhoneList {
    return this.phoneList;
  }

  getPhoneList(): Observable<PhoneNumber[]> {
    const request = this.http.get<PhoneNumber[]>(this.endpoint + 'account/shared');
    request.subscribe((phoneList) => (this.phoneList.Phones = phoneList));
    return request;
  }

  updatePhoneList(data: PhoneList): Observable<PhoneNumber[]> {
    return this.http.post<PhoneNumber[]>(
      this.endpoint + 'account/updateShared',
      data
    );
  }

  checkPassword(password: string){
    return this.http
      .post<boolean>(this.endpoint + 'account/checkPassword', {
        currentPassword: password,
      })
      .toPromise();
  }

  changePassword(request: any) {
    return this.http.post('changePassword', request).toPromise();
  }

  LogoutApp(){
    return this.http.post(this.endpoint + 'logout', '').toPromise();
  }

  LogoutBlanc(){
    return this.blancCommandService.executeCommand(CommandEnum.Logoff, {});
  }

  addToCalendar(orderId: string) {
    const body = {
      orderId,
      sessionId: this.cookieService.getCookie('blanc_session_id')
    };
    return this.http.post(this.endpoint + 'addToCalendar', body).toPromise();
  }

  helpEmail(request: any) {
    return this.http.post('helpEmail', request).toPromise();
  }

}
