import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paginate'
})
export class PaginatePipe implements PipeTransform {

  transform(array: any[], options?: {from: number, to?: number}): any[] {
    if (array) {
      let start = options ? options.from : 0;
      let end = options && options.to ? options.to : array.length;
      return array.slice(start, end);
    }
  }
}