import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hourStartFormat'
})
export class HourStartFormatPipe implements PipeTransform {

    transform(value: string): unknown {
      if (value) {
        let hourStr1 = value.substring(0,2);
        if (hourStr1.includes('08') || hourStr1.includes('10')) {
          if (hourStr1 == '08') {
            hourStr1 = '8';
          }
          hourStr1 = hourStr1;
        }else {
          switch (hourStr1) {
            case '14':
              hourStr1='2';
              break;
            case '16':
              hourStr1='4';
              break;
            case '16':
              hourStr1='4';
              break;
            case '18':
              hourStr1='6';
              break;
            case '20':
              hourStr1='8';
              break;
          }
          hourStr1 = hourStr1;
        }
        return hourStr1 
      }
  }
  

}
