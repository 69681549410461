import { StorageService } from 'src/app/services/common/storage.service';
import { OrderTrackingTypeEnum } from 'src/app/models/order/enums/order-tracking-type.enum';
import { AccountSettingService } from 'src/app/services/user/account-setting.service';
import { Routes } from 'src/app/constants/routes.constant';
import { UserSpot } from './../../models/user/user-spot.model';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';

import { AuthService } from 'src/app/services/user/auth.service';
import { FormStatusEnum } from 'src/app/models/common/enums/form-status.enum';
import { AlertService } from 'src/app/services/common/alert.service';
import { CommandService } from 'src/app/services/common/command.service';
import { LogoButtonEnum } from 'src/app/models/shared/enums/logo-button.enum';
import { SignUpRequest } from 'src/app/models/user/sign-up-request.model';
import { MatDialog } from '@angular/material/dialog';
import { SignupModelComponent } from '../signup-model/signup-model.component';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'src/app/services/common/cookie.service';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
@Component({
  selector: 'app-set-password-new-user',
  templateUrl: './set-password-new-user.component.html',
  styleUrls: ['./set-password-new-user.component.scss']
})
export class SetPasswordNewUserComponent implements OnInit {
  isMobile: boolean;
  logoTypeEnum: typeof LogoButtonEnum = LogoButtonEnum;
  orderid: string;
  redirectUrl: string;
  working = false;
  valid: boolean;
  error: string;
  email: string;
  receiveOffers : string;
  user: any;
  authUser: any;
  isPasswordVisible: boolean;
  orderDetailsRoute = Routes.ORDER.DETAILS;
  isloading = false;
  signupForm = new FormGroup({
    firstName: new FormControl('', [Validators.required, Validators.maxLength(30)]),
    lastName: new FormControl('', [Validators.required, Validators.maxLength(30)]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    conditions:  new FormControl(0, Validators.requiredTrue),
    PrivacyPolicy: new FormControl(0 , Validators.requiredTrue),
    newsletter: new FormControl(0)
  });
  isAuthenticated: boolean;
  disabled: boolean;
  customerId: string;
  signupBtn : string;
  constructor(
    private deviceService: DeviceDetectorService,
    private route: ActivatedRoute,
    private commandService: CommandService,
    private router: Router,
    private auth: AuthService,
    private alertService: AlertService,
    public dialog: MatDialog,
    private accountSettingService: AccountSettingService,
    private cookieService: CookieService,
    private storageService: StorageService,

  ) { }

  async ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
    this.orderid = this.route.snapshot.queryParams['orderId'];
    this.isAuthenticated = this.auth.isLoggedIn();
    
    this.redirectUrl = this.orderid ? this.orderDetailsRoute + this.orderid : '/';
    if(this.router.url.split('/')[1].includes('deliveryonly?email')){
      this.signupBtn ="sms-delivery-only-sign-up";
    }else{
      this.signupBtn ="Sign-up-btn";
    }
    if (this.route.snapshot.queryParams['email']) {
      this.email = decodeURIComponent(this.route.snapshot.queryParams['email']);
      this.email = this.email.replace(/ /g, '+');
      this.customerId = this.route.snapshot.queryParams['ID'];
      
      if (
        (this.router.url.search('deliveryonly') != -1 || this.router.url.search('payments-info') != -1 ) && 
        !(this.email && this.customerId)
        ) {
        this.router.navigate([Routes.GUEST.SIGN]);
      } else {
          if (!this.isAuthenticated) {
          
            this.auth.checkEmail({ EmailAddress: this.email })
              .then(response => {
                this.isPasswordVisible = false;
                this.signupForm.statusChanges.subscribe((status) => {
                  this.error = null;
                  this.valid = status === FormStatusEnum.Valid;
                });
                this.auth
                  .getName(this.email )
                  .then(result => {
                    
                    if (
                      (this.router.url.search('deliveryonly') != -1 || this.router.url.search('payments-info') != -1 ) &&
                    (this.customerId != result['CustomerID'])) {
                      this.router.navigate([Routes.GUEST.SIGN]);
                    } else {
                      this.getFieldFromForm('firstName').setValue(result['FirstName']);
                      this.getFieldFromForm('lastName').setValue(result['LastName']);
                      this.getFieldFromForm('email').setValue(this.email);

                      this.getFieldFromForm('email').disable();
                      this.getFieldFromForm('firstName').disable();
                      this.getFieldFromForm('lastName').disable();
                    }
                });
                
                this.isloading = true;
              }).catch((err) => {
                
                if (this.router.url.search('deliveryonly') != -1) {
                  this.redirectUrl = Routes.DELIVERY_ONLY.SLOT
                } else if (this.router.url.search('payments-info') != -1) {
                  this.redirectUrl = Routes.USER.PAYMENTS
                } else {
                  if (this.orderid) {
                    this.redirectUrl = Routes.ORDER.DETAILS + this.orderid;
                  } else {
                    this.redirectUrl = Routes.ORDER.LIST
                  }
                }
                
                this.router.navigate([Routes.GUEST.SIGN_IN], { queryParams: { redirect: this.redirectUrl } });
              });

          } else {
            if (this.router.url.search('deliveryonly') != -1) {
              this.redirectUrl = Routes.DELIVERY_ONLY.SLOT
            } else if (this.router.url.search('payments-info') != -1) {
              this.redirectUrl = Routes.USER.PAYMENTS
            } else{
              if (this.orderid) {
                var param = {
                  infoOrder_id: +this.orderid,
                }
                this.commandService.executeCommand(CommandEnum.OrderDetailsCommand, param)
                  .then(result => {
                    if (!OrderTrackingTypeEnum[result.ReturnObject.TypeDelivery] ) {
                        result.ReturnObject.TypeDelivery = 'STORE';             
                    }
                    this.redirectUrl = Routes.ORDER.DETAILS + this.orderid + '/' + OrderTrackingTypeEnum[result.ReturnObject.TypeDelivery];
                    
                  })
              } else {
                this.redirectUrl = Routes.ORDER.LIST;
              }
            }
            this.router.navigate([this.redirectUrl]);
          }
        }

      }
    
  }

  getFieldFromForm(fieldName: string) {
    return this.signupForm.get(fieldName);
  }

  showPassword() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  onFormSubmit() {
    this.disabled = false;
    this.working = true;
    this.auth.setPasswordUser(this.getFieldFromForm('email').value, this.getFieldFromForm('password').value)
      .then(() => {
        this.auth
          .login({
            username: this.getFieldFromForm('email').value,
            password: this.getFieldFromForm('password').value,
          })
          .then(async (status) => {
            this.commandService.executeCommand(CommandEnum.CustomerDetail)
            .then((user) => {
              //this.receiveOffers = user.receiveOffers
              const nweletters = this.getFieldFromForm('newsletter').value;
              
              if (nweletters === false) {
                user.ReturnObject.infoCustomer.AcceptMarketing = 0;
              }
              else if (nweletters === true) {
                user.ReturnObject.infoCustomer.AcceptMarketing = 1;
              }
              this.commandService.executeUpdate(CommandEnum.SaveCustomer, user.ReturnObject).then(
                (response) => {
                },
                (error) => {
                }
              );
            });
            if (status.authenticated) {
              this.redirectToOrder(this.orderid);
            }
          });
        
      })
      .catch((err) => {
        console.error('Sign up failed: ', err);
        this.error = err.error.message;
        this.alertService.showError(this.error);
        this.working = false;
      });
    
  }

  signup(request: SignUpRequest) {
    this.working = true;
    this.auth
      .signup(request)
      .then(() => {
        this.auth
          .login({
            username: request.EmailAddress,
            password: request.Password
          })
          .then(() => {
            this.router.navigate([this.redirectUrl]);
          });
      })
      .catch((err) => {
        console.error('Sign up failed: ', err);
        this.error = err.error.message;
        this.alertService.showError(this.error);
        this.working = false;
      });
  }
  async redirectToOrder(id) {
    if (this.router.url.search('deliveryonly') != -1) {
      this.redirectUrl = Routes.DELIVERY_ONLY.SLOT
    } else if (this.router.url.search('payments-info') != -1) {
      this.redirectUrl = Routes.USER.PAYMENTS
    } else{
     if (id) {
        var param = {
          infoOrder_id: +id,
        }
        this.commandService.executeCommand(CommandEnum.OrderDetailsCommand, param)
          .then(result => {

            if (!OrderTrackingTypeEnum[result.ReturnObject.TypeDelivery] ) {
                result.ReturnObject.TypeDelivery = 'STORE';             
            }
            this.redirectUrl = Routes.ORDER.DETAILS + this.orderid + '/' + OrderTrackingTypeEnum[result.ReturnObject.TypeDelivery];
          })
      } else {
       this.redirectUrl = Routes.ORDER.LIST;
      }
    }

    this.router.navigate([this.redirectUrl]);
  }

  openDialogPrivacyPolicy() {
    this.dialog.open(SignupModelComponent, {
      data: {
        modal: 'PrivacyPolicy' ,
      },
    });
  }
  openDialogTermsandConditions(){
    this.dialog.open(SignupModelComponent, {
      data: {
        modal: 'TermsandConditions' ,
      },
    });
  }

}
