import { User } from 'src/app/models/user/user.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

import { PersonalInfo } from 'src/app/models/user/personal-info.model';
import { Authorisation } from 'src/app/models/user/authorisation.model';
import { Preference } from 'src/app/models/user/preference.model';
import { IAccountSettingsService } from './contracts/IAccountSettingsService';
import { CookieService } from '../common/cookie.service';
import { InfoCustomer } from 'src/app/models/user/info-customer.model';
import { StorageService } from '../common/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AccountSettingService implements IAccountSettingsService {
  private readonly endpoint: string;
  bodySettings: any;

  constructor(private http: HttpClient, private cookieService: CookieService, private storageService: StorageService) {
    this.endpoint = environment.blancApi;
    this.bodySettings = {
      RequestType : '',
      AccountKey : environment.blancAccountKey,
      SessionID: '',
      body : {}
    };
  }
/*execute(command, data: string | object = null): Promise<any> {
  this.bodySettings.RequestType = command;

  return this.http.post<any>(this.endpoint, this.bodySettings).toPromise();
}*/
  getReferralLink(): Observable<string> {
    return this.http.get<string>(this.endpoint + '/referralCode');
  }

  getPersonalInfo(): Observable<any> {
    this.bodySettings.RequestType = 'CustomerDetail';
    this.bodySettings.SessionID = this.cookieService.getCookie('blanc_session_id');
    return this.http.post<any>(this.endpoint, this.bodySettings);
  }

  updatePersonalInfo(data: InfoCustomer): Observable<any> {
    this.bodySettings.RequestType = 'SaveCustomer';
    this.bodySettings.SessionID = this.cookieService.getCookie('blanc_session_id');
    this.bodySettings.body = data;
    return this.http.post<any>(this.endpoint, this.bodySettings);
  }

  getAuthorizations(): Observable<Authorisation[]> {
    return this.http.get<Authorisation[]>(this.endpoint + '/authorizations');
  }

  updateAuthorizations(data: Authorisation[]): Observable<Authorisation[]> {
    return this.http.post<Authorisation[]>(this.endpoint + '/updateAuthorizations', data);
  }

  updateAuthorization(data: Authorisation): Observable<Authorisation> {
    return this.http.post<Authorisation>(this.endpoint + '/updateAuthorization', data);
  }


  getPreferences(): Observable<Preference[]> {
    return this.http.get<Preference[]>(this.endpoint + '/preferences');
  }

  updatePreferences(data: Preference[]): Observable<Preference[]> {
    return this.http.post<Preference[]>(this.endpoint + '/updatePreferences', data);
  }

  getAuthorizationsAndPreferencesPercentage(): Observable<number> {
    return this.http.get<number>(this.endpoint + 'percentage');
  }

  getPercentage(SessionID: string) {
    const body = {
      sessionId : SessionID
    };
    return this.http.post<any>('percentage', body).toPromise();
  }

  updatePreferencePercentage() {
    const body = {};
    this.bodySettings.RequestType = 'CustomerDetail';
    this.bodySettings.SessionID = this.cookieService.getCookie('blanc_session_id');

    this.http.post<any>(this.endpoint, this.bodySettings).subscribe((response) => { 

      response.ReturnObject.infoCustomer.LoginPreference = 1;
      this.bodySettings.RequestType = 'SaveCustomer';
      this.bodySettings.body = response.ReturnObject;
      
      this.http.post<any>(this.endpoint, this.bodySettings).subscribe((response) => {
        console.log('save pref')
      })
    })
    
    return this.http.post('preference', body).toPromise();
  }

  updatePromo() {
    const body = {};
    return this.http.post('promo', body).toPromise();
  }

  getPromo() {
    const body = {};
    return this.http.post('promo_value',body).toPromise();
  }
  
  updateDeliveryPreferences(data) {
    const body = {
      RequestType : 'SaveDeliveryPreference',
      AccountKey : environment.blancAccountKey,
      SessionID: '',
      Parameters : {}
    };
    body.Parameters = data;
    body.SessionID = this.cookieService.getCookie('blanc_session_id');
    return this.http.post<any>(this.endpoint, body).toPromise();
  }

  updatePreferencesCustomer(dataPre,dataAuth) {
    return this.http.post(this.endpoint + '/updatePreferencesCustomer', {preference:dataPre,authorisation:dataAuth});
  }

  GetDeliveryPreferences() {
    this.bodySettings.RequestType = 'GetDeliveryPreference';
    this.bodySettings.SessionID = this.cookieService.getCookie('blanc_session_id');
    return this.http.post<any>(this.endpoint, this.bodySettings);
  }


  updateDetailsUserPms(data) {
    return this.http.post(this.endpoint + '/updateDetailsUserPms', data);
  }

  DetailsUserPms() {
    return this.http.get(this.endpoint + '/DetailsUserPms');
  }

  updatePosInformation() {
    return this.http.get(this.endpoint + '/updatePosInformation');
  }
  async getOrderByOrderPmsId(orderid: string) {
    const body = {
      RequestType : '',
      AccountKey : environment.blancAccountKey,
      SessionID: '',
      Parameters : {}
    };
    body.RequestType = 'GetOrderDetail';
    body.Parameters = {
      infoOrder_id: orderid,

    };
    body.SessionID = this.cookieService.getCookie('blanc_session_id');
    const OrderDetail = await this.http.post(this.endpoint, body).toPromise();
    if (OrderDetail['Failed']) {
      return 'not found';
    }
    const resp = {
      orderid: OrderDetail['ReturnObject']['infoOrder_id'],
      type: OrderDetail['ReturnObject']['TypeDelivery']
    };
    return  resp;
  }

  getLoginCount(): number {
    return parseInt(this.storageService.getObject('login_count'));
  }

  updateLoginCount(data: PersonalInfo): Observable<User> {
    return this.http.post<User>(this.endpoint + '/updatelogincount', data);
  }

  getLoginPreference(): boolean {
    return this.storageService.getObject('login_preference') === '1' ? true : false;
  }
  checkAppClicked(email) {
    const body = {
      email : email,
      clicked : true
    };
    return this.http.post('checkAppClicked', body).toPromise();
  }
  closeAppWithoutCkeck(email) {
    const body = {
      email : email,
      closed : true
    };
    return this.http.post('closeAppWithoutCkeck', body).toPromise();
  }
}
