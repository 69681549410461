export class Recurring {
  id: string;
  idHashCode: string;
  isActive: boolean;
  status: number;
  type: number;
  pickupDay: number;
  pickupDayAsString?: string;
  deliveryDay: number;
  deliveryDayAsString?: string;
  pickupSlot: string;
  pickupSlotFormatted: string;
  pickupSlotFormattedDesktop: string;
  deliverySlot: string;
  deliverySlotFormatted: string;
  deliverySlotFormattedDesktop: string;
  pauseStart: string;
  pauseEnd: string;
  pause: boolean;
  hasPause: boolean;
}
