<shared-header [isBooking]="true"></shared-header>
<!-- <booking-slots></booking-slots> -->
<div *ngIf="isMobile" class="content animation-fade-in">
    <shared-back-button customPath=""></shared-back-button>
    <header>
    <div class="media">
        <img src={{imgPath}}>
        <span>{{title}}</span>
    </div>
    </header>

    <ng-template [ngIf]="isMobile" [ngIfElse]="isDesktop">
        <ng-container>

            <div id="delivery-not-selected" class="delivery not-selected box animation-fade-in">
                <div class="heading">
                    <p>Please choose a delivery slot</p>
                    <span>Our driver will come to your chosen address to deliver your clean items back.</span>
                </div>
                <booking-schedule [type]="slotType">
                </booking-schedule>
            </div>

        </ng-container>
    </ng-template>
    <button (click)="submit()" [disabled]="isSaving"  type="submit" class="btn primary bottom-fixed with-arrow"
    [id]="NextBtn">
      <ng-container *ngIf="!isSaving">Book now</ng-container>
      <shared-spinner *ngIf="isSaving" color="white"></shared-spinner>
    </button>
</div>
<ng-template [ngIf]="!isMobile" #isDesktop>
    <div class="delivery box animation-fade-in" >
        <div class="heading">
            <p>Please choose a delivery slot</p>
            <span>Our driver will come to your chosen address to deliver your clean items back.</span>
        </div>
        <booking-schedule [type]="slotType">
        </booking-schedule>
    </div>
    <button (click)="submit()" [disabled]="isSaving" type="submit" class="btn primary absolute with-arrow" 
      [id]="NextBtn">
      <ng-container *ngIf="!isSaving">Next</ng-container>
      <shared-spinner *ngIf="isSaving" color="white"></shared-spinner>
    </button>
</ng-template>
