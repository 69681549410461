import { Component } from '@angular/core';
import { StorageService } from 'src/app/services/common/storage.service';

@Component({
  selector: 'order-list-desktop',
  templateUrl: './list-desktop.component.html',
  styleUrls: ['./list-desktop.component.scss']
})
export class ListDesktopComponent {

  constructor( private storage: StorageService) {
    this.storage.removeItem("paged");
    this.storage.removeItem("paged_delivery");
    this.storage.removeItem("selected_pickup_slot");
    this.storage.removeItem("period_pickup");
    this.storage.removeItem("period_delivery");
    this.storage.removeItem("order_response");
    this.storage.removeItem("adresse");
    this.storage.removeItem("delivery");
    this.storage.removeItem("pickup");
    this.storage.removeItem("GarmentInstructionsFull");
   }

}
