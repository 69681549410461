import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {  DateAdapter, MatNativeDateModule } from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';

import { UserRoutingModule } from './user-routing.module';
import { PipesModule } from '../pipes/pipes.module';
import { SharedModule } from '../shared/shared.module';

import { UserComponent } from './user.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { PersonalInfoComponent } from './personal-info/personal-info.component';
import { AuthorisationsComponent } from './authorisations/authorisations.component';
import { AuthorisationsMobileComponent } from './authorisations/authorisations-mobile/authorisations-mobile.component';
import { AuthorisationsDecktopComponent } from './authorisations/authorisations-desktop/authorisations-desktop.component';
import { AuthorisationsFormMobileComponent } from './authorisations/authorisations-mobile/authorisations-form/authorisations-form.component';
import { AuthorisationsFormDesktopComponent } from './authorisations/authorisations-desktop/authorisations-form/authorisations-form.component';
import { ReadMoreComponent } from './authorisations/authorisations-mobile/read-more/read-more.component';
import { PreferencesComponent } from './preferences/preferences.component';
import { PreferencesMobileComponent } from './preferences/preferences-mobile/preferences-mobile.component';
import { PreferencesDesktopComponent } from './preferences/preferences-desktop/preferences-desktop.component';
import { ShareMyAccountComponent } from './share-my-account/share-my-account.component';
import { ReferFriendComponent } from './refer-friend/refer-friend.component';
import { ShareModalComponent } from './refer-friend/share-modal/share-modal.component';
import { PaymentsComponent } from './payments/payments.component';
import { CardWrapperComponent } from './payments/card-wrapper/card-wrapper.component';
import { CardViewComponent } from './payments/card-view/card-view.component';
import { CardAddComponent } from './payments/card-add/card-add.component';
import { PersonalInfoMobileComponent } from './personal-info/personal-info-mobile/personal-info-mobile.component';
import { PersonalInfoDesktopComponent } from './personal-info/personal-info-desktop/personal-info-desktop.component';
import { PersonalInfoFormComponent } from './personal-info/personal-info-form/personal-info-form.component';
import { ChangePasswordMobileComponent } from './change-password/change-password-mobile/change-password-mobile.component';
import { ChangePasswordDesktopComponent } from './change-password/change-password-desktop/change-password-desktop.component';
import { ChangePasswordFormComponent } from './change-password/change-password-form/change-password-form.component';
import { LandingComponent } from './landing/landing.component';
import { RecurringComponent } from './recurring/recurring.component';
import { RecurringFormComponent } from './recurring/recurring-form/recurring-form.component';
import { RecurringViewComponent } from './recurring/recurring-view/recurring-view.component';
import { RecurringMobileComponent } from './recurring/recurring-mobile/recurring-mobile.component';
import { RecurringDesktopComponent } from './recurring/recurring-desktop/recurring-desktop.component';
import { PauseModalComponent } from './recurring/pause-modal/pause-modal.component';
import { CancelModalComponent } from './recurring/cancel-modal/cancel-modal.component';
import { AuthorisationsBannerComponent } from './landing/authorisations-banner/authorisations-banner.component';
import { AuthorisationsModalComponent } from './landing/authorisations-modal/authorisations-modal.component';
import { PercentageBannerComponent } from './landing/percentage-banner/percentage-banner.component';
import { HelpFeedbackComponent } from './help-feedback/help-feedback.component';
import { HelpFeedbackMobileComponent } from './help-feedback/help-feedback-mobile/help-feedback-mobile.component';
import { HelpFeedbackDesktopComponent } from './help-feedback/help-feedback-desktop/help-feedback-desktop.component';
import { EmailUsComponent } from './help-feedback/email-us/email-us.component';
import { ThankYouComponent } from './help-feedback/thank-you/thank-you.component';
import { RateUsComponent } from './help-feedback/rate-us/rate-us.component';
import { PreferenceInstructionModalComponent } from './preferences/preference-instruction-modal/preference-instruction-modal.component';
import { NewModalComponent } from './new-modal/new-modal.component';
import { ToastrModule } from 'ngx-toastr';
import { ErrorPostalModalComponent } from './personal-info/personal-info-form/error-postal-modal/error-postal-modal.component';
import { PromoBannerComponent } from './landing/promo-banner/promo-banner.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CustomDateAdapter } from '../calendar/custom-date-adapter';
import { RateUsBannerComponent } from './landing/rate-us-banner/rate-us-banner.component';
import { CardConfirmationComponent } from './payments/card-confirmation/card-confirmation.component';
import { CancelledOrderBannerComponent } from './landing/cancelled-order-banner/cancelled-order-banner.component';

@NgModule({
  declarations: [
    UserComponent,
    ChangePasswordComponent,
    PersonalInfoComponent,
    AuthorisationsComponent,
    AuthorisationsFormMobileComponent,
    AuthorisationsFormDesktopComponent,
    AuthorisationsMobileComponent,
    AuthorisationsDecktopComponent,
    ReadMoreComponent,
    PreferencesComponent,
    PreferencesMobileComponent,
    PreferencesDesktopComponent,
    ShareMyAccountComponent,
    ReferFriendComponent,
    ShareModalComponent,
    PaymentsComponent,
    CardWrapperComponent,
    CardAddComponent,
    CardViewComponent,
    CardConfirmationComponent,
    PersonalInfoMobileComponent,
    PersonalInfoDesktopComponent,
    PersonalInfoFormComponent,
    ChangePasswordMobileComponent,
    ChangePasswordDesktopComponent,
    ChangePasswordFormComponent,
    LandingComponent,
    AuthorisationsBannerComponent,
    AuthorisationsModalComponent,
    CancelledOrderBannerComponent,
    PercentageBannerComponent,
    RecurringComponent,
    RecurringFormComponent,
    RecurringViewComponent,
    RecurringMobileComponent,
    RecurringDesktopComponent,
    PauseModalComponent,
    CancelModalComponent,
    HelpFeedbackComponent,
    HelpFeedbackMobileComponent,
    HelpFeedbackDesktopComponent,
    EmailUsComponent,
    ThankYouComponent,
    RateUsComponent,
    PreferenceInstructionModalComponent,
    NewModalComponent,
    ErrorPostalModalComponent,
    PromoBannerComponent,
    RateUsBannerComponent,

  ],
  imports: [
    CommonModule,
    PipesModule,
    UserRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    ClipboardModule,
    MatSidenavModule,
    MatRadioModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 3000,
    }),
    SlickCarouselModule
  ],
  entryComponents: [
    NewModalComponent,
    ErrorPostalModalComponent
  ],
  exports: [
    CommonModule,
    AuthorisationsFormMobileComponent,
    AuthorisationsFormDesktopComponent,
    CardViewComponent,
    CardWrapperComponent,
    CardConfirmationComponent
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter
    },

    DatePipe
 ],
})
export class UserModule { }
