import { Component, Inject, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AuthService } from 'src/app/services/user/auth.service';
import { FormStatusEnum } from 'src/app/models/common/enums/form-status.enum';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  id: string;
  token: string;
  userId: any;
  resetForm: FormGroup;
  isPasswordVisible: boolean;

  error = null;
  message = null;
  isMissing = false;
  isValid = false;
  isWorking = false;
  isDone = false;

  constructor(
    private route: ActivatedRoute,
    private auth: AuthService,
    private dialog: MatDialog,
) { }


  ngOnInit(): void {
    this.id = this.route.snapshot.queryParamMap.get('id');
    this.token = this.route.snapshot.queryParamMap.get('token');
    if (!this.id || !this.token) {
      this.isMissing = true;
      this.error = 'Invalid url - try clicking on the link in your email again or contact customer care';
    }

    this.initForm();
  }

  initForm() {
    this.resetForm = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.min(6)])
    });

    this.resetForm.statusChanges.subscribe((status) => {
      this.error = null;
      this.isValid = status === FormStatusEnum.Valid;
    });
  }

  get passwordForm() {
    return this.resetForm.get('password');
  }

  showPassword() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  resetPassword() {
    this.isWorking = true;
    this.error = null;
    this.message = null;
    this.auth.resetPassword(this.id, this.token, this.passwordForm.value)
      .then(() => {
        this.message = 'Please try logging in with your new password';
        this.isDone = true;
      })
      .catch(err => {
        this.error = err.error.message;
      })
      .finally(() => {
        this.isWorking = false;
      });
  }
  closeDialog() {
    this.dialog.closeAll();
  }
}
