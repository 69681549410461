<!-- <div *ngIf="order.manualStatus !== manualStatusEnum.AwaitingAuthorisations" class="banner authorisations-banner" id="authorisations-banner">
  <div class="left-wrapper">
    <p>Shirt</p>
  </div>
  <div class="right-wrapper">
    <ng-container *ngIf="order.serviceType === serviceTypeEnum.Tailoring">
      <p>The quote for the tailoring service you requested is £__ and the estimated time is __ days. Do you confirm you would like us to proceed?</p>
    </ng-container>
    <ng-container *ngIf="order.serviceType === serviceTypeEnum.Cleaning">
      <p>Our in-house experts assessed that, in this specific case, better cleaning results would be achieved by using a solvent-based method.</p>
      <p class="additional-padding">Do you authorise us to send your item to our solvent dry cleaning partner?</p>
    </ng-container>
    <div class="actions">
      <a (click)="setAuthorisation(false)" class="btn secondary-danger" id="refuse-btn">No</a>
      <a (click)="setAuthorisation(true)" class="btn secondary" id="accept-btn">Yes</a>
    </div>
  </div>
</div>

<div *ngIf="order.manualStatus === manualStatusEnum.AwaitingAuthorisations" class="banner awaiting-authorisations-banner" id="awaiting-authorisations-banner">
  <p>Our experts assessed that our eco cleaning method is not suitable for your item. Since we haven't received your reply within the time limit, we will by default return it unprocessed.</p>
  <p class="additional-padding">If you want us to process that item, you can reschedule your delivery:</p>
  <div class="actions">
    <a (click)="calcelItem()" class="btn secondary-danger" id="cancel-btn">Cancel item</a>
    <a (click)="goToReschedule()" class="btn secondary-neutral" id="reschedule-btn">Delay delivery</a>
  </div>
</div> -->

<div class="banner authorisations-banner" id="authorisations-banner">
  <div class="right-wrapper">
    <ng-container>
      <p>Some items in your order require an authorisation in order for us to proceed.</p>
      <p class="additional-padding">Please review pending authorisations and note that items will be saved as authorised only after you have reviewed all authorisations and booked the required redelivery (if applicable).</p>
    </ng-container>
    <div class="actions">
      <a [routerLink]="[orderAuthorisationRoute, order.OrderID]"  class="btn secondary-danger" id="refuse-btn">REVIEW AUTHORISATIONS
        <img _ngcontent-tbt-c278="" src="assets/icons/arrow-right-white.svg" alt="arrow blanc" class="arrow-img">
      </a>
    </div>
  </div>
