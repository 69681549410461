import { Component, OnDestroy, OnInit } from '@angular/core';

import { CardService } from 'src/app/services/user/card.service';
import { CardResponse } from 'src/app/models/user/card-response.model';
import { CardStateEnum } from 'src/app/models/user/enums/card-state.enum';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Routes } from 'src/app/constants/routes.constant';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BlancCommandService } from 'src/app/services/common/blanc-command.service';
import { CardResponseStripe } from 'src/app/models/user/card-stripe.model';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';

@Component({
  selector: 'app-card-wrapper',
  templateUrl: './card-wrapper.component.html',
  styleUrls: ['./card-wrapper.component.scss'],
})
export class CardWrapperComponent implements OnInit, OnDestroy {
  CardStateEnum: typeof CardStateEnum = CardStateEnum;
  cardState: CardStateEnum = CardStateEnum.Default;
  // card: CardResponse;
  card: CardResponseStripe;
  stateSubscribe: Subscription;
  store: string
  isMobile: boolean;
  currentPath: boolean = false;

  constructor(
    private cardService: CardService,
    private router: Router,
    private route: ActivatedRoute,
    protected deviceService: DeviceDetectorService,
    private blancCommandService: BlancCommandService,
  ) { }
  async ngOnInit() {
    this.store = this.route.snapshot.queryParams.store;
    this.isMobile = this.deviceService.isMobile();
    this.stateSubscribe = this.cardService.state.subscribe(value => {
      this.cardState = value;
    });

    await this.initCard();
  
  }
  ngOnDestroy(): void {
    if (this.stateSubscribe) {
      this.stateSubscribe.unsubscribe();
    }
  }

  async initCard() {
    if (this.router.url == "/booking/payment") {
      this.currentPath = true;
    }
    
    
      this.blancCommandService.executeCommand(CommandEnum.GetCustomerCard)
      .then(response => {
        let card = response['ReturnObject']['card'];
        if (card.Actif != '0') {
          this.card = card;
          this.card.cardNumber = card.cardNumber.substr(-5);
          this.cardService.setState(CardStateEnum.ViewCard);
          this.cardService.set(card);
        }else {
          this.cardService.setState(CardStateEnum.AddCard);
        }
      })
      .catch(err => {
        this.cardService.setState(CardStateEnum.AddCard);
      });
  }
  skipStep() {
    this.router.navigate([Routes.BOOKING_REGULAR.AUTHORISATIONS_STORE],{ queryParams: { store: this.store } });
    // this.router.navigate([Routes.ORDER.LIST ]);
  }
}
