import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hourFormat'
})
export class HourFormatPipe implements PipeTransform {

  transform(value: string): unknown {
    
    if ( value.trim() ) {
      let hourStr1 = value.split('-')[0].substr(0, 2);
      let hourStr2 = value.split('-')[1].substr(1, 2);
      let hour;

      if (hourStr1 === '08') {
        hourStr1 = '8' + 'AM';
      } else {
        if (+hourStr1 < 12){
          hourStr1 = hourStr1 + 'AM';
        } else if (+hourStr1 === 12) {
          hourStr1 = hourStr1 + 'PM';
        } else {
          hour = parseInt(hourStr1) - 12;
          hourStr1 = hour + 'PM';
        }
      }

      if (hourStr2 === '08') {
        hourStr2 = '8' + 'AM';
      } else {
        if (+hourStr2 < 12){
          hourStr2 = hourStr2 + 'AM';
        }else if (+hourStr2 === 12) {
          hourStr2 = hourStr2 + 'PM';
        } else {
          hour = parseInt(hourStr2) - 12;
          hourStr2 = hour + 'PM';
        }
      }

      return  hourStr1 + ' - ' + hourStr2;
    } else {

      return '';
    }
  }

}
