<div class="banner">
  <div class="media">
    <img src="assets/icons/rate-us.svg" alt="blanc rating">
  </div>

  <div class="text">
    <p>Happy with your experience?</p>
    <span>It would mean the world to us if you could take just 2 seconds to rate our services at the store you just visited - it makes such a big difference to small businesses like ours!</span>
    <a href="https://uk.trustpilot.com/evaluate/blancliving.co " target="_blanc" class="rate-btn">Rate us</a>
  </div>
</div>