import { Component, Input, OnInit } from '@angular/core';
import { CommandService } from 'src/app/services/common/command.service';
import { HttpStatusCode } from 'src/app/models/common/enums/http-status-code';
import { AlertService } from 'src/app/services/common/alert.service';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/services/common/storage.service';
import { CookieService } from 'src/app/services/common/cookie.service';

@Component({
  selector: 'booking-voucher-field',
  templateUrl: './voucher-field.component.html',
  styleUrls: ['./voucher-field.component.scss']
})
export class VoucherFieldComponent implements OnInit {
  isImplemented: boolean;
  isCorrect: boolean;
  message: string;
  voucherCode: string;
  voucherForm: FormGroup;
  endpoint: string;
  @Input() voucher: string;

  BookingSaveVoucher: string;

  constructor(
    private http: HttpClient,
    protected commandService: CommandService,
    protected storageService: StorageService,
    private cookieService: CookieService,
    protected alertService: AlertService) {
      this.endpoint = environment.api;
     }

  async ngOnInit() {
    if (this.voucher){
      this.voucherCode = this.voucher;
      this.isImplemented = true;
    }
    // this.isCorrect = true;
    this.voucherForm = new FormGroup({
      voucherCode: new FormControl(this.voucherCode, [Validators.maxLength(15)])
    });

    let verify = localStorage.getItem('first-journey')
    let smsMarketing = localStorage.getItem('smsMarketing')
    if ( verify === 'true'){
      this.BookingSaveVoucher = "first-journey-booking-summary-saveVoucher";
    }else if(smsMarketing){
      this.BookingSaveVoucher = "sms-marketing-booking-summary-saveVoucher";
    }else{
      this.BookingSaveVoucher = "returning-user-booking-summary-saveVoucher";
    }
  }

  getFormField(fieldName: string) {
    return this.voucherForm.get(fieldName.toString());
  }

  onKey(event) {
    if (event.target.value === '') {
      this.isCorrect = true;
    }
  }

  onFormSubmit() {
  }

  async applyVoucher() {
    this.message = '';
    let voucherCode = this.getFormField('voucherCode');
    console.log(voucherCode);
    voucherCode = voucherCode.value;
    
    const body = {
      voucherCode,
      userEmail: this.cookieService.getCookie('logged_email'),
    };
    let addVoucherCommandResult: any;
    addVoucherCommandResult = await this.http.post(this.endpoint + 'voucherCodeVerify', body).toPromise();

    if (addVoucherCommandResult.error){
      this.alertService.showError(addVoucherCommandResult.message);
      this.message = addVoucherCommandResult.message;
      this.isCorrect = false;
      return;
    }
    this.voucherCode = addVoucherCommandResult.voucherCode;
    this.storageService.addVoucher(this.voucherCode);

    this.isImplemented = true;
    this.isCorrect = true;
  }
  
}
