import { Component, Input, OnInit } from '@angular/core';
import { BookingStepsEnum } from 'src/app/models/booking/enums/booking-steps.enum';

import { BookingStepsService } from 'src/app/services/booking/booking-steps.service';

@Component({
  selector: 'booking-progress-bar-mobile',
  templateUrl: './progress-bar-mobile.component.html',
  styleUrls: ['./progress-bar-mobile.component.scss']
})
export class ProgressBarMobileComponent implements OnInit {
  totalSteps: number = 8;
  percentages: number;
  hasMoreSteps: boolean;
  cssWidthPercentage: string;
  cssLeftPosition: string;

  @Input() step?: BookingStepsEnum;

  constructor(private bookingStepsService: BookingStepsService) { }

  ngOnInit(): void {
    this.calculateCssValues();

    this.bookingStepsService.step.subscribe(value => {
      this.step = value;
      this.calculateCssValues();
    });
  }

  calculateCssValues() {
    this.percentages = Math.floor(this.step / this.totalSteps * 100);
    this.hasMoreSteps = this.percentages !== 100;
    this.cssWidthPercentage = `${this.percentages.toString()}%`;
    this.cssLeftPosition = `calc(${this.percentages.toString()}% - 4px)`;
  }
}
