<a [ngSwitch]="logoType" routerLink="/" class="logo-btn">
  <div *ngSwitchCase="logoButtonEnum.LogoGreen" class="logo type-0" id="logo-type-0">
    <img src="assets/images/logo-green.png" alt="blanc logo" class="logo-img green">
  </div>

  <div *ngSwitchCase="logoButtonEnum.LogoGreyWithText" class="logo type-1" id="logo-type-1">
    <img src="assets/images/logo-grey.png" alt="blanc logo" class="logo-img grey">
    <img src="assets/images/logo-text.png" alt="blanc logo" class="logo-text">
  </div>

  <div *ngSwitchCase="logoButtonEnum.LogoGreenWithText" class="logo type-2" id="logo-type-2">
    <img src="assets/images/logo-green.png" alt="blanc logo" class="logo-img green">
    <img src="assets/images/logo-text.png" alt="blanc logo" class="logo-text">
  </div>
</a>
