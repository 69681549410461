<booking-progress-bar-mobile [step]=step></booking-progress-bar-mobile>

<div *ngIf="shouldDisplay" class="content authorisations-mobile animation-fade-in">
  <shared-back-button></shared-back-button>
  <div class="heading">
    <h1>We need your authorisations</h1>
    <p>In order to provide you with the best service possible, we need to know what is important to you.</p>

    <user-authorisations-form-mobile [isInBookingProcess]="true"></user-authorisations-form-mobile>
  </div>
</div>
