<shared-header *ngIf="isDesktop"></shared-header>

<shared-navigation-main></shared-navigation-main>

<router-outlet></router-outlet>

<shared-footer *ngIf="isDesktop"></shared-footer>



