import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

import { Voucher, ApiOrder } from '../models/obsolete/order';
import { User } from '../models/user/user.model';
import { Address } from '../models/obsolete/address';
import { UserSpot } from '../models/user/user-spot.model';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  private readonly interested = "interested/";
  private readonly spotsync = "spot/sync";

  private readonly adminUrl: string;

  constructor(private http: HttpClient) {
    let apiUrl = environment.api;
    this.adminUrl = apiUrl.replace("/api/", "/admin/");
  }

  getInterested(): Promise<Interested[]> {
    console.debug("get<>ing interested emails");
    return this.http.get<Interested[]>(this.adminUrl + this.interested).toPromise();
  }

  getSpotAccount(email: string): Promise<UserSpot> {
    console.debug("Getting sport user: " + email);
    return this.http.get<UserSpot>(`${this.adminUrl}spot/account?email=${email}`).toPromise();
  }

  syncUserSpot(email: string): Promise<UserSpot> {
    console.debug("Synching user: " + email);
    return this.http.get<UserSpot>(`${this.adminUrl}${this.spotsync}?email=${email}`).toPromise();
  }

  getAllOrders(): Promise<OrderDetail[]> {
    console.debug("Getting all orders");
    return this.http.get<OrderDetail[]>(`${this.adminUrl}reporting/orders`).toPromise();
  }

  getOrders(creationDate?: Date, collectionDate?: Date, deliveryDate?: Date): Promise<OrderDetail[]> {
    if (!creationDate && !collectionDate && !deliveryDate) {
      return this.getAllOrders();
    }
    let request = "?";
    if (creationDate) {
      request = request + 'creationDate=' + this.dateToString(creationDate);
    }

    if (collectionDate) {
      if (request != "?") {
        request = request + "&";
      }
      request = request + 'collectionDate=' + this.dateToString(collectionDate);
    }

    if (deliveryDate) {
      if (request != "?") {
        request = request + "&";
      }
      request = request + 'deliveryDate=' + this.dateToString(deliveryDate);
    }

    return this.http.get<OrderDetail[]>(`${this.adminUrl}reporting/orders${request}`).toPromise();
  }

  private dateToString(date: Date) {
    return moment(date).format('YYYY-MM-DD');
  }

  getOrder(searchType: string, searchDate: string): Promise<any> {
    console.debug("Getting orders for: " + searchType + searchDate);
    return this.http.get<any>(`${this.adminUrl}reporting/orders?${searchType}=${searchDate}`).toPromise();
  }

  getAllUsers(startDate?: Date, endDate?: Date): Promise<User[]> {
    if (!startDate) {
      startDate = new Date();
      startDate.setFullYear(2015);
    }

    if (!endDate) {
      endDate = new Date();
    }

    var payload = {
      from: this.dateToString(startDate),
      to: this.dateToString(endDate)
    }
    return this.http.post<User[]>(`${this.adminUrl}reporting/users`, payload).toPromise();
  }

  getUserClaims(email: string): Promise<any[]> {
    return this.http.get<any[]>(`${this.adminUrl}claims?email=${email}`).toPromise();
  }

  saveUserClaim(email: string, type: string, value: string): Promise<any> {
    return this.http.put<any>(`${this.adminUrl}claims?email=${email}&type=${type}&value=${value}`, null).toPromise();
  }

  deleteUserClaim(email: string, type: string, value: string) {
    return this.http.delete(`${this.adminUrl}claims?email=${email}&type=${type}&value=${value}`).toPromise();
  }

  getDeliveryRoutes(): Promise<Route[]> {
    console.debug(`Getting all delivery zones`);
    return this.http.get<Route[]>(`${this.adminUrl}spot/route/delivery_zones`).toPromise();
  }

  saveSchedule(schedules: any): Promise<any> {
    console.debug(`Saving schedules: ${schedules}`);

    return this.http.post<any>(`${this.adminUrl}schedule`, schedules).toPromise();
  }

  getVouchers(): Promise<Voucher[]> {
    console.debug("Getting all vouchers");
    return this.http.get<Voucher[]>(`${this.adminUrl}vouchers`).toPromise();
  }

  addVoucher(voucher: Voucher) {
    console.debug(`Adding voucher: ${voucher}`);

    return this.http.post<Voucher>(`${this.adminUrl}voucher`, voucher).toPromise();
  }

  updateVoucher(voucher: Voucher) {
    console.debug(`Updating voucher: ${voucher}`);
    return this.http.put(`${this.adminUrl}voucher/${voucher.id}`, voucher).toPromise();
  }

  getAllHolidays(): Promise<Holiday[]> {
    console.debug("Getting all holidays");
    return this.http.get<Holiday[]>(`${this.adminUrl}holidays`).toPromise();
  }

  addHoliday(holiday: Holiday) {
    console.debug(`Saving holiday: ${holiday}`);
    return this.http.post<Holiday>(`${this.adminUrl}holidays`, holiday).toPromise();
  }

  deleteHoliday(holiday: Holiday) {
    console.debug(`Deleting holiday: ${holiday}`);
    return this.http.delete<Holiday>(`${this.adminUrl}holidays/${holiday.id}`).toPromise();
  }

}

export interface Interested {
  emailAddress: string,
  postCode: string
}

export interface Holiday {
  id?: string,
  description: string,
  date: string
}

export interface Route {
  routeID: string,
  routeName: string
}

export interface OrderDetail {
  user: User,
  order: ApiOrder,
  address: Address,
  emailConfirmed: boolean
}
