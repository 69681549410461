import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: any, format?: string): string {
    if (value) {
      if (format) {
        return value.format(format);
      } else {
        return value.format('ddd DD MMM YYYY')
      }
    }
  }
}