import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService as AuthGuard } from 'src/app/services/guards/auth-guard.service';
import { OrderComponent } from './order.component';
import { ListComponent } from './list/list.component';
import { DetailsComponent } from './details/details.component';
import { EditMobileComponent } from './edit/edit-mobile/edit-mobile.component';
import { RescheduleComponent } from './reschedule/reschedule.component';
import { GpsTrackingComponent } from './gps-tracking/gps-tracking.component';
import { EditFinalizeComponent } from './edit/edit-finalize/edit-finalize.component';
import { PastComponent } from './past/past.component';
import { NewAuthorisationComponent } from './new-authorisation/new-authorisation.component';


const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: OrderComponent,
    children: [
      {
        path: '',
        component: ListComponent,
      },
      {
        path: 'past/:id',
        component: PastComponent,
      },
      // {
      //   path: ':id',
      //   component: DetailsComponent,
      // },
      {
        path: ':id/:type',
        component: DetailsComponent,
      },
      {
        path: 'edit/:id/:type',
        component: EditMobileComponent,
      },
      {
        path: 'edit/finilize/:id/:type/:edit_type',
        component: EditFinalizeComponent,
      },
      {
        path: 'reschedule/:id',
        component: RescheduleComponent,
      },
      {
        path: 'gps-tracking/:id',
        component: GpsTrackingComponent,
      },
      {
        path: 'authorisations/:id',
        component: NewAuthorisationComponent,
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OrderRoutingModule { }
