import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'postcode'
})
export class PostcodePipe implements PipeTransform {

  postcodeRegEx = /(^[A-Z]{1,2}[0-9]{1,2})([0-9][A-Z]{2}$)/i;

  transform(value: string): string {
    if (value) {
      return value.replace(this.postcodeRegEx,"$1 $2").toUpperCase();
    }
  }
}
