import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { PaymentBase } from '../payment.base';
import { CommandService } from 'src/app/services/common/command.service';
import { BlancCommandService } from 'src/app/services/common/blanc-command.service';
import { CardService } from 'src/app/services/user/card.service';
import { AlertService } from 'src/app/services/common/alert.service';
import { BookingStepsService } from 'src/app/services/booking/booking-steps.service';
import { Routes } from 'src/app/constants/routes.constant';
import { BookingStepsEnum } from 'src/app/models/booking/enums/booking-steps.enum';

@Component({
  selector: 'booking-payment-mobile',
  templateUrl: './payment-mobile.component.html',
  styleUrls: ['./../payment.component.scss', './payment-mobile.component.scss']
})
export class PaymentMobileComponent extends PaymentBase implements OnInit {
  store: string;
  customPathStore: string;
  paymentStripe: string;
  step : BookingStepsEnum = BookingStepsEnum.Address;
  Proceed: string;
  constructor(
    router: Router,
    location: Location,
    route: ActivatedRoute,
    cardService: CardService,
    bookingStepsService: BookingStepsService,
    commandService: CommandService,
    blancCommandService: BlancCommandService,
    alertService: AlertService,
  ) {
    super(router, location,route, cardService,bookingStepsService, commandService, blancCommandService, alertService);
  }

  async ngOnInit() {
    this.paymentStripe = this.route.snapshot.queryParams.paymentStripe ? 'true' : 'false';
    this.bookingStepsService.setStep(BookingStepsEnum.Address);
    this.store = this.route.snapshot.queryParams.store;
    if (this.store) {
      this.customPathStore = 'booking/address?store=' + this.store;
    } else {
      this.customPathStore = 'booking/address';
    }
    await super.ngOnInit();

    var firstName = localStorage.getItem('firstName');
    let smsMarketing = localStorage.getItem('smsMarketing')
    if (smsMarketing){
      this.Proceed="sms-marketing-booking-redirectToSummary-editPayment-proceed";
    }else if (firstName){
      this.Proceed="returning-user-booking-redirectToSummary-editPayment-proceed";
    }else{
      this.Proceed="first-journey-booking-redirectToSummary-editPayment-proceed";
    }
  }
  skipStep() {
    this.router.navigate([Routes.BOOKING_REGULAR.AUTHORISATIONS_STORE],{ queryParams: { store: this.store } });
    // this.router.navigate([Routes.ORDER.LIST ]);
  }

}
