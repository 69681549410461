import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';

import { CardResponse } from 'src/app/models/user/card-response.model';
import { CardStateEnum } from 'src/app/models/user/enums/card-state.enum';
import { ICardService } from './contracts/ICardService';

@Injectable({
  providedIn: 'root',
})
export class CardService implements ICardService {
  endpoint: string;

  state = new BehaviorSubject<CardStateEnum>(CardStateEnum.Default);
  card = new BehaviorSubject<CardResponse>(null);

  constructor(
    private http: HttpClient
  ) {
    this.endpoint = environment.api;
  }

  setState(state: CardStateEnum) {
    this.state.next(state);
  }

  set(card: CardResponse) {
    this.card.next(card);
  }

}
