import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { RequestUrlInterceptor } from './interceptor/request-url.interceptor';
import { AuthTokenInterceptor } from './interceptor/auth-token.interceptor';
import { AppModules } from './app.modules';
import { AuthGuardService } from './services/guards/auth-guard.service';
import { IsSignedInGuardService } from './services/guards/is-signed-in-guard.service';
import { AdminGuardService } from './services/guards/admin-guard.service';
import { PendingChangesGuard } from './services/guards/pending-changes-guard.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LandingGuardService } from './services/guards/landing-guard.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LogsInterceptor } from './interceptor/logs.interceptor';
// import { UrlSerializer } from '@angular/router';
// import { CustomUrlSerializer } from './url/custom-url-serializer';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppModules.imports,
    // TODO: Test purpose. Return the comment line when deploying on production
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true, registrationStrategy: "registerImmediately" }),
    BrowserAnimationsModule  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestUrlInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LogsInterceptor,
      multi: true,
    },
    {
      provide: AuthGuardService,
    },
    {
      provide: AdminGuardService,
    },
    {
      provide: IsSignedInGuardService,
    },
    {
      provide: PendingChangesGuard,
    },
    {
      provide: LandingGuardService,
    },
    // { provide: UrlSerializer, useClass: CustomUrlSerializer }

  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
