<form [formGroup]="voucherForm" (ngSubmit)="onFormSubmit()">
  <p>Voucher code</p>
  <input matInput (keyup)="onKey($event)" formControlName="voucherCode" placeholder="Please enter code here">
  <div><img *ngIf="isImplemented" class="green-success" src="assets/icons/voucher-success-green.svg"></div>
  <mat-error *ngIf="getFormField('voucherCode').hasError('maxlength')">
    Could not be more than 15 symbols
  </mat-error>
  <div class="around-arrow" *ngIf="!isImplemented">
    <a type="submit" (click)="applyVoucher()" [id]="BookingSaveVoucher">
      <img class="green-arrow" src="assets/icons/arrow-right-green.svg">
    </a>
  </div>
  <span *ngIf="isImplemented" class="message" >Voucher applied!</span>
  <span *ngIf="!isCorrect" class="error" >{{message}}</span>

</form>
<!-- <div class="credit"  *ngIf="isImplemented">
  <p>Credit & voucher code</p>
  <span>{{voucherCode}}</span>
</div> -->
