import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, transition, animate, style, state } from '@angular/animations';

import { ServiceWrapperModel } from 'src/app/models/particle/service-wrapper.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BookingBase } from '../../booking.base';
import { Routes } from 'src/app/constants/routes.constant';
import { Router } from '@angular/router';

@Component({
  selector: 'booking-summary-instructions',
  templateUrl: './summary-instructions.component.html',
  styleUrls: ['./summary-instructions.component.scss'],
})
export class SummaryInstructionsComponent extends BookingBase implements OnInit {
  isShown = false;
  EditGarmentIns : string;
  EditMobileGarmentIns: string;
  verif_length:boolean = true;

  @Input() services: ServiceWrapperModel[];

  constructor(
    protected deviceService: DeviceDetectorService,
    private router: Router
  ) {
    super(deviceService)
  }

  ngOnInit(): void {
    console.log(this.services)
    if (this.services.length == 1 ){
      this.services.forEach(element => {
        if (!element) {
          this.verif_length = false
        }
      });      
    }

    let verify = localStorage.getItem('first-journey');
    let smsMarketing = localStorage.getItem('smsMarketing')
    
    if ( verify === 'true'){
      this.EditGarmentIns = "first-journey-booking-summary-editGarmentIns";
    }else if (smsMarketing){
      this.EditGarmentIns = "sms-marketing-booking-summary-editGarmentIns";
    }else if(this.router.url.includes('Pick%20up%20&%20Delivery%20details')){
      if(this.isMobile){
        this.EditGarmentIns = "order-mobile-editGarmentIns";
      }else{
        this.EditGarmentIns = "order-desk-editGarmentIns";
      }
      
    }else{
      this.EditGarmentIns = "returning-user-booking-summary-editGarmentIns";
    }
  }

  toggleShown() {
    this.isShown = !this.isShown;
  }
  redirectTo() {
    if (this.router.url == Routes.BOOKING_REGULAR.SUMMARY) {
      this.router.navigate([Routes.BOOKING_REGULAR.SERVICES], { queryParams: { redirect: Routes.BOOKING_REGULAR.SUMMARY } });
    } else {
      localStorage.setItem('redirectFromGarment', JSON.stringify(this.router.url));
      this.router.navigate([Routes.BOOKING_REGULAR.SERVICES]);
    }
    
  }
}
