import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

import { Brand } from '../../models/booking/brand.model';
import { Particle } from 'src/app/models/particle/particle.model';
import { ParticleTypeEnum } from '../../models/particle/enums/particle-type.enum';
import { IParticleService } from './contracts/IParticleService';
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root',
})
export class ParticleService implements IParticleService {
  endpoint: string;

  constructor(private http: HttpClient) {
    this.endpoint = environment.api;
  }

  searchBrands(search: string): Observable<Brand[]> {
    return this.http.get<Brand[]>(this.endpoint + `particle/search/${ParticleTypeEnum.Brand}?field=${search}`);
  }

  getParticle(type: number, parentId: string = Guid.EMPTY): Observable<Particle[]> {
    return this.http.get<Particle[]>(this.endpoint + `particles/${type}/${parentId}`);
  }
  
  saveNewVersion(version) {
    let body = {
      version:version
    }
    return this.http.post('saveVersion', body).toPromise();
  }

}