import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ParticleTypeEnum } from 'src/app/models/particle/enums/particle-type.enum';
import { ParticleItem } from 'src/app/models/particle/particle-item.model';
import { ParticleService } from 'src/app/services/particle/particle.service';

@Component({
  selector: 'booking-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit {

  @Input() itemId: string;
  @Input() addItemFunc: (item) => {};

  items: ParticleItem[];
  @ViewChild("childMenu", { static: true }) public childMenu;

  constructor(protected particleService: ParticleService) { }

  async ngOnInit() {
    this.items = await this.getChildItems(this.itemId);
  }

  async getChildItems(id) {
    let response = await this.particleService.getParticle(ParticleTypeEnum.Item, id).toPromise();

    let items = response.map(x => new ParticleItem(x));
    return items;
  }

  selectItem(item: ParticleItem) {
    this.addItemFunc(item);
  }
}
