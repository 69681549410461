<div class="box">
  <div *ngIf="pickupSlot && deliverySlot" class="pickup-slot">
    <div class="heading">
      <p>{{pickup}}</p>
      <button class="btn href green edit-btn" [id]="orderDeskEditPickup" (click)="edit(editEnum.Pickup)"
        *ngIf="Edit_pickup">Edit</button>
    </div>
    <div class="info">
      <p class="pickup-date">{{ (myDate_pickup) ? (myDate_pickup | date : 'EEE  dd/MM' ) : "-" }}</p>
      <p class="" *ngIf="Instore">At 3.56PM</p>
      <p class="pickup-hour" *ngIf="!Instore">{{ (pickupSlot) ? pickupSlot : "-" }}</p>
      
      <p class="deliver-to" *ngIf="instructions.deliverToName">Contact: {{instructions.deliverToName ? instructions.deliverToName :'N/a' }}</p>
      <p class="delivery-type" *ngIf="Instore">{{order.TypeDelivery | titlecase}}</p>
    </div>
  </div>

  <div *ngIf="deliverySlot" class="delivery-slot">
    <div class="heading">
      <p>{{delivery}}</p>
      <button class="btn href green edit-btn" id="edit-address-btn" (click)="edit(editEnum.Delivery)" *ngIf="Edit_delivery">Edit</button>
      <button class="btn href green edit-btn" [id]="orderDeskEditDelivery" (click)="edit(editEnum.PickupUpcoming)" *ngIf="Edit_delivery_PickupUpcoming">Edit</button>
    </div>
    <div class="info">
      <p class="delivery-date">{{(myDate) ? (myDate | date : 'EEE  dd/MM'  ):"-"}}</p>
      <p class="" *ngIf="Instore">From 6PM</p>
      <p class="delivery-hour" *ngIf="!Instore">{{(deliverySlot)? deliverySlot : "-" }}</p>
      
      <p class="deliver-to" *ngIf="instructions.deliverToName">Contact: {{instructions.deliverToName ? instructions.deliverToName :'N/a'}}</p>
      <p class="delivery-type" *ngIf="Instore">{{order.TypeDelivery | titlecase}}</p>
     
    </div>
  </div>
</div>