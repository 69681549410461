<shared-navigation-main-desktop *ngIf="isLoggedUser"></shared-navigation-main-desktop>

<div class="content animation-fade-in">
  <div class="wrapper">
    <h1>We are always happy to hear from you!</h1>
    
    <user-email-us *ngIf="!isEmailSend" (onEmailSend)="changeView($event)" id="emailUsComponent"></user-email-us>
    <user-thank-you *ngIf="isEmailSend" id="thankYouComponent" ></user-thank-you>
    
    <user-rate-us></user-rate-us>
  </div>
</div>
