import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { BookingBase } from '../booking.base';

@Component({
  selector: 'booking-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent extends BookingBase {
  
  constructor(protected deviceService: DeviceDetectorService) {
    super(deviceService)
  }

}
