import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, debounceTime } from 'rxjs/operators';

import { GoogleAnalyticsService } from './services/common/google-analytics.service';
import { AuthService } from './services/user/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.authService.handleAuthCookie();
    // TODO: Check which logic in ngOnInit could be deleted
    this.setHeight();
    window.addEventListener('resize', this.setHeight);
    window.addEventListener('orientationchange', this.setHeight);

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      debounceTime(500)
    ).subscribe(event => {
      let header = null;
      if (!((event as NavigationEnd).urlAfterRedirects === '/')) {
        header = document.getElementById('main');
      }
      window.scrollTo({
        top: header ? header.offsetTop - 5 : 0,
        left: 0,
        behavior: 'smooth'
      });
    });
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(event => GoogleAnalyticsService.route(event as NavigationEnd));
  }

  private setHeight() {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
  }
}
