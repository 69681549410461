import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';

import { RecurringService } from 'src/app/services/user/recurring.service';
import { CommandService } from 'src/app/services/common/command.service';
import { StorageService } from 'src/app/services/common/storage.service';
import { Recurring } from 'src/app/models/user/recurring.model';
import { TimeSlot } from 'src/app/models/user/time-slot.model';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { ReccurenceStateEnum } from 'src/app/models/user/enums/recurrence-state.enum';
import { ReccurenceTypeEnum } from 'src/app/models/user/enums/recurrence-type.enum';
import { DayOfWeekEnum } from 'src/app/models/user/enums/day-of-week.enum';
import { PostCodeService } from 'src/app/services/shared/postCode.service';
import { InfoCustomer } from 'src/app/models/user/info-customer.model';
import { Delivery } from 'src/app/models/user/enums/delivery.enum';
import { MatDialog } from '@angular/material/dialog';
import { CheckCardModalComponent } from 'src/app/shared/check-card-modal/check-card-modal.component';

@Component({
  selector: 'user-recurring-form',
  templateUrl: './recurring-form.component.html',
  styleUrls: ['./recurring-form.component.scss'],
})
export class RecurringFormComponent implements OnInit {
  isMobile: boolean;
  dayEnum: typeof DayOfWeekEnum = DayOfWeekEnum;
  typeEnum: typeof ReccurenceTypeEnum = ReccurenceTypeEnum;
  info: any;
  post: any;
  slotsByDay: Array<Array<TimeSlot>> = [];
  pickupSlots: TimeSlot[] = [];
  deliverySlots: TimeSlot[] = [];
  delivery: Delivery;
  recurring: Recurring;
  infoCustomer: InfoCustomer;
  customerDetail: any;
  recurringForm: FormGroup = this.formBuilder.group({
    type: ['', Validators.required],
    pickupDay: ['', Validators.required],
    deliveryDay: ['', Validators.required],
    pickupSlot: ['', Validators.required],
    deliverySlot: [''],
  });
  array_recurring= [];
  constructor(
    private commandService: CommandService,
    private postCodeService: PostCodeService,
    private recurringService: RecurringService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private deviceService: DeviceDetectorService,
    protected storageService: StorageService,
  ) { }

  async ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
    this.storageService.removeItem('fromRecurring');
    this.recurringService.$recurring.subscribe((value) => {
      this.recurring = value;
    });

    this.updateFormValues();
    await this.initSlots();
    await this.setSlots(this.recurring.pickupDay);
    
  }
  
  updateFormValues() {
    this.recurringForm.controls.type.setValue(this.recurring.type ? this.recurring.type : ReccurenceTypeEnum.OnceAWeek);
    this.recurringForm.controls.pickupDay.setValue(this.recurring.pickupDay);
    this.recurringForm.controls.deliveryDay.setValue(
      this.recurring.deliveryDay
    );
    this.recurringForm.controls.pickupSlot.setValue(this.recurring.pickupSlot);
    this.recurringForm.controls.deliverySlot.setValue(
      this.recurring.deliverySlot
    );
  }

  getFormControlValue(fieldName: string) {
    return this.recurringForm.get(fieldName).value;
  }

  async onFormSubmit() {

    await this.commandService.executeCommand(CommandEnum.GetCustomerCard)
    .then(async response => {
      let card = response['ReturnObject']['card'];
      if (!card || card && card.Actif == '0') {
        this.dialog.open(CheckCardModalComponent);
      } else {
        this.recurringService.setState(ReccurenceStateEnum.Loading);
        console.log(this.recurring)
        this.recurring.type = this.getFormControlValue('type');
        this.recurring.pickupDay = this.getFormControlValue('pickupDay');
        this.recurring.deliveryDay = this.getFormControlValue('deliveryDay');
        this.recurring.pickupSlot = this.getFormControlValue('pickupSlot');
        this.recurring.deliverySlot = this.getFormControlValue('deliverySlot');
        // init data
        this.customerDetail.ReturnObject.infoCustomer.DeliveryMon = '';
        this.customerDetail.ReturnObject.infoCustomer.DeliveryTu = '';
        this.customerDetail.ReturnObject.infoCustomer.DeliveryWed = '';
        this.customerDetail.ReturnObject.infoCustomer.DeliveryTh = '';
        this.customerDetail.ReturnObject.infoCustomer.DeliveryFri = '';
        this.customerDetail.ReturnObject.infoCustomer.DeliverySat = '';

        switch (this.recurring.pickupDay) {
          case 1:
            this.customerDetail.ReturnObject.infoCustomer.DeliveryMon =
              this.recurring.pickupSlot;
            break;
          case 2:
            this.customerDetail.ReturnObject.infoCustomer.DeliveryTu =
              this.recurring.pickupSlot;
            break;
          case 3:
            this.customerDetail.ReturnObject.infoCustomer.DeliveryWed =
              this.recurring.pickupSlot;
            break;
          case 4:
            this.customerDetail.ReturnObject.infoCustomer.DeliveryTh =
              this.recurring.pickupSlot;
            break;
          case 5:
            this.customerDetail.ReturnObject.infoCustomer.DeliveryFri =
              this.recurring.pickupSlot;
            break;
          case 6:
            this.customerDetail.ReturnObject.infoCustomer.DeliverySat =
              this.recurring.pickupSlot;
            break;
        }

        if (this.recurring.deliveryDay) {
          switch (this.recurring.deliveryDay) {
            case 1:
              this.customerDetail.ReturnObject.infoCustomer.DeliveryMon =
                this.recurring.deliverySlot;
              break;
            case 2:
              this.customerDetail.ReturnObject.infoCustomer.DeliveryTu =
                this.recurring.deliverySlot;
              break;
            case 3:
              this.customerDetail.ReturnObject.infoCustomer.DeliveryWed =
                this.recurring.deliverySlot;
              break;
            case 4:
              this.customerDetail.ReturnObject.infoCustomer.DeliveryTh =
                this.recurring.deliverySlot;
              break;
            case 5:
              this.customerDetail.ReturnObject.infoCustomer.DeliveryFri =
                this.recurring.deliverySlot;
              break;
            case 6:
              this.customerDetail.ReturnObject.infoCustomer.DeliverySat =
                this.recurring.deliverySlot;
              break;
          }
        }

        await this.commandService
          .executeUpdate(CommandEnum.SaveCustomer, this.customerDetail.ReturnObject)
          .then((response) => {})
          .catch((error) => {
            this.commandService.handleCommandTokenError(error);
        });
        this.commandService
          .executeCommand(CommandEnum.OrderListCommand)
          .then((data) => {
            let array_recurring = [];
            let time;
            data.ReturnObject.forEach(function (item) {
              if (item.Status == 'RECURRING') {
                time = new Date(item.PickupDate.toString());
                array_recurring.push(time.getTime());
              }
            });

            let test_encor = JSON.stringify(
              array_recurring.sort(function (a, b) {
                return a - b;
              })
            );

            localStorage.setItem('array_recurring', test_encor);
            this.recurringService.setState(ReccurenceStateEnum.View);
        });
      }
    })
  }

  async initSlots() {
    this.customerDetail = await this.getPostCode();
    let postCode = this.customerDetail.ReturnObject.address[0].postcode;
    await this.postCodeService
      .slotsByDay(postCode)
      .then((response) => {
        this.slotsByDay = Object.values(response);
        if (this.slotsByDay.length === 6) {
          this.slotsByDay.push(null);
        }
      })
      .catch((error) => {
        this.commandService.handleCommandTokenError(error);
      });
  }
  getPostCode() {
    return this.commandService.executeCommand(CommandEnum.CustomerDetail);
  }

  setSlots(day: Delivery) {
    if (!this.hasSlots(day)) {
      return;
    }
    //this.checked = true;

    switch (this.getFormControlValue('type')) {
      case ReccurenceTypeEnum.OnceAWeek:
        this.pickupSlots = this.slotsByDay[day];
        this.recurringForm.controls.pickupDay.setValue(day);
        this.recurringForm.controls.pickupSlot.setValue(
          this.recurring.pickupSlot ?  this.recurring.pickupSlot : this.pickupSlots[0].value
        );
        this.recurringForm.controls.deliveryDay.setValue(DayOfWeekEnum.Sunday);
        this.recurringForm.controls.deliverySlot.setValue('');
        break;



      case ReccurenceTypeEnum.TwiceAWeek:
        const firstDay = day <= DayOfWeekEnum.Wednesday ? day : day - 3;
        const secondDay = day > DayOfWeekEnum.Wednesday ? day : day + 3;
        this.pickupSlots = this.slotsByDay[firstDay];
        this.deliverySlots = this.slotsByDay[secondDay];
        this.recurringForm.controls.pickupDay.setValue(firstDay);
        this.recurringForm.controls.deliveryDay.setValue(secondDay);
        this.recurringForm.controls.pickupSlot.setValue(
          this.recurring.pickupSlot ?  this.recurring.pickupSlot : this.pickupSlots[0].value
        );
        this.recurringForm.controls.deliverySlot.setValue(
          this.recurring.deliverySlot ? this.recurring.deliverySlot :this.deliverySlots[0].value
        );
        break;
    }
  }

  getCssClasses(day: Delivery) {
    if (!this.hasSlots(day)) {
      return 'disabled';
    }
    if (this.isSelected(day)) {
      return 'selected';
    }
    return '';
  }

  hasSlots = (day: Delivery) => {
    switch (this.getFormControlValue('type')) {
      case ReccurenceTypeEnum.OnceAWeek:
        if (this.slotsByDay[day] !== null) {
          return true;
        }
        return false;
      case ReccurenceTypeEnum.TwiceAWeek:
        const firstDay = day <= Delivery.WedDelivery ? day : day - 3;
        const secondDay = day > Delivery.WedDelivery ? day : day + 3;
        if (
          this.slotsByDay[firstDay] !== null &&
          this.slotsByDay[secondDay] !== null
        ) {
          return true;
        }
        return false;
    }
  };

  isSelected = (day: Delivery) => {
    switch (this.getFormControlValue('type')) {
      case ReccurenceTypeEnum.OnceAWeek:
        if (day === this.getFormControlValue('pickupDay')) {
          return 'selected';
        }
        return false;

      case ReccurenceTypeEnum.TwiceAWeek:

        if (
          day <= Delivery.WedDelivery &&
          day === this.getFormControlValue('pickupDay')
        ) {
          return true;
        }
        if (
          day > Delivery.WedDelivery &&
          day === this.getFormControlValue('deliveryDay')
        ) {
          return true;
        }
        return false;
    }
  };

  getLabel(day: Delivery) {
    const label = Delivery[day].charAt(0);
    return label;
  }

  resetData() {
    this.recurringForm.controls.pickupDay.setValue(DayOfWeekEnum.Sunday);
    this.recurringForm.controls.deliveryDay.setValue(DayOfWeekEnum.Sunday);
    this.recurringForm.controls.pickupSlot.setValue([]);
    this.recurringForm.controls.deliverySlot.setValue([]);
    this.pickupSlots = [];
    this.deliverySlots = [];
  }
}
