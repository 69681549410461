import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { DonateBase } from '../../base/donate.base';

@Component({
  selector: 'booking-donate-desktop',
  templateUrl: './donate.component.html',
  styleUrls: ['./donate.component.scss'],
})
export class DonateDesktopComponent extends DonateBase implements OnInit {
  isShown: boolean;

  @Input() bagsCount: number;
  @Output() onBagsCountSelected: EventEmitter<number> = new EventEmitter<number>();

  constructor() {
    super();
  }
  
  ngOnInit(): void {
    this.isShown = false;
  }

  toggleShown(): void {
    this.isShown = !this.isShown;
  }

  emitBags(): void {
    this.onBagsCountSelected.emit(this.bagsCount);
  }
}
