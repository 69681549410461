<booking-progress-bar-mobile [step]=step></booking-progress-bar-mobile>

<div *ngIf="!hasAllData" class="spinner-wrapper">
    <shared-spinner></shared-spinner>
</div>

<div *ngIf="hasAllData" class="content summary-mobile animation-fade-in">
    <!-- <shared-back-button></shared-back-button>
    <booking-header title="summary" imgPath="assets/icons/summary.svg"></booking-header> -->
    <h3 class="review">Review & confirm</h3>
    <ul class="summary">

        <!-- /////// delevery details/////////////////////////// -->
        <div class="details-delevery">
            <h4>Delivery details</h4>
            <li *ngIf="pickupSlot && deliverySlot" class="slots" id="slots">
                <div class="Delivery-details">
                    <div class="pickup">
                        <div class="heading">
                            <p>Pick up 
                                <a routerLink="/booking/slots" [queryParams]="{'redirect': '/booking/summary'}" class="btn href green before-edit" [id]="EditPickup"><span>Edit</span></a>                            
                            </p>   
                        </div>
                        <div class="info">
                            <p class="pickup-date">{{pickupSlot?.date | toMoment | dateFormat:dateFormat}}</p>
                            <p class="pickup-hour">{{pickupSlot?.from | hourStartFormat}}-{{pickupSlot?.to | hourFormatPms}}</p>
                        </div>
                    </div>
                    <div class="delevery">
                        <div class="heading">
                            <p>Delivery 
                                <a routerLink="/booking/slots" [queryParams]="{'redirect': '/booking/summary'}" class="btn href green before-edit" [id]="EditDelivery"><span>Edit</span></a>                            
                            </p>
                        </div>
                        <div class="info">
                            <p class="delivery-date">{{deliverySlot?.date | toMoment | dateFormat:dateFormat}}</p>
                            <p class="delivery-hour">{{deliverySlot?.from | hourStartFormat}}-{{deliverySlot?.to | hourFormatPms}}</p>
                        </div>
                    </div>
                </div>
            </li>

            <li *ngIf="address" class="address" id="address">
                <div class="heading">
                    <p>Address  
                        <a routerLink="/booking/address" [queryParams]="{'redirect': '/booking/summary'}" class="btn href green before-edit" [id]="EditAddress"><span>Edit</span></a>                    
                    </p>
                </div>
                <div class="info">
                    <p class="address-line-1">{{address.addressLine1}}</p>
                    <p class="address-line-2">{{address.addressLine2}}</p>
                    <p class="address-line-2">{{address.town}} {{address.postcode}}</p>
                </div>
            </li>

            <li class="content">
                <div class="heading">
                    <h2>Delivery instructions  
                        <a routerLink="/booking/instructions" [queryParams]="{'redirect': '/booking/summary'}" class="btn href green before-edit" [id]="EditInstructions">                            
                            <p>Edit</p>
                        </a>
                    </h2>
                    <a (click)="toggleShown()" class="icon arrow-down" id="toggle-button"></a>
                </div>
                <div class="more" class="more animation-slide-down" [ngClass]="{'shown': isShown}">
                    <div *ngIf="hasInstructions" class="instruction">
                        <div class="heading" *ngIf="instructions.other !== ''">
                            <p class="name">instructions</p>
                        </div>
                        <div class="left" *ngIf="instructions.other !== ''">
                            <p class="item-name black">{{instructions.other}}</p>
                        </div>
                        <div class="heading">
                            <p class="item-name">Preferred contact</p>
                        </div>
                        <div class="left">
                            <p class="name">Pick up and deliver to</p>
                            <span>{{instructions.deliverToName}}</span>
                        </div>
                        <div class="left" *ngIf="instructions.phoneNumber !== ''">
                            <p class="name">Their phone number</p>
                            <span class="item-name">{{instructions.countryCode}} {{instructions.phoneNumber}}</span>
                        </div>
                    </div>
                    <div *ngIf="!hasInstructions" class="instruction">
                        <p>You haven't added any delivery instructions.</p>

                    </div>
                </div>
            </li>
            <hr>
        </div>
        <!-- /////////////////////////////////////////////////// -->

        <div class="details-order">
            <h4>Order details</h4>
            <p>All items will be carefully examined by our cleaning experts, but you can let us know about any specific instructions you may have.</p>
            <booking-summary-instructions [services]="services" id="summary-instructions"></booking-summary-instructions>
        </div>
        <hr>

        <div class="details-payment" *ngIf="payment">
            <h4>Payment details</h4>
            <p>You won’t be charged now, payment will be collected once we have examined your items in our Atelier.</p>
            <user-payment-card-view></user-payment-card-view>
            <!-- <div class="card-secure">
                <img src="assets/icons/lock.svg" height="13" width="17">
                <p>Your card details are securely encrypted and stored.</p>

            </div> -->
            <!-- <div *ngIf="!Voucher_code ;else tempv" class="promo-code">
                <div *ngIf="!addVoucher ;else temp" class="promo-code">
                    <p (click)="onAddvoucher()">+</p>
                    <span (click)="onAddvoucher()">Add promo code</span>
                </div>
                <ng-template class="booking-voucher" #temp>
                    <booking-voucher-field class="booking-voucher-field"></booking-voucher-field>
                </ng-template>
            </div>
            <ng-template class="booking-voucher" #tempv> -->
            <booking-voucher-field class="booking-voucher-field" [voucher]="Voucher"></booking-voucher-field>
            <!-- </ng-template> -->

        </div>

    </ul>
    <hr>
    <button *ngIf="_isIos" (click)="confirmBooking()" type="submit" class="btn primary bottom-fixed with-arrow ios btn-resize" [id]="confirmBookingBtn">Confirm booking</button>
    <button *ngIf="!_isIos" (click)="confirmBooking()" type="submit" class="btn primary bottom-fixed with-arrow btn-resize"
    [id]="confirmBookingBtn">Confirm booking</button>
</div>
