import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FormStatusEnum } from 'src/app/models/common/enums/form-status.enum';
import { CommandService } from 'src/app/services/common/command.service';
import { AuthService } from 'src/app/services/user/auth.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  resetForm: FormGroup;
  error = null;
  message = null;
  isWorking = false;
  isValid = false;
  isDone = false;

  constructor(
    protected router: Router,
    private http: HttpClient,
    private commandService: CommandService,
    private auth: AuthService,
    public dialogRef: MatDialogRef<ForgotPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.resetForm = new FormGroup({
      username: new FormControl('', [Validators.email, Validators.required])
    });

    this.resetForm.statusChanges.subscribe((status) => {
      this.error = null;
      this.isValid = status === FormStatusEnum.Valid;
    });

    this.resetForm.patchValue({
      username: this.data.email
    });
  }

  get usernameForm() {
    return this.resetForm.get('username');
  }

  reset() {
    this.isWorking = true;
    this.error = null;
    this.message = null;
    this.auth.forgotPassword(this.usernameForm.value)
      .then((res) => {
        if(res["message"] == "success"){
          this.message = 'Please click on the link in your email to reset your password';
          this.isDone = true;
        } else if (res["message"] == "redirection") {
          this.dialogRef.close();
          this.router.navigate(['/order-store'],{ queryParams: { email: this.usernameForm.value } });
        }
      })
      .catch(err => {
        this.error = err.error.message;
      })
      .finally(() => {
        this.isWorking = false;
      }); 
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
