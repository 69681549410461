<shared-header></shared-header>

<div *ngIf="isLoading" class="spinner-wrapper">
    <shared-spinner></shared-spinner>
</div>

<div *ngIf="!isLoading" class="content animation-fade-in" id="content">
    <div class="heading" [ngClass]="{'banner-below': hasPendingOrders || hasPendingSettings}">
        <h1>{{greetings | async}}</h1>
        <p>What can we do for you today?</p>
    </div>

    <user-authorisations-banner *ngIf="hasPendingOrders" [pendingOrders]="pendingOrders"></user-authorisations-banner>


    <user-percentage-banner *ngIf="!showBannerRateUs && hasPendingSettings" [percentage]="percentage"></user-percentage-banner>
    <app-promo-banner *ngIf="!showBannerRateUs && !hasPendingSettings"></app-promo-banner>
    <app-rate-us-banner *ngIf="showBannerRateUs"></app-rate-us-banner>
    <user-cancelled-order-banner *ngIf="hasCancelledOrder"  [type_stop]="type_stop"></user-cancelled-order-banner>

    <nav class="navigation">
        <a [routerLink]="orderListRoute" class="btn primary left-rounded with-arrow with-icon orders white" [id]="menuMobileYourOrders">Your orders</a>
        <button *ngIf="!spinner" (click)="redirectToRecurring()" class="btn primary left-rounded with-arrow with-icon reccuring-booking white" id="recurring-btn">Recurring booking </button>
        <button *ngIf="spinner"  (click)="redirectToRecurring()" class="btn primary  with-icon white reccuring-booking" id="enter-recurring-btn">Recurring booking &nbsp; <shared-spinner color="green"></shared-spinner></button>
        <button (click)="redirectToBooking()" class="btn primary left-rounded with-arrow with-icon schedule-booking" [id]="menuMobileNewBooking">Schedule a booking</button>
    </nav>
</div>

<!-- <pwa-popup></pwa-popup> -->
<app-update-release *ngIF="showUpdateRelease"></app-update-release>