<div class="content services-desktop animation-fade-in">
    <div class="grey-background"></div>

    <div *ngIf="isLoading" class="spinner-wrapper">
        <shared-spinner></shared-spinner>
    </div>

    <div *ngIf="!isLoading" class="wrapper animation-fade-in">
        <div class="heading">
            <h1>Any instructions?</h1>
            <shared-back-button [customPath]="backRoute"></shared-back-button>
        </div>

        <ul class="services-cards-wrapper">
            <li *ngFor="let service of services" class="box" [ngClass]="{'has-count' : getCount(service) > 0}">
                <div class="text">
                    <h2>{{service.name}}</h2>
                    <span class="price">{{service.parsedValue.priceDescription}}</span>
                    <span>{{service.parsedValue.description}}</span>
                </div>
                <div class="media">
                    <img src="assets/icons/{{service.parsedValue.css}}.svg" alt="service">
                </div>
                <div *ngIf="getCount(service) === 0" class="actions empty">
                    <a [routerLink]="['/booking/services/service']" [queryParams]="{id: service.id}" queryParamsHandling="merge" [id]="AddInst" class="btn secondary secondary-neutral">Add instructions</a>
                </div>
                <div *ngIf="getCount(service) > 0" class="actions">
                    <div class="edit">
                        <p>{{getText(service)}} <a [routerLink]="['/booking/services/service']" [queryParams]="{id: service.id}" queryParamsHandling="merge" class="btn href green" [id]="EditInst">Edit</a></p>
                    </div>
                </div>
            </li>
            <li class="box box-donate" [ngClass]="{'has-bags' : address.donateBags > 0}">
                <div class="donate">
                    <div class="description">
                        <div class="text">
                            <p>Donate clothes </p>
                            <p>to charity</p>
                        </div>
                        <div class="media">
                            <img src="assets/icons/donate-blue.svg">
                        </div>

                    </div>
                    <div *ngIf="donateBags=== 0" class="actions empty">
                        <a [routerLink]="['/booking/address/donate']" [queryParams]="{bagsCount: donateBags}" [id]="AddDonation" class="btn secondary secondary-neutral donate-button">Add donation bag</a>
                    </div>
                    <div *ngIf="donateBags > 0" class="actions">
                        <div class="edit">
                            <p>{{address.donateBags}} donation bag(s) added</p>
                            <a [routerLink]="['/booking/address/donate']" [queryParams]="{bagsCount: donateBags}" class="btn href donate-blue">Edit</a>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <div class="btn-wrapper">
            <button (click)="navigateToNext()" [id]="saveInstNext"  [disabled]="isSaving"  class="btn primary"  [ngClass]="{'with-arrow': !isSaving}"id="save-services-instructions-btn">
                <ng-container *ngIf="!isSaving" >Next</ng-container>
                <shared-spinner *ngIf="isSaving" color="white"></shared-spinner>
            </button>
        </div>
    </div>
</div>
