<div class="content">
    <div class="heading">
      <h2>Delivery instructions</h2>
      <a (click)="toggleShown()" class="icon arrow-down" id="toggle-button"></a>
    </div>
  
    <div class="more" class="more animation-slide-down" [ngClass]="{'shown': isShown}">
      <a routerLink="/booking/services" [queryParams]="{'redirect': '/booking/summary'}" class="btn href green" id="edit-btn"><p>Edit</p></a>
  
      <ul *ngIf="instructions" class="services">
        <!-- <li *ngFor="let service of services" class="service">
          <div class="heading">
            <p class="name">{{service.parentItem.longName}}</p>
            <h2 class="count">{{service.itemsCount}}</h2>
          </div>
          <div class="subheading">
            <p class="left grey-3">Item</p>
            <p class="right grey-3">Instructions</p>
          </div>
  
          <ul *ngIf="service.items.length > 0" class="items" id="items">
            <li *ngFor="let item of service.items" class="item">
              <div class="left">
                <p class="item-name black">{{item.selected.longName}}</p>
                <span *ngIf="item.brand" class="item-text grey-1">{{item.brand.name}}</span>
              </div>
              <div class="right">
                <ul *ngIf="item.instructions.length > 0" class="instrustions" id="instructions">
                  <li *ngFor="let instruction of item.instructions" class="instruction">
                    <p class="instruction-name grey-1">{{instruction.name}}</p>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </li> -->
      </ul>
    </div>
  </div>
  