<mat-menu #childMenu="matMenu" [overlapTrigger]="false">
    <span *ngFor="let child of items">
        <!-- Handle branch node menu items -->
        <span *ngIf="child.hasChildren">
            <button mat-menu-item class="item" [matMenuTriggerFor]="menu.childMenu">
                <span>{{child.name}}</span>
            </button>
            <booking-menu-item #menu [itemId]="child.id" [addItemFunc]="addItemFunc"></booking-menu-item>
        </span>
        <!-- Handle leaf node menu items -->
        <span *ngIf="!child.hasChildren">
            <button mat-menu-item class="item" (click)="selectItem(child)">
                <span>{{child.name}}</span>
            </button>
        </span>
    </span>
</mat-menu>