import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'user-authentications-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss'],
})
export class ReadMoreComponent implements OnInit {
  isShown: boolean;
  
  @Input() text: string;

  constructor() { }

  ngOnInit(): void {
    this.isShown = false;
  }

  toggleShown() {
    this.isShown = !this.isShown;
  }
}
