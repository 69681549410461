export enum CommandEnum {
  RequestPickup = 'RequestPickup',
  Login = 'Login',
  CustomerDetail = 'CustomerDetail',
  SaveCustomer = 'SaveCustomer',
  GetCustomerPreferences = 'GetCustomerPreferences',
  SaveCustomerPreference = 'SaveCustomerPreference',
  GetDeliveryPreference ='GetDeliveryPreference',
  SaveDeliveryPreference = 'SaveDeliveryPreference',
  GetSlotDayPostcode = 'GetSlotDayPostcode',
  OrderCreateCommand = 'OrderCreateCommand',
  OrderSlotsReadCommand = 'OrderSlotsReadCommand',
  OrderSlotsSaveCommand = 'OrderSlotsSaveCommand',
  OrderAddressReadCommand = 'OrderAddressReadCommand',
  OrderAddressSaveCommand = 'OrderAddressSaveCommand',
  OrderAddressInstructionsSaveCommand = 'OrderAddressInstructionsSaveCommand',
  OrderAddressInstructionsReadCommand = 'OrderAddressInstructionsReadCommand',
  OrderPaymentSaveCommand = 'OrderPaymentSaveCommand',
  OrderServiceSaveCommand = 'OrderServiceSaveCommand',
  OrderServiceReadCommand = 'OrderServiceReadCommand',
  OrderSlotsEventFileReadCommand = 'OrderSlotsEventFileReadCommand',
  OrderSummaryReadCommand = 'OrderSummaryReadCommand',
  OrderSummaryReadByIdCommand = 'GetOrderDetail',
  OrderConnectCommand = "OrderConnectCommand",
  OrderConfirmCommand = "OrderConfirmCommand",
  voucherCodeVerify = 'voucherCodeVerify',
  GetVouchersCommand = 'GetVouchersCommand',
  OrderConfirmEmailSendCommand = 'OrderConfirmEmailSendCommand',
  ReferralNotificationEmailSendCommand = 'ReferralNotificationEmailSendCommand',
  OrderCopyCommand = 'OrderCopyCommand',
  OrderDeliveryConfirmCommand = 'OrderDeliveryConfirmCommand',
  OrderListCommand = "GetOrderListDetail", //OrderListCommand
  OrderListPastCommand = 'GetOrderList',//OrderListPastCommand
  SendFeedbackEmailCommand = 'SendFeedbackEmailCommand',
  ResetPasswordEmailSendCommand = 'ResetPasswordEmailSendCommand',
  OrderStatisticsCommand = 'GetOrderList',//OrderStatisticsCommand
  DeletePickup = 'DeletePickup',//OrderCancelCommand
  DeleteDelivery = 'DeleteDelivery',//OrderCancelCommand
  OrderDetailsCommand = 'GetOrderDetail',//OrderDetailsCommand
  PostCodeVerifyCommand = 'PostCodeVerifyCommand',
  RecurringReadCommand = 'RecurringReadCommand',
  RecurringWriteCommand = 'RecurringWriteCommand',
  SlotsByDayCommand = 'SlotsByDayCommand',
  SlotsByPeriodCommand = 'SlotsByPeriodCommand',
  SlotFirstAvailableCommand = 'SlotFirstAvailableCommand',
  InterestedCustomerWriteCommand = 'InterestedCustomerWriteCommand',
  DeliveryOrderErrorEmailSendCommand = 'DeliveryOrderErrorEmailSendCommand',
  HasAuthorizationsCommand = 'HasAuthorizationsCommand',
  UserShortDetailsCommand = 'CustomerDetail',// UserShortDetailsCommand
  EditDelivery = 'EditDelivery',
  GetHolidays = 'GetHolidays',
  GetDeliveryByOrder = 'GetDeliveryByOrder',
  GetBanner = 'GetBanner',
  Logoff = 'Logoff',
  SetDeliveryOnly = 'SetDeliveryOnly',
  saveLogConfirm = 'saveLogConfirm',
  AddCustomerCard = 'AddCustomerCard',
  ConfirmCard = 'ConfirmCard',
  RemoveCustomerCard = 'RemoveCustomerCard',
  GetCustomerCard = 'GetCustomerCard',
  GetLastDriverCancelledOrderForCustomer = 'GetLastDriverCancelledOrderForCustomer'
}
