import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import { AuthService } from '../user/auth.service';
import { Routes } from 'src/app/constants/routes.constant';

@Injectable()
export class IsSignedInGuardService implements CanActivate {
  secretToken: string;

  constructor(
    public authService: AuthService,
    public router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {

    if (this.authService.isLoggedIn()) {
      this.router.navigate(['/']);
      return false;
    }

    return true;
  }
}
