import { BehaviorSubject } from 'rxjs';
import { AuthService } from './../../../services/user/auth.service';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';

import { Instructions } from 'src/app/models/booking/instructions.model';
import { FormStatusEnum } from 'src/app/models/common/enums/form-status.enum';
import { Constant } from 'src/app/models/common/constant.model';
import { ParticleService } from 'src/app/services/particle/particle.service';
import { ParticleTypeEnum } from 'src/app/models/particle/enums/particle-type.enum';
import { CommandService } from 'src/app/services/common/command.service';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { BookingAddressData } from 'src/app/models/booking/address-data.model';
import { StorageService } from 'src/app/services/common/storage.service';
import { AccountSettingService } from 'src/app/services/user/account-setting.service';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class InstructionsBase {
  public hasAllData: boolean;

  public hasInstructions: boolean = false;

  public instructions: Instructions;
  public instructionsForm: FormGroup;
  public isValid: boolean;
  isAuthenticated: boolean;
  public deliverToOptionSelected: string;
  public deliverToOptions: Constant[] = [];
  address: BookingAddressData;
  postcodeFromStorage: any;
  orderMobileEditDeliveryInstSave: string

  constructor(
    public accountSettingService: AccountSettingService,
    protected particleService: ParticleService,
    protected commandService: CommandService,
    protected storageService: StorageService,
    protected router: Router,
    public formBuilder: FormBuilder,
    protected authService: AuthService,
  ) {}

  async ngOnInit() {

    let first = localStorage.getItem('first-journey');
    let smsMarketing = localStorage.getItem('smsMarketing');
    this.isAuthenticated = this.authService.isLoggedIn();
    this.instructionsForm = this.formBuilder.group({
      deliverToId: ['', [Validators.required]],
      countryCode: ['', [Validators.pattern(`^(\\+\\d{1,4})$`)]],
      phoneNumber: ['', [Validators.pattern('^ *(?:[0-9] *){9,12}$')]],
      other: ['', [Validators.maxLength(150)]],
    });
    this.instructionsForm.statusChanges.subscribe((status) => {
      this.isValid = status === FormStatusEnum.Valid;
    });
    await this.initDeliverToOptions();
    this.address = this.storageService.getObject('adresse');
    let id_order = localStorage.getItem('orderid');
    if (this.address === undefined && id_order) {
      var param = {
        infoOrder_id : +id_order
      }     
      var address = await this.commandService.executeCommand(CommandEnum.GetDeliveryByOrder, param);
      let a = new BookingAddressData(
        address.ReturnObject.DeliveryAddress1,
        address.ReturnObject.DeliveryAddress2,
        address.ReturnObject.DeliveryTown,
        address.ReturnObject.DeliveryPostcode,
        address.instructions,
        0
      );
      this.storageService.savePostcode(address?.ReturnObject.DeliveryPostcode);
      this.storageService.saveObject('adresse', a);
      this.address = this.storageService.getObject('adresse');
    } else {
      console.log('');
    }

    if (this.address.instructions) {
      this.instructions = this.address.instructions;
      if (this.instructions.other === undefined) {
        this.instructions.other = '';
      }
      if (this.instructions.deliverToId === undefined) {
        this.instructions.deliverToId = this.deliverToOptions.find(
          (option) => option.name === this.instructions.deliverToName
        ).name;
      }
      this.hasInstructions = !(
        this.instructions.deliverToId === '' &&
        this.instructions.deliverToName === '' &&
        this.instructions.countryCode === '' &&
        this.instructions.phoneNumber === '' &&
        this.instructions.other === ''
      );
      this.initForm();
    } else {
      if (this.isAuthenticated) {
        await this.accountSettingService.GetDeliveryPreferences().subscribe(
          (response: any) => {
            if (response.returnObject) {
              if (response.returnObject.deliverToName.length > 0) {
                this.instructions = response.returnObject;
                this.instructions.deliverToName =
                  response.returnObject.typeDelivery;
                this.instructions.phoneNumber =
                  response.returnObject.phoneNumber;
                this.instructions.other =
                  response.returnObject.otherInstruction;
                this.instructions.countryCode =
                  response.returnObject.codeCountry;
                this.instructions.deliverToId = this.deliverToOptions.find(
                  (option) => option.name === this.instructions.deliverToName
                ).id;
                this.hasInstructions = true;
                this.initForm();
              }
            } else {
              this.instructions = new Instructions();
              this.hasInstructions = false;

              this.setDeliverToValue();
              this.isValid =
                this.instructionsForm.status === FormStatusEnum.Valid;
              this.instructionsForm.statusChanges.subscribe((status) => {
                this.isValid = status === FormStatusEnum.Valid;
              });
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } else {
        this.instructions = new Instructions();
        this.hasInstructions = false;

        this.setDeliverToValue();

        this.isValid = this.instructionsForm.status === FormStatusEnum.Valid;
        this.instructionsForm.statusChanges.subscribe((status) => {
          this.isValid = status === FormStatusEnum.Valid;
        });
      }
    }
    
    if(first){
      if (this.router.url === '/booking/address'){
        this.orderMobileEditDeliveryInstSave="order-mobile-editDeliveryInst-save";
      }else if(this.router.url === '/booking/instructions?redirect=%2Fbooking%2Faddress'){
        this.orderMobileEditDeliveryInstSave="first-journey-booking-adress-save";
      }else{
        this.orderMobileEditDeliveryInstSave="first-journey-booking-redirectToSummary-editInstructions-save";
      }
      
    }else if( smsMarketing){
      this.orderMobileEditDeliveryInstSave ="sms-marketing-booking-redirectToSummary-editInstructions-save";
    }else if (this.router.url === '/booking/instructions?redirect=%2Forder%2Fedit%2Ffinilize%2F124148%2FPick%20up%20%26%20Delivery%20details%2F5'){
      this.orderMobileEditDeliveryInstSave ="order-mobile-editDeliveryInst-save";
    }else{
      this.orderMobileEditDeliveryInstSave ="returning-user-booking-redirectToSummary-editInstructions-save";
    }
    
  }

  initForm() {
    this.instructionsForm = new FormGroup({
      deliverToId: new FormControl(this.address.instructions.deliverToId, [
        Validators.required,
      ]),
      countryCode: new FormControl(this.address.instructions.countryCode, [
        Validators.pattern(`^(\\+\\d{1,4})$`),
      ]),
      phoneNumber: new FormControl(this.address.instructions.phoneNumber, [
        Validators.pattern('^ *(?:[0-9] *){9,12}$'),
      ]),
      other: new FormControl(this.address.instructions.other, [
        Validators.maxLength(150),
      ]),
    });
    this.setDeliverToValue();
    this.isValid = this.instructionsForm.status === FormStatusEnum.Valid;
    this.instructionsForm.statusChanges.subscribe((status) => {
      this.isValid = status === FormStatusEnum.Valid;
    });
    
  }

  async initDeliverToOptions() {
    const response = await this.particleService
      .getParticle(ParticleTypeEnum.DeliverTo)
      .toPromise();
    this.deliverToOptions = response;
    this.getFormField('deliverToId').setValue(this.deliverToOptions[3].id);
    this.hasAllData = true;
  }

  async setDeliverToValue() {
    if (this.hasInstructions) {
      this.deliverToOptionSelected = this.instructions.deliverToId;
    } else {
      this.deliverToOptionSelected = this.deliverToOptions[3].id;
    }
    this.getFormField('deliverToId').setValue(this.deliverToOptionSelected);
  }

  getFormField(fieldName: string) {
    return this.instructionsForm.get(fieldName.toString());
  }

  setCountryCodeValue(code: string) {
    const countryCodeField = this.getFormField('countryCode');
    countryCodeField.setValue(code);
    countryCodeField.markAsDirty();
  }
}