import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'booking-instructions-list-modal',
  templateUrl: './instructions-list-modal.component.html',
  styleUrls: ['./instructions-list-modal.component.scss']
})
export class InstructionsListModalComponent implements OnInit {

  private readonly matDialogRef: MatDialogRef<InstructionsListModalComponent>;

  private readonly triggerElementRef: ElementRef;

  instructions = [];

  selectedInstructionIds = [];

  constructor(matDialogRef: MatDialogRef<InstructionsListModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: { trigger: ElementRef, instructions, selectedInstructionIds},
    )
  {
    this.matDialogRef = matDialogRef;
    this.triggerElementRef = data.trigger;
    this.instructions = data.instructions;
    this.selectedInstructionIds = data.selectedInstructionIds;
  }

  ngOnInit() {
    const matDialogConfig: MatDialogConfig = new MatDialogConfig();

    const rect = this.triggerElementRef.nativeElement.getBoundingClientRect();

    matDialogConfig.position = { left: `${rect.left}px`, top: `${rect.top}px` };
    matDialogConfig.width = '265px';

    this.matDialogRef.updateSize(matDialogConfig.width);
    this.matDialogRef.updatePosition(matDialogConfig.position);
  }

  addInstruction(instruction) {
    if(this.selectedInstructionIds.includes(instruction.id)) {
      this.selectedInstructionIds.splice(this.selectedInstructionIds.indexOf(instruction.id), 1);
    } else {
      this.selectedInstructionIds.push(instruction.id);
    }
  }
}
