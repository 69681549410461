<div *ngIf = "isMobile" class="content">
  <shared-back-button></shared-back-button>
  <h3>Clothes donations<br> made easy</h3>

  <p class="description">Donate your unwanted items through BLANC and help us keep all textiles out of landfill! We will clean & mend everything free of charge, and direct all items to the right charity or recycling partners.</p>
  <p class="description">Please note we can only accept clothes & textile donations. Donations must be added to a regular pick up and delivery including cleaning or tailoring services - we unfortunately cannot proceed a booking for donations only.</p>

  <p class="highlighted">Number of bags :</p>

  <div class="count-wrapper">
    <button (click)="decreaseCount()" class="counter-btn decrease-btn" id="decrease-btn">
      <p>-</p>
    </button>
    <p class="count">{{bagsCount}}</p>
    <button (click)="increaseCount()" class="counter-btn increase-btn" id="increase-btn">
      <p>+</p>
    </button>
  </div>

  <button (click)= "AddDonateClothes(bagsCount)" class="btn secondary" [id]="AddDonate">Add</button>
</div>

<div *ngIf = "!isMobile" class="content">
  <div class="content service-desktop animation-fade-in">
    <div class="grey-background"></div>
    <div class="wrapper animation-fade-in">
        <div class="heading">
            <h3> Clothes donations made easy</h3>
            <shared-back-button></shared-back-button>
        </div>
        <div class="service">
            <div class="info-card">
                <p class="title">Clothes donations</p>
                <p class="title">made easy</p>
                <div class="media">
                    <img src="assets/icons/donate-blue.svg" alt="service">
                </div>
                <span class="description"></span>
            </div>
            <div class="instructions">
        
                <div>
                  <p class="description">Donate your unwanted items through BLANC and help us keep all textiles out of landfill! We will clean & mend everything free of charge, and direct all items to the right charity or recycling partners.</p>
                  <p class="description">Please note we can only accept clothes & textile donations. Donations must be added to a regular pick up and delivery including cleaning or tailoring services - we unfortunately cannot proceed a booking for donations only.</p>
                
                  <p class="highlighted">Number of bags :</p>
                
                  <div class="count-wrapper">
                    <button (click)="decreaseCount()" class="counter-btn decrease-btn" id="decrease-btn">
                      <p>-</p>
                    </button>
                    <p class="count">{{bagsCount}}</p>
                    <button (click)="increaseCount()" class="counter-btn increase-btn" id="increase-btn">
                      <p>+</p>
                    </button>
                  </div>
                
                  <button  (click)= "AddDonateClothes(bagsCount)" class="btn secondary" [id]="AddDonate">Add</button>
                </div>
            </div>
        </div>
    </div>
</div>

</div>