import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ParticleService } from 'src/app/services/particle/particle.service';
import { ParticleItem } from 'src/app/models/particle/particle-item.model';
import { Guid } from 'guid-typescript';
import { ParticleTypeEnum } from 'src/app/models/particle/enums/particle-type.enum';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { ServiceWrapperModel } from 'src/app/models/particle/service-wrapper.model';
import { ServiceViewTypeEnum } from 'src/app/models/booking/enums/service-view-type.enum';
import { CommandService } from 'src/app/services/common/command.service';
import { AuthService } from 'src/app/services/user/auth.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Routes } from 'src/app/constants/routes.constant';
import { CardService } from 'src/app/services/user/card.service';
import { PaymentMethodEnum } from 'src/app/models/booking/enums/payment-method.enum';
import { CardResponse } from 'src/app/models/user/card-response.model';
import { StorageService } from 'src/app/services/common/storage.service';

@Component({
  selector: 'booking-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  isMobile: boolean;

  services: ParticleItem[];
  models: Record<string, ServiceWrapperModel>;
  isLoading: boolean = true;
  backRoute: string = Routes.BOOKING_REGULAR.SUMMARY;
  card: CardResponse;
  isSaving = false;
  show_donate = false;
  constructor(
    protected particleService: ParticleService,
    protected commandService: CommandService,
    protected storageService: StorageService,
    protected router: Router,
    protected cardService: CardService,
    protected route: ActivatedRoute,
    protected authService: AuthService,
    protected deviceDetectorService: DeviceDetectorService
  ) { }

  async ngOnInit() {
    this.isMobile = this.deviceDetectorService.isMobile();
    this.fetchServices();
    await this.fetchSavedServices();
  }

  fetchServices(): void {
    this.particleService
      .getParticle(ParticleTypeEnum.Item, Guid.EMPTY)
      .subscribe(
        (response) => {
          this.services = response.map(x => new ParticleItem(x));
          this.isLoading = false;
          this.show_donate = true;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  async fetchSavedServices() {
    const garmentInstructions = this.storageService.getObject('GarmentInstructionsFull');
    if (garmentInstructions !== undefined) {
      this.models = garmentInstructions.models;
    }else {
      this.models = {};
    }
  }

  getCount(item: ParticleItem) {
    if (this.models) {
      const foundItem = this.models[item.id];
      if (foundItem) {
        return foundItem.itemsCount + foundItem.bagCount;
      }
    }
    return 0;
  }

  getText(item: ParticleItem) {
    const count = this.getCount(item);
    if (item.parsedValue.viewType === ServiceViewTypeEnum.Default) {
      return `${count} instruction(s) added`;
    }
    if (item.parsedValue.viewType === ServiceViewTypeEnum.WashAndFold) {
      return `${count} bag(s) added`;
    }
  }

  navigateToNext() {
    this.isSaving = true;
    if (!this.authService.isLoggedIn()) {
      const path = '/booking/payment';

      this.router.navigate(['/welcome'], { queryParams: { redirect: path } });
      return;
    }
    this.router.navigate([ Routes.BOOKING_REGULAR.SUMMARY]);
    return;
    // this.getRedirectUrl();
  }

  // getRedirectUrl() {
  //    this.cardService.getCard()
  //    .then(card => {
  //      this.card = card;
  //      if (this.card != null ){
  //        const paymentInfo = {
  //          paymentMethodInformation: this.card,
  //          method: PaymentMethodEnum.Card
  //        };

  //        this.commandService.executeCommand(CommandEnum.OrderPaymentSaveCommand, paymentInfo)
  //          .then(response => this.router.navigate([Routes.BOOKING_REGULAR.SUMMARY]))
  //          .catch(err => this.commandService.handleCommandTokenError(err));
  //      } else {
  //        this.router.navigate([Routes.BOOKING_REGULAR.PAYMENT]);
  //      }
  //    })
  //    .catch(err => {
  //     this.router.navigate([Routes.BOOKING_REGULAR.PAYMENT]);
  //   });
  // }
}
