<form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
    <label>Current password</label>
    <div class="password-wrapper">
        <mat-form-field floatPlaceholder="never" floatLabel="never">
            <input matInput formControlName="currentPassword" [type]="isCurrentPasswordVisible ? 'text' : 'password'"
                name="currentPassword">
            <mat-error *ngIf="getFieldFromForm('currentPassword').hasError('required')">
                Current Password is required
            </mat-error>
            <mat-error *ngIf="getFieldFromForm('currentPassword').hasError('minlength')">
                Current Password must be at least 6 symbols
            </mat-error>
            <mat-error *ngIf="getFieldFromForm('currentPassword').hasError('incorrect')">
                Current Password is incorrect
            </mat-error>
           
        </mat-form-field>
        <a (click)="showPassword('current')" [className]="isCurrentPasswordVisible ? 'shown' : '' "></a>
    </div>

    <label>New password</label>
    <div class="password-wrapper">
        <mat-form-field floatPlaceholder="never" floatLabel="never">
            <input matInput formControlName="newPassword" [type]="isNewPasswordVisible ? 'text' : 'password'"
                name="newPassword">
            <mat-error *ngIf="getFieldFromForm('newPassword').hasError('required')">
                New Password is required
            </mat-error>
            <mat-error *ngIf="getFieldFromForm('newPassword').hasError('minlength')">
                New Password must be at least 6 symbols
            </mat-error>
        </mat-form-field>
        <a (click)="showPassword('new')" [className]="isNewPasswordVisible ? 'shown' : '' "></a>
    </div>

    <label>Confirm new password</label>
    <div class="password-wrapper">
        <mat-form-field floatPlaceholder="never" floatLabel="never">
            <input matInput formControlName="confirmPassword" (focusout)="checkPasswordMatching()"
                [type]="isConfirmPasswordVisible ? 'text' : 'password'" name="confirmPassword">
            <mat-error *ngIf="getFieldFromForm('confirmPassword').hasError('required')">
                Confirm Password is required
            </mat-error>
            <mat-error *ngIf="getFieldFromForm('confirmPassword').hasError('same')">
                new password can't be old password
            </mat-error>
            <mat-error *ngIf="getFieldFromForm('confirmPassword').hasError('incorrect')">
                Doesn't match new password
            </mat-error>
            
        </mat-form-field>
        <a (click)="showPassword('confirm')" [className]="isConfirmPasswordVisible ? 'shown' : '' "></a>
    </div>

    <button class="btn primary {{btnCssClasses}}" type="submit" [disabled]="!changePasswordForm.valid">Save</button>
</form>