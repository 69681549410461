import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { HttpStatusCode } from 'src/app/models/common/enums/http-status-code';
import { CommandService } from 'src/app/services/common/command.service';
import { StorageService } from 'src/app/services/common/storage.service';
import { PostCodeService } from 'src/app/services/shared/postCode.service';
import { Routes } from 'src/app/constants/routes.constant';
import { NotSupportedComponent } from 'src/app/shared/not-supported/not-supported.component';
import { AuthService } from 'src/app/services/user/auth.service';
import { CookieService } from 'src/app/services/common/cookie.service';
import { AccountService } from 'src/app/services/user/account.service';
import { BehaviorSubject } from 'rxjs';
import { AuthStatus } from 'src/app/models/user/auth-status.model';
import { LogoButtonEnum } from 'src/app/models/shared/enums/logo-button.enum';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'user-landing-guest-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit, AfterViewInit {

  isMobile: boolean;
  errorShow: boolean;
  redirectUrl: string;
  postCodeList: any;
  logoTypeEnum: typeof LogoButtonEnum = LogoButtonEnum;
  postCodeForm = new FormGroup({
    postCode: new FormControl('', [Validators.required]),
  });
  FirstJourneyPostcode: string;
  constructor(
    private deviceService: DeviceDetectorService,
    private postCodeService: PostCodeService,
    private commandService: CommandService,
    private storage: StorageService,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private elementRef: ElementRef
  ) {
    
    let request = new XMLHttpRequest();
    request.open("GET", environment.postcodeList, false);

    request.send(null)
    var my_JSON_object = JSON.parse(request.responseText);
    this.postCodeList = my_JSON_object.postcodes;
  }
  ngAfterViewInit(): void {
    let input = <HTMLInputElement>document.getElementById('myInputMobile'); 
    
    if (!this.isMobile) {
      input = <HTMLInputElement>document.getElementById('myInput');
    }
    // if ( this.postCodeList &&  this.postCodeList.length>0) {
    //   if (this.isMobile){
    //     $('#myInputMobile').removeClass('error-postcode');
    //     } else {
    //       $('#myInput').removeClass('error-postcode'); 
    //     }
    //     document.getElementById('msgerror').style.display = 'none';
    //   }
    this.autocomplete(input, this.postCodeList);
  }

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
    this.FirstJourneyPostcode = "first-journey-enter-your-postcode";
  }
             
  get postcode() {
    return this.postCodeForm.get('postCode');
  }

  submitForm() {
    if (!this.isMobile) {
      this.postcode.setValue((document.getElementById('myInput') as HTMLInputElement).value)
    } else this.postcode.setValue((document.getElementById('myInputMobile') as HTMLInputElement).value) ;
    
    this.postCodeService.verifyPostCode(this.postcode.value)
      .then(res => {
        this.storage.savePostcode(res['postCode']);
        window.top.location.assign(Routes.BOOKING_REGULAR.PROVISION);
      })
      .catch(err => {
        if (err.status === HttpStatusCode.BadRequest) {
          this.dialog.open(NotSupportedComponent, {
            minWidth: '100vw',
            maxWidth: '100vw',
            maxHeight: '100vh',
            height: '100%',
            width: '100%',
            data: {
              postcode: this.postcode.value,
              isInIframe: true,
            },
          });
        }
      })
      .finally(() => {
        this.postcode.setValue('');
      });
  }
  goToSignIn() {
    if (this.redirectUrl) {
      this.router.navigate(['/sign-in'], {
        queryParams: { redirect: this.redirectUrl },
      });
    } else {
      this.router.navigate(['/sign-in']);
    }
  }
  autocomplete(inp, arr) {
    var currentFocus;
    /*execute a function when someone writes in the text field:*/
    inp.addEventListener("input", () => {
      var a, b, i, value = this.postcode.value;
      var val = value?.replace(/ /g,'');
      /*close any already open lists of autocompleted values*/
      closeAllLists();
      if (val.length < 2) {
        var btn = document.getElementById('submit-postcode') as HTMLButtonElement | null;
        if (btn != null) {
          btn.disabled = true;
          if (this.isMobile){
            $('#myInputMobile').removeClass('error-postcode');
            document.getElementById('msgerror').style.display = 'none';
          } else {
            $('#myInput').removeClass('error-postcode');
            document.getElementById('msgerror').style.display = 'none';
          }
          
        }
        return false;
      }
      currentFocus = -1;
      /*create a DIV element that will contain the items (values):*/
      a = document.createElement("DIV");
      if (this.isMobile){
        a.setAttribute("id", document.getElementById('myInputMobile').id + "autocomplete-list");
        a.setAttribute("class", "autocomplete-items");
        /*append the DIV element as a child of the autocomplete container:*/
        document.getElementById('myInputMobile').parentNode.appendChild(a);
      } else {
        a.setAttribute("id", document.getElementById('myInput').id + "autocomplete-list");
        a.setAttribute("class", "autocomplete-items");
        /*append the DIV element as a child of the autocomplete container:*/
        document.getElementById('myInput').parentNode.appendChild(a);
      }

      /*for each item in the array...*/
      for (i = 0; i < arr.length; i++) {
        /*check if the item starts with the same letters as the text field value:*/
        if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
          /*create a DIV element for each matching element:*/
          b = document.createElement("DIV");
          /*make the matching letters bold:*/
          b.innerHTML = "<b>" + arr[i].substr(0, val.length) + "</b>";
          b.innerHTML += arr[i].substr(val.length);
          /*insert a input field that will hold the current array item's value:*/
          b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
          /*execute a function when someone clicks on the item value (DIV element):*/
          b.addEventListener("click", function (e) {
            /*insert the value for the autocomplete text field:*/
            inp.value = this.getElementsByTagName("input")[0].value;
            const submit_postcode = document.getElementById('submit-postcode') as HTMLButtonElement | null;
            if (submit_postcode != null) {
              submit_postcode.disabled = false;
            }
            if (this.isMobile){
              $('#myInputMobile').removeClass('error-postcode');
            } else {
              $('#myInput').removeClass('error-postcode'); 
            }
            document.getElementById('msgerror').style.display = 'none';

            /*close the list of autocompleted values,
            (or any other open lists of autocompleted values:*/
            closeAllLists();
          });
          a.appendChild(b);

        } else {
          const btn = document.getElementById('submit-postcode') as HTMLButtonElement | null;
          if (btn != null) {
            btn.disabled = true;
          }
        }

      }
      if ($('#myInputautocomplete-list').is(':empty') || $('#myInputMobileautocomplete-list').is(':empty')) {
        //hneee
        closeAllLists();
        if (this.isMobile){
          $('#myInputMobile').addClass('error-postcode');
        } else {
          $('#myInput').addClass('error-postcode'); 
        }
        document.getElementById('msgerror').style.display = 'block';
      } else {
        if (this.isMobile){
          $('#myInputMobile').removeClass('error-postcode');
        } else {
          $('#myInput').removeClass('error-postcode'); 
        }
        document.getElementById('msgerror').style.display = 'none';
      }
      
    });
    /*execute a function presses a key on the keyboard:*/
    inp.addEventListener("keydown", function (e) {
      var x = document.getElementById(this.id + "autocomplete-list");
      //@ts-ignore
      if (x) x = x.getElementsByTagName("div");
      if (e.keyCode == 40) {
        /*If the arrow DOWN key is pressed,
        increase the currentFocus variable:*/
        currentFocus++;
        /*and and make the current item more visible:*/
        addActive(x);
      } else if (e.keyCode == 38) { //up
        /*If the arrow UP key is pressed,
        decrease the currentFocus variable:*/
        currentFocus--;
        /*and and make the current item more visible:*/
        addActive(x);
      } else if (e.keyCode == 13) {
        /*If the ENTER key is pressed, prevent the form from being submitted,*/
        e.preventDefault();
        if (currentFocus > -1) {
          /*and simulate a click on the "active" item:*/
          if (x) x[currentFocus].click();
        }
      }
    2541});
    function addActive(x) {
      /*a function to classify an item as "active":*/
      if (!x) return false;
      /*start by removing the "active" class on all items:*/
      removeActive(x);
      if (currentFocus >= x.length) currentFocus = 0;
      if (currentFocus < 0) currentFocus = (x.length - 1);
      /*add class "autocomplete-active":*/
      x[currentFocus].classList.add("autocomplete-active");
    }
    function removeActive(x) {
      /*a function to remove the "active" class from all autocomplete items:*/
      for (var i = 0; i < x.length; i++) {
        x[i].classList.remove("autocomplete-active");
      }
    }
    function closeAllLists(elmnt?) {
      /*close all autocomplete lists in the document,
      except the one passed as an argument:*/
      var x = document.getElementsByClassName("autocomplete-items");
      for (var i = 0; i < x.length; i++) {
        if (elmnt != x[i] && elmnt != inp) {
          x[i].parentNode.removeChild(x[i]);
        }
      }
    }
    /*execute a function when someone clicks in the document:*/
    document.addEventListener("click", function (e) {
      closeAllLists(e.target);
    });
  }
  
  openCity(evt, cityName) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";
  }
  submitPostcode() {
    const myInput = document.getElementById('myInput') as HTMLInputElement | null;
    let url = 'https://app.blancliving.co/booking/provision/' + myInput.value + "/external";
    window.location.replace(url);
    myInput.value = "";

  }
  submitStore() {
    window.location.href = 'https://blancliving.co/blogs/stores';
  }

  stoppedTyping() {
    const myInput = document.getElementById('myInput') as HTMLInputElement | null;
    if (myInput.value.length === 0 || myInput.value.length === 1) {
      document.getElementById('msgerror').style.display = 'none';
      const btn = document.getElementById('submit-postcode') as HTMLButtonElement | null;
      if (btn != null) {
        btn.disabled = true;
      }
      if (this.isMobile){
        $('#myInputMobile').removeClass('error-postcode');
      } else {
        $('#myInput').removeClass('error-postcode');
      }
    }
  }
}  
