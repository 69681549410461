import { Address } from './../../models/obsolete/address';
import { PersonalInfo } from 'src/app/models/user/personal-info.model';
import { AccountSettingService } from 'src/app/services/user/account-setting.service';
import { Injectable, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Router, NavigationExtras } from '@angular/router';
import { StorageService } from '../common/storage.service';
import { IAuthService } from './contracts/IAuthService';
import { User, UserBlanc } from '../../models/user/user.model';
import { AuthStatus } from 'src/app/models/user/auth-status.model';
import { ServerlessService } from 'src/app/services/common/serverless.service';
import { SignInRequest } from '../../models/user/sign-in-request.model';
import { SignUpRequest } from '../../models/user/sign-up-request.model';
import { CookieService } from '../common/cookie.service';
import { AccountService } from './account.service';
import { Instructions } from 'src/app/models/booking/instructions.model';
import { CommandService } from '../common/command.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements IAuthService {
  private readonly authTokenName = 'auth_token';

  authToken: BehaviorSubject<string> = new BehaviorSubject(null);
  authenticated: BehaviorSubject<AuthStatus> = new BehaviorSubject({ authenticated: false, message: '' });
  status: AuthStatus;
  user: User;
  private readonly SessionID = 'Session-ID';
  private readonly SessionLogin = 'Session-Login';
  user_blanc: UserBlanc;
  Session: BehaviorSubject<string>;
  instructions: Instructions;

  constructor(
    private http: HttpClient,
    private router: Router,
    private storageService: StorageService,
    private cookieService: CookieService,
    public serverlessService: ServerlessService,
    public accountSettingService: AccountSettingService,
    public accountService: AccountService,
    public commandService: CommandService
  ) { }

  handleAuthCookie() {
    this.authToken = new BehaviorSubject(this.cookieService.getCookie(this.authTokenName));
    this.Session = new BehaviorSubject(this.cookieService.getCookie(this.SessionLogin));
    this.authToken.subscribe(token => {
      if (token) {
        const expireDate = new Date();
        expireDate.setDate(expireDate.getHours() + 1);

        this.cookieService.setCookieForLongPeriod(this.authTokenName, token);
      } else {
        this.cookieService.getCookie(this.authTokenName);
      }
    });

    if (this.authToken.value) {
      this.status = { authenticated: true };
    } else {
      this.status = { authenticated: false, message: 'No token found' };
    }
    this.authenticated = new BehaviorSubject(this.status);
  }

  isLoggedIn(): boolean {
    return this.authenticated.value.authenticated;
  }

  login(request: SignInRequest) {

    const data = {
      username: request.username,
      password: request.password,
      grant_type: 'password',
      iosAppLogin: request.iosAppLogin
    };

    if(request.iosAppLogin !=  undefined && request.iosAppLogin){
      this.storageService.saveObject('iosAppLogin', request.iosAppLogin);
    }

    const formBody = [];

    for (const property in data) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(data[property]);

      formBody.push(encodedKey + '=' + encodedValue);
    }

    const formString = formBody.join('&');

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
    return this.http.post('login', formString, { headers })
      .toPromise()
      .then(res => {
        console.log('res hhhhh',res)
        this.cookieService.removeCookie(this.authTokenName);
        this.cookieService.setCookieForLongPeriod('blanc_session_id', res['blanc_session_id']);
        this.cookieService.setCookieForLongPeriod('encrypted_password', res['encrypted_password']);
        this.cookieService.setCookieForLongPeriod('logged_email', res['logged_email']);

        const token = res['access_token'];
        this.authToken.next(token);

        const status: AuthStatus = {
          authenticated: true
        };
        this.authenticated.next(status);
        this.storageService.saveObject('login_count', res['login_count']);
        this.storageService.saveObject('login_preference', res['login_preference']);
        if (this.accountSettingService.getLoginCount() === 1) {
          sessionStorage.setItem('isLogin', 'true');
        } else {
          sessionStorage.setItem('isLogin', 'false');
        }
        if (this.storageService.getString('Instruction') != null) {
          this.SaveInstruction();
        }
        return status;
      })
      .catch(err => {
        const error = err.error.error_description;
        const status: AuthStatus = {
          authenticated: false,
          message: error
        };
        this.authenticated.next(status);
        return status;
      });
  }

  // SaveAddress() { // to save address in the guest booking
  //   let address = this.storageService.getObject('adresse');
  //   const addressData = {
  //     addressLine1: address.addressLine1,
  //     addressLine2: address.addressLine2,
  //     postcode: address.postcode,
  //   };
  //   this.accountSettingService.up
  // }

  SaveInstruction() {
    this.instructions = this.storageService.getObject('adresse').instructions;
    const data = {
      TypeDelivery: this.instructions.deliverToName,
      CodeCountry: this.instructions.countryCode,
      PhoneNumber: this.instructions.phoneNumber,
      OtherInstruction: this.instructions.other,
    };
    this.accountSettingService.updateDeliveryPreferences(data).then(
      (response) => {
        console.log(response);
      },
      (error) => {
      }
    );
  }

  async signup(request: SignUpRequest) {
    const promise = await this.http.post('register', request).toPromise();
    return promise;
  }

  signout() {
    const logoutApp = this.accountService.LogoutApp();
    const logoutBlanc = this.accountService.LogoutBlanc();

    this.authToken.next(null);
    this.authenticated.next({ authenticated: false });
    this.cookieService.clearCookies();
    this.storageService.clear();
    this.router.navigate(['/welcome']);
  }

  confirmEmail(userId: string, token: string) {
    return this.http.post('account/confirmEmail', {
      userId,
      token
    }).toPromise();
  }

  resendConfirmation(email: string): Promise<any> {
    return this.http.get('account/sendEmailComfirmation?email=' + email).toPromise();
  }

  resetPassword(id: string, token: string, password: string): Promise<any> {
    return this.http.post('resetPassword', {
      id,
      token,
      password,
    }).toPromise();
  }

  forgotPassword(email: string) {
    return this.http.post('forgotPassword', {email}).toPromise();
  }

  takeToSignIn(redirect?: string | NavigationExtras) {
    this.router.navigate(['/sign-in'], this.getRedirectParams(redirect));
  }

  getRedirectParams(redirect?: string | NavigationExtras): NavigationExtras {
    if (!redirect) {
      redirect = this.router.routerState.snapshot.url;
      return { queryParams: { redirect }, queryParamsHandling: 'merge' };
    } else {
      if (typeof (redirect) === 'string') {
        return { queryParams: { redirect }, queryParamsHandling: 'preserve' };
      } else {
        return redirect;
      }
    }
  }

  setPasswordUser(email, password) {
    return this.http.post('registerCustomerApp', {email, password}).toPromise();
  }

  checkEmail(email) {
    return this.http.post('checkEmail', email).toPromise();
  }

  getName(email) {
    const data = {
      EmailAddress : email
    }
    const formBody = [];
    for (const property in data) {

      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }

    const formString = formBody.join('&');

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
    return this.http.post('getName', formString,{headers}).toPromise();
  }


  spotCheckEmail(email) {
    return this.http.post('spotCheckEmail', {EmailAddress: email}).toPromise();
  }
}
