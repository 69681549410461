import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject, Subscription } from 'rxjs';

import { CommonConstants } from './../../constants/common';

@Injectable({
  providedIn: 'root'
})
export class PwaService {

  private swUpdateubscribtion: Subscription;
  private _deferredPrompt: any;
  BehaviordeferredPrompt = new BehaviorSubject<boolean>(false);

  constructor(
    private swUpdate: SwUpdate
  ) { }

  private _update(): void {
    this.swUpdate.activateUpdate().then(() => document.location.reload());
  }

  public setDefferdPrompt(event: any): void {
    this._deferredPrompt = event;
    if(event !== undefined){
      this.BehaviordeferredPrompt.next(true)
    }
    
  }
  public getDefferdPrompt(): any {
    return this._deferredPrompt
  }
  public checkForUpdates(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdateubscribtion = this.swUpdate.available.subscribe(() => this._update());
    }
  }

  public addToHomescreen(): void {
    this._deferredPrompt.prompt();
  }

  public unsubscribeFromUpdates(): void {
    if (this.swUpdateubscribtion) {
      this.swUpdateubscribtion.unsubscribe();
    }
  }

  public isOpenedInApp(): boolean {
    return window.matchMedia(CommonConstants.DISPLAY_STANDALONE).matches;
  }

}
