import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthService } from 'src/app/services/user/auth.service';

@Component({
  selector: 'guest-help-feedback',
  templateUrl: './help-feedback.component.html',
  styleUrls: ['./help-feedback.component.scss']
})
export class HelpFeedbackComponent {
  title: string = 'Help & feedback';
  isDesktop: boolean = false;
  isEmailSend: boolean = false;
  isLoggedUser: boolean;

  constructor(
    protected deviceService: DeviceDetectorService,
    protected authService: AuthService,
  ) {
    this.isLoggedUser = this.authService.isLoggedIn();
    this.isDesktop = this.deviceService.isDesktop();
  }

  changeView(isEmailSend: boolean) {
    this.isEmailSend = isEmailSend;
  }

}
