<form [formGroup]="authorisationsForm" (ngSubmit)="onFormSubmit()" class="animation-fade-in">
    <ul>
      <li class="authorisation-category box">
          <p class="name">Cleaning authorisations</p>

          <!-- <div class="bloc-authorisation">
              <div class="authorisation-wrapper">
                  <span>Minor repairs </span>
                  <mat-slide-toggle formControlName="0"></mat-slide-toggle>
              </div>
            <user-authentications-read-more text="I authorise BLANC to proceed with minor repairs up to £20 (such as fixing small tears, missing buttons or moth holes) that are not mentioned in your instructions."></user-authentications-read-more>
          </div> -->

          <div class="bloc-authorisation">
              <div class="authorisation-wrapper">
                  <span>Cleaning partner</span>
                  <mat-slide-toggle formControlName="0"></mat-slide-toggle>
              </div>
              <user-authentications-read-more text="I authorise BLANC to use a cleaning partner whenever your experts assess that solvent-based cleaning will be better suited to an individual garment (if no is selected, we will send your item back to you unprocessed)."></user-authentications-read-more>
            </div>

          <div class="bloc-authorisation">
              <div class="authorisation-wrapper">
                  <span class="shorter">No care labels</span>
                  <mat-slide-toggle formControlName="1"></mat-slide-toggle>
              </div>
              <user-authentications-read-more text="I authorise BLANC to clean items for which the care label is missing without asking me every single time; I understand the risk and assume full liability."></user-authentications-read-more>
            </div>

      </li> 




  </ul>
    <!-- <ng-container *ngIf="isInBookingProcess">
        <button class="btn primary bottom-fixed with-arrow input" type="submit">Save</button>
    </ng-container>

    <ng-container *ngIf="!isInBookingProcess">
        <button [disabled]="authorisationsForm.pristine" class="btn submit primary small" type="submit">Save</button>
    </ng-container> -->
</form>
