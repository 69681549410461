<!-- <div *ngIf="doShowPopup" class="actions animation-fade-in ios-instruction" [ngClass]="{ 'animation-fade-out': doFadeOut }">
    <p class="title">Download our free BLANC app to access your account easily</p>
    <div class="choice-selector">
        <a class="btn secondary-danger" (click)="closePopup($event)">Cancel</a>
        <a class="btn secondary" (click)="addToHomescreen($event)">Download</a>
    </div>
</div>

<div *ngIf="doShowIosInstruction || _isIos" class="actions animation-fade-in ios-pwa" [ngClass]="{ 'animation-fade-out': doFadeOut }">
    <p>To add this app to the homescreen:</p>
    <p>tap <img class="ios-add-to-home-icon" src="/assets/images/action-icon-ios7.png" /></p>
    <p> then tap <strong>Add to Home Screen</strong></p>
</div>

<div #target></div> -->
<div   [ngClass]="{ 'animation-fade-out': doFadeOut }">
  <div class="close-btn-wrapper">
      <div (click)="close()" class="icon close" id="close-btn"></div>
  </div>
  
  <div class="content">
      <!-- for ios -->
    <div class="ios">
      <h3>Even better on your phone!</h3>
      <div class="text-wrapper">
        <p>Download our free BLANC app to access your account easily.</p>
        <p><img class="ios-add-to-home-icon" src="/assets/images/action-icon-ios7.png" />Tap the Share icon</p>
        <p> <mat-icon>add</mat-icon>  Add to Home Screen</p>
      </div>
    </div>
    <div class="android">
      <h3>Even better as an app!</h3>
      <div class="text-wrapper">
        <p>Download our free BLANC app to access your account easily.</p>
      </div>
      <a (click)="addToHomescreen($event)" class="btn primary" id="redirect-btn">DOWNLOAD</a>
    </div>
  </div>
</div>

 