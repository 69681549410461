import { Component, OnInit } from '@angular/core';
import { Route, AdminService } from '../admin.service';
import { ScheduleService } from 'src/app/services/booking/schedule.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-schedule-manager',
  templateUrl: './schedule-manager.component.html',
  styleUrls: ['./schedule-manager.component.scss']
})
export class ScheduleManagerComponent implements OnInit {

  slots: Map<string, any>;
  schedules: any;
  selectedPostcodeSchedule: SelectedSchedule;
  postcodes: string[];
  routes: Route[];
  days: string[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  selectedPostcode: string;
  selectedPostcodes: string[] = [];

  select: any;

  bulkSchedule: {
    route?: Route
    slots?: Slots
  } = {};

  working = false;
  message: string;
  subscription: any;

  constructor(private scheduleService: ScheduleService,
    private adminService: AdminService) { }

  ngOnInit() {
    this.working = true;
  }

  getDayShort(day: string) {
    return day.substr(0, 3);
  }

  getTimeSlot(day: string, fromTime: string, slotPostcode: string): any {
    if (this.schedules[slotPostcode].timeSlots[day]) {
      let slots = this.schedules[slotPostcode].timeSlots[day];
      for (let slot of slots) {
        if (slot.from === fromTime) {
          return true;
        }
      }
    }
  }

  selectedPostcodeChanged(newValue: MatSelectChange) {
    console.debug('selected postcode changed to: ', newValue);

    if (newValue && newValue.value) {
      this.selectedPostcodeSchedule = this.getScheduleFor(newValue.value);
    }
  }

  flip(value: boolean) {
    console.info(value);
  }

  selectedPostcodesChanged() {
    this.bulkSchedule.slots = this.emptySchedules();
    if (this.selectedPostcodes && this.selectedPostcodes.length > 0) {
      console.debug('selected postcode list now has ' + this.selectedPostcodes.length + ' items');
      for (let postcode of this.selectedPostcodes) {
        let official = this.schedules[postcode];
        for (let day of Object.keys(this.bulkSchedule.slots)) {
          if (official.timeSlots[day]) {
            let slots = official.timeSlots[day];
            for (let slot of slots) {
              if (this.bulkSchedule.slots[day][slot.from]) {
                this.bulkSchedule.slots[day][slot.from].active = true;
              }
            }
          }
        }
      }
      this.bulkSchedule.route = this.getRouteFor(this.schedules[this.selectedPostcodes[0]].routeId);
    }
  }

  bulkSave() {
    this.message = null;
    console.debug(`Saving bulk schedule for ${this.selectedPostcodes}`);
    let apiSchedule = this.transformToApi(this.bulkSchedule.slots);
    let count = this.selectedPostcodes.length;
    for (let postcode of this.selectedPostcodes) {
      this.working = true;
      console.debug(`Saving schedule for ${postcode}`);
      let official = this.schedules[postcode];
      let schedule = {
        postCode: official.postCode,
        area: official.area,
        timeSlots: apiSchedule,
        routeId: this.bulkSchedule.route.routeID
      };

      console.debug("Saving: ", schedule);

      this.adminService.saveSchedule(schedule)
        .then(() => {
          console.debug(`Saved ${schedule.postCode}`)
          if (--count == 0) {
            this.working = false;
            this.ngOnInit();
          };
        }).catch(err => {
          this.message = err.error.message;
          this.working = false;
        });
    }
  }

  getRouteFor(id: string): Route {
    let route;
    if (this.routes && this.routes.length > 0) {
      if (id) {
        route = this.routes.find(r => r.routeID == id);
      }
      if (!route) {
        route = this.routes[0];
      }
    }
    return route;
  }

  getScheduleFor(postcode: string): SelectedSchedule {
    if (this.schedules[postcode]) {
      let official = this.schedules[postcode];
      let schedules = {
        postCode: official.postCode,
        area: official.area,
        slots: this.emptySchedules(),
        route: this.getRouteFor(official.routeId)
      };

      for (let day of Object.keys(schedules.slots)) {
        if (this.schedules[postcode].timeSlots[day]) {
          let slots = this.schedules[postcode].timeSlots[day];

          for (let slot of slots) {
            if (schedules.slots[day][slot.from]) {
              schedules.slots[day][slot.from].active = true;
            }
          }
        }
      }
      return schedules;
    } else {
      console.info(`No schedules for ${postcode} - assuming it's new`)
      return {
        postCode: postcode,
        area: "",
        slots: this.emptySchedules(),
        route: this.getRouteFor(null)
      }
    }
  }

  emptySchedules(): Slots {
    let schedules: Slots = {};
    for (let day of this.days) {
      schedules[day] = {};
      this.slots.forEach((v, k) => {
        schedules[day][v.from] = {
          from: v.from,
          to: v.to,
          friendlyName: v.friendlyName,
          active: false
        }
      })
    }
    return schedules;
  }

  transformToApi(slots: Slots) {
    let timeSlots = {};

    for (let day of this.days) {
      for (let slot of Object.keys(slots[day])) {
        if (slots[day][slot].active == true) {
          if (!timeSlots[day]) {
            timeSlots[day] = [];
          }
          timeSlots[day].push({
            from: slots[day][slot].from,
            to: slots[day][slot].to
          })
        }
      }
    }
    return timeSlots;
  }

  save() {
    this.message = null;
    this.working = true;

    let toSave = {
      postCode: this.selectedPostcodeSchedule.postCode,
      area: this.selectedPostcodeSchedule.area,
      timeSlots: this.transformToApi(this.selectedPostcodeSchedule.slots),
      routeId: this.selectedPostcodeSchedule.route.routeID
    }

    console.debug("Saving: ", toSave);

    this.adminService.saveSchedule(toSave)
      .then(() => {
        this.working = false;
        this.ngOnInit();
      }).catch(err => {
        this.message = err;
        this.working = false;
      });
  }
}

interface SelectedSchedule {
  postCode: string,
  area: string,
  slots: Slots,
  route: Route
}

interface Slots {
  [index: string]: {
    [index: string]: {
      from: string,
      to: string,
      friendlyName: string,
      active: boolean
    }
  }
}
