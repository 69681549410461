import { Component, OnInit, Renderer2 } from '@angular/core';

import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { CommandService } from 'src/app/services/common/command.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'delivery-only-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {

  orderId: string;
  isMobile: boolean;

  constructor(
    public commandService: CommandService,
    public renderer: Renderer2,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    protected deviceService: DeviceDetectorService
  ) {}

  ngOnInit(): void {
    
    this.activatedRoute.queryParams.subscribe(params => this.orderId = params['orderId']);

    this.isMobile = this.deviceService.isMobile();
  }

  getSlotsEventData() {
    this.commandService.executeCommand(CommandEnum.OrderSlotsEventFileReadCommand, this.orderId)
      .then(resp => {
        this.downloadSlotsEventData(resp.commandResult.eventData, "text/calendar", resp.commandResult.fileName);
      })
      .catch(err => this.commandService.handleCommandTokenError(err));
  }

  downloadSlotsEventData(data: any, type: string, name: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    
    const downloadLink = this.renderer.createElement('a');
    this.renderer.setProperty(downloadLink, 'href', url);
    this.renderer.setProperty(downloadLink, 'download', name);
    downloadLink.click();
  }
  ViewCurrentOrders(){
    
    this.router.navigate(['/order']);
  }
}
