import { Component, OnInit, OnDestroy, HostListener, ViewChild, ElementRef } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { PwaService } from './../../services/common/pwa.service';
import { CommonConstants } from './../../constants/common';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'pwa-popup',
  templateUrl: './pwa-popup.component.html',
  styleUrls: ['./pwa-popup.component.scss']
})
export class PwaPopupComponent implements OnInit, OnDestroy {

   _isIos: boolean;
  doShowPopup: boolean;
  doShowIosInstruction: boolean;
  doFadeOut: boolean;
  deferredPrompt: any;

  constructor(
    private _pwaService: PwaService,
    private _deviceDetectorService: DeviceDetectorService,
    public dialogRef: MatDialogRef<PwaPopupComponent>
  ) {
    _pwaService.checkForUpdates();
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e: Event) {
    e.preventDefault();
    this._pwaService.setDefferdPrompt(e);
    this._pwaService.BehaviordeferredPrompt.subscribe(value =>{
      console.log(value)
      if (this._deviceDetectorService.isMobile() && !this._pwaService.isOpenedInApp() && value === true ) {
        this.doShowPopup = true;
      }
    })
   
  }

  ngOnInit() {
    if (this._deviceDetectorService.os === CommonConstants.DEVICES_OS.iOS) {
      this._isIos = true;

      if (!this._pwaService.isOpenedInApp()) {
        this.doShowPopup = true;
      }
    }
  }
  private _isInstalled() {
    // For iOS
    if(window.navigator['standalone']) return true
  
    // For Android
    if(window.matchMedia('(display-mode: standalone)').matches) return true
  
    // If neither is true, it's not installed
    return false
  }

  ngOnDestroy() {
    this._pwaService.unsubscribeFromUpdates();
  }

  private _scrollToBottom(): void {
    setTimeout(() => {
      const height = document.body.scrollHeight;
      window.scroll({
        top: height,
        behavior: 'smooth'
      });
    }, 0);
  }

  private _fadeOut(): void {
    this.doFadeOut = true;

    setTimeout(() => {
      this.doShowPopup = false;
      this.doShowIosInstruction = false;
    }, 290);

  }

  addToHomescreen(event: Event): void {
    event.preventDefault();

    if (this._isIos) {
      this.doShowPopup = false;
      this.doShowIosInstruction = true;

      this._scrollToBottom();

      setTimeout(() => {
        this._fadeOut();
      }, 10000);

      return;
    }

    this._pwaService.addToHomescreen();
  }

  closePopup(event: Event): void {
    event.preventDefault();
    this._fadeOut();
  }
  close() {
    this.dialogRef.close();
  }

}
