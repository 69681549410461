import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { Routes } from 'src/app/constants/routes.constant';
import { TimeSlotTypeEnum } from 'src/app/models/booking/enums/time-slot-type.enum';
import { Slot } from 'src/app/models/booking/slot.model';
import { SlotsByPeriod } from 'src/app/models/booking/slots-by-period.model';
import { TimeSlot } from 'src/app/models/booking/time-slot.model';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { ScheduleService } from 'src/app/services/booking/schedule.service';
import { SlotService } from 'src/app/services/booking/slot.service';
import { BlancCommandService } from 'src/app/services/common/blanc-command.service';
import { CommandService } from 'src/app/services/common/command.service';
import { StorageService } from 'src/app/services/common/storage.service';
import { PostcodeModalComponent } from 'src/app/shared/postcode-modal/postcode-modal.component';
import { Instructions } from '../../models/booking/instructions.model';

@Component({
  selector: 'delivery-only-slot',
  templateUrl: './slot.component.html',
  styleUrls: ['./slot.component.scss']
})
export class SlotComponent implements OnInit, OnDestroy {
  daysToShow = 1;
  addressInstructions: Instructions = new Instructions();
  subscription: Subscription;
  sub: Subscription;
  selectedSlot: TimeSlot;
  offset = 0;
  dates: moment.Moment[] = [];
  dates_delivery: moment.Moment[] = [];
  period_delivery: SlotsByPeriod = { days: [] };
  isMobile: boolean;
  period: SlotsByPeriod = { days: [] };
  postCode: string;
  type: string;
  step: number
  slotType: TimeSlotTypeEnum = TimeSlotTypeEnum.Delivery;
  isDesktop: boolean;
  isSaving = true;
  title = "slot";
  imgPath = "assets/icons/calendar.svg";
  NextBtn : string;

  constructor(public dialog: MatDialog,
    public commandService: CommandService,
    public slotService: SlotService,
    protected schedules: ScheduleService,
    private route: ActivatedRoute,
    protected storageService: StorageService,
    private storage: StorageService,
    private router : Router,
    protected blancCommandService: BlancCommandService,
    private deviceService: DeviceDetectorService
  ) {
    if(!this.storage.getPostcode()){
      this.blancCommandService.executeCommand(CommandEnum.CustomerDetail)
      .then(response => {
        const address = response['ReturnObject']['address'];
        if (address.length !== 0) {
          if (address[0]['postcode'] === '') {
            this.router.navigate([Routes.DELIVERY_ONLY.ADDRESS]);
          } 
        }else {
          this.router.navigate([Routes.DELIVERY_ONLY.ADDRESS]);
        }
      });
    }
    
  }

  ngOnDestroy(): void {
    this.slotService.setDeliverySlot(null);
    this.slotService.setDeliveryFromSlot(null);
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    // this.type = TimeSlotTypeEnum.Delivery;
    this.NextBtn = "sms-delivery-only-next";
     this.isMobile = this.deviceService.isMobile();
     this.step = 2;
    
    this.subscription = this.slotService.deliverySlot.subscribe(async value => { 
      if (value) {
        this.isSaving = false;
        this.subscription.unsubscribe();
      }
    })
  }

  async getslots() {
    var tomorrow = moment().add(1, 'days');
    let commandResponse = await this.schedules.slotsBackword(tomorrow, 20, this.selectedSlot?.date);
    this.offset = 0;
    this.period_delivery = commandResponse;
    this.dates_delivery = [];
    this.period_delivery.days.forEach(element => {
      var date = moment(element.date);
      this.dates_delivery.push(date);
    });
  }

  select(date: string, slot: Slot) {
    if (slot && slot.available) {
      this.slotService.setDeliverySlot(this.selectedSlot);
      this.slotService.setDeliveryFromSlot(null);
    }
  }

  selected(date: string, slot: Slot) {

    var result = (
      this.selectedSlot?.date === date &&
      this.selectedSlot?.from === slot.from &&
      this.selectedSlot?.to === slot.to)
    return result;
  }
  
  submit() {
    this.subscription = this.slotService.deliverySlot.subscribe(async value => {
      if (value) {
        const pickupDate = value.date.substr(5);
        const year = value.date.substr(0, 4);
     
        let parametres = {
            DeliveryDate: pickupDate + '-' + year,
            DeliveryStartTime: value.from,
            DeliveryEndTime: value.to
        }
        this.blancCommandService.executeCommand(CommandEnum.SetDeliveryOnly,parametres)
        .then( resp => {
          this.storageService.removeItem('adresse');
          this.storageService.removeItem('delivery');
          this.storageService.removeItem('pickup');
          this.storageService.removeItem('period_pickup');
          this.storageService.removeItem('period_delivery');
          this.storageService.removeItem('smsMarketing');
          this.router.navigate([Routes.DELIVERY_ONLY.THANK_YOU]);
        })
        .catch(err => console.log(err));
      } else {
        console.log('value',value)
      }
      
    });
  }

}