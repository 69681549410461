import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'user-authorisations',
  templateUrl: './authorisations.component.html',
  styleUrls: ['./authorisations.component.scss'],
})
export class AuthorisationsComponent implements OnInit {
  isMobile: boolean;

  constructor(
    private deviceService: DeviceDetectorService
  ) {}

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
  }
}
