import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { Order } from 'src/app/models/order/order.model';
import { AuthorisationsModalComponent } from '../authorisations-modal/authorisations-modal.component';

@Component({
  selector: 'user-authorisations-banner',
  templateUrl: './authorisations-banner.component.html',
  styleUrls: ['./authorisations-banner.component.scss']
})
export class AuthorisationsBannerComponent implements OnInit {
  hasAwaitingAuthorisations: boolean;

  @Input() pendingOrders: Order[];

  constructor(
    public dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.initHasAwaitingAuthorisations();
    this.openDialog();
  }

  initHasAwaitingAuthorisations() {

    //Todo: handle pending orders;
    // if (this.pendingOrders.some(o => o.status === OrderStatusEnum.AwaitingAuthorisations)) {
    //   this.hasAwaitingAuthorisations = true;
    // }
    this.hasAwaitingAuthorisations = true;
  }

  openDialog() {
    if (this.hasAwaitingAuthorisations) {
      return;
    }

    this.dialog.open(AuthorisationsModalComponent, {
      data: {
        pendingOrders: this.pendingOrders
      }
    });
  }

  redirect() {
    if (this.pendingOrders.length > 1) {
      this.router.navigate(['/order']);
    } else if (this.pendingOrders.length > 0) {
      this.router.navigate(['/order', this.pendingOrders[0].id]);
    }
  }

  getCssClass() {
    if (this.hasAwaitingAuthorisations) {
      return 'authorization-banner awaiting-authorisations';
    } else {
      return 'authorization-banner';
    }
  }
}
