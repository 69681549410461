import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { Routes } from 'src/app/constants/routes.constant';

@Component({
  selector: 'shared-personal-info-modal',
  templateUrl: './personal-info-modal.component.html',
  styleUrls: ['./personal-info-modal.component.scss']
})
export class PersonalInfoModalComponent {

  constructor(
    public dialogRef: MatDialogRef<PersonalInfoModalComponent>,
    private router: Router,
  ) { }

  redirectToPersonalInfo() {
    this.close();

    let navigationExtras: NavigationExtras = {
      queryParams: { 'redirect': Routes.USER.RECURRING },
    };
    
    this.router.navigate([Routes.USER.PERSONAL_INFO], navigationExtras);
  }

  close() {
    this.dialogRef.close();
  }
}
