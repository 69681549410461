import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError, switchMap, map, filter } from 'rxjs/operators';
import { TokenService } from '../services/common/token.service';
import { CookieService } from '../services/common/cookie.service';
import { StorageService } from '../services/common/storage.service';
import { AccountService } from '../services/user/account.service';
import { Router } from '@angular/router';

@Injectable()
export class RequestUrlInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private baseUrl;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private router: Router,
    private tokenService: TokenService,
    private cookieService: CookieService,
    private accountService: AccountService,
    private storageService: StorageService
  ) {
    this.baseUrl = environment.api;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!req.url.startsWith('http')) {
      req = req.clone({
        url: this.baseUrl + req.url,
      });
    }
    if (req.url.includes('refreshToken') || req.url.includes('logout')) {
      return next.handle(req).pipe(
        catchError(error => {
          if (error.status === 497) {
            this.cookieService.clearCookies();
            this.storageService.clear();
            this.router.navigate(['/welcome']);
            return this.accountService.LogoutApp();
          }
        })
      );
    }else {
      return next.handle(req).pipe(catchError(error => {
        if (error instanceof HttpErrorResponse && !req.url.includes('login') && error.status === 401 && !this.isRefreshing) {
          this.isRefreshing = true;
          return this.ifTokenExpired().pipe(
            switchMap((resp) => {
              const blancSessionId = resp['blanc_session_id'];
              if (blancSessionId) {
                this.cookieService.setCookieForLongPeriod('blanc_session_id', resp['blanc_session_id']);
              }
              location.reload();
              return next.handle(this.addTokenHeader(req));
            })
          );
        } else {
          return throwError(error);
        }
      }));
    }
  }

  private addTokenHeader(request: HttpRequest<any>) {
    const sessionId = this.cookieService.getCookie('blanc_session_id');
    return request.clone({
      body: { ...request.body, SessionID: sessionId}
    });
  }

  private ifTokenExpired() {
    this.refreshTokenSubject.subscribe({
      complete: () => {
        this.refreshTokenSubject = new BehaviorSubject<any>(null);
      }
    });
    if (this.refreshTokenSubject.observers.length === 1) {
      this.isRefreshing = false;
      return this.tokenService.refreshToken();
    }
  }
}
