import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

import { OrderRoutingModule } from './order-routing.module';
import { PipesModule } from '../pipes/pipes.module';
import { SharedModule } from '../shared/shared.module';
import { BookingModule } from '../booking/booking.module';
import { OrderComponent } from './order.component';
import { ListComponent } from './list/list.component';
import { ListMobileComponent } from './list/list-mobile/list-mobile.component';
import { ListDesktopComponent } from './list/list-desktop/list-desktop.component';
import { RecurringBannerComponent } from './list/list-desktop/recurring-banner/recurring-banner.component';
import { ListTabsComponent } from './list/list-tabs/list-tabs.component';
import { ListCurrentComponent } from './list/list-current/list-current.component';
import { ListCurrentEmptyComponent } from './list/list-current/list-current-empty/list-current-empty.component';
import { ListPastComponent } from './list/list-past/list-past.component';
import { DetailsComponent } from './details/details.component';
import { DetailsMobileComponent } from './details/details-mobile/details-mobile.component';
import { DetailsDesktopComponent } from './details/details-desktop/details-desktop.component';
import { HeadingComponent } from './details/heading/heading.component';
import { BannerWrapperComponent } from './details/banner-wrapper/banner-wrapper.component';
import { ItemsTableComponent } from './details/items-table/items-table.component';
import { EditComponent } from './edit/edit.component';
import { EditMobileComponent } from './edit/edit-mobile/edit-mobile.component';
import { EditDesktopComponent } from './edit/edit-desktop/edit-desktop.component';
import { RescheduleComponent } from './reschedule/reschedule.component';
import { ScheduledComponent } from './details/banner-wrapper/scheduled/scheduled.component';
import { PendingComponent } from './details/banner-wrapper/pending/pending.component';
import { InfoComponent } from './details/banner-wrapper/info/info.component';
import { PendingModalComponent } from './details/banner-wrapper/pending/pending-modal/pending-modal.component';
import { GpsTrackingComponent } from './gps-tracking/gps-tracking.component';
import { GpsTrackingMobileComponent } from './gps-tracking/gps-tracking-mobile/gps-tracking-mobile.component';
import { GpsTrackingSectionComponent } from './gps-tracking/gps-tracking-section/gps-tracking-section.component';
import { LeafletMapComponent } from './gps-tracking/leaflet-map/leaflet-map.component';
import { GpsTrackingBannerComponent } from './gps-tracking/gps-tracking-banner/gps-tracking-banner.component';
import { EditFinalizeComponent } from './edit/edit-finalize/edit-finalize.component';
import { HeadingPastComponent } from './past/heading/heading.component';
import { PastComponent } from './past/past.component';
import { PastDesktopComponent } from './past/past-desktop/past-desktop.component';
import { PastMobileComponent } from './past/past-mobile/past-mobile.component';
import { NoPriceComponent } from './details/banner-wrapper/no-price/no-price.component';
import { NewAuthorisationComponent } from './new-authorisation/new-authorisation.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AuthorisationPermanentPopupComponent } from './new-authorisation/authorisation-permanent-popup/authorisation-permanent-popup.component';

@NgModule({
  declarations: [
    OrderComponent,
    ListComponent,
    ListMobileComponent,
    ListDesktopComponent,
    RecurringBannerComponent,
    ListTabsComponent,
    ListCurrentComponent,
    ListCurrentEmptyComponent,
    ListPastComponent,
    DetailsComponent,
    DetailsMobileComponent,
    DetailsDesktopComponent,
    HeadingComponent,
    HeadingPastComponent,
    BannerWrapperComponent,
    ItemsTableComponent,
    EditComponent,
    EditMobileComponent,
    EditDesktopComponent,
    RescheduleComponent,
    ScheduledComponent,
    PendingComponent,
    InfoComponent,
    PendingModalComponent,
    GpsTrackingComponent,
    GpsTrackingMobileComponent,
    GpsTrackingSectionComponent,
    LeafletMapComponent,
    GpsTrackingBannerComponent,
    EditFinalizeComponent,
    PastComponent,
    PastDesktopComponent,
    PastMobileComponent,
    NoPriceComponent,
    NewAuthorisationComponent,
    AuthorisationPermanentPopupComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    OrderRoutingModule,
    SharedModule,
    BookingModule,
    MatTabsModule,
    MatRadioModule,
    LeafletModule,
    MatSlideToggleModule,
  ],
  exports: [
    CommonModule,
    OrderComponent,
    OrderRoutingModule,
  ]
})
export class OrderModule { }
