<div  class="content authorisations-mobile animation-fade-in">
    <shared-back-button></shared-back-button>
    <form class="example-form" [formGroup]="formGroup" (ngSubmit)="onFormSubmit(formGroup.value)" ngNativeValidate>
    <div *ngIf ="item_authorisation.Cleaning" class ="type_authorization">
        <!-- <p>{{item_authorisation.ReturnObject[0].ActionCleaning}}</p> -->
    <div class = "content_wrapper">
    <div class="heading">
        <img src="assets/icons/bubbles.svg" alt="blanc back btn">
        <h2>{{authorisationEnum.Cleaning}}</h2>
    </div>
    <div class="content">
       <p>Our in-house experts assessed that better results would be achieved by using a solvent-based cleaning method for the item(s) below.</p>
    </div>
    <div class="note">
       <span>Do you authorise us to send the below item(s) to our solvent dry cleaning partner?</span>
    </div>
    </div>

    <div *ngFor="let item of item_authorisation.Cleaning let i = index" class="authorization-wrapper">
        <div>
          <span class="title">{{item.typeitem}}</span>
          <p class ="description">{{item.brand}} , {{item.Colors}}</p>
        </div>
        <div class="check">
          <span>{{item.Quote  ? '+£ ' : '-'}} {{item.Quote}}</span>
          <mat-slide-toggle formControlName="item" (change)="onChange(i ,$event , item)"></mat-slide-toggle>
        </div>
      </div>
      </div>

      <div *ngIf ="item_authorisation.MinorRepair" class ="type_authorization">
        <div class = "content_wrapper">
        <div class="heading">
            <img src="assets/icons/needle.svg" alt="blanc back btn">
            <h2>{{authorisationEnum.Minor_repair}}</h2>
        </div>
        <div class="content">
           <p>Our in-house experts have identified that minor repairs (such as small tears, missing buttons, hem undone, or moth holes) are required that are not mentioned in your instructions. Please see the repair’s price below.</p>
        </div>
        <div class="note">
           <span>Do you authorise us to proceed with the repair(s) for the below items?</span>
        </div>
        </div>
    
        <div *ngFor="let item of item_authorisation.MinorRepair  let i = index" class="authorization-wrapper">
          <div>
            <span class="title">{{item.typeitem}}</span>
            <p class ="description">{{item.brand}} , {{item.Colors}}</p>
          </div>
          <div class="check">
            <span> {{item.Quote  ? '+£ ' : '-'}} {{item.Quote}} </span>
            <mat-slide-toggle formControlName="item" (change)="onChange(i ,$event , item)"></mat-slide-toggle>
          </div>
        </div>
          </div>

          <div *ngIf ="item_authorisation.Tailoring" class ="type_authorization">
            <div class = "content_wrapper">
            <div class="heading">
                <img src="assets/icons/tailor-authorisation.svg" alt="blanc back btn">
                <h2>{{authorisationEnum.Tailoring}}</h2>
            </div>
            <div class="content">
               <p>Please find below the quote(s) for the tailoring service you requested.
                If you approve the tailoring quote(s), the cost will be added to your invoice (please note that the below quote does not include cleaning). 
                </p>
            </div>
            <div class="note">
               <span>Do you authorise us to proceed with the tailoring request(s)?</span>
            </div>
            </div>
            <div *ngFor="let item of item_authorisation.Tailoring let i = index " class="authorization-wrapper">
              <div>
                <span class="title">{{item.typeitem}}</span>
                <p class ="description">{{item.brand}} , {{item.Colors}}</p>
              </div>
              <div class="check">
                <span> {{item.Quote  ? '+£ ' : '-'}} {{item.Quote}} </span>
                <mat-slide-toggle formControlName="item" (change)="onChange(i ,$event , item)"></mat-slide-toggle>
              </div>
            </div>
              </div>

              
          <div  *ngIf ="item_authorisation.Risk" class ="type_authorization">
            <div class = "content_wrapper">
            <div class="heading">
                <img src="assets/icons/risk-authorisation.svg" alt="blanc back btn">
                <h2>{{authorisationEnum.Risk}}</h2>
            </div>
            <div class="content">
               <p> Our in-house experts assessed that there is a possible risk of damage if we proceed to clean the item(s) below - even with our usual care. This could be because the garment care label is missing, or due to the instability of the fabric or age of the garment. Please note we do not assume any liability for items identified as risky.</p>
            </div>
            <div class="note">
               <span>Do you authorise us to proceed with the below item(s) despite the risk?</span>
            </div>
            </div>
        
            <div *ngFor="let item of item_authorisation.Risk let i = index " class="authorization-wrapper">
              <div>
                <span class="title">{{item.typeitem}}</span>
                <p class ="description">{{item.brand}} , {{item.Colors}}</p>
              </div>
              <div class="check">
                <span> {{item.Quote  ? '+£ ' : '-'}} {{item.Quote}} </span>
                <mat-slide-toggle formControlName="item" (change)="onChange(i ,$event , item)"></mat-slide-toggle>
              </div>
            </div>
              </div>

              <div *ngIf ="item_authorisation.Leather" class ="type_authorization">
                <div class = "content_wrapper">
                <div class="heading">
                    <img src="assets/icons/leather-authorisation.svg" alt="blanc back btn">
                    <h2>{{authorisationEnum.Leather}}</h2>
                </div>
                <div class="content">
                   <p> Some of your items have leather elements that need to be dealt with by our specialist leather partner. Turnaround time is longer for leather items - if you wish to proceed, we will therefore ask you to reschedule your delivery or we will postpone your store collection date. Note that we do not assume any liability for leather items. Please see our partner’s quote below.</p>
                </div>
                <div class="note">
                   <span>Do you authorise us to send the below item(s) to our leather partner?
                </span>
                </div>
                </div>
                <div *ngFor="let item of item_authorisation.Leather let i = index" class="authorization-wrapper">
                  <div>
                    <span class="title">{{item.typeitem}}</span>
                    <p class ="description">{{item.brand}} , {{item.Colors}}</p>
                  </div>
                  <div class="check">
                    <span> {{item.Quote  ? '+£ ' : '-'}} {{item.Quote}} </span>
                    <mat-slide-toggle formControlName="item" (change)="onChange(i ,$event , item)"></mat-slide-toggle>
                  </div>
                </div>
                  </div>
    <button _ngcontent-pls-c214="" type="submit" id="save-slots-btn" class="btn primary bottom-fixed with-arrow">Validate</button>
  </form>
  </div>
  