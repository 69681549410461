import { AuthService } from 'src/app/services/user/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommandService } from 'src/app/services/common/command.service';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { AlertService } from 'src/app/services/common/alert.service';
import { AccountSettingService } from 'src/app/services/user/account-setting.service';
import { UserRoleEnum } from 'src/app/models/user/enums/user-role.enum';
import { StorageService } from 'src/app/services/common/storage.service';
import { MatDialog } from '@angular/material/dialog';
import { PostcodeModalComponent } from '../../postcode-modal/postcode-modal.component';
import { Routes } from 'src/app/constants/routes.constant';
import { PersonalInfoModalComponent } from '../../personal-info-modal/personal-info-modal.component';
import { NewModalComponent } from '../../../user/new-modal/new-modal.component';
import { CookieService } from 'ngx-cookie-service';
import { BlancCommandService } from 'src/app/services/common/blanc-command.service';
import * as moment from 'moment';

@Component({
  selector: 'shared-navigation-main-desktop',
  templateUrl: './navigation-main-desktop.component.html',
  styleUrls: ['./navigation-main-desktop.component.scss'],
})
export class NavigationMainDesktopComponent implements OnInit {
  orderListRoute = Routes.ORDER.LIST;
  isLoading: boolean = true;
  isOnRecurring: boolean;
  isAdmin: boolean;
  greetings: string;
  spinner: boolean = false;
  showBadgeNew: boolean;
 
  menuDeskNewBooking: string = "menu-desk-new-booking";
  menuDeskOrders: string ="menu-desk-orders";
  menuDeskRecurring: string = "menu-desk-recurring";
  menuDeskPersonnalInfo: string = "menu-desk-personnal-info";
  menuDeskPreferences: string = "menu-desk-preferences";
  menuDeskHelpFeedback: string = "menu-desk-help-feedback";

  constructor(
    private commandService: CommandService,
    private accountSettingService: AccountSettingService,
    private blancCommandService: BlancCommandService,
    private storage: StorageService,
    private alertService: AlertService,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    public cookieService: CookieService,
    private auth: AuthService,
 
  ) { }

  async ngOnInit() {
    this.showBadgeNew = this.accountSettingService.getLoginCount() > 3 ? false : true;
    if (this.accountSettingService.getLoginCount() <= 1 && sessionStorage.getItem('isLogin') === 'true') {
      this.spinner = true;
      const dialogRef = this.dialog.open(NewModalComponent, {
        panelClass: 'new-modal-desktop-bl',
      });
      dialogRef.afterClosed().subscribe(() => {
        sessionStorage.setItem('isLogin', 'false');
      });
    }
    this.isOnRecurring = this.router.url === '/recurring';
    this.initUserInfo();
    
  }

  initUserInfo() {
    // const firstName = this.storage.getObject('firstName');
    // if (firstName) {
    //   this.greetings = `Welcome ${firstName},`;
    // } else {
      this.commandService
      .executeCommand(CommandEnum.UserShortDetailsCommand)
      .then((resp) => {
        const user = resp.ReturnObject.infoCustomer;
        this.greetings = `Welcome ${user.FirstName},`;
        this.isAdmin = user?.claims?.role === UserRoleEnum.Admin;
        this.storage.saveObject('firstName', user.FirstName);
        if (resp.ReturnObject.infoCustomer.SignupDateOnline === '2000-01-01') {
          resp.ReturnObject.infoCustomer.SignupDateOnline = moment(new Date()).format('YYYY-MM-DD');
          this.commandService.executeUpdate(CommandEnum.SaveCustomer,resp.ReturnObject)
          .then((resp) => {
          })
        } else {
          console.log(' ')
        }
        
      });
    //}
    this.isLoading = false;
  }

  redirectToBooking() {
    this.blancCommandService.executeCommand(CommandEnum.CustomerDetail)
    .then(response => {
      const address = response['ReturnObject']['address'];
      if (address.length !== 0) {
        if (address[0]['postcode'] === '') {
          this.dialog.open(PostcodeModalComponent);
        } else {
          this.storage.savePostcode(address[0]['postcode']);
          this.router.navigate([Routes.BOOKING_REGULAR.PROVISION]);
        }
      }else {
        this.dialog.open(PostcodeModalComponent);
      }
    });
  }

  redirectToRecurring() {
    this.blancCommandService.executeCommand(CommandEnum.CustomerDetail)
    .then(response => {
      let address = response['ReturnObject']['address'];
      if(address.length !== 0) {
        if (address[0]['postcode'] === '') {
          this.dialog.open(PostcodeModalComponent);
        } else {
          this.isOnRecurring = true;
          this.storage.savePostcode(address[0]['postcode']);
          this.router.navigate([Routes.USER.RECURRING]);
        }
      }else {
        this.dialog.open(PersonalInfoModalComponent);
      }
    });
  }
  cleanState() {
    this.isOnRecurring = false;
  }
}