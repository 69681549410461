<shared-navigation-top [title]="title"></shared-navigation-top>

<div class="content animation-fade-in">
    <h3>We are always happy to hear from you!</h3>

    <ul>
        <li>
            <a href="https://blancliving.co/pages/faq" target="_blanc">
                <div class="media">
                    <img src="assets/icons/question.svg" alt="FAQ">
                </div>
                <span>FAQ</span>
            </a>
        </li>
        <li>
            <a href="https://blancliving.co/pages/terms-conditions" target="_blanc">
                <div class="media">
                    <img src="assets/icons/info.svg" alt="Terms and conditions">
                </div>
                <span>Terms and Conditions</span>
            </a>
        </li>
        <li>
            <a href="https://www.subscribepage.com/blanc-review-store" target="_blanc" class="underlined">
                <div class="media">
                    <img src="assets/icons/rating.svg" alt="Rate us">
                </div>
                <span>Rate us on Google</span>
            </a>
        </li>
        <li>
            <a href="tel:+442080042630">
                <div class="media">
                    <img src="assets/icons/phone-secondary.svg" alt="Phone">
                </div>
                <span>Call +44 (0)20 8004 2630</span>
            </a>
        </li>
    </ul>

    <user-email-us *ngIf="!isEmailSend" (onEmailSend)="changeView($event)" id="emailUsComponent"></user-email-us>
    
    <user-thank-you *ngIf="isEmailSend" id="thankYouComponent" ></user-thank-you>
</div>

<shared-navigation-main-mobile *ngIf="isLoggedUser"></shared-navigation-main-mobile>

