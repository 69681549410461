import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { constants } from 'buffer';
import { Routes } from 'src/app/constants/routes.constant';
import { BookingStepsEnum } from 'src/app/models/booking/enums/booking-steps.enum';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { HttpStatusCode } from 'src/app/models/common/enums/http-status-code';
import { BookingStepsService } from 'src/app/services/booking/booking-steps.service';
import { SlotService } from 'src/app/services/booking/slot.service';
import { CommandService } from 'src/app/services/common/command.service';
import { StorageService } from 'src/app/services/common/storage.service';
import { NotSupportedComponent } from 'src/app/shared/not-supported/not-supported.component';
import { PostCodeService } from 'src/app/services/shared/postCode.service';

@Component({
  selector: 'app-provision',
  templateUrl: './provision.component.html',
  styleUrls: ['./provision.component.scss'],
})
export class ProvisionComponent implements OnInit {
  constructor(
    private router: Router,
    private commandService: CommandService,
    private stepService: BookingStepsService,
    private postCodeService: PostCodeService,
    private slotService: SlotService,
    private storage: StorageService,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
   await this.route.params.subscribe(async (params) => {
      if (params['postcode']) {
        await this.submitPostCode(params['postcode']);
      } else {
        // await this.commandService.initializeOrder();
        this.stepService.setStep(BookingStepsEnum.Pickup);
        this.slotService.setPickupSlotVisibility(true);
        this.slotService.setDeliverySlotVisibility(true);
        this.router.navigate([Routes.BOOKING_REGULAR.SLOTS], {
          replaceUrl: true,
        });
      }
    });
  }

  async submitPostCode(postcode) {
    const res = await this.postCodeService.verifyPostCode(postcode)
      .then(async (res) => {
        console.log(res);
        this.storage.savePostcode(res['postCode']);
        // await this.commandService.initializeOrder();
        this.stepService.setStep(BookingStepsEnum.Pickup);
        this.slotService.setPickupSlotVisibility(true);
        this.slotService.setDeliverySlotVisibility(true);
        this.router.navigate([Routes.BOOKING_REGULAR.SLOTS], {
          replaceUrl: true,
        });
      })
      .catch((err) => {
        if (err.status === HttpStatusCode.BadRequest) {
          this.dialog.open(NotSupportedComponent, {
            minWidth: '100vw',
            maxWidth: '100vw',
            maxHeight: '100vh',
            height: '100%',
            width: '100%',
            data: {
              postcode: postcode,
              isInIframe: true,
            },
          });
        }
      })
      .finally(() => {
        return true;
      });
  }
}
