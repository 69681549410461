import { AccountSettingService } from 'src/app/services/user/account-setting.service';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AddressBase } from '../base/address.base';
import { BookingStepsService } from 'src/app/services/booking/booking-steps.service';
import { StorageService } from 'src/app/services/common/storage.service';
import { CommandService } from 'src/app/services/common/command.service';
import { BlancCommandService } from 'src/app/services/common/blanc-command.service';
import { AuthService } from 'src/app/services/user/auth.service';
import { InstructionsModalComponent } from './instructions-modal/instructions-modal.component';
import { DonateModalComponent } from './donate-modal/donate-modal.component';
import { ParticleService } from 'src/app/services/particle/particle.service';
import { BookingStepsEnum } from 'src/app/models/booking/enums/booking-steps.enum';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { Instructions } from 'src/app/models/booking/instructions.model';
import { AccountService } from 'src/app/services/user/account.service';
import { Routes } from 'src/app/constants/routes.constant';
import { EditEnum } from 'src/app/models/booking/enums/edit.enum';
import { RequestPickup } from 'src/app/models/spot/requestPickup.model';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'booking-address-mobile',
  templateUrl: './address-mobile.component.html',
  styleUrls: ['./../address.component.scss', './address-mobile.component.scss'],
})
export class AddressMobileComponent extends AddressBase implements OnInit {
  addressInstructions: Instructions = new Instructions();
  redirect: string;
  store: string;
  orderMobileEditAddressNext: string
  orderMobileEditAddressEdit: string
  saveInst:string
 
  constructor(
    router: Router,
    route: ActivatedRoute,
    blancCommandService: BlancCommandService,
    commandService: CommandService,
    authService: AuthService,
    storageService: StorageService,
    particleService: ParticleService,
    bookingStepsService: BookingStepsService,
    accountservice: AccountService,
    accountSettingService: AccountSettingService

    // public dialog: MatDialog,
  ) {
    super(
      router,
      route,
      blancCommandService,
      commandService,
      authService,
      storageService,
      particleService,
      bookingStepsService,
      accountservice,
      accountSettingService
    );
  }

  ngOnInit(): void {
    this.store = this.route.snapshot.queryParams.store;
    var first = localStorage.getItem('first-journey');
    let smsMarketing = localStorage.getItem('smsMarketing');
    super.ngOnInit();
    this.redirect = this.redirectUrl.includes('edit/finilize')
      ? this.redirectUrl.slice(0, -1)+EditEnum.AddressInstruction
      : '/booking/address';
    
    if(first){
      if(this.router.url === '/booking/address?redirect=%2Fbooking%2Fsummary'){
        this.orderMobileEditAddressNext="returning-user-booking-redirectToSummary-editAddress-next";
      this.orderMobileEditAddressEdit="returning-user-booking-redirectToSummary-editAddress-edit";
      }else if (this.router.url === '/booking/address'){
        this.orderMobileEditAddressNext="first-journey-booking-Address-next";
        this.orderMobileEditAddressEdit="first-journey-booking-redirectToSummary-editAddress-edit";
      }
     
    }else if( smsMarketing){
      this.orderMobileEditAddressNext ="sms-marketing-booking-redirectToSummary-editAddress-next";
    }else{
      this.orderMobileEditAddressNext="order-mobile-editAddress-next";
      this.orderMobileEditAddressEdit="order-mobile-editAddress-edit";
    }
      
  }

  async savaAddress() {
    // if (this.store) {
       const customerDetails = this.commandService.executeCommand(CommandEnum.CustomerDetail)
      .then( (res) => {
        const add = {
          AddressID: '',
          address1: this.getFormField('addressLine1').value,
          address2: this.getFormField('addressLine2').value,
          postcode: this.getFormField('postalCode').value,
          longitude: '',
          Latitude: '',
          County: '',
          Country: '',
          Town: this.getFormField('town').value.length !== 0 ? this.getFormField('town').value : 'London',
          name: ''
        };
      
        res.ReturnObject.address=[add];
        this.commandService.executeUpdate(CommandEnum.SaveCustomer, res.ReturnObject)
          .then((resp) => {
          this.address.addressLine1 = this.getFormField('addressLine1').value;
          this.address.addressLine2 = this.getFormField('addressLine2').value;
          this.address.postcode = this.getFormField('postalCode').value;
          this.address.town = this.getFormField('town').value;
          this.storageService.saveObject('adresse', this.address); 
          if(res.ReturnObject.infoCustomer.OnAccount == 1){
            this.router.navigate([Routes.BOOKING_REGULAR.SUMMARY]);
          }
          if (this.store) {
            this.router.navigate([Routes.BOOKING_REGULAR.PAYMENT], { queryParams: { store: this.store } });
          }
        });
      });
    // } else {
    //   this.address.addressLine1 = this.getFormField('addressLine1').value;
    //   this.address.addressLine2 = this.getFormField('addressLine2').value;
    //   this.address.postcode = this.getFormField('postalCode').value;
    //   this.address.town = this.getFormField('town').value;
    //   this.storageService.saveObject('adresse', this.address); 
     
    // }
  }
  async savaAddressStore() {
    if (this.store) {
      this.address.addressLine1 = this.getFormField('addressLine1').value;
      this.address.addressLine2 = this.getFormField('addressLine2').value;
      this.address.postcode = this.getFormField('postalCode').value;
      this.address.town = this.getFormField('town').value;
      this.storageService.saveObject('adresse', this.address); 
    }
  }

  skipStep() {
    this.router.navigate([Routes.BOOKING_REGULAR.PAYMENT ],{ queryParams: { store: this.store } });
  }

}
