<div *ngIf="!isMobile">
    <div class="content-bg animation-fade-in"></div>
    <div class="content payment-desktop animation-fade-in center-absolute-element" >

        <div class="wrapper">
            <div class="heading" style="margin-bottom: 30px;">
                <h1>Preferences</h1>
                <p>Please take a moment to let us know about your finish preferences.
                You can return to this later in app.</p>
            </div>
            <form [formGroup]="preferencesForm" class="box animation-fade-in">
                <p class="green">Finish instructions</p>
                <ul>
                    <li class="preference-category">
                        <div class="preference-wrapper">
                            <div class="bloc-preference">
                                <span>Shirts premium finish </span>
                                <p class="shorter" >I want all my shirts to be hand-finished (premium) with a crease on the sleeves.</p>
                                <p class="sous_shorter" > (+£1.30/shirt)</p>
                            </div>
                            <mat-slide-toggle formControlName="0"></mat-slide-toggle>
                        </div>

                        <div class="preference-wrapper">
                            <div class="bloc-preference">
                                <span>Shirts folded </span>
                                <p class="shorter" >I want all my shirts to be delivered folded in a compostable shirt bag.</p>

                                <p class="sous_shorter" >(+£1.20/shirt)</p>
                            </div>
                            <mat-slide-toggle formControlName="1"></mat-slide-toggle>
                        </div>
                        <div class="preference-wrapper">
                            <div class="bloc-preference">
                                <span>Garments folded </span>
                                <p class="shorter"> I want all my items to be delivered folded in a
                                    reusable bag. (+£1.00/ item)
                                </p>
                            </div>
                            <mat-slide-toggle formControlName="2"></mat-slide-toggle>
                        </div>

                        <div class="preference-wrapper">
                            <div class="bloc-preference">
                                <span class="shorter">Debobbling</span>
                                <p class="shorter" >
                                    I want all my coats and jumpers to be debobbled when required, without having to specify it in special instructions.
                                </p>
                                <p class="sous_shorter" > (+£2 to £5/ item)
                                </p>
                            </div>
                            <mat-slide-toggle formControlName="3"></mat-slide-toggle>
                        </div>
                        <div class="preference-wrapper">
                            <div class="bloc-preference">
                                <span>Minor repairs</span>
                                <p class="shorter">I authorise BLANC to proceed with minor repairs up to £8 (such as
                                </p>
                                <p class="sous_shorter">
                                fixing small tears, missing buttons or moth holes) that are not
                                mentioned in my instructions.
                                </p>
                            </div>
                            <mat-slide-toggle formControlName="4"></mat-slide-toggle>
                        </div>

                    </li>

                </ul>

                <button 
                    class="btn primary absolute" type="submit" (click)="onFormSubmit()">
                    <ng-container *ngIf="!isSaving">Complete</ng-container>
                    <shared-spinner *ngIf="isSaving" color="white"></shared-spinner>
                </button>
            </form>
        </div>
    </div>
</div>
<div *ngIf="isMobile">
    <booking-progress-bar-mobile [step]= step></booking-progress-bar-mobile>
    <div class="content animation-fade-in center-absolute-element">
        <shared-back-button></shared-back-button>
        <div class="wrapper">
            <div class="heading" >
                <h1>Your <br> preferences</h1>
                <p>Please take a moment to let us know about your finish preferences.
                You can return to this later in app.</p>
            </div>

            <form [formGroup]="preferencesForm">
                <ul>
                    <li class="preference-category">
                        <div class="preference-wrapper">
                            <div class="bloc-preference">
                                <span>Shirts premium finish </span>
                                <p class="shorter">I want all my shirts to be hand-finished (premium) with a crease on the sleeves. (+£1.30/shirt)</p>
                            </div>
                            <mat-slide-toggle formControlName="0"></mat-slide-toggle>
                        </div>
                
                        <div class="preference-wrapper">
                            <div class="bloc-preference">
                                <span>Shirts folded </span>
                                <p class="shorter">I want all my shirts to be delivered folded in a compostable shirt bag. (+£1.20/shirt)</p>
                            </div>
                            <mat-slide-toggle formControlName="1"></mat-slide-toggle>
                        </div>
                
                        <div class="preference-wrapper">
                            <div class="bloc-preference">
                                <span>Garments folded </span>
                                <p class="shorter"> I want all my items to be delivered folded in a
                                reusable bag. (+£1.00/ item)
                                </p> 
                            </div>
                            <mat-slide-toggle formControlName="2"></mat-slide-toggle>
                        </div>
                        
                        <div class="preference-wrapper">
                            <div class="bloc-preference">
                                <span class="shorter">Debobbling</span>
                                <p class="shorter">I want all my coats and jumpers to be debobbled when required, without having to specify it in special instructions. (+£2 to £5/ item)
                                </p>
                            </div>
                            <mat-slide-toggle formControlName="3"></mat-slide-toggle>
                        </div>
                        <div class="preference-wrapper">
                            <div class="bloc-preference">
                                <span>Minor repairs </span>
                                <p class="shorter">I authorise BLANC to proceed with minor repairs up to £8 (such as
                                fixing small tears, missing buttons or moth holes) that are not
                                mentioned in my instructions.
                                </p>
                            </div>
                            <mat-slide-toggle formControlName="4"></mat-slide-toggle>
                        </div>
                
                    </li>
                
                </ul>
                <button id="save-address-btn"
                    class="btn primary with-arrow bottom-fixed" type="submit" (click)="onFormSubmit()">
                    <ng-container *ngIf="!isSaving">Complete</ng-container>
                    <shared-spinner *ngIf="isSaving" color="white"></shared-spinner>
                </button>
                
            </form>
        </div>
    </div>
</div>