import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PersonalInfoService {
    nameUpdated = new BehaviorSubject<string>('');
    changeName(changedName) {
        this.nameUpdated.next(changedName);
        console.log(this.nameUpdated)
    }
}
