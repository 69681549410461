export class SummaryAddress {
  addressLine1: string;
  addressLine2: string;
  postcode: string;
  town: string;

  constructor(
    addressLine1: string = '',
    addressLine2: string = '',
    postcode: string = '',
    town: string = '',
  ) {
    this.addressLine1 = addressLine1;
    this.addressLine2 = addressLine2;
    this.postcode = postcode;
    this.town = town;
  }
}
