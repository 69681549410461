

     <div  class="close-btn-wrapper">
      <div (click)="closeDialog()" class="icon close" id="close-btn"></div>
    </div>
  <div class="content" *ngIf="!isDone">

    <h3>Reset your password</h3>

    <ng-container *ngIf="!isMissing">
      <div >
      <p>Please enter your new password in the form below</p>

      <form [formGroup]="resetForm" (ngSubmit)="resetPassword()" id="reset-form">
        <div class="field-wrapper password-wrapper">
          <label>Create password</label>
          <mat-form-field floatPlaceholder="never" floatLabel="never" class="full-width">
            <input matInput formControlName="password" autocomplete="new-password" name="new-password" [type]="isPasswordVisible ? 'text' : 'password'">
            <mat-error *ngIf="resetForm.hasError('required')">
              Required field
            </mat-error>
            <mat-error *ngIf="resetForm.hasError('min')">
              Password should have at least 6 symbols
            </mat-error>
          </mat-form-field>
          <p *ngIf="error" class="mat-error">{{error}}</p>
          <a (click)="showPassword()" [className]="isPasswordVisible ? 'shown' : '' "></a>
        </div>

        <ng-container >
          <button [disabled]="!isValid && !isWorking" id="submit-btn" class="btn primary submit small" type="submit">
            <ng-container *ngIf="!isWorking">Reset</ng-container>
            <shared-spinner *ngIf="isWorking" color="white"></shared-spinner>
          </button>
        </ng-container>

      </form>
    </div>
    <div>


    <ng-container *ngIf="isMissing">
      <p *ngIf="error" id="query-params-error" class="mat-error">Missing query params</p>
    </ng-container>
  </div>
</ng-container>
</div>
  <div class="content" *ngIf="isDone">
  <h3>Password saved!</h3>
  <ng-container >
    <p *ngIf="message" class="message">{{message}}</p>
    <a routerLink="/sign-in" (click) = "closeDialog()" id="close-btn"  class="btn secondary submit small">Sign in</a>
  </ng-container>
  </div>

