import { AlertService } from './../../../services/common/alert.service';
import { ActivatedRoute } from '@angular/router';
import { Component, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { BookingStepsService } from 'src/app/services/booking/booking-steps.service';
import { TimeSlot } from 'src/app/models/booking/time-slot.model';
import { BookingStepsEnum } from 'src/app/models/booking/enums/booking-steps.enum';
import { SlotService } from 'src/app/services/booking/slot.service';
import { TimeSlotTypeEnum } from 'src/app/models/booking/enums/time-slot-type.enum';
import { Subscription } from 'rxjs';
import { StorageService } from 'src/app/services/common/storage.service';

@Component({
  selector: 'booking-pick-up',
  templateUrl: './pick-up.component.html',
  styleUrls: ['./../slots.component.scss', './pick-up.component.scss']
})
export class PickUpComponent implements OnDestroy {
  isMobile: boolean;
  dateFormat: string = 'dddd[,] D MMMM';
  pickupSlot: TimeSlot;
  deliverySlot: TimeSlot;
  slotType: TimeSlotTypeEnum = TimeSlotTypeEnum.Pickup;
  stepsEnum: typeof BookingStepsEnum = BookingStepsEnum;
  step: BookingStepsEnum;
  pickupSlotSubscription: Subscription;
  deliverySlotSubscription: Subscription;
  bookingStepSubscription: Subscription;

  constructor(
    private bookingStepsService: BookingStepsService,
    private slotService: SlotService,
    private deviceService: DeviceDetectorService,
    private route: ActivatedRoute,
    protected storageService: StorageService,
    protected alertService: AlertService
  ) {}
  ngOnDestroy(): void {
    this.pickupSlotSubscription.unsubscribe();
    this.bookingStepSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
    this.initSlots();
    this.initStep();
  }

  initSlots() {
    this.pickupSlot = this.slotService.pickupSlot.getValue();
    this.slotService.deliverySlot.subscribe((slot) => {
      this.deliverySlot = slot;
    });
    const response = this.storageService.getObject('order_response');
    if (!this.deliverySlot) {
      if (response && response.DeliveryDate) {
        let delivery_object = {
          date: response.DeliveryDate,
          from: response.DeliveryTimeSlot.split('-')[0].substr(0, 5),
          to: response.DeliveryTimeSlot.split('-')[1].substr(1, 6),
          ignore : true
        }
        this.deliverySlot = delivery_object;
      } else if (this.storageService.getObject('pickup') && !response) {
        this.deliverySlot = this.storageService.getObject('pickup');
      }
    }
    
    this.pickupSlotSubscription = this.slotService.pickupSlot.subscribe(
      (slot) => {
        this.pickupSlot = slot;

        if (this.route.snapshot.queryParams['redirect']) {
          if (
            this.route.snapshot.queryParams['redirect'].includes(
              'edit/finilize'
            ) ||
            this.route.snapshot.queryParams['redirect'].includes('summary')
          ) {
            if (this.deliverySlot && slot ) {
              if (
                (new Date(this.deliverySlot.date).getTime() -
                  new Date(slot.date).getTime()) /
                  (1000 * 3600 * 24) <
                3
              ) {
                this.alertService.showError(
                  'DeliveryDate should be schedule a minimum 3 days after PickupDate.'
                );
                this.slotService.setDeliverySlotVisibility(true);
                this.slotService.setDeliverySlot(null);
                this.bookingStepsService.setStep(BookingStepsEnum.Delivery);
              } else {
               this.slotService.setDeliverySlot(this.deliverySlot);
                if (this.route.snapshot.queryParams['redirect']) {
                  if (
                    this.route.snapshot.queryParams['redirect'].includes('summary')
                  ) {
                    this.bookingStepsService.setStep(BookingStepsEnum.SlotsSummary);
                  }
                }
              }
            }
          }
        } else {
          this.onSelected(slot);
        }
      }
    );
  }

  initStep() {
    this.bookingStepSubscription = this.bookingStepsService.step.subscribe(
      (value) => {
        this.step = value;
      }
    );
  }

  onSelected(slot: TimeSlot) {
    if (slot) {
      this.pickupSlot = slot;
      this.bookingStepsService.setStep(BookingStepsEnum.Delivery);
      window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  edit() {
    this.bookingStepsService.setStep(BookingStepsEnum.Pickup);
  }
}
