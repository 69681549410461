import { Component, OnInit } from '@angular/core';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { AccountSettingService } from 'src/app/services/user/account-setting.service';
import { ShareModalComponent } from './share-modal/share-modal.component';
import { AlertService } from 'src/app/services/common/alert.service';

@Component({
  selector: 'app-refer-friend',
  templateUrl: './refer-friend.component.html',
  styleUrls: ['./refer-friend.component.scss']
})
export class ReferFriendComponent implements OnInit {
  title: string = 'Refer a friend';
  isMobile: boolean;
  link: string = '';

  constructor(
    private accountSettingService: AccountSettingService, 
    private shareService: NgNavigatorShareService, 
    private matDialog: MatDialog,
    private deviceServce: DeviceDetectorService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.isMobile = this.deviceServce.isMobile();
    
    this.accountSettingService
      .getReferralLink()
      .subscribe(link => {
        this.link = link;
      }, error => {
        console.log(error);
    });
  }

  shareLink(): void {
    if (!this.shareService.canShare()) {
      this.openShareModal();
      return;
    }
 
    this.shareService.share({
      title: 'Referral Code',
      text: 'Hey register on BlancLiving with my referral link.',
      url: this.link
    }).then( (response) => {
      console.log(response);
    })
    .catch( (error) => {
      console.log(error);
    });
  }

  openShareModal(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.id = "modal-component";
    dialogConfig.height = "350px";
    dialogConfig.width = "600px";

    dialogConfig.data = {
      link: this.link
    }

    this.matDialog.open(ShareModalComponent, dialogConfig);
  }

  showMessage(): void {
    this.alertService.showMessage("Link copied to clipboard");
  }
}
