import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { PaymentBase } from '../payment.base';
import { CommandService } from 'src/app/services/common/command.service';
import { BlancCommandService } from 'src/app/services/common/blanc-command.service';
import { CardService } from 'src/app/services/user/card.service';
import { AlertService } from 'src/app/services/common/alert.service';
import { BookingStepsService } from 'src/app/services/booking/booking-steps.service';
import { Routes } from 'src/app/constants/routes.constant';

@Component({
  selector: 'booking-payment-desktop',
  templateUrl: './payment-desktop.component.html',
  styleUrls: ['./../payment.component.scss', './payment-desktop.component.scss']
})
export class PaymentDesktopComponent extends PaymentBase implements OnInit {
  Proceed: string;
  store:string;
  paymentStripe:string;

  constructor(
    router: Router,
    location: Location,
    route: ActivatedRoute,
    cardService: CardService,
    bookingStepsService: BookingStepsService,
    commandService: CommandService,
    blancCommandService: BlancCommandService,
    alertService: AlertService,
  ) {
    super(router, location,route, cardService, bookingStepsService, commandService, blancCommandService, alertService);
  }

  async ngOnInit() {
    this.paymentStripe = this.route.snapshot.queryParams.paymentStripe ? 'true' : 'false';
    this.store = this.route.snapshot.queryParams.store;
    await super.ngOnInit();

    var firstName = localStorage.getItem('firstName');
    let smsMarketing = localStorage.getItem('smsMarketing')
    if (smsMarketing){
      this.Proceed="sms-marketing-booking-redirectToSummary-editPayment-proceed";
    }else if (firstName){
      this.Proceed="returning-user-booking-redirectToSummary-editPayment-proceed";
    }else{
      this.Proceed="first-journey-booking-redirectToSummary-editPayment-proceed";
    }

  }
  skipStep() {
    this.router.navigate([Routes.BOOKING_REGULAR.AUTHORISATIONS_STORE],{ queryParams: { store: this.store } });
    // this.router.navigate([Routes.ORDER.LIST ]);
  }
}
