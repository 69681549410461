import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminService, OrderDetail } from '../admin.service';
import { FormGroup, FormControl } from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource, MatTable} from '@angular/material/table';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-order-search',
  templateUrl: './order-search.component.html',
  styleUrls: ['./order-search.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class OrderSearchComponent implements OnInit {

  searchDates = new FormGroup({
    creationDate: new FormControl(new Date()),
    collectionDate: new FormControl(),
    deliveryDate: new FormControl()
  });

  working = false;
  error;

  displayedColumns: string[] = ['id', 'created', 'email', 'name', 'collectionDate', 'collectionTime', 'deliveryDate', 'deliveryTime', 'type', 'status'];
  dataSource: MatTableDataSource<OrderDetail>;
  expandedElement: OrderDetail | null;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  matTable: MatTable<OrderDetail>;

  constructor(private admin: AdminService) { 
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (data: OrderDetail, filter) => {
      return this.objectsValueContains(filter, data.order, data.user, data.address);
    }
    
    this.reset()
  }

  objectValueContains(object: any, filter: string) {
    let find = Object.values(object).find(s => {
      if (s && typeof(s) != 'object') {
        let toTest = s + '';
        return toTest.toLowerCase().includes(filter);
      }
    });
    return find != null;
  }

  objectsValueContains(filter: string, ...objects : any) {
    for (let object of objects) {
      if (this.objectValueContains(object, filter)) {
        return true;
      }
    }
    return false;
  }


  reset() {
    let creationDate = this.searchDates.get('creationDate').value;
    let collectionDate = this.searchDates.get('collectionDate').value;
    let deliveryDate = this.searchDates.get('deliveryDate').value;

    this.admin.getOrders(creationDate, collectionDate, deliveryDate)
              .then(orders => {
                this.dataSource.data = orders;
                if (this.matTable) {
                  console.info("Rendering");
                  this.matTable.renderRows();
                }
              })
              .catch(err => this.error = err.error.description)
              .finally(() => this.working = false);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filter(filterValue)
  }

  filter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}