<div class="close-btn-wrapper">
  <div (click)="close()" class="icon close" id="close-btn"></div>
</div>

<div class="content">
    <!-- for ios -->
  <div class="ios">
    <h3>Even better on your phone!</h3>
    <div class="text-wrapper">
      <p>To download this app to your homescreen, tap:</p>
      <p><img class="ios-add-to-home-icon" src="/assets/images/action-icon-ios7.png" />Tap the Share icon</p>
      <p> <mat-icon>add</mat-icon>  Add to Home Screen</p>
    </div>
  </div>
  <!-- for android -->
  <div class="android">
    <h3>Even better on your phone!</h3>
    <div class="text-wrapper">
      <p>Download our free BLANC app to access your account easily.</p>
    </div>
    <a (click)="addToHomescreen($event)" class="btn primary" id="redirect-btn">DOWNLOAD</a>
  </div>
</div>
