<div class="modal-content-wrapper">
    <div class="close-btn-wrapper">
      <div (click)="close()" class="icon close" id="close-btn"></div>
    </div>
    <header class="modal-header">
        <h1 class="modal-title">Are you sure?</h1>
    </header>
    <section class="modal-body">
        <p>You are about to lose your changes, please save or cancel.</p>
    </section>
    <footer class="modal-footer">
        <a class="btn secondary-danger" (click)="close()">Discard changes</a>
        <a class="btn secondary" (click)="save()">Save</a>
    </footer>
</div>
