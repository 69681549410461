import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormStatusEnum } from 'src/app/models/common/enums/form-status.enum';
import { CardService } from 'src/app/services/user/card.service';
import { AlertService } from 'src/app/services/common/alert.service';
import { BlancCommandService } from 'src/app/services/common/blanc-command.service';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { Routes } from 'src/app/constants/routes.constant';
import { ActivatedRoute, Router } from '@angular/router';
import { CardStateEnum } from 'src/app/models/user/enums/card-state.enum';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfirmModalService } from 'src/app/services/shared/confirm-modal.service';
import { Subscription } from 'rxjs';
import { CreditCardsToSave } from 'src/app/models/spot/creditCardsToSave.model';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'user-payment-card-add',
  templateUrl: './card-add.component.html',
  styleUrls: ['./card-add.component.scss'],
})
export class CardAddComponent implements OnInit, OnDestroy {
  isMobile: boolean;
  isInBooking: boolean;
  submitButtonText: string;
  btnCssClasses: string;
  cardForm: FormGroup;
  isValid: boolean;
  statusSubscription: Subscription;
  submitSubscription: Subscription;
  store: string;
  customerName: string;
  customCvc: string;
  isStore: boolean = false;
  EditCard : boolean = false;
  paymentStripe:string;
  data = JSON.parse(localStorage.getItem('Edit'));
  bookingPayment : string
  cameFrom : string

  constructor(
    private cardService: CardService,
    private alertService: AlertService,
    private blancCommandService: BlancCommandService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private confirmModalService: ConfirmModalService,
    public dialog: MatDialog,
    public route: ActivatedRoute,

  ) {
    this.paymentStripe = this.route.snapshot.queryParams.paymentStripe?'true':'false;'
   
    if(this.data?.edit ){
      this.EditCard = true;
    };
    console.log(this.EditCard);
    this.isMobile = this.deviceService.isMobile();
    this.cardForm = new FormGroup({
      holder: new FormControl('', Validators.required),
      number: new FormControl('', [
        Validators.required,
        Validators.pattern('^ *(?:[0-9] *){14,16}$'),
      ]),
      month: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.min(1),
          Validators.max(12),
        ])
      ),
      year: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.min(20),
          Validators.max(99),
        ])
      ),
      cvc: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(3),
        ])
      ),
    });
    
  }

  ngOnInit(): void {
    let verify = localStorage.getItem('first-journey');
    this.store = this.route.snapshot.queryParams.store;
    this.statusSubscription = this.cardForm.statusChanges.subscribe(
      (status) => {
        this.isValid = status === FormStatusEnum.Valid && this.cardForm.dirty;
        this.confirmModalService.setShowModal(this.isValid);
      }
    );

    this.submitSubscription = this.confirmModalService.$submitForm.subscribe(
      (value) => {
        if (value) {
          this.onFormSubmit();
        }
      }
    );

    if (this.router.url.indexOf('/booking') > -1) {
      this.isInBooking = true;
    }

    this.initSubmitBtnDifferences();
    if (verify){
      if(this.router.url.includes('/booking/payment')){
        this.bookingPayment = "first-journey-booking-payment-addCard";
      }
    }else{
      this.bookingPayment = "returning-user-booking-payment-addCard";
    }
  }

  ngOnDestroy(): void {
    this.statusSubscription.unsubscribe();
    this.submitSubscription.unsubscribe();
  }

  getFormField(fieldName: string) {
    const result = this.cardForm.get(fieldName.toString());
    return result;
  }

  formatNumber = (n) => ('0' + n).slice(-2);

  async onFormSubmit(): Promise<void> {
    
    this.cardService.setState(CardStateEnum.Default);
    this.cardService.setState(CardStateEnum.InProcess);

    const cardRequest = new CreditCardsToSave();
   
    cardRequest.Number = this.getFormField('number').value.replace(/\s/g, '');
    cardRequest.Expiration =
      this.formatNumber(this.getFormField('month').value) +
      '/' +
      this.formatNumber(this.getFormField('year').value);
    
    this.customCvc = this.getFormField('cvc').value;
    this.customerName = this.getFormField('holder').value;

    
    if(this.router.url.includes("/booking/payment")){
      this.cameFrom = "booking";
    }else if(this.router.url.includes("personal-info")){
      this.cameFrom = "personal-info";
    }else{
      this.cameFrom = "payments";
    }
    let Parameters= {
      "StripeTest":false,
      "CardNum":cardRequest.Number,
      "CardExp":cardRequest.Expiration,
      "CardHolderName":this.customerName,
      "CardCVC": this.customCvc,
      "CameFrom":this.cameFrom
    }
    console.log("cameFrom = ",this.cameFrom)
    /* add card to blanc api */
    
    await this.blancCommandService.executeCommand(CommandEnum.AddCustomerCard, Parameters)
    .then( response => { 
      console.log('response',response)
        this.confirmModalService.setShowModal(false);
        /* navigate to summary page */
        if (
          this.router.url == Routes.BOOKING_REGULAR.PAYMENT ||
          this.router.url == '/booking/payment?paymentStripe=add'
          ) {
          this.router.navigate([Routes.BOOKING_REGULAR.SUMMARY]);
        } else {
          if(this.router.url == '/deliveryonly/payment?paymentStripe=add'){
            this.router.navigate([Routes.DELIVERY_ONLY.SLOT]);
          }else {
            location.reload();
            this.cardService.setState(CardStateEnum.ViewCard);
          }
          
        } 
      
      if(response['stripe_error']){
        this.alertService.showError(response['stripe_error'].message);
        location.reload();
      }
      if(response['ReturnObject'] &&  response['ReturnObject'].result.status != 'succeeded'){
        window.location.href = response['ReturnObject'].result.next_action.redirect_to_url.url;
      }
    }).catch(err => {
      this.alertService.showError('Invalid card number');
      this.confirmModalService.setShowModal(false);
      this.cardService.setState(CardStateEnum.AddCard);
    });  
 
  }

  initSubmitBtnDifferences() {
    if (this.isInBooking && this.isMobile) {
      this.submitButtonText = 'Save & Continue';
      this.btnCssClasses = 'primary bottom-fixed with-arrow btn-resize';
    } else if (this.isInBooking && !this.isMobile) {
      this.submitButtonText = 'Save & Continue';
      this.btnCssClasses = 'primary absolute';
    } else if (!this.isInBooking && this.isMobile) {
      this.submitButtonText = 'Save';
      this.btnCssClasses = 'primary bottom-fixed with-arrow btn-resize btn-edit';
    } else if (!this.isInBooking && !this.isMobile) {
      this.submitButtonText = 'Save';
      this.btnCssClasses = 'primary absolute';
    }
  }

  keytab(e) {
    if (e.srcElement.value.length === 2) {
      e.preventDefault();
      document.getElementById('year').focus();
      return;
    }
  }
  keytabNext(e) {
    if (e.srcElement.value.length === 2) {
      e.preventDefault();
      document.getElementById('cvc').focus();
      return;
    }
  }
}
