export enum OrderStatusEnum {
  Scheduled = 10,
  PickedUp = 11,
  Pending = 12,
  Cancelled = 13,
  InProgress = 14,
  AwaitingDelivery = 15,
  OnItsWay = 16,
  ReadyInStore = 17,
  Delivered = 18,
  Collected = 19,
  DroppedOff = 20,
  RecurringActive = 30,
  RecurringInactive = 31,
}