import { Component, OnInit, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { CommandService } from 'src/app/services/common/command.service';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { BookingBase } from '../booking.base';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AccountService } from 'src/app/services/user/account.service';
import { StorageService } from 'src/app/services/common/storage.service';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent extends BookingBase implements OnInit {

  orderId: string;
  iosAppLogin: boolean;


  constructor(
    private accountService: AccountService,
    private router: Router,
    private commandService: CommandService,
    public renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private storageService: StorageService,
    protected deviceService: DeviceDetectorService
  ) {
    super(deviceService);
    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger === 'popstate') {
          this.router.navigate(['/order']);
        }
      }
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => this.orderId = params['orderId']);
    this.iosAppLogin = this.storageService.getObject('iosAppLogin')?true:false;
  }

  getSlotsEventData() {
    return this.accountService.addToCalendar(this.orderId)
      .then((resp: any) => {
        this.downloadSlotsEventData(resp.pickUp, 'pickup.ics');
        this.downloadSlotsEventData(resp.delivery, 'delivery.ics');
      })
      .catch(err => this.commandService.handleCommandTokenError(err));
  }

  downloadSlotsEventData(data: any, name: string) {
    const downloadLink = this.renderer.createElement('a');
    this.renderer.setProperty(downloadLink, 'href', data);
    this.renderer.setProperty(downloadLink, 'download', name);
    downloadLink.click();
  }
  ViewCurrentOrders(){
    this.router.navigate(['/order']);
  }

}
