<div class="country-code-picker">
    <form [formGroup]="countryCodeForm">
        <label>Country code</label>
        <mat-form-field class="country-code" >
            <mat-select formControlName="countryCodeField" disableOptionCentering (selectionChange)="onSelect($event)" [value]="countryCode">
                <mat-select-trigger *ngIf="countryCodeField.value !== ''">
                    <span class="selected-code">{{countryCodeField.value}}</span>
                </mat-select-trigger>
    
                <mat-select-trigger *ngIf="countryCodeField.value === ''">
                    <span class="selected-code">{{countryCode}}</span>
                </mat-select-trigger>
    
                <mat-option *ngIf="countryCode" [value]="countryCode" id="defaultOption">
                    {{countryCode}}
                </mat-option>
                
                <mat-option *ngFor="let option of countryCodeOptions; let i = index" [value]="option.value">
                  {{option.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</div>
