import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Routes } from 'src/app/constants/routes.constant';

@Component({
  selector: 'order-gps-tracking-banner',
  templateUrl: './gps-tracking-banner.component.html',
  styleUrls: ['./gps-tracking-banner.component.scss']
})
export class GpsTrackingBannerComponent implements OnInit {
  gpsTranckingRoute: string;

  constructor(protected route: ActivatedRoute) { }

  ngOnInit(): void {
    let orderId = this.route.snapshot.paramMap.get('id');
    this.gpsTranckingRoute = Routes.ORDER.GRP_TRACKING + orderId;
  }
}
