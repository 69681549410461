<form class="email-us" [formGroup]="emailForm" (ngSubmit)="onFormSubmit()">
    <header>
        <p>Email us</p>
        <span>Not 100% happy about your experience? Any suggestions to help us improve?</span>
    </header>

    <div class="fields">
        <div class="field-wrapper custom-input-wrapper">
            <label>Name</label>
            <input matInput type="text" formControlName="name">
            <div class="error" *ngIf="getFieldFromForm('name').hasError('required') && emailForm.controls.name.touched">
                Field is required
            </div>
            <div class="error" *ngIf="getFieldFromForm('name').hasError('maxlength') && emailForm.controls.name.touched">
                Name can't be more than 50 symbols
            </div>
        </div>

        <div class="field-wrapper custom-input-wrapper">
            <label>Email</label>
            <input matInput type="text" formControlName="email">
            <div class="error" *ngIf="getFieldFromForm('email').hasError('email') && emailForm.controls.email.touched">
                Please enter a valid email address
            </div>
            <div class="error" *ngIf="getFieldFromForm('email').hasError('required') && emailForm.controls.email.touched">
                Field is required
            </div>
        </div>

        <div class="field-wrapper custom-input-wrapper">
            <label>Phone number</label>
            <input matInput type="tel" formControlName="phone">
            <div class="error" *ngIf="getFieldFromForm('phone').hasError('required') && emailForm.controls.phone.touched">
                Field is required
            </div>
            <div class="error" *ngIf="getFieldFromForm('phone').hasError('pattern') && emailForm.controls.phone.touched">
                Phone number is invalid
            </div>
        </div>

        <div class="field-wrapper message custom-input-wrapper">
            <label class="textarea-label">YOUR MESSAGE</label>
            <textarea maxlength="1000" cdkTextareaAutosize formControlName="message"></textarea>
            <div class="counter">
                <p>{{emailForm.controls.message.value.length}} / 1000</p>
            </div>
            <div class="error" *ngIf="getFieldFromForm('message').hasError('required') && getFieldFromForm('message').touched">
                Message is required
            </div>
        </div>
    </div>

    <button type="submit" class="btn primary left-rounded" [disabled]="!emailForm.valid || formSubmitted">Send</button>
</form>