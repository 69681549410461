import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'user-percentage-banner',
  templateUrl: './percentage-banner.component.html',
  styleUrls: ['./percentage-banner.component.scss']
})
export class PercentageBannerComponent implements OnInit {
 
  @Input() percentage: number;
  
  constructor() {
  }

  ngOnInit(): void {
  }
}
