import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AccountService } from 'src/app/services/user/account.service';
import { PhoneNumber } from 'src/app/models/user/phone-number.model';
import { AlertService } from 'src/app/services/common/alert.service';

@Component({
  selector: 'user-share-my-account',
  templateUrl: './share-my-account.component.html',
  styleUrls: ['./share-my-account.component.scss'],
})
export class ShareMyAccountComponent implements OnInit {
  phonesList: PhoneNumber[] = [];
  valid: boolean;

  phoneForm = new FormGroup({
    countryCode: new FormControl('', [Validators.required, Validators.pattern(`^(\\+\\d{1,4})$`)]),
    phone: new FormControl('', [Validators.required, Validators.pattern('^ *(?:[0-9] *){9,12}$')]),
  });

  constructor(private accountService: AccountService, private alertService: AlertService) {}

  ngOnInit(): void {
    this.phoneForm.statusChanges.subscribe((status) => {
      this.valid = 'VALID' === status;
    });
    this.getSharedAccountsPhones();
  }

  getFieldFromForm(fieldName: string) {
    return this.phoneForm.get(fieldName);
  }

  setCountryCodeValue(value) {
    const countryCodeField = this.getFieldFromForm('countryCode');

    countryCodeField.setValue(value);
  }

  getSharedAccountsPhones() {
    this.accountService.getPhoneList().subscribe((response) => {
      this.phonesList = response;
    });
  }

  onSubmit() {
    if (this.valid) {
      const phoneNumber: PhoneNumber = {
        phoneNumber: this.getFieldFromForm('phone').value,
        countryCode: this.getFieldFromForm('countryCode').value
      };

      this.getFieldFromForm('phone').reset();
      this.getFieldFromForm('phone').setErrors(null);

      if (this.isPhoneAlreadyInList(phoneNumber)) {
        this.alertService.showError('Phone number already added');
        return;
      }

      this.phonesList.push(phoneNumber);
      this.accountService.setLocalPhoneList(this.phonesList);

      this.updateSharedAccounts();
    }
  }

  isPhoneAlreadyInList(phone: PhoneNumber): boolean {
    for (const p of this.phonesList) {
      if (p.countryCode === phone.countryCode && p.phoneNumber === phone.phoneNumber) {
        return true;
      }
    }

    return false;
  }

  updateSharedAccounts() {
    const phoneList = this.accountService.getLocalPhoneList();

    this.accountService.updatePhoneList(phoneList).subscribe(
      (response) => { },
      (error) => { this.alertService.showError('Error sharing account'); }
    );
  }

  removePhone(index) {
    this.phonesList.splice(index, 1);
    this.accountService.setLocalPhoneList(this.phonesList);

    this.updateSharedAccounts();
  }
}
