import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmModalService } from 'src/app/services/shared/confirm-modal.service';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';

@Component({
  selector: 'user-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  isMobile: boolean;
  showModal: boolean;

  constructor(
    private deviceService: DeviceDetectorService,
    private confirmModalService: ConfirmModalService,
    public matDialog: MatDialog,
  ) { }
  
  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();

    this.confirmModalService.$showModal.subscribe((value) => {
      this.showModal = value;
    });
  }

  canDeactivate(): boolean {
    if (this.showModal) {
      this.matDialog.open(ConfirmModalComponent);
      return false;
    }
    return true;
  } 

  ngOnDestroy() {
    this.confirmModalService.setShowModal(false);
    this.confirmModalService.setSubmitForm(false);
  }
}
