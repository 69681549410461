import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-external-usage',
  templateUrl: './external-usage.component.html',
  styleUrls: ['./external-usage.component.scss']
})
export class ExternalUsageComponent implements OnInit {

  isMobile: boolean;

  constructor(protected deviceService: DeviceDetectorService) { }

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();

  }
}
