import { BookingServiceAddBtnStateEnum } from '../booking/enums/booking-service-add-btn-state.enum';
import { ParticleItem } from './particle-item.model';
import { ServiceItem } from './service-item.model';

export class ServiceWrapperModel {
  public id: string;
  public parentItem: ParticleItem = { parsedValue: { css: "default" } } as ParticleItem;
  public items: ServiceItem[] = [];
  public itemsCount: number = 0;
  public bagCount: number = 0;
  public areAddAndSaveBtnVisible: boolean;
  public addItemBtnState: BookingServiceAddBtnStateEnum = BookingServiceAddBtnStateEnum.AddItemBtn;
}