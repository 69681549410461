import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ParticleService } from 'src/app/services/particle/particle.service';
import { MatDialog } from '@angular/material/dialog';

import { environment } from 'src/environments/environment';

import { ParticleItem } from 'src/app/models/particle/particle-item.model';
import { ParticleTypeEnum } from 'src/app/models/particle/enums/particle-type.enum';
import { Guid } from 'guid-typescript';
import { ParticleAdditionalInstruction } from 'src/app/models/particle/additional-instruction.model';
import { ServiceWrapperModel } from 'src/app/models/particle/service-wrapper.model';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { AlertService } from 'src/app/services/common/alert.service';
import { CommandService } from 'src/app/services/common/command.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BookingServiceAddBtnStateEnum } from 'src/app/models/booking/enums/booking-service-add-btn-state.enum';
import { BookingServiceItemStateEnum } from 'src/app/models/booking/enums/booking-service-item-state.enum';
import { StorageService } from 'src/app/services/common/storage.service';
import { GarmentInstruction } from 'src/app/models/booking/garment-instruction.model';

@Component({
  selector: 'booking-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent implements OnInit {
  public router: Router;
  public commandService: CommandService;
  public route: ActivatedRoute;
  public particleService: ParticleService;
  public dialog: MatDialog;
  public alertService: AlertService;
  public storageService: StorageService;
  public deviceDetectorService: DeviceDetectorService;

  public serviceModel: ServiceWrapperModel = new ServiceWrapperModel();

  isMobile: boolean;
  additionalInstructionsMaxLength: number;
  isLoading: boolean = true;
  saveInst : string;
  constructor(
    router: Router,
    route: ActivatedRoute,
    particleService: ParticleService,
    dialog: MatDialog,
    commandService: CommandService,
    alertService: AlertService,
    storageService: StorageService,
    deviceDetectorService: DeviceDetectorService
  ) {
    this.router = router;
    this.route = route;
    this.particleService = particleService;
    this.dialog = dialog;
    this.commandService = commandService;
    this.alertService = alertService;
    this.storageService = storageService;
    this.deviceDetectorService = deviceDetectorService;
  }

  async ngOnInit() {
    this.isMobile = this.deviceDetectorService.isMobile();
    this.additionalInstructionsMaxLength = environment.itemsAdditionalInstructionsMaxLength;

    this.serviceModel.id = this.route.snapshot.queryParams['id'];
    this.fetchParentItem();
    const garmentInstructions = this.storageService.getObject('GarmentInstructionsFull');
    
    if (garmentInstructions !== undefined && garmentInstructions.models !== undefined) {
      const serviceModel = garmentInstructions.models[this.serviceModel.id];
      if (serviceModel) {
        this.serviceModel = serviceModel;
      }
    }
    // For desktop version
    if (this.serviceModel.items.length > 0) {
      this.serviceModel.areAddAndSaveBtnVisible = true;
      this.serviceModel.addItemBtnState = BookingServiceAddBtnStateEnum.AddItemBtn;

      for (const item of this.serviceModel.items) {
        item.selectedState = BookingServiceItemStateEnum.ItemWithEditCard;
        item.selectedInstructionIds = item.instructions.map(i => i.id);
      }
    }
    this.isLoading = false;
    var firstName = localStorage.getItem('firstName');
    let smsMarketing = localStorage.getItem('smsMarketing')
    if(smsMarketing){
      this.saveInst="sms-marketing-booking-redirectToSummary-editGarmentIns-save";
    }
    else if (firstName){
      this.saveInst="returning-user-booking-redirectToSummary-editGarmentIns-save";
    }else{
      this.saveInst="first-journey-booking-redirectToSummary-editGarmentIns-save";
    }
  }

  async fetchParentItem() {
    const result = await this.particleService
      .getParticle(ParticleTypeEnum.Item, Guid.EMPTY)
      .toPromise();
    const particle = result.find((x) => x.id === this.serviceModel.id);
    this.serviceModel.parentItem = new ParticleItem(particle);
  }

  async fetchAdditionalInstructions(item: ParticleItem) {
    const result = await this.particleService
      .getParticle(ParticleTypeEnum.AdditionalInstruction, item.id)
      .toPromise();
    const resultAdditionalInstructions = result.map(
      (r) => new ParticleAdditionalInstruction(r)
    );
    return resultAdditionalInstructions;
  }

  async save() {
    this.serviceModel.itemsCount = this.serviceModel.items.length;
    let GarmentInstructionsFull = this.storageService.getObject('GarmentInstructionsFull');
    if (GarmentInstructionsFull === undefined) {
      GarmentInstructionsFull = {
        models: {}
      };
    }

    GarmentInstructionsFull.models[this.serviceModel.parentItem.id] = this.serviceModel;
    this.storageService.saveObject('GarmentInstructionsFull', GarmentInstructionsFull);
    this.router.navigate(['/booking/services']);
  }
}