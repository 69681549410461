<div class="h-padding v-padding">
  <h1>Holidays</h1>
  <form (ngSubmit)="addHoliday()">
    <ng-container [formGroup]="holidayForm">
      <mat-form-field>
        <mat-label>Holiday Name</mat-label>
        <input matInput formControlName="holidayName">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Holiday Date</mat-label>
        <input matInput [matDatepicker]="holidayDatePicker" formControlName="holidayDate">
        <mat-datepicker-toggle matSuffix [for]="holidayDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #holidayDatePicker></mat-datepicker>
      </mat-form-field>
    </ng-container>

    <button class="button" type="submit">
      <span *ngIf="!working">Add Holiday</span>
      <shared-spinner *ngIf="working" color="white"></shared-spinner>
    </button>
  </form>

  <div *ngIf="error" class="mat-error">{{error}}</div>

  <div class="mat-elevation-z8 v-margin">
    <table mat-table #matTable [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let row"> {{row.description}}</td>
      </ng-container>
      
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
        <td mat-cell *matCellDef="let row"> {{row.date}}</td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
        <td mat-cell *matCellDef="let row" 
            (click)="deleteHoliday(row)" 
            style="color:var(--red) ; cursor: pointer;"> delete </td>
      </ng-container>
      
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

</div>