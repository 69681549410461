import { ParticleItem } from './particle-item.model';
import { Particle } from './particle.model';
import { Brand } from '../booking/brand.model';
import { ParticleAdditionalInstruction } from './additional-instruction.model';
import { FormGroup } from '@angular/forms';
import { BookingServiceItemStateEnum } from '../booking/enums/booking-service-item-state.enum';

export class ServiceItem {
  selected: ParticleItem;
  brand: Brand;
  instructions: Particle[] = [];
  availableInstructionOptions: Particle[] = [];
  selectedInstructionIds: string[] = [];
  additionalInstructions: ParticleAdditionalInstruction[] = [];
  selectedState: BookingServiceItemStateEnum = BookingServiceItemStateEnum.ItemWithBrandCard; 
}