import { OrderTrackingTypeEnum } from 'src/app/models/order/enums/order-tracking-type.enum';
import { AuthService } from './../../../services/user/auth.service';
import { Router } from '@angular/router';
import { AlertService } from './../../../services/common/alert.service';
import { PreferencesBase } from './../preferences.base';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { InstructionsBase } from 'src/app/booking/address/base/instructions.base';
import { Instructions } from 'src/app/models/booking/instructions.model';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { FormStatusEnum } from 'src/app/models/common/enums/form-status.enum';
import { ParticleTypeEnum } from 'src/app/models/particle/enums/particle-type.enum';
import { CommandService } from 'src/app/services/common/command.service';
import { StorageService } from 'src/app/services/common/storage.service';
import { ParticleService } from 'src/app/services/particle/particle.service';
import { AccountSettingService } from 'src/app/services/user/account-setting.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-preference-instruction-modal',
  templateUrl: './preference-instruction-modal.component.html',
  styleUrls: ['./preference-instruction-modal.component.scss']
})
export class PreferenceInstructionModalComponent extends PreferencesBase implements OnInit {
  public instructionsForm: FormGroup;
  public isValidInstruction: boolean;
 //@Output() outputChanged: EventEmitter<any> = new EventEmitter<any>();
  accountSubscription: any;
  working: Promise<boolean>;
  code : string ;
 //deliveryInstruction: Instructions;
  loading: boolean=false;

  constructor(
    accountSettingService: AccountSettingService,
    formBuilder: FormBuilder,
    router: Router,
    public commandService: CommandService,
    authService: AuthService,
    alertService: AlertService,
    public particleService: ParticleService,
    protected storageService: StorageService,
    public deviceService: DeviceDetectorService


    ) {
      super(accountSettingService, formBuilder, router, commandService, authService, alertService, particleService, storageService,deviceService);
    }


  async ngOnInit() {
    this.initDeliverToOptions();
    this.instructionsForm = this.formBuilder.group({
      deliverToId: ["", [Validators.required]],
      countryCode: ["", [ Validators.pattern(`^(\\+\\d{1,4})$`)]],
      phoneNumber: ["", [ Validators.pattern('^ *(?:[0-9] *){9,12}$')]],
      other: ["", [Validators.maxLength(150)]]
    });
    this.instructionsForm.statusChanges.subscribe((status) => {
      this.isValidInstruction = status === FormStatusEnum.Valid;
      // this.outputChanged.emit({ isValidInstruction: this.isValidInstruction });
    });

     this.commandService.executeCommand(CommandEnum.GetDeliveryPreference).then(
       (response: any) => {
        if (response.ReturnObject) {
          if (response.ReturnObject.TypeDelivery.length > 0 || response.ReturnObject.OtherInstruction.length > 0) {
            this.hasInstruction = true;
            this.code = response.ReturnObject.CodeCountry.split('+')[1];
            this.deliveryInstruction = response.ReturnObject;
            this.deliveryInstruction.deliverToName = response.ReturnObject.TypeDelivery;
            this.deliveryInstruction.phoneNumber = response.ReturnObject.PhoneNumber;
            this.deliveryInstruction.other = response.ReturnObject.OtherInstruction;
            this.deliveryInstruction.countryCode = response.ReturnObject.CodeCountry;
            this.deliveryInstruction.deliverToId = response.ReturnObject.TypeDelivery;
            console.log(this.deliveryInstruction.deliverToId)
           // this.deliveryInstruction.deliverToId = this.deliverToOptions.find(option => option.name === this.deliveryInstruction.deliverToName).id;
            this.initForm();
            this.setDeliverToValue();
            this.working = Promise.resolve(true);
            this.confirmInstruction=true;
          } else {
            this.hasInstruction = false;
           this.setDeliverToValue();
            this.working = Promise.resolve(true);
          }
        } else {
            this.setDeliverToValue();
            this.working = Promise.resolve(true);

        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  initForm() {
    this.instructionsForm = new FormGroup({
      deliverToId: new FormControl(this.deliveryInstruction?.deliverToId, [Validators.required]),
      countryCode: new FormControl(this.deliveryInstruction?.countryCode, [ Validators.pattern(`^(\\+\\d{1,4})$`)]),
      phoneNumber: new FormControl(this.deliveryInstruction?.phoneNumber, [ Validators.pattern('^ *(?:[0-9] *){9,12}$')]),
      other: new FormControl(this.deliveryInstruction?.other, [Validators.maxLength(150)]),
    });
         
    this.isValidInstruction = this.instructionsForm.status === FormStatusEnum.Valid;
    this.outputChanged.emit({ isValidInstruction: this.isValidInstruction, hasInstruction: this.hasInstruction });
    this.instructionsForm.statusChanges.subscribe((status) => {
      this.isValidInstruction = status === FormStatusEnum.Valid;
      this.outputChanged.emit({ isValidInstruction: this.isValidInstruction });
    });

  }
  initDeliverToOptions() {
    this.particleService.getParticle(ParticleTypeEnum.DeliverTo).subscribe(
      (response) => {
        this.deliverToOptions = response;
        this.hasAllData = true;
      }, (error) => {
        console.log(error);
      }
    );
  }

  setDeliverToValue() {
    if (this.hasInstruction) {
      this.deliverToOptionSelected = this.deliveryInstruction.deliverToId;
    } else {
      this.deliverToOptionSelected = 'N/A';
    }
    this.getInstructionFormField('deliverToId').setValue(this.deliverToOptionSelected);
  }

  getInstructionFormField(fieldName: string) {
    return this.instructionsForm.get(fieldName.toString());
  }

  setCountryCodeValue(code: string) {
    const countryCodeField = this.getInstructionFormField('countryCode');
    countryCodeField.setValue(code);
    countryCodeField.markAsDirty();
  }
  SaveInstruction() {
    this.hasInstruction=true;
    this.confirmInstruction = true;
    this.deliveryInstruction = this.instructionsForm.value;
    this.deliveryInstruction.otherInstruction = this.getInstructionFormField('other').value;
    this.outputChanged.emit({ confirmInstruction: this.confirmInstruction,clearInstruction: false  });
    this.confirmInstructions();
  }
 async clearInstruction() {
    this.loading=true;
    this.instClear = true;
    this.deliveryInstruction = {
      TypeDelivery: '',
      CodeCountry: '',
      PhoneNumber: '',
      OtherInstruction: ''
    };
    this.storageService.saveObject('dataInstruction', this.deliveryInstruction);

    this.commandService.executeCommand(CommandEnum.SaveDeliveryPreference, this.deliveryInstruction).then(
      (response) => {
        this.ngOnInit();
        this.loading=false;

      },
      (error) => {
        console.log(error);
      }
    );
    this.ngOnInit();
    this.lessons = false;
    this.hasInstruction = false;
    this.instClear = true;
    this.confirmInstruction=true;
    this.outputChanged.emit({ confirmInstruction: true,clearInstruction: true  });


    }
  onAddInstruction() {
    this.lessons = true;
    this.hasInstruction = false;
    this.instClear = false;
  }

  cancelInstruction() {
    if( this.confirmInstruction ){
      this.hasInstruction = true;
      this.lessons = false;
    } else {
      this.hasInstruction = false;
      this.lessons = false;
      this.instClear = true;
    }

    //  this.ngOnInit();
  }

}
