import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

import { CommandService } from 'src/app/services/common/command.service';
import { AlertService } from 'src/app/services/common/alert.service';
import { PastComponent } from '../past.component';

@Component({
  selector: 'order-past-desktop',
  templateUrl: './past-desktop.component.html',
  styleUrls: ['./past-desktop.component.scss']
})
export class PastDesktopComponent extends PastComponent implements OnInit {

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected commandService: CommandService,
    protected alertService: AlertService,
    protected deviceService: DeviceDetectorService,
  ) {
    super(route, router, commandService, alertService, deviceService);
  }

  async ngOnInit() {
    await this.initOrder();
    this.isLoading = false;
  }
}
