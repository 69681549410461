<p *ngIf="isBrandSelected" (click)="deselectBrand()" class="brand-text" id="brand">{{brandInput.value}}</p>

<div *ngIf="!isBrandSelected" #searchInput class="search">
    <label>Brand (optional)</label>
    <mat-form-field floatLabel="never">
        <input [formControl]="brandInput" matInput type="text" placeholder="E.g. Chanel" [matAutocomplete]="auto"
            (input)="search()" (focusout)="selectBrand()">
        <mat-autocomplete id="autocomplete" #auto="matAutocomplete">
            <mat-option *ngFor="let suggestion of suggestions" [value]="suggestion.name" (mouseover)="setBrand($event)">
                {{suggestion.name}}
            </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="brandInput.hasError('maxlength')">
            Brand can't be more than 50 symbols
        </mat-error>
    </mat-form-field>
</div>
