import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'user-personal-info-mobile',
  templateUrl: './personal-info-mobile.component.html',
  styleUrls: ['./../personal-info.component.scss', './personal-info-mobile.component.scss']
})
export class PersonalInfoMobileComponent implements OnInit {
  title: string = 'Personal information';

  constructor() { }

  ngOnInit(): void {
  }
}
