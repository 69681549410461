import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { BookingBase } from '../booking.base';

@Component({
  selector: 'booking-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent extends BookingBase {

  constructor(protected deviceService: DeviceDetectorService) {
    super(deviceService);
  }
}
