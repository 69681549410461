<div  class="close-btn-wrapper">
  <div (click)="closeDialog()" class="icon close" id="close-btn"></div>
</div>

<div *ngIf="!isDone" class="content forgot-password">
  <div class="heading">
    <h3>Password Reset</h3>
    <p>Please enter your email address below and we will send you an email with the password reset link.</p>
  </div>

  <form class="login-form" [formGroup]="resetForm" (ngSubmit)="reset()">  
    <div class="field-wrapper">
      <label>Email</label>
      <mat-form-field floatPlaceholder="never" floatLabel="never" class="full-width">
        <input matInput formControlName="username" autocomplete="username" name="username">
        <mat-error *ngIf="usernameForm.hasError('email') && !usernameForm.hasError('required')">
          Please enter a valid email address
        </mat-error>
        <mat-error *ngIf="usernameForm.hasError('required')">
          Email is required
        </mat-error>
      </mat-form-field>
      <p *ngIf="error" class="mat-error">{{error}}</p>
      <p *ngIf="message" class="message">{{message}}</p>
    </div>

    <ng-container >
      <button [disabled]="!isValid && !isWorking" id="submit-btn" class="btn primary submit small">
        <ng-container *ngIf="!isWorking">Send</ng-container>
        <shared-spinner *ngIf="isWorking" color="white"></shared-spinner>
      </button>
    </ng-container>
    <!-- <ng-container *ngIf="isDone">
      <a id="close-btn" class="btn secondary small" (click)="close()">Close</a>
    </ng-container> -->
  </form>
</div>
<div *ngIf="isDone" class="content forgot-password">
  <div class="heading">
    <h3> Almost there!</h3>
    <span>An email was just sent to you - please click on the reset link to create a new password</span>
  </div>
  </div>
