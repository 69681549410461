export abstract class Routes {

  static readonly USER = {
    LANDING: '/',
    PERSONAL_INFO: '/personal-info',
    CARD_CONFIRMATION:'/card-confirmation',
    CHANGE_PASS: '/change-password',
    AUTHORISATIONS: '/authorisations',
    PREFERENCES: '/preferences',
    PAYMENTS: '/payments',
    REFER: '/refer',
    HELP_FEEDBACK: '/help-feedback',
    RECURRING: '/recurring',
  };

  static readonly GUEST = {
    SIGN: '/welcome',
    SIGN_IN: '/sign-in',
    SIGN_UP: '/sign-up',
    SIGN_IN_UP: '/signinup'
  };

  static readonly ORDER = {
    LIST: '/order',
    DETAILS: '/order/',
    PASTDETAILS: '/order/past/',
    EDIT: '/order/edit/',
    EDITFINALIZE: '/order/edit/finilize/',
    RESCHEDULE: '/order/reschedule/',
    GRP_TRACKING: '/order/gps-tracking/',
    AUTHORISATION: '/order/authorisations/',

  };

  static readonly DELIVERY_ONLY = {
    PROVISION: '/deliveryonly/provision',
    SLOT: '/deliveryonly/slot',
    SUMMARY: '/deliveryonly/summary',
    PAYMENT: '/deliveryonly/payment',
    ADDRESS: '/deliveryonly/address',
    THANK_YOU: '/deliveryonly/thank-you'
  };

  static readonly EXTERNAL_APP = {
    LANDING: 'https://blancliving.co/',
    PRICES: 'https://blancliving.co/pages/dry-cleaning-prices/',
  };

  static readonly BOOKING_REGULAR = {
    ROOT: '/booking/',
    PROVISION: '/booking/provision',
    SLOTS: '/booking/slots',
    ADDRESS: '/booking/address',
    Instructions: '/booking/instructions',
    SERVICES_LANDING: '/booking/services/landing',
    SERVICES: '/booking/services',
    SERVICES_SERVICE: '/booking/services/service',
    PAYMENT: '/booking/payment',
    AUTHORISATIONS: '/booking/authorisation',
    AUTHORISATIONS_STORE: '/booking/authorisation-store',
    SUMMARY: '/booking/summary',
    THANK_YOU: '/booking/thank-you',
  };

}
