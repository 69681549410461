<div class="content">
  <div class="close-btn-wrapper">
    <div (click)="closeModal()" class="icon close" id="close-btn"></div>
  </div>

  <ng-container [ngSwitch]="state">
    <div *ngSwitchCase="stateEnum.Initial" id="state-initial">
      <h3>Save choice for future orders?</h3>
      <p>We can add your choice to your authorisation settings for all </p>
      <p>future orders.</p>
      <div class="actions">
        <a (click)="submit(stateEnum.NotSavedAsDefault)" class="btn secondary-danger" id="do-not-save-btn">No</a>
        <a (click)="submit(stateEnum.SavedAsDefault)" class="btn secondary" id="save-btn">Save as default</a>
      </div>
    </div>

    <div *ngSwitchCase="stateEnum.SavedAsDefault" id="state-saved">
      <h3>Added to your settings!</h3>
      <p>You can manage your </p>
      <p>authorisation in your settings.</p>
      <img src="assets/icons/success.svg" alt="blanc success">
    </div>

    <div *ngSwitchCase="stateEnum.NotSavedAsDefault" id="state-not-saved">
      <h3>Ok, your authorisation settings remain the same.</h3>
      <p>We will ask you each time we </p>
      <p class="mb-10">need your authorisation.</p>
    </div>
  </ng-container>
</div>

