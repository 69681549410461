import { Injectable } from '@angular/core';
import { NavigationEnd } from '@angular/router';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  private static readonly id = 'UA-82887522-1';

  constructor() {
    gtag('config', GoogleAnalyticsService.id);
  }

  public static event(name: string,
                      data: {
                        category: string, 
                        label?: string, 
                        value?: string
                      }) {
    console.debug(`Sending event: ${name} with data: `, data);
    gtag('event', name, {
      'event_category': data.category,
      'event_label': data.label,
      'value': data.value
    });

    
  }

  public static route(navEnd: NavigationEnd) {
    let route = navEnd.urlAfterRedirects.split('?')[0]
    console.debug(`Sending nav event: ${route}`);
    gtag('config', GoogleAnalyticsService.id, { 'page_path': route });
  }
}