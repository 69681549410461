import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { DeliveryOnlyState } from 'src/app/models/delivery-only/delivery-only-state.model';
import { IDeliveryOnlyStateService } from './contracts/IDeliveryOnlyStateService';

@Injectable({
  providedIn: 'root'
})
export class DeliveryOnlyStateService implements IDeliveryOnlyStateService{

  public state = new BehaviorSubject<DeliveryOnlyState>(new DeliveryOnlyState());

  constructor() { }

  setState(state: DeliveryOnlyState) {
    this.state.next(state);
  }

  getState() {
    return this.state.value;
  }
}