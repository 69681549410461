<div class="min-full-height h-padding">
  <h1 class="reset-header bold">Email confirmation</h1>
  <shared-spinner *ngIf="working" color="white"></shared-spinner>
  <div class="card h-padding v-padding" *ngIf="!missing && done">
    <p>Your account is now verified!</p>
    <p>Thank you for confirming your email</p>
  </div>
  <div *ngIf="error" class="h-padding v-margin mat-error" style="white-space: pre-line;">
    {{error}}
  </div>
  <div *ngIf="message" class="h-padding v-margin" style="color: var(--green);">
    {{message}}
  </div>
