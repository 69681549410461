import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },
  {
    path: '',
    loadChildren: () => import('./guest/guest.module').then(m => m.GuestModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'booking',
    loadChildren: () => import('./booking/booking.module').then(m => m.BookingModule)
  },
  {
    path: 'deliveryonly',
    loadChildren: () => import('./delivery-only/delivery-only.module').then(m => m.DeliveryOnlyModule)
  },
  {
    path: 'order',
    loadChildren: () => import('./order/order.module').then(m => m.OrderModule)
  },
  {
    path: 'enter-your-postcode',
    loadChildren: () => import('./external-usage/external-usage.module').then(m => m.ExternalUsageModule)
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full'
  },
   
];

@NgModule({
  imports: [RouterModule.forRoot(routes ,
    // { enableTracing: true } // <-- debugging purposes only
    )],
  exports: [RouterModule],
})
export class AppRoutingModule {}
