import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { PendingModalComponent } from './pending-modal/pending-modal.component';
import { OrderManualStatusEnum } from 'src/app/models/order/enums/order-manual-status.enum';
import { ServiceTypeEnum } from 'src/app/models/order/enums/service-type.enum';
import { Authorisation } from 'src/app/models/user/authorisation.model';
import { AuthorisationGroupEnum } from 'src/app/models/user/enums/authorisation-group.enum';
import { AuthorisationValueEnum } from 'src/app/models/user/enums/authorisation-value.enum';
import { Routes } from 'src/app/constants/routes.constant';

@Component({
  selector: 'order-details-banner-pending',
  templateUrl: './pending.component.html',
  styleUrls: ['./../banner-wrapper.component.scss', './pending.component.scss']
})
export class PendingComponent implements OnInit {
  manualStatusEnum: typeof OrderManualStatusEnum = OrderManualStatusEnum;
  serviceTypeEnum: typeof ServiceTypeEnum = ServiceTypeEnum;
  authorisation: Authorisation;
  orderAuthorisationRoute = Routes.ORDER.AUTHORISATION;
  @Input() order: any;

  constructor(
    public dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit(): void {
    console.log(this.order)
  }

  setAuthorisation(hasAuthorised: boolean) {
    // TODO: Set authorisation for the pending item
    if (this.order.serviceType === this.serviceTypeEnum.Cleaning) {
      this.openDialog(hasAuthorised);    
    }
  }

  openDialog(hasAuthorised: boolean) {
    if (hasAuthorised) {
      this.authorisation = {
        referenceGroup: AuthorisationGroupEnum.SendToCleaningPartner,
        referenceValue: AuthorisationValueEnum.ThirdChoice
      };
    } else {
      this.authorisation = {
        referenceGroup: AuthorisationGroupEnum.SendToCleaningPartner,
        referenceValue: AuthorisationValueEnum.FirstChoice
      };
    }

    this.dialog.open(PendingModalComponent, {
      data: { 
        authorisation: this.authorisation
      }
    });
  }

  goToReschedule() {
    this.router.navigate([Routes.ORDER.RESCHEDULE + this.order.id]);
  }

  calcelItem() {
    throw new Error('Method not implemented.');
  }
}
