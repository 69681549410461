<div *ngIf="!loadingOredrpast" class="spinner-wrapper"><shared-spinner></shared-spinner></div>
<div *ngIf="loadingOredrpast">

  <section *ngIf="!hasPastOrders" class="list-past-empty">
    <p>You don’t have any past orders yet.</p>
  </section>

<table *ngIf="hasPastOrders">
  <tr>
    <th>Date</th>
    <th style="padding-left: 0;">Status</th>
    <th>Order n°</th>
  </tr>

  <tr *ngFor="let order of pastOrders" class="box">
    <a [routerLink]="[pastOrderDetailsRoute, order.id]">
      <td>{{order.created_at}}</td>
    </a>
    <td>
      {{order.Status | titlecase}}
    </td>
    <td>
      <a>
        <td [routerLink]="[pastOrderDetailsRoute, order.id]" >{{order.id}}</td>
      </a>
    </td>
  </tr>
</table>
</div>