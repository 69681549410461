import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserSpot } from 'src/app/models/user/user-spot.model';
import { ServerlessService } from 'src/app/services/common/serverless.service';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-spot-manager',
  templateUrl: './spot-manager.component.html',
  styleUrls: ['./spot-manager.component.scss']
})
export class SpotManagerComponent implements OnInit {

  syncForm = new FormGroup({
    username: new FormControl('', [Validators.email, Validators.required])
  });

  error = null;
  working = false;
  errorV1 = null;
  workingV1 = false;
  user: UserSpot = null;

  constructor(private admin: AdminService,
    public serverlessService: ServerlessService) { }

  get usernameForm() {
    return this.syncForm.get('username');
  }

  ngOnInit(): void {}

  sync() {
    if (this.usernameForm.valid && !this.working) {
      this.working = true;
      this.error = null;
      let email = this.usernameForm.value;
      console.info("Syncing: ", email);
      this.admin.syncUserSpot(email)
                .then(() => {
                  this.admin.getSpotAccount(email)
                            .then(u => this.user = u)
                            .catch(err => this.error = err.error.message)
                            .finally(() => this.working = false)
                })
                .catch(err => {
                  this.error = `Failed to sync: ${email} - Please ensure that the password is updated in spot before requesting sync. Message from server: ${err.error.message}`;
                  this.working = false;
                });
    }
  }

  syncSpotToBlancApi() {
      this.workingV1 = false;
      this.errorV1 = null;
      console.info("Syncing all user");
      const blancSignUpAllUsersResult = this.serverlessService.blancSignUpAllUsersFunctionAsync();
      this.serverlessService.blancSignUpAllUsersFunctionAsync()
      .then((orderFunctionResponse) => {
        console.log(orderFunctionResponse.message);
        this.errorV1 = orderFunctionResponse.message;
        this.workingV1 = orderFunctionResponse.isSuccessful;

      });
      console.log(blancSignUpAllUsersResult);
      this.workingV1 = true;
  }
}