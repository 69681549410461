import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Routes } from 'src/app/constants/routes.constant';
import { FormStatusEnum } from 'src/app/models/common/enums/form-status.enum';
import { AuthorisationTypeEnum } from 'src/app/models/order/enums/order-authorisation-type.enum';
import { MockOrderAuthorisatiuon } from 'src/app/models/order/mocks/mock-order-authorisation.model';
import { MockOrderDetails } from 'src/app/models/order/mocks/mock-order-details.model';
import { AuthorisationPermanentPopupComponent } from './authorisation-permanent-popup/authorisation-permanent-popup.component';

@Component({
  selector: 'app-new-authorisation',
  templateUrl: './new-authorisation.component.html',
  styleUrls: ['./new-authorisation.component.scss']
})
export class NewAuthorisationComponent implements OnInit {
  authorisationEnum: typeof AuthorisationTypeEnum = AuthorisationTypeEnum;
  item_authorisation : any;
  tab_response_authorisation:any[] = [];
  formGroup: FormGroup;
  Timing : number;
  orderId: any;
  ValeuMax: number;

  constructor(formBuilder: FormBuilder , protected router: Router ,  public dialog: MatDialog,) { 
    this.formGroup = formBuilder.group({
      item: new FormControl('', Validators.required),
    });
  }

  ngOnInit(): void {
    this.item_authorisation = MockOrderAuthorisatiuon.ReturnObject;
    console.log(this.item_authorisation)
  }

  onFormSubmit(formValue: any) {
   console.log(formValue)
   this.orderId = this.item_authorisation.id;
  //  MockOrderDetails.status = 12;
  //  MockOrderDetails.statusAsString = "Pending";
   this.router.navigate([Routes.ORDER.DETAILS + this.orderId]);
   if(this.ValeuMax > 5 ){
    this.router.navigate([Routes.ORDER.RESCHEDULE + "2a761efd-d8a7-408c-be9e-be1a0d1b7265"]);
   } else {
    this.router.navigate([Routes.ORDER.DETAILS + "2a761efd-d8a7-408c-be9e-be1a0d1b7265"]);
    this.openInstructionsDialog();
   }
  }

  onChange(index , event , item){
    if (event.checked == true){
      this.tab_response_authorisation.push(item)
    } else {
      this.tab_response_authorisation.splice(this.tab_response_authorisation.indexOf(item),1)
  }
    if(this.tab_response_authorisation.length > 0 )  {
      this.ValeuMax = 0;
      for (let i = 0; i < this.tab_response_authorisation.length ; i++){
        {
          if ( Number(this.tab_response_authorisation[i].Timing) > this.ValeuMax)
              {
                  this.ValeuMax = Number(this.tab_response_authorisation[i].Timing);
              }
      }
    }
    // this.formGroup.statusChanges.subscribe((status) => {
     
        
    //   });
    }  
  
  }

  openInstructionsDialog() {
    this.dialog.open(AuthorisationPermanentPopupComponent);
    

}

}
