import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CommandResponse } from 'src/app/models/common/command-response.model';
import { OrderStatistics } from 'src/app/models/order/order-statistics.model';
import { User } from 'src/app/models/user/user.model';
import { AuthService } from 'src/app/services/user/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ListService {
  public currentorder = new BehaviorSubject<boolean>(false);
  public currentOrdersCount = new BehaviorSubject<number>(0);
  public orders_list = new BehaviorSubject<OrderStatistics>(null);
  endpoint: string;
  user: User;
  constructor(private http: HttpClient, private auth: AuthService) {
    this.endpoint = environment.api;
  }

  setOrder(state: boolean) {
    this.currentorder.next(state);
  }
  // used in past order list
  getState() {
    return this.currentorder;
  }

  setcurrentOrders(count: number): void {
    this.currentOrdersCount.next(count);
  }

  getcurrentOrdersCount() {
    return this.currentOrdersCount;
  }

  setOrders(orders: OrderStatistics) {
    this.orders_list.next(orders);
  }
  getcurrentOrders() {
    return this.orders_list;
  }

  async getStatistics(): Promise<CommandResponse> {
    return this.http
      .get<CommandResponse>(
        this.endpoint + `user/statistics?username=` + this.user.userName
      )
      .toPromise();
  }
}
