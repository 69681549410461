import { Injectable } from '@angular/core';
import { IStorageService } from './contracts/IStorageService';

@Injectable({
  providedIn: 'root'
})
export class StorageService implements IStorageService {

  readonly storage: Storage;

  constructor() {
    this.storage = StorageService.isLocalStorageSupported() ? localStorage : sessionStorage;
  }

  private static isLocalStorageSupported() {
    try {
      const testKey = 'testLocalStorage';
      localStorage.setItem(testKey, testKey);
      localStorage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  }

  saveString(name: string, value: string) {
    this.storage.setItem(name, value);
  }

  getString(name: string) {
    return this.storage.getItem(name);
  }

  saveNumber(name: string, value: number) {
    this.storage.setItem(name, value.toString());
  }

  saveObject(name: string, value: any) {
    this.storage.setItem(name, JSON.stringify(value));
  }

  getObject(name: string) {
    const value = this.getString(name);
    if (value) {
      return JSON.parse(value);
    }
  }

  removeItem(name: string) {
    this.storage.removeItem(name);
  }

  getPostcode() {
    return this.getString('POSTCODE');
  }

  savePostcode(postcode: string) {
    this.saveString('POSTCODE', postcode);
  }

  addVoucher(voucher: string) {
    this.saveObject('voucher', voucher);
  }

  clear() {
    this.storage.clear();
  }
}
